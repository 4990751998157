import AsyncModalItemView from 'views/lib/async_modal_itemview'

export default AsyncModalItemView.extend({
  behaviors: {
    RailsRemoteFormCallbacks: {},
    CustomFieldsManners: {},
  },

  ui: {
    submitButton: 'button[type="submit"]',
    form: 'form.edit-employee-fields',
  },

  asyncItemViewUniquenessKey: 'edit_custom_fields_modal_view',

  onRemoteFormSuccess: function(e, data, status, xhr) {
    App.Util.showAjaxFlashNotice(xhr)
    this.model.setUDFValues(data, 'custom_fields')
    this.hideModal()
  },

  onRemoteFormError: function(e, xhr) {
    if (xhr.getResponseHeader('X-Form-Errors')) {
      this.replaceModalContent(xhr.responseText)
    } else {
      // Reset UI to allow re-submit
      this.ui.submitButton.data('ladda').stop()
    }
  },
})
