// Persist page state and settings. Allows a page to set specific settings (filters, etc) and fetch them when
// loaded again. Also saves to URL for bookmarking/sharing.
//
// Pages may have multiple states they need to save independently. For example, a page may maintain
// 'filters' and 'sort_order'.
//
// This would be represented as an object:
// {
//   sort_order: [1, 'asc'],
//   filters: {
//     groups: [1,2],
//     groupFilterType: 'all',
//     tenure: 'new'
//   }
// }
//
// 'sort_order' and 'filters' are the 'key' used to save and fetch this data
//
// The data will be saved to the browser's Local Storage. This means the data will persist for the user until
// cleared. For example, a page's filtering is stored, the same filters would be applied whenever they visit the page.
//
// The data is also saved to the URL query string. This allows a user to bookmark the page with the desired settings.
// The query string will always override local storage.

// Constructor
//
export default function(){
  this.pageName = (function(){
    return window.location.pathname
  })(),

  // Save Page State
  //
  // key - string, unique identifier for restoring data later
  // data - object, data to save
  //
  // returns - nothing
  this.savePageState = function(key, data) {
    this._saveToLocalStorage(key, data)
    this._saveToQueryString(key, data)
  },

  // Fetch Page using query string from url or Local Storage
  // if query string does not contain key
  //
  // key - string, unique identifier used to save data
  //
  // returns - saved object, or null if not found
  this.fetchPageState = function(key) {
    return this._fetchFromQueryString(key) || this._fetchFromLocalStorage(key)
  },

  // Fetch Page using query string from url ONLY
  //
  // key - string, unique identifier used to save data
  //
  // returns - saved object, or null if not found
  this.fetchFromQueryString = function(key) {
    return this._fetchFromQueryString(key)
  },

  // Save data to the query string
  //
  // key - string, unique identifier data for restoring data later
  // data - object, data to save
  //
  // returns - nothing
  this._saveToQueryString = function(key, data) {
    const query = this._decodeQueryString()
    query[key] = data
    const queryString = `?${decodeURIComponent($.param(query))}`

    const url = window.location.toString()
    const newUrl = url.split('?')[0] + queryString
    if (url != newUrl){ // only replace if different
      window.history.replaceState({}, '', newUrl)
    }
  },

  // Save data to local storage
  //
  // key - string, unique identifier data for restoring data later
  // data - object, data to save
  //
  // returns - nothing
  this._saveToLocalStorage = function(key, data) {
    const pageStore = store.get(this.pageName) || {}
    pageStore[key] = data

    try {
      store.set(this.pageName, pageStore)
    } catch (e) {
      // Browser does not support local storage. Encountered in Safari in private-browsing mode
      store.set = function(){}
    }
  },

  // Return saved data from the query string
  //
  // key - string, unique identifier used to save data
  //
  // returns - saved object, or empty object if not found
  this._fetchFromQueryString = function(key) {
    return this._decodeQueryString()[key]
  },

  // Return saved data from local storage
  //
  // key - string, unique identifier used to save data
  //
  // returns - saved object, or null if not found
  this._fetchFromLocalStorage = function(key) {
    const pageStore = store.get(this.pageName)
    return pageStore ? pageStore[key] : null
  },

  // Decode saved state from the URL query string
  //
  // returns - decoded object
  this._decodeQueryString = function(){
    const query = {}

    const queryString = this._queryString()
    if (!queryString.length) {return query}

    const parameters = _.map(queryString.split('&'), function(parameter){return parameter.split('=')})

    _.each(parameters, function(parameter){
      // Split on [, but keep it (lookahead)
      // filters[groupNames] => ['filters', '[groupNames]']
      const keys = parameter[0].split(/(?=\[)/g)
      const value = parameter[1]
      this._recurseAppend(query, keys, value)
    }.bind(this))
    return query
  },

  // Returns location query string
  this._queryString = function(){
    return window.location.search.slice(1)
  },

  // Recusively load data from the URL query string
  // Handles nested content (object in object, array in object, etc)
  //
  // dest - Object, destination to populate
  // keys - Array of keys indicating the position in the desintation object (e.g. ['filter', ['groupFilterType']])
  //
  // returns - nothing
  this._recurseAppend = function(dest, keys, value){
    if (!value) { return null } // Removes empty params from url

    // Clear [ and ]
    const key = keys[0].replace(/[\[\]]/g, '')

    if (keys.length == 1){ // at the end of the key list. Save the value
      if (value == 'true') {value = true}
      if (value == 'false') {value = false}
      if (parseInt(value).toString() == value) {value = parseInt(value)}

      dest[key] = value
    } else {
      const isArray = !!keys[1].match(/\[[0-9]*\]/) // Does the string contain "[<number>]"
      dest[key] = dest[key] || (isArray ? [] : {}) // Does the key exist already? If not, create it.

      if (isArray){ // This is an array- append the value
        if (parseInt(value).toString() == value) {value = parseInt(value)}
        dest[key].push(value)
      } else {
        this._recurseAppend(dest[key], keys.slice(1), value)
      }
    }
  }

  return this
}
