import { createApp } from 'vue'
import vuexStore from 'init/store'
import EmployeeHelper from 'lib/vue/plugins/employee_helper'
import highlightOnChangeDirective from 'lib/vue/directives/highlight_on_change'
import * as mutate from 'vuex/mutation_types'
import popoverDirective from 'lib/vue/directives/popover'
import SelfDestructOnPageFetch from 'lib/vue/mixins/self_destruct_on_page_fetch'
import tooltipDirective from 'lib/vue/directives/tooltip'
import WbConstants from 'lib/vue/plugins/wb_constants'
import WbLocale from 'lib/vue/plugins/wb_locale'
import WbRoutes from 'lib/vue/plugins/wb_routes'
import WbWait from 'lib/vue/wb_vue_wait'
import { VueMaskDirective } from 'v-mask'
import VueWait from 'vue-wait'

function cleanup (store) {
  store.commit(mutate.CLEAR_PAGE_CONTEXT)
}

function loadInitialData (store, initialData, pageContext) {
  store.initializeModules(store)

  if (initialData) {
    for (const moduleName of Object.keys(initialData)) {
      store.dispatch(`${moduleName}/loadInitialData`, initialData[moduleName])
    }
  }

  if (pageContext) {
    store.commit(mutate.SET_PAGE_CONTEXT_KEYS, pageContext)
  }
}

function registerDirectives (app) {
  app.directive('popover', popoverDirective)
  app.directive('tooltip', tooltipDirective)
  app.directive('highlight-on-change', highlightOnChangeDirective)
  app.directive('mask', VueMaskDirective)
}

function registerPlugins (app, store) {
  const waitInstaller = { ...WbWait, installed: false }
  app.use(store)
  app.use(EmployeeHelper)
  app.use(WbRoutes)
  app.use(WbLocale)
  app.use(WbConstants)
  app.use(waitInstaller)
  app.use(VueWait)
}

// App is built with the expectation that mounting will replace the mountPoint element (Vue 2)
// However, Vue3 preserves the element so we want to replace it
export function mountApp(app, mountPoint) {
  const fragment = document.createDocumentFragment()
  const vNode = app.mount(fragment)

  mountPoint.parentNode.replaceChild(fragment, mountPoint)

  return vNode
}

export function createVueApp(componentDef, {
  initialData,
  pageContext,
  propsData,
  removeElementOnDestroy,
}) {
  const component = {
    ...componentDef,
    mixins: [
      ...(componentDef.mixins || []),
      SelfDestructOnPageFetch
    ],
    unmounted () {
      cleanup(vuexStore)
      if(removeElementOnDestroy) {
        this.$el.remove()
      }
    },
    beforeCreate () {
      loadInitialData(vuexStore, initialData, pageContext)
    },
  }
  
  const app = createApp(
    component,
    propsData
  )

  registerPlugins(app, vuexStore)
  registerDirectives(app)

  return app
}
