<template>
  <div>
    <PageHeader
      title="Edit Permissions Policy"
      variant="bordered"
    />
    <ManagedAccessPolicyTemplateWizard
      ref="wizard"
      :policy="localPolicy"
      :isEditing=true
    />
  </div>
</template>

<script>
  import ManagedAccessPolicyTemplateWizard from "components/admin/managed_access_policy_templates/ManagedAccessPolicyTemplateWizard"
  import ManagedAccessPolicy from "models/policy_generator/managed_access_policy"

  import PageHeader from 'components/common/PageHeader'

  export default {
    name: "the-managed-access-policy-templates-edit-root",

    components: {
      ManagedAccessPolicyTemplateWizard,
      PageHeader,
    },

    props: {
      policy: {
        type: Object,
        required: true,
      },
    },

    computed: {
      localPolicy() {
        return ManagedAccessPolicy.build(this.policy)
      },
    },
  }
</script>
