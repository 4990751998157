<template>
  <div>
    <Spinner v-if="showSpinner" :showLongWaitMessage="true" :longWaitMessageTimeout="5000" />
    <video hidden="hidden" ref="video" />
    <canvas hidden="hidden" ref="canvas" />
    <div class="alert alert-danger mt-4" v-if="showCameraAccessFailed">
      Access to your camera failed.
      <ul>
        <li>
          <strong>
            Option 1:
          </strong>
          Check your settings and reload this page to try again.
        </li>
        <li>
          <strong>
            Option 2:
          </strong>
          Open your camera app and use it to scan the QR code.
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import Spinner from 'components/common/Spinner'
  import jsQR from "jsqr"

  export default {
    name: 'wb-qr-code-reader',

    components: {
      Spinner,
    },

    data(){
      return {
        showSpinner: true,
        showCameraAccessFailed: false,
        foundQrCode: false,
      }
    },

    mounted(){
      this.setupCamera()
    },

    methods: {
      cameraAccessFailed() {
        this.showSpinner = false
        this.showCameraAccessFailed = true
      },

      continueProcessing() {
        return !this.foundQrCode
      },

      handleFoundQrCode(data){
        this.foundQrCode = true
        this.$emit("wbQrCodeFound", data)
        this.shutdownCamera()
      },

      mediaDevicesAvailable(){
        return typeof navigator !== 'undefined' &&
          typeof navigator.mediaDevices !== 'undefined' &&
          typeof navigator.mediaDevices.getUserMedia !== 'undefined'
      },

      // NOTE: this is refactored from https://cozmo.github.io/jsQR/
      setupCamera(){
        const animationCallback = () => this.animationCallback()
        const cameraAccessFailed = () => this.cameraAccessFailed()
        const video = this.$refs.video
        if (this.mediaDevicesAvailable()) {
          // Use facingMode: environment to attempt to get the front camera on phones
          navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } }).then(function(stream) {
            video.srcObject = stream
            video.setAttribute("playsinline", true) // required to tell iOS safari we don't want fullscreen
            
            // Starting in iOS 10, WkWebView only allows hidden videos to play inline when muted.
            // more info: https://webkit.org/blog/6784/new-video-policies-for-ios/
            video.setAttribute("muted", true) 

            video.play()
            requestAnimationFrame(animationCallback)
          }).catch(function() {
            // NOTE: If camera access is denied by the user, err == "DOMException: Permission denied"
            cameraAccessFailed()
          })
        }
        else
        {cameraAccessFailed()}
      },

      // NOTE: this is refactored from https://cozmo.github.io/jsQR/
      animationCallback() {
        const video = this.$refs.video
        const canvasElement = this.$refs.canvas
        const canvas = canvasElement.getContext("2d")
        if (video.readyState === video.HAVE_ENOUGH_DATA) {
          this.showSpinner = false
          canvasElement.hidden = false
          canvasElement.height = video.videoHeight
          canvasElement.width = video.videoWidth
          canvas.drawImage(video, 0, 0, canvasElement.width, canvasElement.height)
          const imageData = canvas.getImageData(0, 0, canvasElement.width, canvasElement.height)
          const code = jsQR(imageData.data, imageData.width, imageData.height, {inversionAttempts: "dontInvert"})
          if (code)
          {this.handleFoundQrCode(code.data)}
        }
        if (this.continueProcessing())
        {requestAnimationFrame(this.animationCallback)}
      },

      shutdownCamera(){
        const video = this.$refs.video
        video.srcObject.getTracks().forEach(function(track) {
          track.stop()
        })
      },
    },
  }
</script>
