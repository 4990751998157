<template>
  <AdminUserFormContainer
    :isEdit="true"
    :permissionsFeature="permissionsFeature"
    :accessPolicies="accessPolicies"
    :groups="groups"
    :user="user"
    :scope="scope"
    :accessPolicy="accessPolicy"
    :employeeProfile="employeeProfile"
    :permissions="permissions"
  />
</template>
<script>
  import AdminUserFormContainer from 'components/admin/admin_users/AdminUserFormContainer'

  export default {
    name: 'the-edit-admin-user-root',
    components: {
      AdminUserFormContainer,
    },
    props: {
      permissionsFeature: {
        type: Boolean,
        required: true,
      },
      accessPolicies: {
        type: Array,
        required: true,
      },
      accessPolicy: {
        required: true,
      },
      employeeProfile: {
        type: Object,
        required: true,
      },
      user: {
        type: Object,
        required: true,
      },
      groups: {
        type: Array,
        default() {
          return []
        },
      },
      scope: {
        type: Array,
        default() {
          return []
        },
      },
      permissions: {
        type: Array,
        default: () => ([]),
      },
    },
  }
</script>
