<template>
  <div v-if="totalCount">
    <a :href="listViewPath" v-if="listViewPath" @click="stopListViewPathPropagation">
      {{ pluralizedCount }}
    </a>
    <div class="wb-breakdown-chart">
      <WbBreakdownChartSection
        v-for="section in validLegendData"
        :key="section.legend_key"
        :legend_key="section.legend_key"
        :width="sectionWidth(section.legend_key)"
        :variant="section.variant"
        :tooltipMsg="sectionTitle(section)"
        @click="handleSectionClick"
      />
    </div>
    <div class="wb-breakdown-chart__legend" v-if="includeLegend">
      <div
        class="wb-breakdown-chart__legend__row"
        v-for="section in validLegendData"
        :key="section.legend_key"
      >
        <div class="wb-breakdown-chart__legend__row__indicator">
          <WbDotIndicator :variant="section.variant" />
        </div>
        <a
          class="wb-breakdown-chart__legend__row__content"
          v-tooltip.top="!sectionPath(section) ? tooltipMsg : ''"
          :class="{'disabled': !sectionPath(section) }"
          :href="sectionPath(section) ? section.path : 'javascript:void(0)'"
        >
          {{ getPluralizedSectionCount(sectionCount(section.legend_key), modelNameHumanized) }} {{ section.message }}
        </a>
        <div
          class="wb-breakdown-chart__legend__row__percentage"
          :class="`wb-breakdown-chart__legend__row__percentage--${section.variant}`"
        >
          {{ sectionWidthReadable(section.legend_key) }}
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="wb-list__subtitle">
      No {{modelNameHumanized}}s to display
    </div>
  </div>
</template>

<script>
  import WbBreakdownChartSection from 'components/common/WbBreakdownChartSection'
  import WbDotIndicator from 'components/common/WbDotIndicator'
  import { SECTION_REQUIRED_KEYS } from 'components/admin/dashboard/BreakdownChartConstants'
  import StringUtil from 'lib/util/string'
  import { difference } from 'underscore'

  export default {
    name: 'wb-breakdown-chart',
    components: {
      WbBreakdownChartSection,
      WbDotIndicator,
    },

    props: {
      maxWidth: {
        type: Number,
        required: false,
        default: 100,
      },

      // Object representing keys and counts of each section
      chartData: {
        type: Object,
        required: true,
      },

      // An array of objects that include legend metadata
      legendData: {
        type: Array,
        required: true,
        validator: value => {
          const keysCollection = value.map(item => Object.keys(item))

          // Returns true if all keys match
          return keysCollection.every(keys => {
            return difference(SECTION_REQUIRED_KEYS, keys).length == 0
          })
        },
      },

      // The path for the entire list view you want to redirect to
      listViewPath: {
        type: String,
        required: false,
      },

      // Singular model name
      modelNameHumanized: {
        type: String,
        required: true,
      },

      // Should legend be displayed?
      includeLegend: {
        type: Boolean,
        required: false,
        default: true,
      },

      tooltipWarning: {
        type: String,
        required: false,
      },
    },

    data() {
      return {
        tooltipMsg: this.tooltipWarning,
      }
    },
    
    computed: {
      pluralizedCount() {
        return StringUtil.pluralize(this.totalCount, this.modelNameHumanized)
      },
      totalCount() {
        return this.chartData && Object.entries(this.chartData).length && Object.values(this.chartData).reduce((a, b) => a + b)
      },
      validLegendData() {
        return this.legendData.filter(section => this.sectionCount(section.legend_key))
      },
    },

    methods: {
      getPluralizedSectionCount (count, modelName) {
        return StringUtil.pluralize(count, modelName)
      },
      sectionCount(legendKey) {
        return this.chartData[legendKey]
      },

      sectionWidth(legendKey) {
        return (this.sectionCount(legendKey) / this.totalCount) * this.maxWidth
      },

      sectionWidthReadable(legendKey) {
        let width = this.sectionWidth(legendKey).toFixed(1)

        if (width == '0.0') {
          width = '<.1'
        }
        return `${width}%`
      },

      sectionTitle(section) {
        return `${StringUtil.pluralize(this.sectionCount(section.legend_key), this.modelNameHumanized, null)} ${section.message}`
      },

      sectionPath(section) {
        return section.path && section.path.length
      },

      stopListViewPathPropagation(e){
        e.stopPropagation()
      },

      handleSectionClick({legend_key}) {
        this.$emit('section-click', {legend_key})
      },
    },
  }
</script>
