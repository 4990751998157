export default Marionette.ItemView.extend({
  template: '#template-submit-sheet-view',
  ui: {
    confirmButton: '.confirm',
  },
  events: {
    'click @ui.confirmButton': 'confirmColumns',
  },
  ready: false,
  serializeData: function(){
    return {
      ready: this.ready,
    }
  },

  // Indicate that the column match can be submitted, re-render
  readyToSubmit: function(){
    this.ready = true
    if (!this.isDestroyed){ this.render() };
  },

  // Indicate that the column match cannot be submitted, re-render
  notReadyToSubmit: function(){
    this.ready = false
    if (!this.isDestroyed){ this.render() }
  },

  // Change submit button class based on readyToSubmit state
  onRender: function(){
    const btn = this.$el.find('.btn')
    const readyClass = 'btn-primary'
    const notReadyClass = 'btn-default disabled'

    this.ui.confirmButton.removeClass(readyClass)
    this.ui.confirmButton.removeClass(notReadyClass)

    const btnClass = this.ready ? readyClass : notReadyClass
    this.ui.confirmButton.addClass(btnClass)

    $.runInitializers(this.$el) // Ladda
  },

  // Post event to start column confirmation
  confirmColumns: function(){
    if (this.ready){
      this.ui.confirmButton.data('ladda').start()
      App.vent.trigger('admin_user_import:column_match_confirm')
    }
  },
})
