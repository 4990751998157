<template>
  <tr :data-id="submissionId">
    <td>
      <div
        v-if="isSelectable"
        class="a4s-checkbox"
        ref="checkbox"
      >
        <input
          type="checkbox"
          :id="`row_select_id${this.submissionId}`"
          :value="this.submissionId"
          :checked="assignment.selected"
          @change="toggleSubmissionSelectedById({id: submissionId})"
        />
        <label
          :for="`row_select_id${this.submissionId}`"
        />
      </div>
    </td>
    <td ref="fullName">{{fullName}}</td>
    <td>
      <WbSimpleDateTime
        :value="submission.created_at"
        :invalidMessage="defaultValue"
      />
    </td>
    <td>
      <a
        v-if="submissionId"
        class="btn btn-default btn-sm"
        :href="submissionPath"
        target="_blank"
      >
        <WbIcon type="file-text-o" />
        View Form
      </a>
      <div v-else>{{ defaultValue }}</div>
    </td>
    <td ref="status">
      <I9InboxAssignmentStatus :assignment="assignment" />
    </td>
    <td>
      <WbSimpleDateTime
        :value="submission.reverify_on"
        :invalidMessage="defaultValue"
      />
    </td>
    <td
      v-if="shouldShowVerificationStatus"
      ref="verificationStatus"
    >
      <DocumentVerificationBadge
        v-if="verificationStatus"
        :status="verificationStatus"
        :submission="assignment.submission"
      />
      <template v-else>
        --
      </template>
    </td>
    <td ref="auditLogs">
      <button
        v-if="hasAuditLogs"
        class="btn btn-link pl-0"
        ref="auditLogsButton"
        @click="handleAuditClick"
      >
        View audit events
      </button>
      <p v-else>
        {{ defaultValue }}
      </p>
    </td>
    <td
      class="text-right"
      v-if="shouldShowAdditionalActions"
      ref="additionalItemsCell"
    >
      <WbButton
        class="btn-sm btn-restart-i9"
        @click="restartRemoteI9"
        :isLoading="isRestartingI9"
        label="Restart Remote I-9"
        title="Restart Remote I-9 Verification"
        v-if="canRestartRemoteI9"
        ref="restartRI9"
      />
    </td>
  </tr>
</template>
<script>
  import Constants from 'lib/constants'
  import DocumentVerificationBadge from 'components/document_submissions/DocumentVerificationBadge'
  import I9InboxAssignmentStatus from 'components/i9/employer_review/I9InboxAssignmentStatus'
  import SelectableI9Assignment from 'components/mixins/i9/SelectableI9Assignment'
  import WbButton from 'components/common/WbButton'
  import WbIcon from 'components/common/WbIcon'
  import WbSimpleDateTime from 'components/common/WbSimpleDateTime'
  import { mapMutations, mapActions } from 'vuex'
  import { SET_PAGE_CONTEXT_KEYS } from 'vuex/mutation_types'
  import { ajax } from 'jquery'
  import Util from 'lib/util'
  import UrlUtil from 'lib/util/url'

  export default {
    components: {
      DocumentVerificationBadge,
      I9InboxAssignmentStatus,
      WbButton,
      WbIcon,
      WbSimpleDateTime,
    },

    mixins: [
      SelectableI9Assignment,
    ],

    props: {
      assignment: {
        type: Object,
        required: true,
      },
      shouldShowAdditionalActions: {
        type: Boolean,
        default: false,
      },
      shouldShowVerificationStatus: {
        type: Boolean,
        default: false,
      },
    },

    data () {
      return {
        defaultValue: Constants.DATA_MISSING_VALUE,
        isRestartingI9: false,
      }
    },

    computed: {
      canRestartRemoteI9 () {
        return this.assignment.can_restart_remote_i9
      },

      fullName () {
        return this.assignment.employee_profile?.full_name || Constants.DATA_MISSING_VALUE
      },

      isSelectable () {
        return this.checkAssignmentSelectable(this.assignment)
      },

      submission () {
        return this.assignment.submission
      },

      submissionPath () {
        return this.$routes.admin_employee_submission_path({ employee_id: this.assignment.employee_profile?.id, id: this.submissionId })
      },

      submissionId () {
        return this.assignment.current_submission_id
      },

      hasAuditLogs () {
        return this.submission.has_audit_logs
      },

      verificationStatus () {
        return this.submission.document_verification_result
      },
    },

    methods: {
      ...mapMutations({
        setPageContextKeys: SET_PAGE_CONTEXT_KEYS,
      }),

      ...mapActions({
        toggleSubmissionSelectedById: 'i9_inbox_assignments/toggleSubmissionSelectedById',
      }),

      handleAuditClick () {
        this.setPageContextKeys({ show_audit_log_submission_id: this.submissionId })
      },

      restartRemoteI9 () {
        this.isRestartingI9 = true
        ajax({
          method: 'POST',
          url: UrlUtil.urlWithParams(this.$routes.onboarding_i9_restart_remote_verification_path, {
            "ids[]": this.submissionId,
          }),
        }).then((_data, _status, xhr) => {
          Util.navigateAndShowAjaxFlashNotice(this.$routes.onboarding_i9_review_path, xhr)
        }).fail((xhr) => {
          Util.genericAjaxError('We were unable to restart I-9 remote verification.', xhr)
        }).always(() => {
          this.isRestartingI9 = false
        })
      },
    },
  }
</script>
