<template>
  <div class="panel minimal gray-bg">
    <FormAlerts
      v-if="formResponse"
      :success="formResponse.success"
      :errors="formResponse.errors"
      :msgSuccess="formResponse.msgSuccess"
    />
    <div class="panel-heading no-border">
      <div class="panel-title" v-if="title">
        <h3>
          {{ title }}
        </h3>
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
  import FormAlerts from 'components/common/FormAlerts'

  export default {
    name: 'wb-panel',

    components: {
      FormAlerts,
    },

    props: {
      title: {
        type: String,
        required: false,
      },

      formResponse: {
        type: Object,
        required: false,
      },
    },
  }
</script>
