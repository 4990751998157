// This code is heavily based on:
// views/document_submissions/i9/form_layout_view.js
//
import I9SupplementBWizard from 'lib/i9_supplement_b_wizard'
import WizardLayoutView from 'views/common/wizard_layout_view'
import StepStore from 'models/step_store'
import I9SupplementBDocumentationWizardStep from 'models/document_submissions/i9_supplement_b_documentation_wizard_step'
import Util from 'lib/util'


export default Marionette.LayoutView.extend({
  template: false,

  regions: {
    wizardRegion: "section.wizard-form",
  },

  initialize: function() {
    const documentationChoices = this.getOption('documentationChoices')
    const onFinishRedirectPath = this.getOption('onFinishRedirectPath')

    const context = new StepStore()

    // REVIEW: Unfortunately, putting documentationChoices in 'global' context means it gets copied to EVERY individual
    // step context, but step contexts are only instantiated when the view is first needed @ runtime, so there is no
    // great way to inject the documentationChoices to just the step(s) that needs it :(
    const globalContext = context.register('global')
    globalContext.set('documentationChoices', documentationChoices)

    this.wizard = new I9SupplementBWizard({
      steps: [
        new I9SupplementBDocumentationWizardStep({ percent: 100 }),
      ],
      model: this.model,
      context: context,
      onFinish: function(xhr) {
        Util.navigateAndShowAjaxFlashNotice(onFinishRedirectPath, xhr)
      },
    })
  },

  onRender: function() {
    this.wizard.layout = new WizardLayoutView({ wizard: this.wizard })
    this.wizardRegion.show(this.wizard.layout)
  },
})
