import DownloadRequest from 'models/downloads/DownloadRequest'
import routes from 'lib/routes'
import { ajax } from 'jquery'

const state = () => {
  return {
    downloadRequests: [],
  }
}

const getters = {
  downloadRequests: state => state.downloadRequests,
}

const mutations = {
  setdownloadRequests: (state, downloadRequests) => {
    state.downloadRequests = downloadRequests
  },
}

const actions = {
  fetchUsageReports ({ commit }) {
    return new Promise((resolve, reject) => {
      ajax({
        url: routes.Api.usage_reports_path,
        method: 'GET',
        progressBar: true,
      }).success(data => {
        let requests = []
        if (data && data.length) {
          requests = data.map(request => new DownloadRequest(request))
        }
        commit('setdownloadRequests', requests)
        resolve()
      }).error(() => {
        commit('setdownloadRequests', [])
        reject()
      })
    })
  },
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
}
