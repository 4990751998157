<template>
  <div>
    <PageHeader
      title="New Permissions Policy"
      variant="bordered"
    />
    <ManagedAccessPolicyTemplateWizard :policy="policy"/>
  </div>
</template>

<script>
  import ManagedAccessPolicy from "models/policy_generator/managed_access_policy"
  import ManagedAccessPolicyTemplateWizard from "components/admin/managed_access_policy_templates/ManagedAccessPolicyTemplateWizard"

  import PageHeader from 'components/common/PageHeader'

  export default {
    name: "the-managed-access-policy-templates-new-root",

    components: {
      ManagedAccessPolicyTemplateWizard,
      PageHeader,
    },

    data: function() {
      return {policy: new ManagedAccessPolicy()}
    },
  }
</script>
