<template>
  <div>
    <span
      v-if="hasValidStatus"
      class="label label-inline"
      :class="classNames"
      role="button"
      @click="handleClick"
      ref="label"
    >
      <WbIcon
        v-if="!!icon"
        :type="icon"
        class="mr-1"
      />
      {{ label }}
    </span>
    <span v-else>
      Unknown Verification Status
    </span>
    <DocumentVerificationSlideout
      :submission="submission"
      @update="handleUpdate"
    />
  </div>
</template>
<script>
  import WbIcon from 'components/common/WbIcon'
  import DocumentVerificationSlideout from 'components/document_submissions/DocumentVerificationSlideout'
  import { mapMutations } from 'vuex'
  import * as mutate from 'vuex/mutation_types'
  import {
    getDocumentVerificationClassNames,
    getDocumentVerificationHasValidStatus,
    getDocumentVerificationIcon,
    getDocumentVerificationLabel,
  } from "decorators/document_verification_decorator"

  export default {
    components: {
      DocumentVerificationSlideout,
      WbIcon,
    },

    props: {
      status: {
        type: String,
      },
      submission: {
        type: Object,
        required: true,
      },
    },

    emits: [
      'update',
    ],

    computed: {
      submissionId () {
        return this.submission.id
      },
      classNames () {
        return getDocumentVerificationClassNames(this.status)
      },
      hasValidStatus () {
        return getDocumentVerificationHasValidStatus(this.status)
      },
      icon () {
        return getDocumentVerificationIcon(this.status)
      },
      label () {
        return getDocumentVerificationLabel(this.status)
      },
      slideoutKey() {
        return `documentVerificationSlideout-${this.submissionId}`
      },
    },

    methods: {
      ...mapMutations({
        setPageContextKeys: mutate.SET_PAGE_CONTEXT_KEYS,
      }),
      handleClick () {
        const context = {}
        context[this.slideoutKey] = true

        this.setPageContextKeys(context)
      },
      handleUpdate (submissionId) {
        this.$emit('update', submissionId)
      },
    },
  }
</script>
