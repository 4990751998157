import AsyncModalItemView from 'views/lib/async_modal_itemview'

export default AsyncModalItemView.extend({
  behaviors: {
    RailsRemoteFormCallbacks: {},
  },

  ui: {
    form: 'form',
    submitButton: 'button[type="submit"]',
  },

  asyncItemViewUniquenessKey: 'employee_season_form_modal_view',

  onRemoteFormSuccess: function(e, data, status, xhr) {
    this.model.set(data)
    App.Util.showAjaxFlashNotice(xhr)
    this.hideModal()
  },

  onRemoteFormError: function(e, xhr) {
    const flashError = App.Util.getAjaxFlashMessages(xhr).error
    if (flashError) {
      App.Util.ajaxErrorDialog(flashError, xhr)
    }

    if (xhr.getResponseHeader('X-Form-Errors')) {
      this.replaceModalContent(xhr.responseText)
    } else {
      // Modal content is not reset, but we need to re-enable the submit button manually
      this.ui.submitButton.data('ladda').stop()
    }
  },
})
