<template>
  <div class="wb-card wb-card--upsell">
    <div class="wb-card__title">
      Add E-Verify to your onboarding today.
    </div>
    <div class="wb-card__body mt-3">
      <ul>
        <li>Tested by E-Verify and complies with their standards</li>
        <li>Save your team time and costs associated with manually using E-Verify</li>
        <li>Improve compliance and eligibility of your staff</li>
      </ul>
      <a class="btn btn-primary mt-2" :href="$routes.everify_account_path">
        Learn more about our E-Verify module
      </a>
    </div>
  </div>
</template>
