<template>
  <div class="workflow-step mb-3">
    <h3 class="h4" ref="header">Step {{ stepNumber }}{{ stepTitle }}</h3>
    <div class="workflow-step-body">
      <slot />
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      stepNumber: {
        type: Number,
        required: true,
      },
      title: {
        type: String,
        required: false,
      },
    },
    computed: {
      stepTitle () {
        return this.title ? `: ${this.title}` : ''
      },
    },
  }
</script>
