<template>
  <div id="active-employment-form-i9-options" v-if="shouldShow" ref="component">
    <div class="panel panel-simple">
      <div class="panel-heading">
        <h3 class="panel-title">Form I-9</h3>
      </div>
      <table class="table info-table panel-info-table" id="form-i9-options">
        <tbody>
          <tr v-if="shouldShowEverify" ref="everify">
            <th class="col-md-4" scope="row">E-Verify Case Status</th>
            <td class="col-md-8 info-item everify-case-status">
              <template v-if="everifyCase">
                <EverifyCaseStatusIndicator :everifyCase="everifyCase" />
                <div class="breathe above" v-if="!everifyCaseClosed">
                  <a ref="everifyCaseLink" :href="everifyCasePath">View E-Verify Cases</a>
                  for details.
                </div>
              </template>
              <template v-else>
                Case not submitted, or not available in WorkBright. E-Verify Case Status is linked to the current Form I-9 submission.
                This staff may have additional cases from previous Form I-9 submissions. See <a :href="everifyCaseOnboardingLinkPath">E-Verify Cases</a> for the full list.
              </template>
            </td>
          </tr>
          <tr v-if="shouldShowRemoteVerification" ref="ri9">
            <th class="col-md-4" scope="row">Remote Verification</th>
            <td class="col-md-8 info-item info-hire-date">
              <I9RemoteSettingToggle
                setting="countersign"
                :employmentId="employmentId"
              />
              <PopoverIcon
                title="I-9 Remote Verification"
                :message="$locale.t('employments.i9_remote_verification')"
              />
            </td>
          </tr>
          <tr v-if="shouldShowRemoteReverification" ref="remoteReverification">
            <th class="col-md-4" scope="row">Remote Reverification</th>
            <td class="col-md-8 info-item info-hire-date">
              <I9RemoteSettingToggle
                setting="reverification"
                :employmentId="employmentId"
              />
              <PopoverIcon
                title="I-9 Remote Reverification (Supplement B)"
                :message="$locale.t('employments.i9_remote_reverification')"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
  import EverifyCaseStatusIndicator from 'components/everify/cases/EverifyCaseStatusIndicator'
  import PopoverIcon from '../common/PopoverIcon'
  import I9RemoteSettingToggle from 'components/employees/I9RemoteSettingToggle'
  import UrlUtil from "lib/util/url"
  import { mapGetters } from 'vuex'

  export default {
    components: {
      EverifyCaseStatusIndicator,
      PopoverIcon,
      I9RemoteSettingToggle,
    },
    props: {
      everifyCase: {
        type: Object,
      },
      shouldShowRemoteReverification: {
        type: Boolean,
      },
      shouldShowRemoteVerification: {
        type: Boolean,
      },
      employmentId: {
        type: String,
        required: true,
      },
    },
    data () {
      return {
        errors: null,
      }
    },
    computed: {
      hasErrors () {
        return !!this.errors
      },
      shouldShow () {
        return this.shouldShowEverify || this.shouldShowRemoteVerification
      },
      shouldShowEverify (){
        return this.currentAccount.everifyFeature
      },
      everifyCaseClosed (){
        return this.everifyCase.closed
      },
      everifyCasePath () {
        return `${UrlUtil.urlWithParams(this.$routes.everify_cases_path,{id: this.everifyCase.id})}#detail`
      },
      everifyCaseOnboardingLinkPath() {
        return this.$routes.everify_cases_path
      },
      ...mapGetters({
        currentAccount: 'account/current',
      }),
    },
  }
</script>
