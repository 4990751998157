// Generic List Item
// Displays custom content (via slot) and a context menu

<template>
  <div class="list-group-item wb-list__item">
    <div class="wb-list__row" :class="{ selectable: selectable, selected: selected }">
      <div class="wb-list__column-selector" v-if="selectable">
        <div class="wb-list__checkbox" @click="toggleSelected">
          <input ref='checkbox' type='checkbox' :checked="selected" :disabled="disabled" />
        </div>
      </div>
      <a class="wb-list__container"  :href="href" @click="handleClick">
        <slot />
      </a>
      <div class="wb-list__column-actions dropdown dropdown-secondary-actions" v-if="secondaryActions">
        <button class="btn btn-sm dropdown-toggle" data-toggle="dropdown" role="menu" />
        <ul class="dropdown-menu dropdown-menu-right">
          <slot name="secondaryActionsMenuItems" />
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'list-item',
    components: {
    },
    props: {
      href: {
        type: String,
        required: false,
      },
      selectable: {
        type: Boolean,
        default: false,
      },
      collectionViewName: {
        type: String,
        required: false,
      },
      item: {
        type: Number,
        required: false,
      },
      secondaryActions: {
        type: Boolean,
        default: true,
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false,
      },
      defaultSelected: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    computed: {
      // Returns boolean of whether or not this ListItem is selected
      selected() {
        if (!this.collectionViewName) { return false };
        if (this.defaultSelected) { return true }

        return this.$store.getters[`${this.collectionViewName}/isSelected`](this.item)
      },
    },
    methods: {
      toggleSelected() {
        if (this.disabled) { return };

        if (!this.collectionViewName) { return false };
        this.$store.dispatch(`${this.collectionViewName}/select`, this.item)
      },

      handleClick() {
        this.$emit('click')
      },
    },

  }
</script>
