import PubSubUtil from 'lib/pub_sub_wrapped_action'
import * as BaseCollectionModule from 'vuex/mixins/base_vuex_collection_module'
import ErrorService from 'lib/error_service'
import {ajax} from 'jquery'
import {getRoute} from 'lib/routes'

function state() {
  return {
    collection: {},
    activeFetchAllRequest: null, // Holds the current AJAX request
  }
};

const getters = {
  ...BaseCollectionModule.getters,
}

const mutationTypes = {
  ...BaseCollectionModule.mutationTypes,
}

const mutations = {
  ...BaseCollectionModule.mutations,

  setNotifcationMethods (state, {
    employeeId,
    notificationMethods,
    notificationType,
  }) {
    const employee = state.collection[employeeId]
    if (employee) {
      employee[notificationType] = notificationMethods
    }
  },
  SET_ACTIVE_FETCHALL_REQUEST(state, request) {
    state.activeFetchAllRequest = request
  },
  CLEAR_ACTIVE_FETCHALL_REQUEST(state) {
    state.activeFetchAllRequest = null
  },
}

const actions = {
  ...BaseCollectionModule.actions,

  // Activates Employees
  // Posts to API, and receives response back on PubSub channel, as this is a long-running bulk operation
  //
  // data
  //   employees [Array] - employees to reset
  //   emailMessage [String] - optional, email message
  activate: ({ dispatch }, { employees, emailMessage }) => {
    return PubSubUtil.pubSubWrappedAction(
      'employees_activate',
      (channelName, reject) => {
        ajax({
          method: 'PATCH',
          url: getRoute('Api.bulk_activate_employees_path'),
          data: {
            employee_ids: employees.map(a => a.id),
            email_message: emailMessage,
            pubsub_channel: channelName,
          },
          wbGenericFailureMsg: 'Sorry, we could not activate these employees.',
          progressBar: false,
        }).fail(xhr => reject(xhr))
      },
      (_response, resolve, reject) => {
        dispatch(
          'employees/bulkShow',
          { employeeIds: employees.map((e) => e.id) },
          { root: true },
        )
          .then(() => resolve() )
          .catch((xhr) => reject(xhr) )
      },
    )
  },

  // Deactivates Employees
  // Posts to API, and receives response back on PubSub channel, as this is a long-running bulk operation
  //
  // data
  //   employees [Array] - employees to deactivate
  //   note [String] - optional, deactivation reason
  deactivate: ({ dispatch }, { employees, note }) => {
    return PubSubUtil.pubSubWrappedAction(
      'employees_deactivate',
      (channelName, reject) => {
        ajax({
          method: 'PATCH',
          url: getRoute('Api.bulk_deactivate_employees_path'),
          data: {
            employee_ids: employees.map(a => a.id),
            note: note,
            pubsub_channel: channelName,
          },
          wbGenericFailureMsg: 'Sorry, we could not deactivate these employees.',
          progressBar: false,
        }).fail(xhr => reject(xhr))
      },
      (_response, resolve, reject) => {
        dispatch(
          'employees/bulkShow',
          { employeeIds: employees.map((e) => e.id) },
          { root: true },
        )
          .then(() => resolve() )
          .catch((xhr) => reject(xhr) )
      },
    )
  },

  // Fetches all (permitted) staff members that match the requested filters
  //
  // Acceptable filters: search, groups, employment_status, onboarding_progress, sort_order, page
  //
  // returns a paginated list of staff members (from the StaffPageEmployee table) along with pagination fields
  fetchAllStaff: ({ commit, state, dispatch }, { filters }) => {
    // Return a promise so the component can chain `then` or `catch`
    return new Promise((resolve, reject) => {
      // If there’s an ongoing request, abort it
      if (state.activeFetchAllRequest) {
        state.activeFetchAllRequest.abort()
        commit('CLEAR_ACTIVE_FETCHALL_REQUEST')
      }

      // Initiate the new AJAX request and store its reference
      const currentRequest = ajax({
        timeout: 60_000,
        method: 'GET',
        url: getRoute('Api.staff_list_employees_path'),
        data: filters,
      })

      currentRequest.then(response => {
        commit('CLEAR_ACTIVE_FETCHALL_REQUEST')
        // Dispatch loadInitialData action and resolve on success
        return dispatch('employees/loadInitialData', response.employees, { root: true })
          .then(() => resolve(response))
          .catch(error => reject(error))
      })
        .fail(xhr => {
          commit('CLEAR_ACTIVE_FETCHALL_REQUEST')
          // Reject if the request fails (except when aborted)
          if (xhr.statusText !== "abort") {
            new ErrorService(
              "Failed to fetch employees in fetchAllStaff from /api/employees/staff_list",
              "FetchAllStaffError",
            ).report(xhr)
            reject(xhr)
          }
        })

      commit('SET_ACTIVE_FETCHALL_REQUEST', currentRequest)
    })
  },

  fetchAllStaffIds: ({}, { filters }) => {
    return new Promise((resolve) => {
      ajax({
        method: 'GET',
        url: getRoute('Api.staff_list_ids_employees_path'),
        data: filters,
      })
        .then(response => {
          resolve(response)
        })
        .fail(xhr => {
          new ErrorService("Failed to fetch employee ids in fetchEmployeeIds from /api/employees/select_all_yall", "FetchAllStaffError").report(xhr)
        })
    })
  },

  fetchStaffListSlideoutData: ({}, { employeeID }) => {
    return new Promise((resolve, reject) => {
      ajax({
        method: 'POST',
        url: getRoute('Api.staff_list_slideout_employee_path',{id: employeeID}),
      }).then(response => {
        resolve(response)
      }).fail(xhr => {
        new ErrorService("Failed to fetch StaffListSlideout data", "FetchStaffListSlideoutError").report(xhr)
        reject(xhr)
      })
    })
  },

  // Bulk Show
  // Fetch a set of employees from the API, update collection on success
  //
  // data
  //   employeesIds [Array] - employee ids to fetch
  //
  // returns Promise
  //          resolve -> employees
  //          reject -> xhr
  bulkShow: ({ commit }, { employeeIds }) => {
    return new Promise((resolve, reject) => {
      ajax({
        method: 'POST',
        url: getRoute('Api.bulk_show_employees_path'),
        data: {
          employee_ids: employeeIds,
        },
        // FIXME: Find a new method for sending down all this data.
        timeout: 60000,
      }).then((employees) => {
        employees.forEach((employee) => { commit(mutationTypes.SET_RECORD, employee) })
        resolve(employees)
      }).fail((xhr) => {
        reject(xhr)
      })
    })
  },

  // Trigger resend of welcome email
  //
  // employeeId: [Integer] employee_id to resend
  resendWelcomeEmail: ({}, { employeeId }) => {
    return new Promise((resolve, reject) => {
      ajax({
        url: getRoute('employee_resend_welcome_email_path',{ employee_id: employeeId }),
        method: 'POST',
      }).then(resolve)
        .fail((xhr) => reject(xhr))
    })
  },

  // Bulk Download
  // Submit a request to download a list of employees' forms and files (aka "Print All")
  //
  // data
  //   employeesIds [Array] - selected employee ids (required)
  //   documentIds [Array] - selected form ids to fetch staff submissions (required)
  //   acceptedOnly [Boolean] - download only accepted submissions?
  //   includePreviousSubmissions [Boolean] - include previous submissions?
  //   supplementalFiles [Boolean] - include staff's supplemental files?
  //   deactivationNotes [Boolean] - include staff's deactivation notes?
  //   description [String] - user's note about download
  bulkDownload: ({}, { data }) => {
    return new Promise((resolve, reject) => {
      ajax({
        method: 'POST',
        url: getRoute('Api.bulk_download_requests_path'),
        data: {
          employee_ids: data.employeeIds,
          document_ids: data.documentIds,
          accepted_only: data.acceptedOnly,
          include_previous_submissions: data.includePreviousSubmissions,
          supplemental_files: data.supplementalFiles,
          deactivation_notes: data.deactivationNotes,
          description: data.description,
        },
        wbGenericFailureMsg: "Sorry, we could not process the download request.",
      }).then(resolve)
        .fail(xhr => reject(xhr))
    })
  },

  saveNotificationMethods: ({ commit }, { employeeId, notificationMethods, notificationType }) => {
    return new Promise((resolve, reject) => {
      ajax({
        method: "PATCH",
        url: getRoute('employee_path',{ id: employeeId }),
        contentType: "application/json",
        data: JSON.stringify({
          employee: {
            [notificationType]: notificationMethods,
          },
        }),
      }).success(() => {
        commit('setNotifcationMethods', {
          employeeId,
          notificationMethods,
          notificationType,
        })
        resolve()
      }).fail((xhr) => {
        reject(xhr)
      })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
