<template>
  <div>
    <button
      class="btn btn-link pl-0"
      @click="handleClick"
    >
      View audit events
    </button>
    <SubmissionAuditLogContainer />
  </div>
</template>
<script>
  import SubmissionAuditLogContainer from 'components/document_submissions/SubmissionAuditLogContainer'
  import { mapMutations } from 'vuex'
  import { SET_PAGE_CONTEXT_KEYS } from 'vuex/mutation_types'

  export default {
    name: 'the-submission-audit-log-root',
    components: {
      SubmissionAuditLogContainer,
    },
    props: {
      submission: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        isLoading: false,
      }
    },
    computed: {
      submissionId () {
        return this.submission.id
      },
    },
    methods: {
      handleClick () {
        this.setPageContextKeys({ show_audit_log_submission_id: this.submissionId })
      },

      ...mapMutations({
        setPageContextKeys: SET_PAGE_CONTEXT_KEYS,
      }),
    },
  }
</script>
