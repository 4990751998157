// Entry point for Webpacked app

// Initialization, defaults, etc.
require('init/system_events')
require('init/check_browser_support')
require('init/manually_vendored')
require('init/bugsnag')
require('init/ajax')
require('init/ajax_pool')
require('init/appender')
require('init/bootstrap_validator')
require('init/clearable')
require('init/eula')
require('init/turbolinks')
require('init/pubsub_init')
require('init/moment')
require('init/datatables')
require('init/stickit')
require('init/store')
require('init/startRootVue')

// Make modularized code available to parts of the application that don't support modules.
require('init/bridge')

// Jquery initializers run after loading everything
require('init/jquery_initializers')
