<template>
  <div>
    <div class="alert alert-warning">
      <p class="mb-4" v-html="message" />
      <ul>{{ itemsWithErrors }}</ul>
    </div>
  </div>
</template>

<script>
  import StringUtil from 'lib/util/string'

  export default {
    name: 'bulk-action-warning',
    components: {},
    props: {
      message: {
        type: String,
        required: false,
      },

      collection: {
        type: Array,
        required: true,
      },

      // A callback function that is passed from the bulk action container to the BulkActionListAlert
      displayNameLookupFn: {
        type: Function,
        required: false,
      },
    },
    computed: {
      itemsWithErrors() {
        const names = this.collection.map(item => this.displayNameLookupFn(item))
        return StringUtil.joinTruncated(names, 25)
      },
    },
    methods: {},
  }
</script>
