<template>
  <div class="well">
    <h4>Multiple Jobs Worksheet</h4>
    <p>This will calculate the value to enter in Step 4(c), Extra withholding.</p>

    <FormFieldCurrency
      orientation="horizontal"
      :required="false"
      label="Job 1 annual income"
      :errors="errors"
      name="mjw_job_1"
      min="0"
      v-model="formData.job1"
    />

    <FormFieldCurrency
      orientation="horizontal"
      :required="false"
      label="Job 2 annual income"
      :errors="errors"
      name="mjw_job_2"
      min="0"
      v-model="formData.job2"
    />

    <FormFieldCurrency
      v-if="jobs === 3"
      orientation="horizontal"
      :required="false"
      label="Job 3 annual income"
      :errors="errors"
      name="mjw_job_3"
      min="0"
      v-model="formData.job3"
    />

    <FormFieldRadio
      label="For the highest paying job, how often do you get paid?"
      :required="false"
      :options="payFrequencyOptions"
      v-model="formData.payFrequency"
      :errors="errors"
      name="pay_frequency"
      orientation="horizontal"
    />

    <FormFieldNumber v-if="isCustomPay"
      orientation="horizontal"
      :required="false"
      label="How many times a year are you paid?"
      :errors="errors"
      name="custom_pay_frequency"
      min="0"
      v-model="formData.customPayFrequency"
      ref="customPayFrequency"
    />
  </div>
</template>

<script>
  import FormFieldCurrency from 'components/common/FormFieldCurrency'
  import FormFieldNumber from 'components/common/FormFieldNumber'
  import FormFieldRadio from 'components/common/FormFieldRadio'

  export const PAY_FREQUENCIES = {
    weekly: 52,
    biweekly: 26,
    monthly: 12,
  }
  export default {
    name: 'multiple-jobs-worksheet',

    components: {
      FormFieldCurrency,
      FormFieldNumber,
      FormFieldRadio,
    },

    props: {
      jobs: {
        required: true,
        type: Number,
      },

      status: {
        required: true,
        type: String,
      },

      errors: {
        required: false,
        type: Object,
      },
    },

    data() {
      return {
        formData: {
          job1: '',
          job2: '',
          job3: '',
          mjwLine1: '',
          mjwLine2a: '',
          mjwLine2b: '',
          payFrequency: null,
          customPayFrequency: '',
        },
        payFrequencyOptions: [
          { label: "Weekly", value: 'weekly' },
          { label: "Biweekly", value: 'biweekly' },
          { label: "Monthly", value: 'monthly' },
          { label: "Other", value: 'custom' },
        ],
      }
    },

    computed: {
      isCustomPay () {
        return this.formData.payFrequency === 'custom'
      },
    },

    watch: {
      formData: {
        deep: true,
        handler () {
          this.sendUpdate()
        },
      },
    },

    methods: {
      // update the Extra Withholding field on the W4
      // and the MJW form values
      sendUpdate() {
        const extraWithholding = this.calculateExtraWithholding()
        const mjw1 = this.formData.mjwLine1
        const mjw2a = this.formData.mjwLine2a
        const mjw2b = this.formData.mjwLine2b
        const pay = this.getPayFrequency(
          this.formData.payFrequency,
          this.formData.customPayFrequency,
        )
        const job1 = this.formData.job1
        const job2 = this.formData.job2
        const job3 = this.formData.job3
        this.$emit('updateFromMjw', extraWithholding, mjw1, mjw2a, mjw2b, pay, job1, job2, job3)
      },

      getPayFrequency(payFrequency, customPayFrequency) {
        if (payFrequency === 'custom') {
          return parseInt(customPayFrequency)
        }
        return PAY_FREQUENCIES[payFrequency] || 0
      },

      // calculate the value of the 'Extra Withholding' field on the W4
      calculateExtraWithholding() {
        const numberOfJobs = parseInt(this.jobs)

        const jobs = [
          parseFloat(this.formData.job1) || 0,
          parseFloat(this.formData.job2) || 0,
          parseFloat(this.formData.job3) || 0,
        ].sort((a, b) => a - b) // from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort

        return numberOfJobs < 3
          ? this.calculateExtraWithholdingTwoJobs(jobs)
          : this.calculateExtraWithholdingThreeJobs(jobs)
      },

      calculateExtraWithholdingTwoJobs(jobs){
        const nextHighestPayingJob = jobs[1]
        const highestPayingJob = jobs[2]

        let lookupValue
        switch (this.status) {
          case 'single_or_married_filing_separately':
            lookupValue = this.getValueFromStatusOneTable(highestPayingJob, nextHighestPayingJob)
            this.formData.mjwLine1 = lookupValue
            return this.processLookupValue(lookupValue)

          case 'married_filing_jointly':
            lookupValue = this.getValueFromStatusTwoTable(highestPayingJob, nextHighestPayingJob)
            this.formData.mjwLine1 = lookupValue
            return this.processLookupValue(lookupValue)

          case 'head_of_household':
            lookupValue = this.getValueFromStatusThreeTable(highestPayingJob, nextHighestPayingJob)
            this.formData.mjwLine1 = lookupValue
            return this.processLookupValue(lookupValue)

          default:
            return 0
        }
      },

      calculateExtraWithholdingThreeJobs(jobs){
        const lowestPayingJob = jobs[0]
        const nextHighestPayingJob = jobs[1]
        const highestPayingJob = jobs[2]

        let lookupValue
        let lookupValueTwo
        switch (this.status) {
          case 'single_or_married_filing_separately':
            lookupValue = this.getValueFromStatusOneTable(highestPayingJob, nextHighestPayingJob)
            this.formData.mjwLine2a = lookupValue
            lookupValueTwo = this.getValueFromStatusOneTable((highestPayingJob + nextHighestPayingJob), lowestPayingJob)
            this.formData.mjwLine2b = lookupValueTwo
            return this.processLookupValue((lookupValue + lookupValueTwo))

          case 'married_filing_jointly':
            lookupValue = this.getValueFromStatusTwoTable(highestPayingJob, nextHighestPayingJob)
            this.formData.mjwLine2a = lookupValue
            lookupValueTwo = this.getValueFromStatusTwoTable((highestPayingJob + nextHighestPayingJob), lowestPayingJob)
            this.formData.mjwLine2b = lookupValueTwo
            return this.processLookupValue((lookupValue + lookupValueTwo))

          case 'head_of_household':
            lookupValue = this.getValueFromStatusThreeTable(highestPayingJob, nextHighestPayingJob)
            this.formData.mjwLine2a = lookupValue
            lookupValueTwo = this.getValueFromStatusThreeTable((highestPayingJob + nextHighestPayingJob), lowestPayingJob)
            this.formData.mjwLine2b = lookupValueTwo
            return this.processLookupValue((lookupValue + lookupValueTwo))

          default:
            return 0

        }
      },

      // uses values from lookup tables to determine withholding
      // this will get called before we have all our values in place
      // and will return 0 when we don't have enough data to make a calculation
      processLookupValue(lookupValue) {
        const payFrequency = this.getPayFrequency(
          this.formData.payFrequency,
          this.formData.customPayFrequency,
        )
        const periodValue = lookupValue / payFrequency

        let processedLookupValue = 0
        if (periodValue < Infinity) {
          processedLookupValue = periodValue
        }

        return processedLookupValue.toFixed(2)
      },

      // we have three different lookup charts - one for each filing status
      // the `rows` are the left hand side of the chart and are used to look up the value for the highest paying job
      // the `columns` are top of the chart, and are used to look up the value for the lower paying job
      // `table` is an array of arrays representing the data in the chart
      // the positions of the `row` and the `column` are used to access the data in `table`
      getValueFromStatusOneTable(highest, lowest) {
        // single or married filing separately
        const rows = {
          1: { low: 0,      high: 9999     },
          2: { low: 10000,  high: 19999    },
          3: { low: 20000,  high: 29999    },
          4: { low: 30000,  high: 39999    },
          5: { low: 40000,  high: 59999    },
          6: { low: 60000,  high: 79999    },
          7: { low: 80000,  high: 99999    },
          8: { low: 100000, high: 124999   },
          9: { low: 125000, high: 149999   },
          10: { low: 150000, high: 174999   },
          11: { low: 175000, high: 199999   },
          12: { low: 200000, high: 249999   },
          13: { low: 250000, high: 399999   },
          14: { low: 400000, high: 449999   },
          15: { low: 450000, high: Infinity },
        }

        const columns = this.lookupTableColumns()

        const table = [
          // comment row below is the salary lower bounds, for easier column referencing
          //0   10000 20000  30000  40000  50000  60000  70000  80000  90000  100000 110000
          [240,  870,  1020, 1020,  1020,  1540,  1870,  1870,  1870,  1870,  1910,  2040],  // 1
          [870,  1680, 1830, 1830,  2350,  3350,  3680,  3680,  3680,  3720,  3920,  4050],  // 2
          [1020, 1830, 1980, 2510,  3510,  4510,  4830,  4830,  4870,  5070,  5270,  5400],  // 3
          [1020, 1830, 2510, 3510,  4510,  5510,  5830,  5870,  6070,  6270,  6470,  6600],  // 4
          [1390, 3200, 4360, 5360,  6360,  7370,  7890,  8090,  8290,  8490,  8690,  8820],  // 5
          [1870, 3680, 4830, 5840,  7040,  8240,  8770,  8970,  9170,  9370,  9570,  9700],  // 6
          [1870, 3690, 5040, 6240,  7440,  8640,  9170,  9370,  9570,  9770,  9970,  10810], // 7
          [2040, 4050, 5400, 6600,  7800,  9000,  9530,  9730,  10180, 11180, 12180, 13120], // 8
          [2040, 4050, 5400, 6600,  7800,  9000,  10180, 11180, 12180, 13180, 14180, 15310], // 9
          [2040, 4050, 5400, 6860,  8860,  10860, 12180, 13180, 14230, 15530, 16830, 18060], // 10
          [2040, 4710, 6860, 8860,  10860, 12860, 14380, 15680, 16980, 18280, 19580, 20810], // 11
          [2720, 5610, 8060, 10360, 12660, 14960, 16590, 17890, 19190, 20490, 21790, 23020], // 12
          [2970, 6080, 8540, 10840, 13140, 15440, 17060, 18360, 19660, 20960, 22260, 23500], // 13
          [2970, 6080, 8540, 10840, 13140, 15440, 17060, 18360, 19660, 20960, 22260, 23500], // 14 (this is the same as the previous row)
          [3140, 6450, 9110, 11610, 14110, 16610, 18430, 19930, 21430, 22930, 24430, 25870],  // 15
        ]

        const lookupRow = this.getLookupRow(rows, highest)
        const lookupColumn = this.getLookupColumn(columns, lowest)

        return table[lookupRow][lookupColumn]
      },

      getValueFromStatusTwoTable(highest, lowest) {
        // married filing jointly or qualifying widow(er)
        const rows = {
          1: { low: 0,      high: 9999     },
          2: { low: 10000,  high: 19999    },
          3: { low: 20000,  high: 29999    },
          4: { low: 30000,  high: 39999    },
          5: { low: 40000,  high: 49999    },
          6: { low: 50000,  high: 59999    },
          7: { low: 60000,  high: 69999    },
          8: { low: 70000,  high: 79999    },
          9: { low: 80000,  high: 99999    },
          10: { low: 100000, high: 149999   },
          11: { low: 150000, high: 239999   },
          12: { low: 240000, high: 259999   },
          13: { low: 260000, high: 279999   },
          14: { low: 280000, high: 299999   },
          15: { low: 300000, high: 319999   },
          16: { low: 320000, high: 364999   },
          17: { low: 365000, high: 524999   },
          18: { low: 525000, high: Infinity },
        }

        const columns = this.lookupTableColumns()

        const table = [
          // comment row below is the salary lower bounds, for easier column referencing
          //0   10000 20000  30000  40000  50000  60000  70000  80000  90000  100000 110000
          [0,    0,    780,   850,   940,   1020,  1020,  1020,  1020,  1020,  1020,  1370],  // 1
          [0,    780,  1780,  1940,  2140,  2220,  2220,  2220,  2220,  2220,  2570,  3570],  // 2
          [780,  1780, 2870,  3140,  3340,  3420,  3420,  3420,  3420,  3770,  4770,  5770],  // 3
          [850,  1940, 3140,  3410,  3610,  3690,  3690,  3690,  4040,  5040,  6040,  7040],  // 4
          [940,  2140, 3340,  3610,  3810,  3890,  3890,  4240,  5240,  6240,  7240,  8240],  // 5
          [1020, 2220, 3420,  3690,  3890,  3970,  4320,  5320,  6320,  7320,  8320,  9320],  // 6
          [1020, 2220, 3420,  3690,  3890,  4320,  5320,  6320,  7320,  8320,  9320,  10320], // 7
          [1020, 2220, 3420,  3690,  4240,  5320,  6320,  7320,  8320,  9320,  10320, 11320], // 8
          [1020, 2220, 3620,  4890,  6090,  7170,  8170,  9170,  10170, 11170, 12170, 13170], // 9
          [1870, 4070, 6270,  7540,  8740,  9820,  10820, 11820, 12830, 14030, 15230, 16430], // 10
          [1960, 4360, 6760,  8230,  9630,  10910, 12110, 13310, 14510, 15710, 16910, 18110], // 11
          [2040, 4440, 6840,  8310,  9710,  10990, 12190, 13390, 14590, 15790, 16990, 18190], // 12
          [2040, 4440, 6840,  8310,  9710,  10990, 12190, 13390, 14590, 15790, 16990, 18190], // 13 (this is the same as the previous row)
          [2040, 4440, 6840,  8310,  9710,  10990, 12190, 13390, 14590, 15790, 16990, 18380], // 14
          [2040, 4440, 6840,  8310,  9710,  10990, 12190, 13390, 14590, 15980, 17980, 19980], // 15
          [2040, 4440, 6840,  8310,  9710,  11280, 13280, 15280, 17280, 19280, 21280, 23280], // 16
          [2720, 6010, 9510,  12080, 14580, 16950, 19250, 21550, 23850, 26150, 28450, 30750], // 17
          [3140, 6840, 10540, 13310, 16010, 18590, 21090, 23590, 26090, 28590, 31090, 33590],  // 18
        ]

        const lookupRow = this.getLookupRow(rows, highest)
        const lookupColumn = this.getLookupColumn(columns, lowest)

        return table[lookupRow][lookupColumn]
      },

      getValueFromStatusThreeTable(highest, lowest) {
        // head of household
        const rows = {
          1: { low: 0,      high: 9999     },
          2: { low: 10000,  high: 19999    },
          3: { low: 20000,  high: 29999    },
          4: { low: 30000,  high: 39999    },
          5: { low: 40000,  high: 59999    },
          6: { low: 60000,  high: 79999    },
          7: { low: 80000,  high: 99999    },
          8: { low: 100000, high: 124999   },
          9: { low: 125000, high: 149999   },
          10: { low: 150000, high: 174999   },
          11: { low: 175000, high: 199999   },
          12: { low: 200000, high: 249999   },
          13: { low: 250000, high: 449999   },
          14: { low: 450000, high: Infinity },
        }

        const columns = this.lookupTableColumns()

        const table = [
          // comment row below is the salary lower bounds, for easier column referencing
          //0   10000 20000  30000  40000  50000  60000  70000  80000  90000  100000 110000
          [0,    510,  850,  1020,  1020,  1020,  1020,  1220,  1870,  1870,  1870,  1960],  // 1
          [510,  1510, 2020, 2220,  2220,  2220,  2420,  3420,  4070,  4070,  4160,  4360],  // 2
          [850,  2020, 2560, 2760,  2760,  2960,  3960,  4960,  5610,  5700,  5900,  6100],  // 3
          [1020, 2220, 2760, 2960,  3160,  4160,  5160,  6160,  6900,  7100,  7300,  7500],  // 4
          [1020, 2220, 2810, 4010,  5010,  6010,  7070,  8270,  9120,  9320,  9520,  9720],  // 5
          [1070, 3270, 4810, 6010,  7070,  8270,  9470,  10670, 11520, 11720, 11920, 12120], // 6
          [1870, 4070, 5670, 7070,  8270,  9470,  10670, 11870, 12720, 12920, 13120, 13450], // 7
          [2020, 4420, 6160, 7560,  8760,  9960,  11160, 12360, 13210, 13880, 14880, 15880], // 8
          [2040, 4440, 6180, 7580,  8780,  9980,  11250, 13250, 14900, 15900, 16900, 17900], // 9
          [2040, 4440, 6180, 7580,  9250,  11250, 13250, 15250, 16900, 18030, 19330, 20630], // 10
          [2040, 4510, 7050, 9250,  11250, 13250, 15250, 17530, 19480, 20780, 22080, 23380], // 11
          [2720, 5920, 8620, 11120, 13420, 15720, 18020, 20320, 22270, 23570, 24870, 26170], // 12
          [2970, 6470, 9310, 11810, 14110, 16410, 18710, 21010, 22960, 24260, 25560, 26860], // 13
          [3140, 6840, 9880, 12580, 15080, 17580, 20080, 22580, 24730, 26230, 27730, 29230],  // 14
        ]

        const lookupRow = this.getLookupRow(rows, highest)
        const lookupColumn = this.getLookupColumn(columns, lowest)

        return table[lookupRow][lookupColumn]
      },

      // used to find the position on the side of the chart
      getLookupRow(rows, highest) {
        const rowKeys = Object.keys(rows)
        for (const key of rowKeys) {
          if (highest <= rows[key]['high'] && highest >= rows[key]['low'] ) {
            return key - 1
            break
          }
        }
      },

      // used to find the position at the top of the chart
      getLookupColumn(columns, lowest) {
        const columnKeys = Object.keys(columns)
        for (const key of columnKeys) {
          if (lowest <= columns[key]['high'] && lowest >= columns[key]['low']) {
            return key - 1
            break
          }
        }
      },

      // the 'columns' for all three of the charts are identical
      lookupTableColumns() {
        return {
          1: { low: 0,      high: 9999   },
          2: { low: 10000,  high: 19999  },
          3: { low: 20000,  high: 29999  },
          4: { low: 30000,  high: 39999  },
          5: { low: 40000,  high: 49999  },
          6: { low: 50000,  high: 59999  },
          7: { low: 60000,  high: 69999  },
          8: { low: 70000,  high: 79999  },
          9: { low: 80000,  high: 89999  },
          10: { low: 90000,  high: 99999  },
          11: { low: 100000, high: 109999 },
          12: { low: 110000, high: 120000 },
        }
      },
    },
  }
</script>
