import UDFValuesStoreConcern from './concerns/udf_values_store_concern'
import HALAPIConcern from './concerns/hal_api_concern'

export default class DocumentSubmission extends Backbone.Model.extend({
  ...HALAPIConcern,
  ...UDFValuesStoreConcern,
}) {
  parse(data, options) {
    this.parseHAL(data)

    // Deep initialize UDF fields
    this.parseUDFValuesStore(data, 'fields')

    return data
  }
}
