<template>
  <div>
    <WbModal
      ref="modal"
      title="Update Employment Dates"
      watchPageContextVariable="bulk_update_employments"
      :locked="$wait.is('bulk_update_employments_loading')"
      @modalHidden="handleModalHidden"
    >
      <v-wait :for="loadingName">
        <template #waiting>
          <Spinner :showLongWaitMessage="true" />
        </template>
        <p ref="updateNotification" class="pb-3">Update the employment dates for {{ pluralizedStaffMembers }}.</p>
        <div class="form-horizontal">
          <EmploymentDates
            v-model="dateChanges"
            :errors="formErrors"
            orientation='horizontal'
            placeholder='Unchanged'
          />
        </div>
        <hr />
        <ScheduleOnboarding
          label="When would you like the selected staff member(s) to begin receiving notifications?"
          :value="dateChanges.onboarding_start_date"
          :errors="formErrors"
        />
        <div class="small">
          NOTE: Deactivated staff members will not receive ANY communication until they are re-activated. At that time, your selected option will go into effect.
        </div>
      </v-wait>
      <template #footer>
        <button class="btn btn-default" :disabled="$wait.is('bulk_update_employments_loading')" @click="hide">Cancel</button>
        <LaddaButton ref="submitButton" :disabled="noDatesSelected" @click="submit">Submit</LaddaButton>
      </template>
    </WbModal>
    <BulkActionListAlert
      ref="alert"
      permissionName="update_employments"
      permissionModule="employments"
      collectionModule="employees"
      :actionMethod="hasEmployment"
      actionErrorMessage="The employment dates for these staff members <strong>cannot be updated</strong> because they do not have an employment."
      :displayNameLookupFn="displayNameLookupFn"
      :collectionViewName="collectionViewName"
      @continue="openModal"
      @cancelled="$emit('hidden')"
    />
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex'
  import * as mutate from 'vuex/mutation_types'
  import WbModal from 'components/common/WbModal'
  import ScheduleOnboarding from 'components/employees/ScheduleOnboarding'
  import EmploymentDates from 'components/employments/EmploymentDates'
  import BulkActionListAlert from 'components/common/BulkActionListAlert'
  import Spinner from 'components/common/Spinner'
  import LaddaButton from 'components/common/LaddaButton'
  import Locale from 'lib/locale'
  import Util from 'lib/util'
  import StringUtil from 'lib/util/string'

  export default {
    name: 'bulk-update-employments',

    components: {
      WbModal,
      EmploymentDates,
      ScheduleOnboarding,
      BulkActionListAlert,
      Spinner,
      LaddaButton,
    },

    props: {
      collectionViewName: {
        type: String,
        required: true,
      },
    },

    data() {
      return {
        dateChanges: {
          start_date: null,
          end_date: null,
          hire_date: null,
          onboarding_start_date: {
            selected: null,
            date: null,
          },
        },
        loadingName: 'bulk_update_employments_loading',
        formErrors: {},
      }
    },

    computed: {
      employees() {
        return this.employeesGetBatch(this.employeeIds)
      },

      employeeIds() {
        return this.$store.state[this.collectionViewName].selected
      },

      pluralizedStaffMembers() {
        return StringUtil.pluralize(this.employees.length, 'staff member')
      },

      // This creates an object that contains all of the date changes requested by the user. If the user only selects date
      // changes for *some* of the fields (for example, if they only want to update the start date), we want to update the
      // selected fields but we don't want the other fields to be updated to null. The API is designed to update any field
      // that is listed in the params so we only want to send the fields with new dates; we don't want to send a field with
      // null or empty string, for example, unless the user wants that field reset.
      //
      // The only time we want to purposefully set an employment date to null is when the user selects "Don't Onboard",
      // which means that the API should reset the onboarding_start_date to null. Otherwise, if the user doesn't select
      // a date for a field, we don't want to include that field in the params sent to the API. For example, if the user
      // has selected a start date and "Onboard Now", we will send params that look like this:
      //     { dateChanges: { start_date: 01-31-2019, onboarding_start_date: 01-20-2019 }}
      // And if the the user has selected a start date and "Don't Onboard", we will send params that look like this:
      //     { dateChanges: { start_date: 01-31-2019, onboarding_start_date: null }}
      // The end date and hire date are not be included in the list of params because we don't want them reset to null.
      //
      // The selectedDates() function ensures that only the fields with selected dates are updated.
      selectedDates() {
        const submittedDates = {}
        Object.keys(this.dateChanges).forEach((key) => {
          if (key == 'onboarding_start_date') {
            // We track the user's selection with a "selection" property on the onboarding_start_date. If the user chooses
            // "Don't Onboard", the "selection" property is updated to "never" and the OSD value is set to null here.
            // Otherwise, the OSD is assigned a date: either today's date for "Onboard Today" or a date selected by the
            // user when they choose "Onboard Later".
            if (this.dateChanges[key].selection == 'never' || this.dateChanges[key].date) {
              submittedDates[key] = this.dateChanges[key].date
            }
          } else if (this.dateChanges[key]) {
            { submittedDates[key] = this.dateChanges[key] }
          }
        })

        return submittedDates
      },

      noDatesSelected() {
        return (Object.keys(this.selectedDates).length === 0)
      },

      ...mapGetters({
        pageContext: 'pageContext',
        getEmployee: 'employees/get',
        employeesGetBatch: 'employees/getBatch',
      }),
    },

    methods: {
      hasEmployment(employee) {
        // ensure that the employee has employment
        return employee.employment_status != 'unemployed'
      },

      handleModalHidden() {
        // Reset the data to allow dynamic reuse
        Object.assign(this.$data, this.$options.data())

        // Indicate the modal has closed
        this.$emit('hidden')
      },

      openModal(permittedEmployeeIds) {
        this.setPageContextKeys({ bulk_update_employments: permittedEmployeeIds })
      },

      displayNameLookupFn(employee) {
        return this.$employee.fullName(employee)
      },

      hide() {
        this.$refs.modal.hide()
      },

      submit() {
        this.$wait.start(this.loadingName)

        this.employmentsBulkUpdate({
          employeeIds: this.employeeIds,
          dateChanges: this.selectedDates,
        }).then(() => { // success callback
          this.$wait.end(this.loadingName)

          Util.showFlashNotice(Locale.t('employments.update.success', this.employees.length))

          this.$emit('success')

          this.$nextTick(() => {
            this.hide()
          })
        }).catch((xhr) => {
          if (xhr.responseJSON) {
            // On error, the API will provide back a full list of specific errors which are displayed on input fields.
            this.formErrors = xhr.responseJSON['errors']
          }
          this.$refs.submitButton.stop()
          this.$wait.end(this.loadingName)
        })
      },

      ...mapActions({
        employmentsBulkUpdate: 'employments/bulkUpdate',
      }),

      ...mapMutations({
        setPageContextKeys: mutate.SET_PAGE_CONTEXT_KEYS,
      }),
    },
  }
</script>
