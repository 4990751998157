<template>
  <div>
    <HistoricalSubmissionsContainer />
  </div>
</template>

<script>
  import HistoricalSubmissionsContainer from 'components/employees/document_assignments/HistoricalSubmissionsContainer'

  export default {
    name: 'the-admin-document-submissions-show-root',
    components: {
      HistoricalSubmissionsContainer,
    },
  }
</script>
