import I9Attachment from 'models/i9/i9_attachment'
import I9DocumentationChoice from 'models/document_submissions/i9_documentation_choice'
import Routes from 'lib/routes'

// Collection of I9Attachments that make up the actual chosen documentation
export default Backbone.Collection.extend({
  model: I9Attachment,

  save: function(submissionId) {
    const path = Routes.update_documentation_i9_submission_path({ id: submissionId })

    const data = {
      documentation_set: this.map(function(a){ return a.toJSON() }),
      is_edit_section_2: gon.is_edit_section_2 || false,
    }

    // REVIEW: Use ApiOperation
    return $.ajax({
      url: path,
      method: 'PATCH',
      dataType: 'json',
      contentType: 'application/json',
      data: JSON.stringify(data),
      wbGenericFailureMsg: "Sorry, we couldn't update the documentation.",
    }).always(function() { NProgress.done() })
  },

  // Sort the documents
  // First, sort by list key (A < B < C)
  // Next, sort by the optional list_index field
  //
  // returns - integer, -1, 0, 1
  //            -1 : modelA before modelB
  //            0  : equal, no difference
  //            1  : modelB before modelA
  comparator: function(modelA, modelB){
    // Sort by list_key (A,B,C) and list_index (0,1,2) by converting to integer values
    // list_key   => 10's place
    // list_index => 1's place
    const listKeyVals = {A: 0, B: 10, C: 20}
    const modelAVal = listKeyVals[modelA.get('list_key')] + (modelA.get('list_index') || 0)
    const modelBVal = listKeyVals[modelB.get('list_key')] + (modelB.get('list_index') || 0)

    if ( modelAVal < modelBVal ) {
      return -1
    } else if ( modelAVal > modelBVal ) {
      return 1
    } else {
      return 0
    }
  },

  // Is the selected combination of documents valid to continue
  // REVIEW: Turn into validate() method?
  valid: function() {
    const listKeys = _.uniq(this.map(function(m){return m.get('list_key')}))

    return (  _.intersection(listKeys, ['A']).length == 1 ||
              _.intersection(listKeys, ['B', 'C']).length == 2)
  },

  // Is this document in the collection?
  //
  // key - the unique documentation_key of the choice
  //
  // returns - Boolean
  hasAttachment: function(key) {
    return !!this.findWhere({ documentation_key: key })
  },

  // Removes invalid attachments in existing collection
  //
  // For each existing attachment:
  // - remove if it is in the same list
  // - remove if it is in a different group of options (A vs B/C)
  // - remove if it is not a valid attachment for citizenship
  //
  // citizenshipDesignation - (String) the user's choice from I9DocumentationChoices
  // docSetChoice - (Backbone.Model<I9DocumentationChoice>) the user's choice from I9DocumentationChoices
  removeInvalidAttachments: function (citizenshipDesignation, docSetChoice) {
    const selectedList = docSetChoice && docSetChoice.get('list_key')
    const toRemove = []

    this.each((attachment) => {
      const attachmentList = attachment.get('list_key')

      if (selectedList && (attachmentList == selectedList)){
        toRemove.push(attachment)
      } else if (selectedList && !this.compatibleLists(selectedList, attachmentList)){
        toRemove.push(attachment)
      } else if (citizenshipDesignation && !attachment.docSetChoice.validCitizenship(citizenshipDesignation)) {
        toRemove.push(attachment)
      }
    })

    // Remove anything that is no longer valid. Since `additional_docs` share a list id with their parent, they are
    // caught in this removal as well.
    this.remove(toRemove)
  },

  removeAllAttachments: function () {
    this.remove(this.models)
  },

  // Add document and all additional documents to the collection
  // Removes any documents that are no longer compatible
  //
  // docSetChoice - (Backbone.Model<I9DocumentationChoice>) the user's choice from I9DocumentationChoices
  // citizenshipDesignation - (String) citizenship designation e.g. (permanent_resident, citizen, etc.)
  selectDocument: function(docSetChoice, citizenshipDesignation) {
    this.removeInvalidAttachments(citizenshipDesignation, docSetChoice)

    // Create a new empty attachment for this choice
    this.add(this._createAttachmentFromDocSetChoice(docSetChoice))

    // Automatically include attachments for additional documents if the docSetChoice specifies so
    const additionalDocs = docSetChoice.get('additional_docs')
    if (additionalDocs && additionalDocs.length) {
      additionalDocs.each(function(additionalDocSetChoice){
        this.add(this._createAttachmentFromDocSetChoice(additionalDocSetChoice))
      }.bind(this))
    }
  },

  // Identify if documents from two lists can be combined together
  // B + C documents can work together
  // A doesn't work with anything
  //
  // return true if compatible
  compatibleLists: function(listKey1, listKey2){
    const listOption1 = (listKey1 == 'A') ? 'A' : 'B'
    const listOption2 = (listKey2 == 'A') ? 'A' : 'B'

    return listOption1 == listOption2
  },

  // Is this documentation set from a legacy I9
  // Indicated by the absence of 'documentation_key'
  //
  // returns true if any documents do not contain 'documentation_key'
  isLegacy: function() {
    let isLegacy = false
    _.each(this.models, function(attachment){
      const key = attachment.get('documentation_key')
      isLegacy = isLegacy || (!key || key.length == 0)
    })
    return isLegacy
  },

  // (Private) Creates an I9Attachment model from the original choice metadata.
  // (NOTE: additional docs which may be specified by `docSetChoice.additional_docs` are added via selectDocument, not
  // here).
  //
  // docSetChoice [I9DocumentationChoice] - model from I9DocumentationChoices
  //
  // Returns - I9Attachment
  _createAttachmentFromDocSetChoice: function(docSetChoice) {
    let prefillDocumentTitleValue = docSetChoice.get('document_title')
    if (docSetChoice.hasMultiplePrefilledValues('document_title')) {
      prefillDocumentTitleValue = prefillDocumentTitleValue[0]
    }

    let prefillIssuingAuthorityValue = docSetChoice.get('issuing_authority')
    if (docSetChoice.hasMultiplePrefilledValues('issuing_authority')) {
      prefillIssuingAuthorityValue = prefillIssuingAuthorityValue[0]
    }

    const instance = new I9Attachment({
      documentation_key: docSetChoice.get('key'),
      list_key: docSetChoice.get('list_key'),
      document_title: prefillDocumentTitleValue,
      issuing_authority: prefillIssuingAuthorityValue,
      document_number: docSetChoice.get('document_number'),
      expiration_date: docSetChoice.isExpirationDateRequired ? docSetChoice.get('expiration_date') || null : 'N/A',
      validation_enabled: docSetChoice.get('validation_enabled') ? true : false,
    })
    instance.docSetChoice = docSetChoice

    return instance
  },

  // Returns attachment from Documentation Set using a filename identifier
  attachmentForIdentifier: function (identifier) {
    return this.find(function (attachment) {
      const front = attachment.get('file_front_filename') == identifier
      const back = attachment.get('file_back_filename') == identifier

      return (front || back)
    }) || null
  },

  // Goes through each I9Attachment in this collection and sets `docSetChoice` as a reference back to the original
  // I9DocumentationChoice. This is done by lookup on the `documentation_key`. For legacy attachments that do not have
  // a documentation key, a dummy I9DocumentationChoice is substituted.
  //
  // allChoices - [I9DocumentationChoices] collection of all possible choices (DI)
  lookupAndLinkToChoices: function(allChoices) {
    this.each((attachment) => {
      const documentationKey = attachment.get('documentation_key')
      if (documentationKey && documentationKey.length > 0) {
        attachment.docSetChoice = allChoices.findByDocumentationKey(documentationKey)
      } else {
        // If the attachment doesn't have a documentation key, it means it's from a legacy I9. We substitute a dummy
        // docSetChoice model since we can't officially look up the original.
        attachment.docSetChoice = new I9DocumentationChoice({
          optional: false,
          view_title: attachment.get('document_title'),
        })
      }
    })
  },

})
