<template>
  <DashboardWidget
    :widgetTitle="widgetTitle"
    :errorMsg="errorMsg"
    :lastUpdated="lastUpdated"
    @refresh="fetch(true)"
  >
    <v-wait :for="source">
      <template #waiting>
        <Spinner :showLongWaitMessage="true" />
      </template>
      <WbBreakdownChart
        v-if="!errorMsg.length && chartData"
        :legendData="legendData"
        :chartData="chartData"
        :listViewPath="this.getPathFromFilter('all')"
        modelNameHumanized="Form I-9 Document"
        @section-click="handleSectionClick"
      />
    </v-wait>
  </DashboardWidget>
</template>
<script>
  import WbBreakdownChart from 'components/common/WbBreakdownChart'
  import { BREAKDOWN_VARIANTS } from 'components/admin/dashboard/BreakdownChartConstants'
  import Spinner from 'components/common/Spinner'
  import DashboardWidget from 'components/admin/dashboard/DashboardWidget'
  import UrlUtil from 'lib/util/url'
  import DashboardFetch from 'components/mixins/DashboardFetch'

  export default {
    name: 'i9-reverify-container',
    components: {
      DashboardWidget,
      Spinner,
      WbBreakdownChart,
    },
    mixins: [DashboardFetch],
    data () {
      return {
        widgetTitle: 'Expiring I-9 Documents Requiring Review',
        legendData: [
          {
            legend_key: 'reverify_late',
            message: 'expired',
            path: this.getPathFromFilter('reverify_late'),
            variant: BREAKDOWN_VARIANTS.NEEDS_IMMEDIATE_ATTENTION,
          },
          {
            legend_key: 'reverify_soon',
            message: 'expiring within 30 days',
            path: this.getPathFromFilter('reverify_soon'),
            variant: BREAKDOWN_VARIANTS.NEEDS_ATTENTION,
          },
          {
            legend_key: 'reverify_future',
            message: 'expiring in the future',
            path: this.getPathFromFilter('reverify_future'),
            variant: BREAKDOWN_VARIANTS.COMPLETE,
          },
        ],
        source: 'dashboard_i9_reverify',
      }
    },
    methods: {
      handleSectionClick ({ legend_key}) {
        const path = this.getPathFromFilter(legend_key)
        Turbolinks.visit(path)
      },
      getPathFromFilter (expiration) {
        return UrlUtil.urlWithParams(this.$routes.onboarding_i9_index_path, {
          filter: 'reverify',
          expiration,
        })
      },
    },
  }
</script>
