import HALAPIConcern from 'models/concerns/hal_api_concern'


const _EmployeeGroupMembership = Backbone.Model.extend({
  defaults: { name: "" },
  parse: function(data, options) {
    this.parseHAL(data)
    return data
  },

  // Backend API only cares about `employee_id` and `group_id`
  toJSON: function(options) { 
    const attrs = _.clone(this.attributes)
    delete attrs.name
    return attrs
  },
})
// Mixin concerns:
_.extend(_EmployeeGroupMembership.prototype, 
  HALAPIConcern)

export default _EmployeeGroupMembership
