// DEPRECATED. MOVE TO FormValidation PLUGIN and/or service-side validation

(function($) {
  $.fn.bootstrapValidator.validators.signaturePad = {
    /**
     * @param {BootstrapValidator} validator The validator plugin instance
     * @param {jQuery} $field The jQuery object represents the field element. In this case, it is the #signature-pad wrapper, an element
     *                 which has the attribute name="signature-pad-input".
     * @param {Object} options The validator options
     * @returns {boolean}
     */
    validate: function(validator, $field, options) {
      var signaturePad = $field.data("signaturePad")
      if (signaturePad == undefined) { // not instantiated yet
        return true
      }

      return !signaturePad.isEmpty()
    }
  }
}(window.jQuery))
