// I-9 Form: Preparer/Translator Step

import I9PreparerStepContainer from 'components/i9/I9PreparerStepContainer'
import RemoteFormValidation from 'lib/remote_form_validation'
import Routes from 'lib/routes'
import { pureOrphanVue } from 'lib/vue/pureOrphanVue'

export default Backbone.Marionette.ItemView.extend({
  template: '#i9-preparer-tmpl',
  ui: {
    form: 'form#supplement-a',
  },

  modelEvents: {
    sync: "render",
  },

  onRender: function() {
    setTimeout(() => {
      this.renderVueComponent()

      // bind Vue form to Marionette UI
      this.bindUIElements()

      new RemoteFormValidation({
        resourceName: 'i9_submission',
        model: this.model,
        formEl: this.ui.form,
        validations: {},
        path: Routes.staff_i9_submission_path,
      })
    })
  },

  renderVueComponent() {
    this.vueComponent = pureOrphanVue(I9PreparerStepContainer, "#i9-preparer-step-container", {
      props: {
        supportsMultipleTranslators: gon.i9_next_status,
        supplementA: this.model.get('supplement_a'),
        usStates: gon.us_states,
        updateBackboneModel: (data) => { this.setModel(data) }, // Update model with data entered in Vue component.
        nextButtonStatus: (status) => { this.canAdvance = status }, // Enable/disable 'next' button
      },
    }, {
      removeElementOnDestroy: true,
    })
  },

  setModel: function(supplementA) {
    this.model.set(supplementA)
  },

  validate: function(validationCompleteCallback){
    if (this.canAdvance) {
      this.validationCompleteCallback = validationCompleteCallback(true)
    } else {
      this.vueComponent.handleErrors()
      this.validationCompleteCallback = validationCompleteCallback(false)
    }
  },
})
