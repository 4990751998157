import AsyncModalItemView from 'views/lib/async_modal_itemview'
import Auth from 'lib/util/auth'
import Routes from 'lib/routes'
import String from 'lib/util/string'
import NewEmploymentFormFields from 'components/employees/NewEmploymentFormFields'
import RehireSummary from 'components/employees/RehireSummary'
import * as mutate from 'vuex/mutation_types'
import { pureOrphanVue } from 'lib/vue/pureOrphanVue'

export default AsyncModalItemView.extend({
  asyncItemViewUniquenessKey: 'rehire_wizard_modal_view',

  behaviors: {
    RailsRemoteFormCallbacks: {},
    CustomFieldsManners: {},
  },

  ui: {
    title: '.modal-header > h2',
    form: 'form',
    roleNameHiddenField: 'input[type="hidden"]#employee_rn',
    wizardFinishButton: '.btn-wizard-finish',
    wizardNextButton: '.btn-wizard-next',
    wizardPrevButton: '.btn-wizard-prev',
    formWizard: '.wizard',
    formFieldStartDate: 'input[name="rehire_wizard[new_employment_attributes][start_date]"]',
    formFieldEndDate: 'input[name="rehire_wizard[new_employment_attributes][end_date]"]',
    formFieldHireDate: 'input[name="rehire_wizard[new_employment_attributes][hire_date]"]',
    formFieldOnboardingStartDate: 'input[name="rehire_wizard[new_employment_attributes][onboarding_start_date]"]',
    formFieldEmail: 'input[name="rehire_wizard[user_attributes][email]"]',
    formFieldCustomFields: 'input[name*="rehire_wizard[custom_fields]"]',
    multiselectGroups: '#rehire_wizard_employee_group_ids',
    resetDocumentAssignments: '#rehire_wizard_reset_document_assignment_ids',
  },

  bindings: {
    '.alert-crossover': {
      classes: {
        hidden: {
          observe: 'crossoverStatus',
          onGet: function(value) { return value != 'exists' },
        },
      },
    },
    '.alert-exists': {
      classes: {
        hidden: {
          observe: 'crossoverStatus',
          onGet: function(value) { return value != 'exists_in_account' },
        },
      },
    },
    'span.alert-exists-employee-name': {
      observe: 'crossoverEmployeeProfile',
      onGet: function(employeeProfile) { return `${_.escape(employeeProfile.first_name)} ${_.escape(employeeProfile.last_name)}`},
    },
    '.btn-wizard-next': {
      classes: {
        disabled: {
          observe: 'crossoverStatus',
          onGet: function(value,event) {
            return value == 'exists_in_account' || event.view.model.get("loadingCrossoverStatus")
          },
        },
      },
    },

    '#employment-active-season .info-hire-date': 'hire_date',
    '#employment-active-season .info-start-date': 'start_date',
    '#employment-active-season .info-end-date': 'end_date',
    '#employment-active-season .info-end-date': {
      observe: 'status',
      visible: function(val) {
        return (val != 'active')
      },
      visibleFn: function($el, visible) {
        if (visible && !$el.hasClass('in')) {
          Animations.temporaryHighlight($('td', $el))
        }

        Animations.slideInLTR($el, visible, {
          callback: function() {
            $el.toggleClass('status-active', !visible)
            $el.toggleClass('status-inactive', visible)
          },
        })
      },
      updateView: false,
    },
  },

  triggers: {
    'change @ui.formFieldEmail': 'emailChanged',
    'change @ui.formFieldCustomFields': 'updateCustomFieldsVuex',
  },

  initialize: function(options) {
    this.model.set({
      crossoverStatus: false,
      crossoverEmployeeProfile: {},
      loadingCrossoverStatus: false,
    })

    AsyncModalItemView.prototype.initialize.call(this, options)
  },

  onEmailChanged: function(event,b,c) {
    const email = this.ui.formFieldEmail.val()
    this.checkCrossover(email)
  },

  checkCrossover: function(email) {
    if (email && email.length > 0) {
      this.model.set({loadingCrossoverStatus: true})
      $("input[name='employee[user_attributes][email]']").after()

      $.ajax({
        method: 'GET',
        url: `${Routes.validate_new_email_employees_path}?email=${encodeURIComponent(email)}`,
        progressBar: false,
        wbGenericFailureMsg: "Sorry, an error occurred validating the email.",
      }).done( (response) => {
        if (response.status != 'available') {
          this.addCrossoverStickitBindings()
        }
        // If email matches the current employee, don't show a warning
        const crossoverStatus = response.email == this.model.get('email') ? null : response.status
        this.model.set({
          crossoverStatus: crossoverStatus,
          crossoverEmployeeProfile: response.employee_profile,
          loadingCrossoverStatus: false,
        })
      }).fail( (xhr, textStatus, errorThrown) => {
        this.model.set({loadingCrossoverStatus: false})
      })
    }

    // If crossover, clear while we're checking
    if (this.model.get('crossoverStatus') != 'available') {
      this.model.set({crossoverStatus: false, crossoverEmployeeProfile: {}})
    }
  },

  onBeforeModalShow: function() {
    // Bind enter key
    this.$el.on('keypress', $.proxy(function(e) {
      if (e.keyCode == 13) {
        this.ui.wizardNextButton.click()
        e.preventDefault()
        e.stopPropagation()
        return false
      }
    }, this))
    this.stickit()
  },

  onModalShow: function () {
    this.addressFieldsView = new App.Views.Common.AddressFieldsItemView()
    this.addressFieldsView.render()

    this.loadCustomFieldsVue()
  },

  onRemoteFormSuccess: function(e, data, status, xhr) {
    App.Util.showAjaxFlashNotice(xhr)
    this.hideModal()

    // Reload the page so that the updated data is available for a new rehire modal
    window.location.reload()
  },

  onRemoteFormError: function(e, xhr) {
    if (xhr.getResponseHeader('X-Form-Errors')) {
      this.replaceModalContent(xhr.responseText)
    } else {
      // Modal content is not reset, but we need to re-enable the submit button manually
      this.ui.wizardFinishButton.data('ladda').stop()
    }

    this.stickit()
    // Reapply crossover bindings if applicable
    if (['exists', 'exists_in_account'].indexOf(this.model.get('crossoverStatus')) > -1) {
      this.addCrossoverStickitBindings()
    }
  },

  onRender: function() {
    if (this.getOption('title') && this.getOption('title').length > 0)
    {this.ui.title.text(this.getOption('title'))}

    this.ui.wizardFinishButton.hide()
    this.initializeWizard()

    if (this.getOption('roleName') && this.getOption('roleName').length > 0)
    {this.ui.roleNameHiddenField.val(this.getOption('roleName'))}
  },

  initializeWizard: function() {
    this.ui.formWizard.bootstrapWizard({
      tabClass: null,
      nextSelector: this.ui.wizardNextButton,
      previousSelector: this.ui.wizardPrevButton,
      onTabShow: $.proxy(this.onTabShow, this),
    })
  },

  onTabShow: function(tab, navigation, index) {
    const bsWizard = this.ui.formWizard.data('bootstrapWizard')
    if (!bsWizard) { return }

    const total = bsWizard.navigationLength()
    const currentIdx = bsWizard.currentIndex()

    if (currentIdx >= total) {
      this.ui.wizardNextButton.hide()
      this.ui.wizardFinishButton.show().removeClass('disabled').css('display', '')
    } else {
      this.ui.wizardNextButton.show().css('display', '')
    }

    if ($('#the-reassign-forms-root').length) {
      this.loadReassignFormsVue()
    }

    if ($('#employment-dates-form').length) {
      this.loadEmploymentDatesVue()
    }

    if ($('#rehire-summary').length) {
      this.loadSummaryVue()
    }
  },

  onBeforeDestroy: function() {
    if (this.addressFieldsView) {
      this.addressFieldsView.destroy()
    }
  },

  // Add updated custom fields to Vuex store so they can be included on RehireSummary page.
  onUpdateCustomFieldsVuex: function() {
    const currentCustomFields = $(this.ui.formFieldCustomFields).get().map(cf => {
      // Currency values are stored in the defaultValue field with only one decimal place (if remaining decimal places
      // are zeros). So, 100.00 gets stored in the defaultValue as 100.0, which doesn't match 100.00 in the value field.
      // In order to find matching values (to see if a field has been changed), we're coercing xx.0 numbers to xx.00
      let defaultValue
      if (cf.classList[0] == 'numeric' && cf.classList[1] == 'currency' && cf.defaultValue.match(/^.*[.]\d{1}$/)) {
        defaultValue = `${cf.defaultValue}0`
      } else {
        defaultValue = cf.defaultValue
      }
      return { id: cf.id, current_value: cf.value, original_value: defaultValue }
    })

    window.vuexStore.commit(mutate.SET_PAGE_CONTEXT_KEYS, { customFields: currentCustomFields })
  },

  // When modal is opened, load the current custom-fields into Vuex store so that we can track if they change.
  loadCustomFieldsVue: function() {
    const currentCustomFields = this.model.get('custom_fields').map(cf => ({ id: cf.id, value: cf.get('value_decorated') }))
    window.vuexStore.dispatch('custom_fields/loadInitialData', currentCustomFields)
  },

  loadReassignFormsVue: function() {
    startRootVue('the-reassign-forms-root', {
      pageContext: {
        employee: gon.employee,
        groups: this.ui.multiselectGroups.val(),
        updateGroupsCallback: (groupIds) => {
          $(this.ui.multiselectGroups).val(groupIds)
        },
        updateAssignmentsCallback: (documentAssignmentIds) => {
          $(this.ui.resetDocumentAssignments).val(documentAssignmentIds)
        },
      },
      initialData: {},
    })
  },

  loadEmploymentDatesVue: function() {
    const collectionViewName = 'collection_view_document_assignments'

    // Prepopulate Vuex filterValues with an empty employment_dates object so that setting a date in the wizard
    // doesn't wipe out selected forms.
    window.vuexStore.commit(`${collectionViewName}/SET_FILTER_VALUE`, {
      filter_key: 'employment_dates',
      value: {},
    })

    this.employmentFields = pureOrphanVue(NewEmploymentFormFields, '#employment-dates-form', {
      props: {
        // Initialize Vue component with collection_view_document_assignments so that RehireSummary knows where to fetch updated dates.
        collectionViewName: collectionViewName,
        formPrefix: 'rehire_wizard',
        value: {},

        // When new values are entered into the Vue form, update the fields in the rehire_wizard_form.
        updateCallback: (dates) => {
          this.ui.formFieldStartDate.val(dates.start_date)
          this.ui.formFieldEndDate.val(dates.end_date)
          this.ui.formFieldHireDate.val(dates.hire_date)
          this.ui.formFieldOnboardingStartDate.val(dates.onboarding_start_date)
        },
      },
    })
  },

  loadSummaryVue: function() {
    pureOrphanVue(RehireSummary, '#rehire-summary', {
      props: { employeeId: gon.employee.id },
    }, {
      removeElementOnDestroy: true,
    })
  },

  authFormFieldContent: function(value, element) {
    if (Auth.isBlockedContent(value)){
      $(element).after()
      $(element).hide()
      $(element).after(Auth.blockedContentTag())
    } else {
      $(element).show()
      $(element).parent().find('.blocked-content').remove()
      return value
    }
  },

  // These bindings auto-populate the Profile Info form with the crossover employee's data if the admin enters an email
  // address for a crossover employee.
  addCrossoverStickitBindings: function() {
    const baseOptionsBinding = {updateModel: false, observe: 'crossoverEmployeeProfile'}
    const baseOptionsAttr = {name: 'disabled', observe: 'crossoverEmployeeProfile'}
    const baseOptionsAttrReadonly = {name: 'readonly', observe: 'crossoverEmployeeProfile'}

    this.addBinding(null, 'input[name="rehire_wizard[employee_profile_attributes][first_name]"]', _({
      onGet: function(employeeProfile) { return employeeProfile.first_name },
      attributes: [_({onGet: function(employeeProfile) { return employeeProfile.first_name != undefined }}).extend(baseOptionsAttrReadonly)],
    }).extend(baseOptionsBinding))

    this.addBinding(null, 'input[name="rehire_wizard[employee_profile_attributes][middle_name]"]', _({
      onGet: function(employeeProfile) { return employeeProfile.middle_name },
      // Using first_name on purpose for disable- if it's set, it means someone has had the chance to define the optional
      // middle_name field, which may not be present
      attributes: [_({onGet: function(employeeProfile) { return employeeProfile.first_name != undefined }}).extend(baseOptionsAttrReadonly)],
    }).extend(baseOptionsBinding))

    this.addBinding(null, 'input[name="rehire_wizard[employee_profile_attributes][last_name]"]', _({
      onGet: function(employeeProfile) { return employeeProfile.last_name },
      attributes: [_({onGet: function(employeeProfile) { return employeeProfile.last_name != undefined }}).extend(baseOptionsAttrReadonly)],
    }).extend(baseOptionsBinding))

    this.addBinding(null, 'input[name="rehire_wizard[employee_profile_attributes][address_attributes][street]"]', _({
      onGet: function(employeeProfile) { return employeeProfile.address ? employeeProfile.address.street : null },
      attributes: [_({onGet: function(employeeProfile) { return employeeProfile.address && String.present(employeeProfile.address.street) }}).extend(baseOptionsAttr)],
    }).extend(baseOptionsBinding))

    this.addBinding(null, 'input[name="rehire_wizard[employee_profile_attributes][address_attributes][apt]"]', _({
      onGet: function(employeeProfile) { return employeeProfile.address ? employeeProfile.address.apt : null },
      // Using street on purpose for disable- if it's set, it means someone has had the chance to define the optional
      // apt field, which may not be present
      attributes: [_({onGet: function(employeeProfile) { return employeeProfile.address && String.present(employeeProfile.address.street) }}).extend(baseOptionsAttr)],
    }).extend(baseOptionsBinding))

    this.addBinding(null, 'input[name="rehire_wizard[employee_profile_attributes][address_attributes][city]"]', _({
      onGet: function(employeeProfile) { return employeeProfile.address ? employeeProfile.address.city : null },
      attributes: [_({onGet: function(employeeProfile) { return employeeProfile.address && String.present(employeeProfile.address.city) }}).extend(baseOptionsAttr)],
    }).extend(baseOptionsBinding))

    this.addBinding(null, 'select[name="rehire_wizard[employee_profile_attributes][address_attributes][country]"]', _({
      onGet: function(employeeProfile, event) {
        if (employeeProfile.address == undefined) { return 'US' }
        const countryCode = String.present(employeeProfile.address.country) ? employeeProfile.address.country : 'US'
        this.addressFieldsView.changeCountryByCode(countryCode)

        // Update the state field- this won't propagate correctly otherwise, since the field changed
        if (String.present(employeeProfile.address.state)){
          const stateSelect = $('select[name="rehire_wizard[employee_profile_attributes][address_attributes][state]"]')
          stateSelect.val(employeeProfile.address.state)
          stateSelect.attr('disabled', true)
        }
        return countryCode
      },
      attributes: [_({onGet: function(employeeProfile) { return employeeProfile.address && String.present(employeeProfile.address.country) }}).extend(baseOptionsAttr)],
    }).extend(baseOptionsBinding))

    this.addBinding(null, 'select[name="rehire_wizard[employee_profile_attributes][address_attributes][state]"]', _({
      onGet: function(employeeProfile) { return employeeProfile.address ? employeeProfile.address.state : null }.bind(this),
      attributes: [_({onGet: function(employeeProfile) { return employeeProfile.address && String.present(employeeProfile.address.state) }}).extend(baseOptionsAttr)],
    }).extend(baseOptionsBinding))

    this.addBinding(null, 'input[name="rehire_wizard[employee_profile_attributes][address_attributes][zip]"]', _({
      onGet: function(employeeProfile) { return employeeProfile.address ? employeeProfile.address.zip : null },
      attributes: [_({onGet: function(employeeProfile) { return employeeProfile.address && String.present(employeeProfile.address.zip) }}).extend(baseOptionsAttr)],
    }).extend(baseOptionsBinding))

    this.addBinding(null, 'input[name="rehire_wizard[employee_profile_attributes][phone]"]', _({
      onGet: function(employeeProfile) { return employeeProfile.primary_phone },
      attributes: [_({onGet: function(employeeProfile) { return String.present(employeeProfile.primary_phone) }}).extend(baseOptionsAttr)],
    }).extend(baseOptionsBinding))

    this.addBinding(null, 'select[name="rehire_wizard[employee_profile_attributes][gender]"]', _({
      onGet: function(employeeProfile, event) { this.authFormFieldContent(employeeProfile.gender, $(event.selector)) },
      attributes: [_({onGet: function(employeeProfile) { return String.present(employeeProfile.gender) }}).extend(baseOptionsAttr)],
    }).extend(baseOptionsBinding))

    this.addBinding(null, 'input[name="rehire_wizard[employee_profile_attributes][birthdate]"]', _({
      onGet: function(employeeProfile, event) {
        this.authFormFieldContent(employeeProfile.birthdate, $(event.selector).parent())
      },
      attributes: [_({onGet: function(employeeProfile) { return String.present(employeeProfile.birthdate) }}).extend(baseOptionsAttr)],
    }).extend(baseOptionsBinding))

    this.addBinding(null, 'input[name="rehire_wizard[employee_profile_attributes][ssn]"]', _({
      onGet: function(employeeProfile, event) {
        this.authFormFieldContent(employeeProfile.ssn, $(event.selector))
      },
      attributes: [_({onGet: function(employeeProfile) { return String.present(employeeProfile.ssn) }}).extend(baseOptionsAttr)],
    }).extend(baseOptionsBinding))

    this.addBinding(null, 'input[name="rehire_wizard[employee_profile_attributes][nickname]"]', _({
      onGet: function(employeeProfile) { return employeeProfile.nickname },
      attributes: [_({onGet: function(employeeProfile) { return String.present(employeeProfile.nickname) }}).extend(baseOptionsAttr)],
    }).extend(baseOptionsBinding))
  },
})
