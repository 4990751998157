import { ajax } from 'jquery'
import { getRoute } from 'lib/routes'

const EVENT_TYPE_QR_SCAN = 'qr_scan'

export function submitCountersignQrScan (id, isMatch, actor) {
  return ajax({
    method: 'POST',
    url: getRoute('i9rc_event_path', {
      id,
    }),
    data: {
      i9rc: {
        event_type: EVENT_TYPE_QR_SCAN,
        actor,
        qr_secret_matched: isMatch,
      },
    },
  })
}

export function submitReverifyQrScan (id, isMatch, actor) {
  return ajax({
    method: 'POST',
    url: getRoute('i9_remote_reverify_event_path', {
      id,
    }),
    data: {
      i9_remote_reverify: {
        event_type: EVENT_TYPE_QR_SCAN,
        actor,
        qr_secret_matched: isMatch,
      },
    },
  })
}
