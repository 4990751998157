<template>
  <ul class="nav navbar-nav navbar-right">
    <li>
      <AppSignoutLink />
    </li>
  </ul>
</template>
<script>
  import AppSignoutLink from 'components/navbar/AppSignoutLink'

  export default {
    name: 'authenticated-navbar-nav',
    components: {
      AppSignoutLink,
    },
  }
</script>
