<template>
  <div>
    <div v-if="nonPermittedIds.length">
      <BulkActionWarning
        ref="nonPermittedActionWarning"
        message='You are <strong>not authorized</strong> to perform this action for:'
        :collection="collectionGetBatch(nonPermittedIds)"
        :displayNameLookupFn="displayNameLookupFn"
      />
    </div>
    <div v-if="nonActionableIds.length">
      <BulkActionWarning
        ref="nonActionableActionWarning"
        :message="actionErrorMessage"
        :collection="collectionGetBatch(nonActionableIds)"
        :displayNameLookupFn="displayNameLookupFn"
      />
    </div>
    <div v-if="allowableActions">
      <p class="mt-4">
        <strong>By choosing Next, all items listed above will be unselected and no action will be performed on them.</strong>
      </p>
    </div>
    <div v-else>
      <p class="mt-4">
        None of the selected items are allowed. Select different rows to try again.
      </p>
    </div>
  </div>
</template>

<script>
  import {mapState} from 'vuex'
  import BulkActionWarning from './BulkActionWarning'
  import {difference, intersection, union} from "underscore"

  export default {
    name: 'bulk-action-list-alert-details',

    components: {
      BulkActionWarning,
    },

    props: {
      // The module needed to load data from the store
      collectionModule: {
        type: String,
        required: true,
      },

      // The name of the collection store related metadata for the list
      collectionViewName: {
        type: String,
        required: true,
      },

      // A callback function that will return the list item name
      // This could be as simple as a function returning `.name` or using a relation to get the title/name of a record
      displayNameLookupFn: {
        type: Function,
        required: true,
      },

      // The module to check permissions against
      permissionModule: {
        type: String,
        required: true,
      },

      // Note: The following may need to accept arrays if multiple perms and actions need to be checked in the future
      permissionName: {
        type: String,
        required: true,
      },

      // The action you are trying to take on the model
      actionMethod: {
        type: [String, Function],
        required: true,
      },

      // The error message displayed that explains why some employees/documents can't be updated
      actionErrorMessage: {
        type: String,
        required: true,
      },
    },

    emits: ['allowableActions'],

    computed: {
      collection() {
        return this.collectionGetBatch(this.selected)
      },

      collectionIds() {
        return this.collection.map(item => item.id)
      },

      nonePermitted() {
        return this.permittedAndActionableIds.length === 0
      },

      allPermittedAndActionable() {
        return this.forbiddenIds.length === 0
      },

      actionable() {
        if (!Object.keys(this.collection).length) { return [] }

        if (typeof(this.actionMethod) == 'function') {
          return this.collection.filter(item => this.actionMethod(item))
        } else {
          return this.collection.filter(item => item[this.actionMethod])
        }
      },

      actionableIds() {
        return this.actionable.map(item => item.id)
      },

      nonActionable() {
        if(!this.collection) { return [] }
        return difference(this.collection, this.actionable)
      },

      nonActionableIds() {
        return this.nonActionable.map(item => item.id)
      },

      permitted() {
        return this.collection.filter(item => {
          const perm = this.permissions[item.id]

          if (!perm) { return }

          return perm[this.permissionName] === true
        })
      },

      permittedIds() {
        return this.permitted.map(item => item.id)
      },

      permittedAndActionableIds() {
        // returns all IDs that are BOTH permitted and actionable
        return intersection(this.permittedIds, this.actionableIds)
      },

      nonPermitted() {
        return this.collection.filter(item => !this.permittedIds.includes(item.id))
      },

      nonPermittedIds() {
        return this.nonPermitted.map(item => item.id)
      },

      forbiddenIds() {
        // returns all IDs that are EITHER non-permitted or non-actionable
        return union(this.nonPermittedIds, this.nonActionableIds)
      },

      // FIXME: move the following within mapGetters if possible
      collectionGetBatch() {
        return this.$store.getters[`${this.collectionModule}/getBatch`]
      },

      allowableActions() {
        return this.permittedAndActionableIds.length
      },

      ...mapState({
        selected(state) {
          if (!this.collectionViewName || !state[this.collectionViewName]) { return [] }
          return state[this.collectionViewName].selected
        },
        permissions(state) {
          return state['permissions'][this.permissionModule]
        },
      }),
    },

    mounted() {
      this.$emit("allowableActions", this.allowableActions)
    },

    methods: {
      // Deselect forbidden items from the collection view
      // Return permitted IDs
      filter() {
        this.forbiddenIds.forEach(id => this.$store.dispatch(`${this.collectionViewName}/select`, id))
      },
    },
  }
</script>
