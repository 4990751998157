// This component will do the following:
// User can show or hide optional data on I-9
// Button is added to toggle between "N/A" & original user data

<template>
  <div class="form-group" :class="name">
    <div class="control-label col-sm-4 col-md-3 col-lg-3" ref="label">
      {{ label }} (optional):
    </div>
    <div class="form-control-static col-sm-8 col-md-9 col-lg-9 block-translate">
      <strong class="info" ref="displayValue">
        {{ displayValue }}
      </strong>
      <button
        class="btn btn-xs btn-default btn-text-primary ml-1"
        :class="name"
        @click.prevent="toggleField"
        type="button"
        ref="excludeButton"
      >
        {{ buttonText }}
      </button>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'form-field-i9-excludeable',

    props: {
      value: {
        type: String,
      },

      name: {
        type: String,
        required: true,
      },

      label: {
        type: String,
        required: false,
      },
      // Value to show in place of the excluded value
      excludedValue: {
        type: String,
        default: 'N/A',
      },

      isExcluded: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      displayValue () {
        return this.isExcluded ? this.excludedValue : this.value
      },
      buttonText() {
        return this.isExcluded ? 'Include on my I-9' : 'Exclude from my I-9'
      },
    },

    methods: {
      toggleField() {
        this.$emit('excludeToggle', !this.isExcluded)
      },
    },
  }
</script>
