// Constants that represent the different states of a Workbright breakdown chart
export const BREAKDOWN_VARIANTS = Object.freeze({
  COMPLETE: 'success',
  IN_PROGRESS: 'progress',
  NEEDS_ATTENTION: 'warning',
  NEEDS_IMMEDIATE_ATTENTION: 'danger',
  ALL: ['success', 'progress', 'warning', 'danger' ],
})

export const BREAKDOWN_VARIANTS_FOR_STATUS = Object.freeze({
  internal_error: BREAKDOWN_VARIANTS.NEEDS_IMMEDIATE_ATTENTION,
  submission_error: BREAKDOWN_VARIANTS.NEEDS_IMMEDIATE_ATTENTION,
  draft: BREAKDOWN_VARIANTS.IN_PROGRESS,
  unconfirmed_data: BREAKDOWN_VARIANTS.NEEDS_ATTENTION,
  photo_match: BREAKDOWN_VARIANTS.NEEDS_ATTENTION,
  scan_and_upload: BREAKDOWN_VARIANTS.NEEDS_ATTENTION,
  manual_review: BREAKDOWN_VARIANTS.IN_PROGRESS,
  pending_referral: BREAKDOWN_VARIANTS.NEEDS_ATTENTION,
  referred: BREAKDOWN_VARIANTS.NEEDS_ATTENTION,
  queued: BREAKDOWN_VARIANTS.IN_PROGRESS,
  employment_authorized: BREAKDOWN_VARIANTS.COMPLETE,
  final_nonconfirmation: BREAKDOWN_VARIANTS.NEEDS_IMMEDIATE_ATTENTION,
  close_case_and_resubmit: BREAKDOWN_VARIANTS.NEEDS_ATTENTION,
  closed_authorized: BREAKDOWN_VARIANTS.COMPLETE,
  closed_nonconfirmation: BREAKDOWN_VARIANTS.NEEDS_ATTENTION,
  closed_other: BREAKDOWN_VARIANTS.NEEDS_ATTENTION,
})

export const SECTION_REQUIRED_KEYS = ['legend_key', 'message', 'variant']
