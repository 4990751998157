// Header row representing bulk action functionality

<template>
  <div class="wb-list-header">
    <div class="wb-list__row" ref="wbContentRow" :class="{ 'select-all-active': anySelected }">
      <div class="wb-list__column-selector" v-if="filteredRowIds && filteredRowIds.length > 0 && selectable">
        <SelectAll
          :disabled="disabled"
          :collectionViewName="collectionViewName"
          :items="filteredRowIds"
        />
      </div>
      <div class="wb-header-actions-row">
        <div class="wb-header-actions-column bulk-action" v-if="anySelected">
          <slot name="bulkActions" />
        </div>
        <div class="wb-header-actions-column static-action" v-if="!anySelected">
          <slot name="staticActions" />
        </div>
        <div class="wb-header-actions-column select-all" v-if="allSelected">
          <slot name="selectAll" />
        </div>
        <div class="wb-header-actions-column total-selected" v-if="anySelected">
          <span v-if="anySelected">
            {{ selectionCount }} Selected
          </span>
        </div>
        <div class="wb-header-actions-column search-action" v-if="search && !anySelected">
          <SearchFilter
            class="mr-0"
            ref="searchInput"
            :placeholder="searchPlaceholder"
            :collectionViewName="collectionViewName"
            :filter="{ filter_key: 'search' }"
            @search-input="handleSearchEntry"
            label=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SelectAll from 'components/common/SelectAll'
  import SearchFilter from 'components/common/collection_view_filters/SearchFilter'
  import { mapState } from 'vuex'

  export default {
    name: 'wb-list-header',
    components: {
      SelectAll,
      SearchFilter,
    },
    props: {
      selectable: {
        type: Boolean,
        default: true,
      },

      filteredRowIds: {
        type: Array,
        required: true,
      },

      collectionViewName: {
        type: String,
        required: true,
      },

      search: {
        type: Boolean,
        default: true,
      },

      searchPlaceholder: {
        type: String,
        required: false,
      },

      availableSelections: {
        type: Array,
        required: false,
        default() {
          return []
        },
      },

      disabled: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    computed: {
      ...mapState({
        selected(state) {
          return state[this.collectionViewName].selected
        },
      }),

      selectionCount() {
        if (this.availableSelections.length) {
          return this.selected.filter(selectedId => this.availableSelections.map(a => a.id).includes(selectedId)).length
        } else {
          return this.selected.length
        }
      },

      anySelected() {
        return this.selectionCount > 0
      },

      allSelected() {
        return this.filteredRowIds.length == this.selected.length
      },
    },

    methods: {
      handleSearchEntry(entry) {
        this.$emit("search-input", entry)
      },
    },
  }
</script>
