<template>
  <div>
    <SortOrder
      :filter="sortBy.filter"
      :collectionViewName="collectionViewName"
    />
    <ListFilterSet
      :filters="filters"
      :collectionViewName="collectionViewName"
      class='pt-4 mt-4'
    />
  </div>
</template>

<script>
  import ListFilterSet from 'components/common/ListFilterSet'
  import SortOrder from 'components/common/collection_view_filters/SortOrder'

  export default {
    name: 'staff-list-filter-container',
    components: {
      ListFilterSet,
      SortOrder,
    },

    props: {
      collectionViewName: {
        type: String,
        required: true,
      },

      groupsOptions: {
        type: Array,
        required: false,
      },
    },

    data() {
      return {
        sortBy: {
          filter: {
            filterType: 'sort',
            title: '',
            defaultValue: 'last_name',
            options: [
              { value: 'last_name', label: 'Last Name' },
              { value: 'created_at', label: 'Date Added' },
              { value: 'hire_date', label: 'Hire Date' },
              { value: 'start_date', label: 'Start Date' },
            ],
          },
        },
        filters: [
          {
            type: 'select',
            title: 'Employment Status',
            filter_key: 'employment_status',
            options: [
              { value: 'active', label: 'Active Staff Only' },
              { value: 'inactive', label: 'Inactive Staff Only' },
              { value: 'unemployed', label: 'Unemployed Staff Only' },
              { value: 'all', label: 'All Staff' },
            ],
          },
          {
            type: 'multiselect',
            title: 'Onboarding Progress',
            filter_key: 'onboarding_progress',
            buttonWidth: '100%',
            options: [
              { value: 'no_assigned_forms', label: 'No Assigned Forms' },
              { value: 'missing_all_forms', label: 'Missing All Forms' },
              { value: 'in_progress', label: 'In Progress (incomplete)' },
              { value: 'finished', label: 'Finished' },
            ],
            help_text: 'This filter does not apply to internal forms',
          },
          {
            type: 'multiselect',
            title: 'Internal Forms Progress',
            filter_key: 'internal_onboarding_progress',
            buttonWidth: '100%',
            options: [
              { value: 'no_assigned_forms', label: 'No Assigned Internal Forms' },
              { value: 'missing_all_forms', label: 'Missing All Internal Forms' },
              { value: 'in_progress', label: 'Internal Forms In Progress (incomplete)' },
              { value: 'finished', label: 'Internal Forms Finished' },
            ],
            help_text: 'This filter only applies to internal forms',
          },
          {
            type: 'multiselect',
            title: 'Groups',
            filter_key: 'groups',
            enableSearchField: true,
            disableIfEmpty: true,
            buttonWidth: '100%',
            options: this.groupsOptions,
            toggle: {
              title: '',
              filter_key: 'groups_filter_toggle',
              defaultValue: 'any',
              options: [
                { value: 'any', label: 'ANY' },
                { value: 'all', label: 'ALL' },
              ],
              helpText: 'Toggle for selected groups query.</br><b>ANY</b>: find staff who belong to any of the selected groups.</br><b>ALL</b>: find staff who belong to all of the selected groups.',
            },
          },
          {
            type: 'select',
            title: 'Last Sign In',
            filter_key: 'last_login',
            options: [
              { value: null, label: 'All' },
              { value: 'anytime', label: 'Anytime' },
              { value: '365', label: 'Within last year' },
              { value: '30', label: 'Within last month' },
              { value: '7', label: 'Within last week' },
              { value: 'never', label: 'Never' },
            ],
          },
        ],
      }
    },
  }
</script>
