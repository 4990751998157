// Icon and text label representing a submission status

<template>
  <div class="staff-list-item-indicator" :class="baseClass" v-tooltip.html="tooltipTitle">
    <i class="fa fa-fw status-icon" />
  </div>
</template>

<script>
  const STATUS_TABLE = {
    fulfilled: {
      text: 'Completed',
    },
    incomplete: {
      text: 'Started but not finished',
    },
    not_started: {
      text: 'Not started',
    },
  }

  export default {
    name: 'staff-list-progress-indicator',
    props: {
      onboardingSummary: {
        type: Object,
        required: true,
      },
    },

    computed: {
      progressStatus() {
        if (this.notStartedCount == this.totalCount)
        {return 'not_started'}

        if (this.fulfilledCount == this.totalCount)
        {return 'fulfilled'}

        return 'incomplete'
      },

      totalCount() {
        return this.onboardingSummary.total
      },

      notStartedCount() {
        return this.onboardingSummary.missing
      },

      fulfilledCount() {
        return this.onboardingSummary.pending + this.onboardingSummary.accepted
      },

      // Class applied to the base element of the component; largely drives the CSS of the child elements.
      baseClass() {
        return `status--${this.progressStatus}`
      },

      tooltipTitle() {
        if (this.progressStatus == 'not_started') {
          return STATUS_TABLE.not_started.text
        }

        if (this.progressStatus == 'incomplete') {
          return STATUS_TABLE.incomplete.text
        }

        if (this.progressStatus == 'fulfilled') {
          return STATUS_TABLE.fulfilled.text
        }
      },
    },
  }
</script>
