/* RepresentableFieldSelectView is essentially a fancy dropdown with search (via Select2) that allows a user to pick
 * a field from a list of choices.
 */
import RepresentableField from 'models/representable_field'


// FUTURE:
//  - Options for Modifiers
//  - Options for Required Fields
// NOTE: `model` attribute is not used.
export default Backbone.Marionette.ItemView.extend({
  template: '#field-select-view-tmpl',
  className: 'representable-field-select',

  ui: {
    selectFieldButton: '.select-field-btn',
    selectFieldButtonLabel: '.select-field-btn > span',
    menuContainer: '.fields-menu',
  },

  events: {
    'click @ui.selectFieldButton': 'openFieldSelectionMenu',
  },

  // Constructor
  //
  // fieldsTree - (Required) an Array representing the tree of fields' data. It is expected that this comes from the
  //              server in the proper format (see server-side docs for more info).
  initialize: function() {
    this.fieldsTree = this.getOption('fieldsTree')
    this.selectedField = this.getOption('selectedField')
  },

  // Two-step rendering process: first, draw the tree nodes. Then, once the tree is in the DOM, wire it up to the
  // mMenu plugin.
  //
  // Important NOTE: this view (self) MUST be already "shown" on the DOM before render() is called. Put another way,
  // if nesting this view within a region, make sure to call `render()` in `onShow`.
  onRender: function() {
    this._renderTree()
    this._initializeMMenu()

    // If we have an initially-selected field, go ahead and trigger the onFieldChanged callback which will take care
    // of UI changes
    if (this.selectedField) {
      this.onFieldChanged(this.selectedField)
    }
  },

  // Callback from when user clicks on the "Select a Field" button. Opens up the MMenu
  openFieldSelectionMenu: function() {
    this.menuPlugin.open()
  },

  // Callback when the menu opens up
  onFieldSelectionMenuOpened: function() {
    this.$menu.find('.mm-search > input').focus()
  },

  // Callback when user selects an actual field from the menu. The actual RepresentableField object should be set in
  // the node's `data` Hash.
  fieldSelected: function(e) {
    e.preventDefault()
    e.stopPropagation()

    const $fieldNode = $(e.target)

    this.selectedField = $fieldNode.data('field-obj')

    // Emit the event so handlers can manage; this will also trigger onFieldChanged to update our own UI
    this.triggerMethod('field:changed', this.selectedField)

    // We have to close the menu plugin since it won't do that for us
    this.menuPlugin.close()
  },

  // Callback whenever the selected field has been assigned. Only perform UI changes in here.
  onFieldChanged: function() {
    // Update the button so that it holds the name of the field selected (like a dropdown would)
    this.ui.selectFieldButtonLabel.text(this.selectedField.get('label'))
  },

  onBeforeDestroy: function() {
    // There is no way to "destroy" the menuPlugin, but by removing the menu container element, we should be good.
    this.ui.menuContainer.remove()
  },

  // PRIVATE

  // Initialization for the jQuery.mMenu plugin – the tree must already be in the DOM
  _initializeMMenu: function() {
    // In order for mMenu to show up in the right spot, it needs to be attached to an element on the page via a
    // SPECIFIC selector. In order to guarantee uniqueness for any other "Select a Field" buttons on the page, we use
    // the internal cid of this ItemView.
    const positioningClass = `mmenu-${this.cid}`
    const positioningSelector = `.${positioningClass}`
    this.ui.selectFieldButton.addClass(positioningClass)

    // Reference: http://mmenu.frebsite.nl/
    const mMenuOptions = {
      dropdown: {                             // MMenu is designed to be an off-canvas "page-level" menu, but this option turns
        // it into a nifty little dropdown perfect for us.
        position: positioningSelector,      // See positioning notes above
        drop: true,                          // Just another way to turn "ON" dropdown mode
      },
      counters: true,                        // Show counters next to each category
      dividers: {
        fixed: true,                           // Not sure
      },
      autoHeight: true,                       // Adjusts the height automatically based on which category (pane) is selected
      navbar: {
        title: 'Select a Field',              // Main title
      },
      navbars: [                              // Adds multiple "navbars" to the top of the menu
        { position: 'top',                  // The first navbar is the search box
          content: ['searchfield'] },
        { position: 'top' },                 // the second navbar is the title of the currently-selected pane
      ],
      setSelected: {
        parent: true,                         // Not sure
      },
      searchfield: {
        placeholder: "Quick Search: Start typing the name of a field",
        noResults: "No fields found with that name.",
        resultsPanel: true,                  // Show search results in their own panel
      },
    }

    // mMenu has two parameters: "options" and "configuration." I think the only distinction between the two is that
    // configuration can be set globally, whereas options can ONLY be set per instance. Seems dumb, but whatever.
    const mMenuConfig = {
      searchfield: {
        clear: true,                           // Draw a 'clear' button in the search box
      },
      offCanvas: { pageSelector: "#wrapper"},  // Set the page selector to let mmmenu known what to wrap, otherwise it guesses
    }

    const menu = this.ui.menuContainer.mmenu(mMenuOptions, mMenuConfig)

    // Bind click handler here (can't use Backbone `View.events` configuration because of how mMenu manipulates the DOM)
    menu.on('click', 'a.field-option', this.fieldSelected.bind(this))

    // Save access to the mMenu API
    this.menuPlugin = this.ui.menuContainer.data('mmenu')

    this.menuPlugin.bind('opened', this.onFieldSelectionMenuOpened)
  },

  // Renders all of the tree nodes representing the hierarchy of fields from `this.fieldsTree`.
  _renderTree: function() {
    const $trunk = $("<ul>")

    this.fieldsTree.forEach(function(item) {
      this._renderTreeNode($trunk, item)
    }, this)

    $trunk.appendTo(this.ui.menuContainer)
  },

  // Recursive tree rendering function. This should be called on every node in the tree. The algorithm will determine
  // if the node is a leaf (field) or a branch (category or subcategory). Categories are represented by a JS Object
  // with structure:
  //
  //    { label: "Category name", items: [] }
  //
  // Fields are represented by a JS Object with structure from their server-side JSON serialization, e.g.:
  //
  //    { label: 'Field Name', field_key: 'a4s_whatever', ...etc... }
  //
  // Note that rendering happens IN PLACE – so this method does not return anything.
  //
  // $parentNode - (jQuery Element) The parent node in the recursion
  // item - the item in the fields tree data structure
  //
  // Example Output (in Slim format for readability)
  //
  //    ul
  //      li
  //        span Category 1
  //        ul
  //          li
  //            span Subcategory
  //            ul
  //              li: a.field-option Field 1
  //              li: a.field-option Field 2
  //      li
  //        span Category 2
  //        ul
  //          li: a.field-option Field 3
  //          li: a.field-option Field 4
  //
  // Returns - nothing (renders in-place)
  _renderTreeNode: function($parentNode, item) {
    const $childNode = $("<li>")  // The new child node that will be attached to the tree at parentNode

    if (this._isLeaf(item)) {
      // We create a RepresentableField instance from the JSON data and save it within the node itself. As a result,
      // the DOM becomes our managed collection.
      const rf = new RepresentableField(item)

      const fieldLink = $("<a>").attr("href", "javascript:void(0)").text(rf.get('label'))
      fieldLink.addClass('field-option')   // This class is what distinguishes a "selectable" field
      fieldLink.data("field-obj", rf)

      fieldLink.appendTo($childNode)
    } else {
      const branchName = item.label
      const leaves = item.items

      $("<span>").text(branchName).appendTo($childNode)

      // Iterate through each item and recursively render its nodes
      const $leavesContainer = $("<ul>")
      leaves.forEach(function(leaf) {
        this._renderTreeNode($leavesContainer, leaf)
      }, this)
      $leavesContainer.appendTo($childNode)
    }

    // Finish by attaching the completely rendered $childNode to the tree by way of its $parentNode
    $childNode.appendTo($parentNode)
  },

  // Determines whether or not an item is a leaf or not. Our leaves are the individual fields which can be chosen,
  // so this simple algorithm just checks for the presence of a field key.
  //
  // Returns - Boolean
  _isLeaf: function(item) {
    return (typeof item === "object" && item.field_key)
  },
})
