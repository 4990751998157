<template>
  <div class="panel panel-primary">
    <div class="panel-heading">
      {{ heading }}
    </div>
    <div class="panel-body">
      <ActionGroupCollectionSummary :permission-set="permissionSet" name="Fields" filter="RepresentableField" />
      <ActionGroupCollectionSummary :permission-set="permissionSet" name="Forms" filter="Document" />
      <MiscActionSummary :permission-set="permissionSet" />

      <p class="text-center" v-if="noPermissions">
        <em>Not authorized to view or modify.</em>
      </p>
    </div>
  </div>
</template>

<script>
  import ActionGroupCollectionSummary from 'components/permissions/summary/ActionGroupCollectionSummary'
  import MiscActionSummary from 'components/permissions/summary/MiscActionSummary'
  import { mapGetters } from 'vuex'

  export default {
    name: 'permission-set-summary',

    components: {
      ActionGroupCollectionSummary,
      MiscActionSummary,
    },

    props: {
      permissionSet: {
        type: Object,
        required: true,
      },
    },
    computed: {
      heading() {
        let str = ''

        // NOTE: We could do this based on the contents of the permission set itself, but today it's fixed and the logic around
        // what we display is very hard coded.
        if (this.permissionSet.name == 'group') {
          const scope = this.permissionSet.scope
          if (scope && scope.field_key == 'a4s_employee_groups' && scope.value != []) {
            const groupNames = scope.value.map((id) => this.getGroup(id).name).sort().join(', ')
            str = `Employees in Groups: ${groupNames}`
          } else {
            str = "Employees in Assigned Groups"
          }
        } else if (this.permissionSet.name == 'baseline') {
          str = 'All Other Employees'
        } else if (this.permissionSet.name == 'other') {
          str = 'Other Permissions'
        } else {
          throw new Error(`Invalid permission set name`)
        }

        return str
      },

      noPermissions() {
        return this.permissionSet.permissions.length == 0
      },

      ...mapGetters({
        getGroup: 'groups/get',
      }),
    },
  }
</script>
