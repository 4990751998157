export default {

  // Concatenate:
  //  1) first and last name with or without a middle initial
  //  2) full address with or without an apartment number
  //  of an employee
  //
  // obj - Object, any object that defines first_name and last_name as parameters
  //               or as Backbone Model attributes
  //
  // Returns
  // String "First Last"
  fullName: function(obj) {
    let firstName
    let lastName

    if (obj instanceof Backbone.Model) { // Backbone Model
      firstName = obj.get('first_name') || ""
      lastName = obj.get('last_name') || ""
    } else { // Object
      firstName = obj.first_name || ""
      lastName = obj.last_name || ""
    }
    return `${firstName} ${lastName}`
  },

  fullNameWithMiddleInitial: function(obj) {
    const firstName = obj.first_name || ""
    const middleInitial = obj.middle_initial || ""
    const lastName = obj.last_name || ""

    return middleInitial ? `${firstName} ${middleInitial} ${lastName}` : `${firstName} ${lastName}`
  },

  employeeAddressWithApartmentNum: function(obj) {
    const street = obj.street || ""
    const apartmentNum = obj.apt || ""
    const city = obj.city || ""
    const state = obj.state || ""
    const zip = obj.zip || ""
    let userAddress

    userAddress = [street, apartmentNum].filter(address => address).join(" ")
    return `${userAddress}, ${city}, ${state} ${zip}`
  },
}
