import countriesLocale from 'locales/app/countries.en.yml' // eslint-disable-line import/extensions

const countriesRaw = countriesLocale.en.countries
const countries = {}

Object.entries(countriesRaw).forEach(([name, code]) => {
  countries[code] = {
    code,
    name,
  }
})

export default countries
