<template>
  <div class="i9-documentation">
    <h4 ref="attachmentName" v-if="!shouldShowFallbackComponent">{{ attachmentName }}</h4>
    <AlertMessage
      v-if="shouldShowAlert"
      :variant="alertVariant"
      :title="alertTitle"
      :message="alertMessage"
      :ref="`${verification_status}Alert`"
    />
    <UploadDocumentation
      v-if="shouldShowFallbackComponent"
      :document="document"
      :errors="errors"
      :attachmentAttributes="attachmentAttributes"
      :verificationFallbackMode="true"
      @update="handleInput"
      ref="uploadDocumentationFallback"
    />
    <div id="persona-parent" />
  </div>
</template>
<script>
  import ErrorService from 'lib/error_service'
  import AlertMessage from 'components/common/AlertMessage'
  import UploadDocumentation from 'components/document_submissions/i9/UploadDocumentation'
  import Persona from 'persona'
  import PubSub from "lib/pub_sub"
  import { mapGetters } from 'vuex'
  import Locale from 'lib/locale'

  export default {
    components: {
      AlertMessage,
      UploadDocumentation,
    },
    props: {
      document: {
        type: Object,
      },
      errors: {
        type: Object,
        default: () => ({}),
      },
      attachmentAttributes: {
        type: Object,
      },
      personaSettings: {
        type: Object,
        required: true,
      },
      currentEmployee: {
        type: Object,
        required: true,
      },
      documentationKey: {
        type: String,
      },
    },
    emits: [
      'update',
      'verificationCreated',
      'verificationReceived',
    ],
    data: () => {
      return {
        persona: null,
        pubSubChannel: null,
        verification_status: null,
        formData: {
          external_id: null,
        },
      }
    },
    computed: {
      referenceId() {
        const currentTime = new Date().getTime()

        return `${this.currentAccount.id}_${this.currentEmployee.id}_${currentTime}`
      },
      personaCustomFields() {
        return {
          wb_document_title: this.document.document_title,
          wb_documentation_key: this.documentationKey,
          wb_list_key: this.document.list_key,
          wb_social_security: this.currentEmployee.ssn,
        }
      },
      attachmentName() {
        return this.document.view_title ||
          this.document.list_title ||
          this.document.document_title
      },
      shouldShowFallbackComponent() {
        return this.verification_status === 'failed'
      },
      shouldShowAlert() {
        return ['pending', 'completed', 'cancelled', 'error'].includes(this.verification_status)
      },
      alertTitle() {
        return Locale.t(`staff.i9.documentVerification.${this.verification_status}.title`)
      },
      alertMessage() {
        return Locale.t(`staff.i9.documentVerification.${this.verification_status}.message`)
      },
      alertVariant() {
        return Locale.t(`staff.i9.documentVerification.${this.verification_status}.variant`)
      },
      ...mapGetters({
        currentAccount: 'account/current',
      }),
    },
    watch: {
      formData: {
        handler(newValue, _oldValue) {
          this.handleInput(newValue)
        },
        deep: true,
      },
    },
    created () {
      this.verification_status = 'pending'
      this.handleInput(this.formData)

      try {
        this.persona = new Persona.Client({
          referenceId: this.referenceId,
          templateId: this.personaSettings.template_id,
          environmentId: this.personaSettings.environment_id,
          onEvent: (eventName, eventData) => this.handlePersonaClientEvent(eventName, eventData),
          onReady: () => this.openPersonaClient(),
          onCancel: () => {
            this.verification_status = 'cancelled'
          },
          onError: ({ status, code }) => {
            this.destroyPersonaClient()
            this.verification_status = 'error'

            this.reportPersonaError('Failed to begin Persona verification', {status, code})
          },
          fields: this.personaCustomFields,
        })
      } catch {
        this.reportPersonaError('Failed to create Persona UI', {})
      }

      this.subscribeToPubSubChannel()
      this.$emit('verificationCreated')
    },
    methods: {
      handleInput(value) {
        this.$emit('update', value)
      },
      handleVerificationStarted(data){
        this.formData.external_id = data.inquiryId
      },
      handleVerificationReceived(data) {
        const {external_id, status} = data

        if (this.formData.external_id !== external_id) { return }

        if (status === "completed"){
          this.handleVerificationCompleted()
        } else if (status === "failed") {
          this.handleVerificationFailed()
        }
      },
      handleVerificationCompleted() {
        this.verification_status = "completed"
        this.$emit('verificationReceived', {external_id: this.formData.external_id})
      },
      handleVerificationFailed() {
        this.verification_status = "failed"
        this.$emit('verificationReceived')
      },
      handlePersonaClientEvent(eventName, eventData){
        if (eventName === "start") { this.handleVerificationStarted(eventData) }
      },
      openPersonaClient() {
        this.persona.open()
      },
      destroyPersonaClient() {
        if (!this.persona.isOpen) { return }
        this.persona.cancel(true) // sets .isOpen to false
        try {
          this.persona.destroy() // removes Persona UI from DOM
        } catch {
          this.reportPersonaError('Failed to destroy Persona UI', {})
        }
      },
      reportPersonaError(errorMessage, data) {
        new ErrorService(errorMessage, 'PersonaError').report({
          templateId: this.personaSettings.template_id,
          environmentId: this.personaSettings.environment_id,
          employeeId: this.currentEmployee.id,
          account: this.currentAccount.subdomain,
          ...data,
        })
      },
      subscribeToPubSubChannel() {
        this.pubSubChannel = PubSub.subscribeToUserChannel({topic: "document_verification"})
        this.pubSubChannel.bind("verificationReceived", this.handleVerificationReceived.bind(this))
      },
    },
  }
</script>
