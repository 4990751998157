import PrerenderedTableRowItemView from 'views/common/prerendered_table_row_item_view.js'
export default PrerenderedTableRowItemView.extend ({
  ui: {
    removeAccessAction: '.js-remove-admin-access',
    destroyAdminAction: '.js-destroy-admin',
    addEmployeeRecord: '.js-add-employee-record',
  },

  triggers: {
    'click @ui.removeAccessAction': 'adminUser:revoke',
    'click @ui.destroyAdminAction': 'adminUser:destroy',
    'click @ui.addEmployeeRecord': {
      event: "adminUser:addEmployeeRecord",
      stopPropagation: false,
      preventDefault: true,
    },
    'click .js-resend-welcome-email': {
      event: 'resendWelcomeEmail',
      stopPropagation: false,
      preventDefault: false,
    },
  },

  onResendWelcomeEmail: function(args) {
    const ajaxOptions = {
      type: "POST",
      wbGenericFailureMsg: "Sorry, we couldn't resend the welcome notification.",
    }

    const path = `${App.Util.getPageRootPath()}/${args.model.id}/resend_welcome_email`

    $.ajax(path, ajaxOptions)
      .done((data, status, xhr) => {
        App.Util.showAjaxFlashNotice(xhr, {alertType: 'success'})
      })
  },
})
