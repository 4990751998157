import DocumentsController from 'src/controllers/documents_controller'

export default function(Sector, App, Backbone, Marionette, $, _) {

  // Set up all listenTo events within an initializer!
  Sector.addInitializer(function() {
    Sector.controller = new DocumentsController()  

    Sector.listenTo(App.vent, 'document:destroy', function(id){
      Sector.controller.destroyDocument(id)
    })
  })
}
