import Routes from 'lib/routes'

export default class AdminUser extends Backbone.Model.extend({
  urlRoot: Routes.admin_users_path,
  defaults: { },
}) {
  // Constructor
  initialize() {
  }
};
