import { ajax } from 'jquery'
import { getRoute } from 'lib/routes'

export function fetchFeatureFlags(accountId) {
  return ajax({
    method: 'GET',
    url: getRoute('superadmin_feature_flags_index_path', {
      account_id: accountId,
    }),
    dataType: 'json',
  })
}

export function patchFeatureFlag(accountId, flagId, isEnabled) {
  return ajax({
    method: 'PATCH',
    url: getRoute('superadmin_feature_flags_path_update_path', {
      account_id: accountId,
    }),
    data: {
      flag: flagId,
      enabled: isEnabled,
    },
  })
}
