export default {
  // Displays a confirmation modal using Bootbox.js
  // Calls callback function if confirmed, otherwise returns
  confirmation: function (options, callbackFn) {
    const defaults = {
      confirmTitle: 'Are you sure?',
      confirmMessage: 'Please confirm that this is what you want to do.',
      confirmBtnLabel: 'Confirm',
    }
    const dialog = $.extend(defaults, options)

    return bootbox.confirm({
      title: dialog.confirmTitle,
      message: dialog.confirmMessage,
      className: "modal-danger",
      callback: function(result) {
        if (result === true)
        {if (callbackFn) {callbackFn()}
        else
        {return}}
      },
      buttons: {
        cancel: {
          label: "Cancel",
          className: "btn-default",
        },
        confirm: {
          label: dialog.confirmBtnLabel,
          className: "btn-danger",
        },
      },
    })
  },

}
