export default Marionette.ItemView.extend({
  template: "#report-attributes-form-modal-tmpl",
  className: "report-attributes-form-modal modal fade",
  behaviors: {
    PrerenderedModalView: {},
  },

  ui: {
    form: "form",
    nameInput: "input#report_name",
    descriptionInput: "textarea#report_description",
    submitButton: '.btn-primary',
  },

  events: {
    'submit @ui.form': 'onFormSubmit',
  },

  // Using a function for stickit bindings so that we can reuse this.ui properties
  bindings: function() {
    const bindings = {}
    bindings[this._uiBindings.nameInput] = 'name'
    bindings[this._uiBindings.descriptionInput] = 'description'

    return bindings
  },

  onBeforeRender: function() {
    // Dirty tracking
    this.modelPreviousAttributes = _.clone(this.model.attributes)
  },

  onRender: function() {
    this.stickit()

    this.$el.one('hide.bs.modal', function() {
      if (!this.modelSaved) {
        // If the model wasn't saved it means the user has canceled. Revert the model attributes to the original set we saved
        // at the onset of this dialog
        this.model.set(this.modelPreviousAttributes)
      }
    }.bind(this))
  },

  onFormSubmit: function(e) {
    e.preventDefault()
    e.stopPropagation()

    if (!this._isValid()) {
      this.ui.submitButton.data('ladda').stop()
      return
    }

    // If we have been asked to ONLY save the report "attributes" (name and description, as opposed to filters and columns),
    // we specify them explicitly here. Otherwise, by passing `null` to the save call below, all attributes will be sent to the
    // server for sync. Additionally, the `patch` option is set to true if the attrs are not null.
    let attrs = null
    if (this.getOption('saveAttributesOnly'))
    {attrs = { name: this.model.get('name'),
      description: this.model.get('description') }}

    this.model.save(attrs, {
      patch: (attrs != null),
      wait: true,
      error: this.onError.bind(this),
      wbGenericFailureMsg: "Sorry, we couldn't save this report.",
    }).done(function(responseText, textStatus, xhr) {
      this.modelSaved = true
      App.Util.showAjaxFlashNotice(xhr)
      this.$el.modal('hide')
    }.bind(this))
      .always(function() {
        this.ui.submitButton.data('ladda').stop()
      }.bind(this))
  },

  _isValid: function() {
    // !!! FIXME: This is not scalable! HACK HACK HACK

    // Clear validation UI
    this.ui.nameInput.closest('.form-group').removeClass('has-error')

    if (_.isEmpty(this.model.get('name'))) {
      this.ui.nameInput.closest('.form-group').addClass('has-error')
      // alert('Please enter a name for this report.');
      return false
    }

    return true
  },

  onError: function(model, xhr, options) {
    if (xhr.getResponseHeader('X-Form-Errors')) {
      App.Util.showAjaxFlashInTarget(
        this.$el.find('.modal-header'),
        App.Util.getAjaxFlashMessages(xhr).alert,
        { replaceTarget: false })
    }
  },

  onBeforeDestroy: function() {
    Marionette.TemplateCache.clear(this.getOption('template'))
  },
})
