<template>
  <WbModal
    :title="standardField && standardField.label"
    :locked="false"
    watchPageContextVariable="standard_field_id"
    ref="modal"
  >
    <slot>
      <v-wait :visible="isLoading">
        <template #waiting>
          <Spinner :showLongWaitMessage="true" />
        </template>
        <div
          class="form-horizontal"
          v-if="hasMappableFields"
          ref="mappingForm"
        >
          <div class="alert alert-danger" v-if="hasErrors && shouldShowErrors">
            {{$locale.t('errors.form.generic')}}
          </div>
          <div
            class="row"
            :class="{ 'has-error': hasErrors && !mappedField }"
          >
            <div class="col-sm-7 col-sm-offset-4 col-md-offset-3">
              <p class="help-block">
                {{ $locale.t('standard_fields.choose_field', standardField.label)}}
              </p>
              <WbNestedSelect
                class="mb-3 no-wrap"
                :tree="mappableFieldsTree"
                @select="handleFieldSelected"
                :enableSearchField="false"
                :value="mappedFieldKey"
                valueKey="field_key"
                @input="handleFieldSelected"
              />
              <div>
                <button
                  class="btn btn-link pl-0"
                  v-if="mappedField"
                  @click="handleDeleteClick"
                  ref="deleteMapping"
                >
                  <i class="fa fa-close" />
                  {{ $locale.t('standard_fields.delete_mapping')}}
                </button>
              </div>
              <div
                class="help-block error"
                ref="fieldFeedback"
                v-if="errors && errors.reference_field_key && errors.reference_field_key.length"
              >
                <p
                  v-for="(error, index) in errors.reference_field_key"
                  :key="index"
                >
                  {{ error }}
                </p>
              </div>
            </div>
          </div>
          <OptionsMapper
            v-if="mappedField && shouldMapOptions"
            class="my-3"
            :mappedField="mappedField"
            :standardField="standardField"
            :shouldShowErrors="shouldShowErrors"
            @change="handleOptionsUpdate"
            @validate="handleOptionsMapperValidate"
            ref="optionsMapper"
          />
        </div>
        <div v-else class="alert alert-info" ref="defaultContent">
          {{ $locale.t('standard_fields.no_fields', standardField.label)}}
        </div>
      </v-wait>
    </slot>
    <template #footer>
      <button
        class="btn btn-primary mr-1"
        ref="saveButton"
        @click="handleSaveClick"
      >
        {{ $locale.t('forms.save')}}
      </button>
      <button class="btn btn-default" @click="cancelMapping">
        {{ $locale.t('forms.cancel')}}
      </button>
    </template>
  </WbModal>
</template>
<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex'
  import Spinner from 'components/common/Spinner'
  import OptionsMapper from 'components/admin/standard_fields/OptionsMapper'
  import WbModal from 'components/common/WbModal'
  import WbNestedSelect from 'components/common/WbNestedSelect'
  import Util from 'lib/util'
  import * as mutate from 'vuex/mutation_types'

  const OPTIONED_FIELD_TYPES = ['DropdownField', 'RadioField', 'CheckboxField']

  const getFilteredTree = (node, validTypes) => {
    if (node.items) {
      node.items = node.items.filter(item => getFilteredTree(item, validTypes))

      return node
    } else {
      return validTypes.includes(node.type) ? node : null
    }
  }

  export default {
    components: {
      OptionsMapper,
      Spinner,
      WbModal,
      WbNestedSelect,
    },
    created () {
      this.handleStandardFieldUpdate()
    },
    computed: {
      ...mapGetters({
        representableFields: 'representable_fields_tree/tree',
        getMappedFieldByKey: 'representable_fields_tree/get',
      }),
      hasErrors () {
        return this.errors && Object.keys(this.errors).length
      },
      hasMappableFields () {
        return this.mappableFieldsTree && this.mappableFieldsTree.length
      },
      isValidForm () {
        return !!this.mappedField && this.mappedOptionsValid
      },
      mappedField () {
        return this.mappedFieldKey && this.getMappedFieldByKey(this.mappedFieldKey)
      },
      mappableFieldsTree () {
        const baseTree = JSON.parse(JSON.stringify(this.representableFields)) // Deep Copy to avoid mutating the store
        return baseTree.map(tree => getFilteredTree(tree, this.validFieldTypes))
      },
      shouldMapOptions () {
        return OPTIONED_FIELD_TYPES.includes(this.standardField.type)
      },
      validFieldTypes () {
        return this.standardField.reference_field_types_allowed_for_mapping || []
      },
    },
    data () {
      return {
        errors: {},
        isLoading: false,
        mappedFieldKey: null,
        mappedOptions: {},
        mappedOptionsValid: true,
        shouldShowErrors: false,
      }
    },
    methods: {
      ...mapActions({
        fetchRepresentableFields: 'representable_fields_tree/fetch',
        saveMapping: 'standard_fields/saveMapping',
        deleteMapping: 'standard_fields/deleteMapping',
      }),
      ...mapMutations({
        setPageContextKeys: mutate.SET_PAGE_CONTEXT_KEYS,
      }),
      cancelMapping () {
        this.closeModal()
      },
      closeModal () {
        this.$refs.modal.hide()
      },
      async fetchData () {
        this.isLoading = true
        try {
          await this.fetchRepresentableFields(['mappable'])
        } catch {}
        finally {
          this.isLoading = false
        }
      },
      handleFieldSelected ({ field_key }) {
        this.setMappedFieldKey(field_key)
      },
      handleOptionsUpdate (mappedOptions) {
        this.mappedOptions = mappedOptions
      },
      handleOptionsMapperValidate (isValid) {
        this.mappedOptionsValid = isValid
        if (isValid) {
          delete this.errors.reference_field_choices_map
          this.errors = { ...this.errors } // Trigger observer/ reactivity
        } else {
          this.errors = { ...this.errors, reference_field_choices_map: ['error'] } // Trigger observer/ reactivity
        }
      },
      handleStandardFieldUpdate() {
        this.mappedOptionsValid = true

        this.fetchData()

        this.setMappedFieldKey(this.standardField.reference_field_key)

        this.setMappedOptions(this.standardField.reference_field_choices_map || {})
      },
      async fetchDelete () {
        this.isLoading = true

        try {
          await this.deleteMapping({
            standardFieldKey: this.standardField.id,
          })
          this.errors = {}
          this.shouldShowErrors = false
          Util.showFlashNotice(this.$locale.t('standard_fields.mapping_deleted'))
          this.closeModal()
        } catch (response) {
          Util.genericAjaxError(this.$locale.t('delete_mapping_error'), response)
        } finally {
          this.isLoading = false
        }
      },
      async fetchUpdate () {
        this.isLoading = true

        try {
          await this.saveMapping({
            standardFieldKey: this.standardField.id,
            mappedFieldKey: this.mappedFieldKey,
            mappedOptions: this.mappedOptions,
          })
          this.errors = {}
          this.shouldShowErrors = false
          Util.showFlashNotice(this.$locale.t('standard_fields.mapping_updated'))
          this.closeModal()
        } catch (errors) {
          this.errors = errors
        }
        finally {
          this.isLoading = false
        }
      },
      handleDeleteClick () {
        this.mappedFieldKey = null
        this.fetchDelete()
      },
      handleSaveClick () {
        this.shouldShowErrors = true
        this.validateMappedField()
        if (this.isValidForm) {
          this.fetchUpdate()
        }
      },
      setMappedFieldKey (fieldKey) {
        this.mappedFieldKey = fieldKey
        this.shouldShowErrors = false
        this.setMappedOptions({}) // Reset any mapped options
        this.errors = {} // Reset any errors
      },
      setMappedOptions (val) {
        this.mappedOptions = val
      },
      validateMappedField () {
        if (!this.mappedField) {
          const referenceFieldErrors = [this.$locale.t('standard_fields.specify_field'), ...(this.errors.reference_field_key || [])]
          this.errors = { ...this.errors, reference_field_key: referenceFieldErrors }
        }
      },
    },
    props: {
      standardField: {
        type: Object,
        required: true,
      },
    },
    watch: {
      standardField () {
        this.handleStandardFieldUpdate()
      },
    },
  }
</script>
