export default Marionette.ItemView.extend({
  template: '#import-status-view',

  modelEvents: {
    change: 'changed',
  },
  changed: function(){
    this.render()
  },
})
