<template>
  <div>
    <div class="alert alert-success" v-if="success">
      {{ msgSuccess }}
    </div>
    <div class="alert alert-danger" v-if="hasError">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
  // Form Success/Error alerts shown at the top of a form
  export default {
    name: 'form-alerts',
    props: {
      success: {
        type: Boolean,
        required: true,
      },
      errors: {
        type: Object,
        required: true,
      },
      msgSuccess: {
        type: String,
        required: true,
      },
      msgError: {
        type: String,
        required: false,
      },
    },
    computed: {
      errorMessage () {
        return this.msgError || this.$locale.t("errors.form.generic")
      },
      hasError() {
        return Object.keys(this.errors).length > 0
      },
    },
  }
</script>
