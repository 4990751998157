export default {
  // Resizes image to be less than max dimensions and keeps aspect ratio of image
  // 
  // Arguments:
  //   - img: original image element
  // Returns <img> element with dataURI as src
  resize: function (img, options) {
    const max_width = options.maxWidth
    const max_height = options.maxHeight
    let width = img.width
    let height = img.height
      
    // Resize large images
    if (width > height) {
      if (width > max_width) {
        height *= max_width / width
        width = max_width
      }
    } else {
      if (height > max_height) {
        width *= max_height / height
        height = max_height
      }
    }

    if (img.width == width && img.height == height) {
      // Image does not to be resized
      return img

    } else {
      const drawnCanvas = this._buildCanvas(img, height, width)
        
      // Create an <img> el from that canvas
      const resizedImg = new Image()
      resizedImg.src = drawnCanvas.toDataURL()
        
      return resizedImg
    }
  },

  // Builds a new canvas from the updated dimensions and draws img onto canvas
  // The new image is resized down with same aspect ratio as the original image
  _buildCanvas: function (originalImg, height, width) {
    const canvas = document.createElement('canvas')
    canvas.width = width
    canvas.height = height
    const ctx = canvas.getContext("2d")
    ctx.drawImage(originalImg, 0, 0, width, height)

    return canvas
  },
}
