$(document).on('page:change', function() {
  // For some reason, using toggleClass does not work – UI gets funky. Leaving as is. No biggie.
  function tog(v){ return v ? 'addClass': 'removeClass' } 
  
  $(document).on('input', '.clearable', function() {
    $(this)[tog(this.value)]('activated')
  }).on('mousemove', '.activated', function(e) {
    $(this)[tog(this.offsetWidth - 22 < e.clientX - this.getBoundingClientRect().left)]('hovered')
  }).on('click', '.hovered', function() {
    $(this).removeClass('activated hovered').val('').trigger("keyup.DT")
  })
})
