<template>
  <div>
    <ul
      class="list-group wb-list"
      v-if="hasEntries"
    >
      <li
        class="list-group-item wb-list__item"
        v-for="(event, index) in auditEvents"
        :key="index"
      >
        <EverifyCaseAuditLogItem :event="event" />
      </li>
    </ul>
    <div
      v-else
      ref="defaultContent"
    >
      <em>There are currently no audit events for this case</em>
    </div>
  </div>
</template>
<script>
  import EverifyCaseAuditLogItem from 'components/everify/cases/EverifyCaseAuditLogItem'

  export default {
    components: {
      EverifyCaseAuditLogItem,
    },
    props: {
      auditEvents: {
        type: Array,
        required: true,
      },
    },
    computed: {
      hasEntries () {
        return this.auditEvents?.length
      },
    },
  }
</script>
