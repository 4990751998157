import EmployeeHelper from 'lib/employee'

// Access information about the current account
function install(app, _options) {
  app.config.globalProperties.$employee = {
    fullName: EmployeeHelper.fullName,
    fullNameWithMiddleInitial: EmployeeHelper.fullNameWithMiddleInitial,
    employeeAddress: EmployeeHelper.employeeAddress,
    employeeAddressWithAptNum: EmployeeHelper.employeeAddressWithAptNum,
  }
}

export default install
