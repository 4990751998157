export default class EmbeddedService {
  static postMessage(data){
    // If a window does not have a parent, its parent
    // property is a reference to itself.
    //
    // The targetOrigin argument is set to * since
    // the Workbright app is expected to be embeded
    // in many domains.
    window.parent.postMessage(data, "*")
  }
}
