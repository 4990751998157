import AnimUtil from 'lib/util/animations'

// Shows intermediate progress while a report query is running on the backend
export default class ReportLoadingView extends Marionette.ItemView.extend({
  template: '#report-loading-tmpl',
  attributes: {
    class: 'report-loading-view',
  },
  ui: {
    progressBar: '.progress-bar',
    loadingMessage: '.loading-message',
  },
}) {
  // Options
  //   pubSubChannel - (Pusher Channel)
  initialize() {
    this.pubSubChannel = this.getOption('pubSubChannel')
    this.lastProgressPercent = 0

    // NOTE: we don't bind to `done` because it's likely our view will be destroyed before the callback function
    // fires. Thus, parent view must handle any UI state changes for `done`.

    this.pubSubChannel.bind('pusher:subscription_error', () => {
      this.setFailedState()
    })
    this.pubSubChannel.bind('queued', () => {
      this.setProgress(25, 'Report is queued; please wait...')
    })
    this.pubSubChannel.bind('started', () => {
      this.setProgress(50, 'Running report...')
    })
    this.pubSubChannel.bind('progress', (data) => {
      // The 'Processing' step starts at 50% and runs to 90%, with updates from the backend as it's completed
      const stepPercentStart = 50
      const stepPercent = 40

      const currentPercent = stepPercentStart + data.progress * stepPercent
      this.setProgress(parseInt(currentPercent), `Processing results (${data.processed_count} of ${data.total_count} employees)...`)
    })
    this.pubSubChannel.bind('failed', () => {
      this.setFailedState()
    })
  }

  onDestroy() {
    if (this.pubSubChannel) {
      this.pubSubChannel.unsubscribe()
    }
  }

  // Final UI state if any kind of error encountered prior to loading results.
  setFailedState() {
    // If another CSS animation is in progress, let's wait at least half a sec for it to complete
    AnimUtil.onTransitionEnd(this.ui.progressBar, 500 /* ms */, () => {
      this.ui.progressBar.addClass('progress-bar-danger').removeClass('progress-bar-primary active progress-bar-striped')
      this.setProgress(100, 'Failed to run report. Please try again.')
    })
  }

  setProgress(percentage, message) {
    // Ensure out-of-order event doesn't cause bar to go backwards, or full reset to 0
    const isForward = percentage > this.lastProgressPercent || percentage == 0
    if (isForward) {
      this.ui.progressBar.css('width', `${percentage}%`)
      if (message) {
        this.ui.loadingMessage.text(message)
      }

      this.lastProgressPercent = percentage
    }
  }
}
