<template>
  <div>
    <h2>Promote Staff to Admin</h2>

    <!-- Form: Shows employee search, role selection, policy create button, submit -->
    <div>
      <div class="row">
        <div class="col-lg-9">
          <div class="panel gray-bg">
            <!-- Button for returning back to employee/role selection -->
            <transition name="vue-fade">
              <span v-show="!modeForm">
                Promoting
                <strong>
                  {{ firstName }}
                </strong>
                to administrator.
                <a href="javascript:void(0)" @click="policyBack">
                  <i class="fa fa-times-circle-o" />
                </a>
              </span>
            </transition>
            <!-- Form contents -->
            <transition name="vue-fade">
              <form class="form-horizontal" v-show="modeForm">
                <div v-show="!modePolicy">
                  <FormField
                    label='Staff members'
                    :required='true'
                    :errors="errors"
                    name='employee_id'
                    hint="Select the staff member you want to promote."
                    orientation="horizontal"
                  >
                    <EmployeeSearch v-model="employee" />
                  </FormField>
                  <!-- Access role not visible unless Employee selected -->
                  <transition name="vue-fade">
                    <FormFieldRadio
                      v-show="employee && permissionsEnabled"
                      :options="adminAccessOptions"
                      v-model="access_role"
                      ref="adminAccess"
                      label="Admin Access"
                      :required="true"
                      :errors="errors"
                      name='access_role'
                      orientation="horizontal"
                    />
                  </transition>
                  <!-- Create Policy button not visible unless required -->
                  <transition name="vue-fade">
                    <FormField
                      v-show="policyRequired && !modePolicy && employee"
                      label="Policy"
                      :required='true'
                      :errors="errors"
                      name="policy"
                      hint="A policy describes administrator permissions."
                      orientation="horizontal"
                    >
                      <a class="btn btn-secondary"
                        href='javascript:void(0)'
                        @click="editPolicy"
                      >
                        <span v-if="policySet">
                          <i class="fa fa-plus-circle" />
                          Create a new Permissions Policy
                        </span>
                        <span v-else>
                          <i class="fa fa-edit" />
                          Modify Policy
                        </span>
                      </a>
                    </FormField>
                  </transition>
                  <transition name="vue-fade">
                    <div class="form-group" v-show="!modePolicy">
                      <div class="col-sm-offset-4 col-md-offset-3 col-lg-offset-3 col-sm-8 col-md-9">
                        <LaddaButton ref="submit" :disabled="!canSubmit" @click="submit">
                          Submit
                        </LaddaButton>
                      </div>
                    </div>
                  </transition>
                </div>
              </form>
            </transition>
          </div>
        </div>
      </div>
    </div>
    <!-- Policy Editor -->
    <transition name="vue-fade">
      <div v-show="modePolicy">
        <PermissionsWizard
          ref="wizard"
          v-if="modePolicy"
          :firstName="firstName"
          v-model:policy="policy"
          :backButtonEnabled="false"
          @done="policyFinished"
          @prewizardBack="policyBack"
        />
      </div>
    </transition>
  </div>
</template>

<script>
  import EmployeeSearch from 'components/employees/EmployeeSearch'
  import FormField from 'components/common/FormField'
  import FormFieldRadio from 'components/common/FormFieldRadio'
  import LaddaButton from 'components/common/LaddaButton'
  import PermissionsWizard from 'components/permissions/PermissionsWizard'
  import Policy from 'models/policy_generator/policy'
  import Util from 'lib/util'
  import { mapGetters } from 'vuex'

  export default {
    name: 'the-admin-promote-root',

    components: {
      EmployeeSearch,
      FormField,
      FormFieldRadio,
      LaddaButton,
      PermissionsWizard,
    },

    data() {
      return {
        adminAccessOptions: [
          { label: "Full Access", value: "admin" },
          { label: "Restricted Access", value: "restricted_admin" },
        ],
        mode: "form", // Form visibility states: form, policy

        errors: {},

        employee: null,
        access_role: "admin",
        policy: null,
      }
    },

    computed: {
      adminConfigured () {
        return this.policyRequired ? !this.policySet : true
      },

      permissionsEnabled () {
        return this.currentAccount.permissionsEnabled
      },

      policyRequired() {
        return this.permissionsEnabled && this.access_role === 'restricted_admin'
      },

      policySet() {
        return !this.policy.permissionSets.find(p => p.name === 'group').scope.value.length
      },

      canSubmit() {
        return this.employee && this.adminConfigured
      },

      firstName() {
        return this.employee ? this.employee.first_name : ''
      },

      modeForm() {
        return this.mode === 'form'
      },

      modePolicy() {
        return this.mode === 'policy'
      },

      ...mapGetters({
        currentAccount: 'account/current',
      }),
    },

    watch: {
      // Clear if access role changes
      access_role() {
        this.initPolicy()
      },
    },

    created() {
      this.initPolicy()
    },

    methods: {
      // Switch to Policy Editor
      editPolicy() {
        if (this.employee && this.policyRequired) {
          this.mode = "policy"
        }
      },

      // Return from Policy Editor without finishing.
      // Clears out the policy
      policyBack() {
        this.mode = "form"
        this.initPolicy()
      },

      // Return from Policy Editor with a completed policy
      policyFinished() {
        this.mode = "form"
      },

      submit() {
        this.clearErrors()

        $.ajax({
          method: 'POST',
          url: this.$routes.promote_employee_admin_users_path,
          data: {
            promote: {
              employee_id: this.employee.id,
              access_role: this.access_role,
              // Policy only used for restricted admin
              policy: this.access_role == 'restricted_admin' ? JSON.stringify(this.policy.toJSON()) : null,
            },
          },
        }).then((_data, _status, xhr) => {
          Util.navigateAndShowAjaxFlashNotice(this.$routes.admin_users_path, xhr)
        }).fail((xhr) => {
          if (xhr.responseJSON && xhr.responseJSON.errors) {
            this.errors = xhr.responseJSON.errors || {}
          } else {
            Util.genericAjaxError("An error occurred promoting.", xhr)
          }
          this.$refs.submit.stop()
        })
      },

      initPolicy() {
        this.policy = new Policy()
      },

      clearErrors() {
        this.errors = {}
      },
    },
  }
</script>
