import PrerenderedTableRowItemView from 'src/views/common/prerendered_table_row_item_view'

export default class UdfFieldTableRowItemView extends PrerenderedTableRowItemView.extend({
  triggers: {
    'click @ui.editAction': 'field:edit',
    'click @ui.deleteAction': 'field:delete',
  },

  ui: {
    editAction: '.js-edit',
    deleteAction: '.js-delete',
  },
}) {

}
