<template>
  <div>
    <ul class="nav-wizard pl-0">
      <li v-for="(step, index) in steps" v-bind:key="index" :class="{active: index === currentStepIdx}">
        {{ step.label }}
      </li>
    </ul>
    <h2>{{ currentStep.title }}</h2>
    <p>{{ currentStep.subTitle }}</p>
    <WbCard>
      <component :is="currentStepComponentName"/>
    </WbCard>
  </div>
</template>

<script>
  import BaseWizard from 'components/common/BaseWizard'
  import FileUploadStep from 'components/user_updates/steps/FileUploadStep'
  import WbButton from 'components/common/WbButton'
  import WbCard from 'components/common/WbCard'

  export default {
    name: 'bulk-staff-update-wizard',
    components: {
      BaseWizard,
      FileUploadStep,
      WbButton,
      WbCard,
    },
    mixins: [BaseWizard],
    data() {
      return {
        steps: [
          {
            component: 'FileUploadStep',
            label: '1. Upload File',
            title: 'Upload Your Spreadsheet',
            subTitle: 'Make bulk updates to your staff in WorkBright using an Excel or CSV file.',
          },
        ],
        currentStepIdx: 0,
      }
    },
    computed: {
      currentStep() {
        return this.steps[this.currentStepIdx]
      },
    },
  }
</script>
