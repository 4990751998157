<template>
  <a
    href="javascript:void(0)"
    @click="clicked"
  >
    <span v-show="allSelected || onePageOnly">
      {{totalItemCount}} staff selected. Click to cancel all selections.
    </span>
    <span v-show="!allSelected" v-tooltip.html="toolTipMsg">
      Click to select all {{ totalItemCount }} staff that match this search/filter.
    </span>
  </a>
</template>

<script>
  export default {
    name: 'wb-all-yall',
    props: {
      totalItemCount: {
        type: Number,
        required: false,
      },
    },

    data() {
      return {
        allSelected: false,
        toolTipMsg: "Clicking this will allow you to apply a Bulk Action to ALL staff that match your search/filter, not just the ones displayed on the page.",
      }
    },

    computed: {
      onePageOnly() {
        // check to see if all items available to select are on the same page
        if (this.$parent.selected.length == this.totalItemCount) {
          this.allSelected = true
          return true
        } else {
          return false
        }
      },
    },

    methods: {
      clicked() {
        if (this.allSelected) {
          this.allSelected = false
          this.$emit('undo-all-yall')
        } else {
          this.allSelected = true
          this.$emit('selected-all-yall')
        }
      },
    },
  }
</script>
