export default Marionette.ItemView.extend({
  template: '#template-sheet-column-view',
  tagName: 'li',
  ui: {
    fieldDropdown: '.js-fields',
    ignoreButton: '.ignore',
    previewPopover: '.preview-popover',
  },
  events: {
    'click @ui.ignoreButton': 'ignoreColumn',
  },
  modelEvents: {
    change: 'changed',
  },

  // Model change, re-render
  changed: function(){
    this.render()
  },

  onRender: function(){
    this.renderSelectDropdown()
    this.renderPreviewPopover()
  },

  onShow: function(){
    this.fieldSelectView.render()
  },

  onDomRefresh: function(){
    this.fieldSelectView.render()
  },

  onBeforeDestroy: function() {
    // Since we are managing fieldSelectView ourselves, destroy it manually before we go away.
    this.fieldSelectView.off()
    this.fieldSelectView.destroy()
  },

  // Match this spreadsheet column to a Field by key
  // Callback fired from RepresentableFieldSelectView when a field has been selected.
  onFieldChanged: function(field) {
    App.AdminUserImport.userImportColumnMatchCoordinator.matchColumnAndSave(this.model, field.get('field_key'))
  },

  // Ignore this spreadsheet column, it is not matched to a Field
  ignoreColumn: function(){
    this.ui.ignoreButton.popover('hide')
    App.AdminUserImport.userImportColumnMatchCoordinator.ignoreColumnAndSave(this.model)
  },

  // Render the dropdown selector for SheetColumns
  renderSelectDropdown: function(){
    const successClass = 'list-group-item-success'
    const disabledClass = 'disabled'

    this.$el.addClass('list-group-item')

    this.$el.removeClass(successClass)
    this.$el.removeClass(disabledClass)
    if (this.model.isMatched()){
      this.$el.addClass(successClass)
    } else if (this.model.isIgnored()) {
      this.$el.addClass(disabledClass)
    }

    const matchedColumn = App.AdminUserImport.userImportColumnMatchCoordinator.sheetColumnMatchedTo(this.model)

    // Initialize a RepresentableFieldSelectView that we will manage ourselves. Don't render it now.
    this.fieldSelectView = new App.Views.Common.RepresentableFieldSelectView({
      fieldsTree: App.AdminUserImport.userImportColumnMatchCoordinator.fieldsTree,
      el: this.ui.fieldDropdown,
      selectedField: matchedColumn})
    this.fieldSelectView.on('field:changed', this.onFieldChanged, this)
  },

  // Render the column preview popover
  renderPreviewPopover: function(){
    let content = this.model.get('preview')
    content = (content && content.length > 0) ? content : "(no content)"
    const contentHtml = content.replace(/\|/g, "<hr/>")

    this.ui.previewPopover.popover({
      container: 'body',
      trigger: 'hover',
      content: contentHtml,
      title: 'Sample data from your spreadsheet',
      html: true,
    })
  },
})
