<template>
  <div>
    <div v-if="usingAuthApp">
      <p>
        <strong>Step 1: Get an Authenticator application.</strong>
      </p>
      <p>
        First, you will need an Authenticator application on your phone. If you already have one, continue to step 2. If not, we recommend Google Authenticator, Microsoft Authenticator, or Authy, which you can download free in the Apple App Store or Google Play Store.
      </p>
      <hr />
      <p>
        <strong>Step 2: Scan this QR code with your Authenticator application.</strong>
      </p>
      <p>
        Open the authenticator app, tap "Scan QR Code" or "+", and when it asks, point your phone camera at this QR code.
      </p>
      <QrcodeVue :value="provisioningUri" size="300" level="H" />
    </div>
    <div v-else>
      <p>
        You have chosen to configure Multi-Factor Authentication via text message, which is less secure than using an authenticator app. This is because hackers have been successful in tricking phone carriers (AT&T, Verizon, etc.) into porting phone numbers to a new device (SIM-swapping). If you choose this method of MFA in WorkBright, please update your phone carrier settings to require an access code so no changes can be made to your phone carrier account in-person or online, unless that code is provided.
      </p>
      <a @click="handleClickAuthAppConfig" href="#authapp">
        Click here if you prefer to configure MFA using an authenticator app.
      </a>
      <hr />
      <p>
        <strong>Step 1: Enter your mobile phone number</strong>
      </p>
      <form @submit="handleSubmitSmsForm">
        <form-field-input
          placeholder="555-555-5555"
          :required="true"
          label="Phone"
          :errors="formErrors"
          name="phone"
          v-model="formData.phone"
        />
      </form>
      <hr />
      <p>
        <strong>Step 2: Request a text message with your one-time password</strong>
      </p>
      <div class="form-group">
        <ladda-button ref="sendSmsButton" @click="handleClickSmsButton">
          Send Text Message
        </ladda-button>
      </div>
    </div>
  </div>
</template>

<script>
  import MfaMixin from 'components/mfa/MfaMixin'
  import QrcodeVue from 'qrcode.vue'

  export default {
    name: 'mfa-config-container',

    components: {
      QrcodeVue,
    },

    mixins: [MfaMixin],

    props: {
      usingAuthApp: {
        type: Boolean,
        required: true,
      },
      provisioningUri: {
        type: String,
        required: true,
      },
    },

    methods: {
      handleClickAuthAppConfig(){
        this.$emit("wbClickAuthAppConfig", {})
      },

      // user has entered their phone number and will be ready
      // to submit (activate their mfa) once they receive their otp (ajax+json success)
      handleClickSmsButton(){
        this.formErrors = {}
        this.sendOtpViaSms(this.formData.phone).success( (json) => {
          if (json.success)
          {this.$emit("wbOtpSentViaSms", {phone: this.formData.phone})}
          else {
            this.formErrors = json.errors || {}
            this.$refs.sendSmsButton.stop()
          }
        }).always(() => {
          // stop sms button spinner after timeout
          // setTimeout(() => this.$refs.sendSmsButton.stop(), Constants.SMS_TIMEOUT);
          this.deferredActionPostSms(() => this.$refs.sendSmsButton.stop())
        })
      },

      // user pressed enter in the phone field
      handleSubmitSmsForm(event) {
        event.preventDefault()
        this.$refs.sendSmsButton.start()
        this.handleClickSmsButton()
      },
    },
  }
</script>
