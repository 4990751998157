<script>
  // BaseFilter provides some shared behavior for CollectionViewStore filters, including:
  // - standardized props
  // - fetching value from Vuex with filter key
  // - setting value in Vuex by filter key
  export default {
    props: {
      // Filter
      // {
      //   filter_key: (required) String
      //   title: (required) String
      //   ... other required properties vary based on filter type, commonly
      // }
      filter: {
        type: Object,
        required: true,
      },

      // Vuex CollectionViewStore module name
      collectionViewName: {
        type: String,
        required: true,
      },
    },
    computed: {
      value: {
        get() {
          const filterValues = this.$store.getters[`${this.collectionViewName}/filterValues`]
          let value = filterValues[this.filter.filter_key]
          if (value == undefined) { value = null };
          return value
        },
        set(value) {
          this.setFilterValue(value)
        },
      },
    },
    methods: {
      setFilterValue(value) {
        this.$store.dispatch(`${this.collectionViewName}/setFilterValue`,
                             {
                               filter_key: this.filter.filter_key,
                               value: value,
                             })
      },
    },
  }
</script>
