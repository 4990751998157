import UserImportEmployeesCollection from 'models/user_import/user_import_employees_collection'
import UserImportFieldCollection from 'models/user_import/user_import_fields_collection'
import UserImportSheetColumnCollection from 'models/user_import/user_import_sheet_column_collection'
import UserImportColumnMatchCoordinator from 'objects/user_import/user_import_column_match_coordinator'
import UserImportValidationMeta from 'models/user_import/user_import_validation_meta'
import UserImportWarnings from 'models/user_import/user_import_warnings'
import EmployeesCollectionView from 'views/user_imports/validation/employees_collections_view'
import ImportConfirmView from 'views/user_imports/validation/import_confirm_view'
import ImportStatusView from 'views/user_imports/import/import_status_view'
import SheetColumnsCompositeView from 'views/user_imports/columns/sheet_columns_composite_view'
import ReuploadStartView from 'views/user_imports/validation/reupload_start_view'
import ReuploadView from 'views/user_imports/shared/reupload_view'
import SubmitSheetView from 'views/user_imports/columns/submit_sheet_view'
import SuggestRequiredFieldsView from 'views/user_imports/columns/suggest_required_fields_view'
import SuggestUnmatchedColumnsView from 'views/user_imports/columns/suggest_unmatched_columns_view'
import ValidatingEmployees from 'views/user_imports/validating_employees'
import ValidationMessageView from 'views/user_imports/validation/validation_message_view'
import UserImportBulkImportStatus from 'models/user_import/user_import_bulk_import_status'

export default function(Sector, App, Backbone, Marionette, $, _) {
  Sector.addInitializer(function() {

    //
    // New User Import
    //

    Sector.listenTo(App.vent, 'admin_user_import:init_new_user_import', function(){
      Sector.mainPageLayout.uploaderRegion.show(new ReuploadView({}))
    })

    //
    // Column Matching:
    //
    Sector.userImportColumnMatchCoordinator = null

    Sector.listenTo(App.vent, 'admin_user_import:init_column_match', function(id, autoConfirm) {

      Sector.userImportColumnMatchCoordinator = new UserImportColumnMatchCoordinator({
        fields: new UserImportFieldCollection(),
        sheetColumns: new UserImportSheetColumnCollection([], {id: id}),
        representableFields: App.Collections.RepresentableFieldCollection,
        submitSheetView: new SubmitSheetView({}),
        autoConfirm: autoConfirm,
      })

    })

    Sector.listenTo(App.vent, 'admin_user_import:column_match_data_loaded', function() {
      Sector.mainPageLayout.sheetColumnsRegion.show(new SheetColumnsCompositeView({
        collection: Sector.userImportColumnMatchCoordinator.sheetColumns,
      }))

      Sector.mainPageLayout.suggestRequiredFieldsRegion.show(new SuggestRequiredFieldsView({
        collection: Sector.userImportColumnMatchCoordinator.fields,
      }))

      Sector.mainPageLayout.suggestUnmatchedColumnsRegion.show(new SuggestUnmatchedColumnsView({
        collection: Sector.userImportColumnMatchCoordinator.sheetColumns,
      }))

      Sector.mainPageLayout.submitSheetRegion.show(Sector.userImportColumnMatchCoordinator.submitSheetView)

      Sector.userImportColumnMatchCoordinator.saveMatches()
    })

    Sector.listenTo(App.vent, 'admin_user_import:column_match_confirm', function() {
      Sector.userImportColumnMatchCoordinator.sheetColumns.confirmColumns()
    })

    Sector.listenTo(App.vent, 'admin_user_import:column_confirm_started', function(){
      Sector.userImportColumnMatchCoordinator.columnConfirmStarted()
    })

    Sector.listenTo(App.vent, 'admin_user_import:employee_validation_started', function(){
      App.AdminUserImport.renderMainPageLayout(ValidatingEmployees)
    })

    Sector.listenTo(App.vent, 'admin_user_import:employee_validation_complete', function(){
      Turbolinks.visit(`/user_imports/${Sector.userImportColumnMatchCoordinator.sheetColumns.id}/validate`)
    })

    Sector.listenTo(App.vent,'admin_user_import:employee_validation_failed', function() {
      Sector.mainPageLayout.validationFailure()
    })

    //
    // Employee Validation:
    //

    Sector.userImportEmployeesCollection = null
    Sector.userImportValidationMeta = null

    Sector.listenTo(App.vent, 'admin_user_import:init_employee_validation', function(id, state){
      const errorsOnly = (state == 'validation_errors')

      Sector.userImportEmployeesCollection = new UserImportEmployeesCollection([], {id: id})
      Sector.userImportEmployeesCollection.fetch({
        data: {errors_only: errorsOnly},
        success: function(){

          Sector.mainPageLayout.validationMessageRegion.show(new ValidationMessageView({
            model: Sector.userImportValidationMeta,
          }))

          Sector.mainPageLayout.employeeTableRegion.show(new EmployeesCollectionView({
            collection: Sector.userImportEmployeesCollection,
            showErrors: Sector.userImportValidationMeta.errors(),
          }))

          if (errorsOnly) {
            Sector.mainPageLayout.reuploadStartRegion.show(new ReuploadStartView({}))
          } else {
            const warnings = new UserImportWarnings({}, {id: id})
            warnings.fetch()
            Sector.mainPageLayout.importConfirmRegion.show(new ImportConfirmView({
              model: warnings,
            }))
          }

          Sector.mainPageLayout.uploaderRegion.show(new ReuploadView({}))
        },
      })

      Sector.userImportValidationMeta = new UserImportValidationMeta({
        state: state,
        users: Sector.userImportEmployeesCollection,
      })
    })


    Sector.listenTo(App.vent, 'admin_user_import:show_import', function(){
      Turbolinks.visit(`/user_imports/${Sector.userImportEmployeesCollection.id}/import`)
    })


    //
    // Import
    //

    Sector.userImportBulkImportStatus = null

    Sector.listenTo(App.vent, 'admin_user_import:init_import', function(id, state){
      Sector.userImportBulkImportStatus = new UserImportBulkImportStatus({id: id, state: state})
      Sector.userImportBulkImportStatus.subscribe()
      Sector.userImportBulkImportStatus.import()

      Sector.mainPageLayout.statusRegion.show(new ImportStatusView({
        model: Sector.userImportBulkImportStatus,
      }))
    })

    Sector.listenTo(App.vent, 'admin_user_import:import_complete', function(id){
      Turbolinks.visit(`/user_imports/${id}/complete`)
    })

  })
}
