<template>
  <main>
    <template v-if="hasStandardFields">
      <p>{{ $locale.t('standard_fields.mapping.instructions')}}</p>
      <p v-if="directDepositIncluded">
        <em>{{ $locale.t('standard_fields.mapping.single_direct_deposit_note') }}</em>
      </p>
      <table class="table table-striped" ref="fieldsTable">
        <thead>
          <tr>
            <th>{{$locale.t('standard_fields.field')}}</th>
            <th>{{$locale.t('standard_fields.description')}}</th>
            <th class="text-center">{{$locale.t('standard_fields.internal')}}?</th>
            <th class="text-center">{{$locale.t('standard_fields.mapped')}}?</th>
            <th role="presentation" />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(field, index) in standardFields"
            :key="index"
          >
            <td>{{ field.label }}</td>
            <td>{{ field.description }}</td>
            <td class="text-center">
              <i class="fa fa-check" v-if="field.internal" />
              <template v-else>--</template>
            </td>
            <td class="text-center">
              <i class="fa fa-check" v-if="getFieldMappingStatus(field)" />
              <template v-else>--</template>
            </td>
            <td class="text-right">
              <button
                class="btn btn-link"
                @click="openMapperModal(field)"
              >
                {{ getMappingActionText(field) }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <FieldMapper
        v-if="activeField"
        :standardField="activeField"
      />
      <div class="mt-3 text-right">
        <p
          class="help-block error"
          ref="helpText"
          v-if="shouldShowErrors && hasErrors"
        >
          {{ $locale.t('standard_fields.mapping.error_message')}}
        </p>
        <LaddaButton
          class="btn btn-primary"
          @click="handleFinishClick"
          ref="finishButton"
        >
          Finish
        </LaddaButton>
      </div>
    </template>
    <div class="alert alert-info" v-else ref="noData">
      {{ $locale.t('standard_fields.account_no_fields') }}
    </div>
  </main>
</template>
<script>
  import FieldMapper from 'components/admin/standard_fields/FieldMapper'
  import LaddaButton from 'components/common/LaddaButton'
  import { mapGetters, mapMutations } from 'vuex'
  import * as mutate from 'vuex/mutation_types'
  import { ajax } from 'jquery'
  import Util from 'lib/util'

  export default {
    name: 'the-standard-fields-index-root',
    components: {
      FieldMapper,
      LaddaButton,
    },
    computed: {
      ...mapGetters({
        standardFieldsById: 'standard_fields/fieldsById',
      }),
      hasErrors () {
        return !this.hasMappedAllRequiredFields
      },
      hasMappedAllRequiredFields () {
        return this.standardFields.every(field => {
          return field.internal || this.getFieldMappingStatus(field)
        })
      },
      hasStandardFields () {
        return !!(this.standardFields && this.standardFields.length)
      },
      standardFields () {
        return Object.values(this.standardFieldsById || {}).sort((a, b) => a.position - b.position)
      },
      directDepositIncluded () {
        return this.standardFields.some(field => {
          return field.label.includes("Direct Deposit Account")
        })
      },
    },
    data () {
      return {
        activeField: null,
        shouldShowErrors: false,
      }
    },
    methods: {
      ...mapMutations({
        setPageContextKeys: mutate.SET_PAGE_CONTEXT_KEYS,
      }),
      getFieldMappingStatus (field) {
        return !!(field && field.reference_field_key)
      },
      getMappingActionText(field) {
        return this.getFieldMappingStatus(field) ?
          this.$locale.t('standard_fields.update_mapping') :
          this.$locale.t('standard_fields.add_mapping')
      },
      fetchFinishUpdate () {
        this.$refs.finishButton.start()

        ajax({
          url: this.$routes.integration_finish_mapping_path({ id: this.integration.id }),
          method: 'PATCH',
        }).then(() => {
          location.reload()
        }).fail(xhr => {
          Util.genericAjaxError(this.$locale.t('errors.ajax.generic'), xhr)
        }).always(() => {
          if (this.$refs.finishButton) {
            this.$refs.finishButton.stop()
          }
        })
      },
      handleFinishClick () {
        this.shouldShowErrors = true
        if (this.hasMappedAllRequiredFields) {
          this.fetchFinishUpdate()
        } else {
          this.$refs.finishButton.stop()
        }
      },
      openMapperModal (field) {
        this.activeField = field

        this.$nextTick(() => {
          this.setPageContextKeys({ standard_field_id: field.id })
        })
      },
    },
    props: {
      integration: {
        type: Object,
        required: true,
      },
    },
  }
</script>

