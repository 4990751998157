import FormsUtil from 'lib/util/forms'

// This class wraps FormValidation.io for use with server-side validation. This can be used with both simple
// one-page forms as well as more complex multi-step forms (i.e. wizards).
//
// Complete documentation can be found at: https://app.tettra.co/teams/workbright/pages/remoteformvalidation
//
// SETTINGS (passed to RemoteFormValidation initializer)
//
//    path - (String, Required) the backend URL where to submit the form for validation
//    successCallback - (Function()) called when validation passes
//    failedCallback - (Function(response)) called when server returns validation errors
//    errorCallback - (Function(xhr)) called if there is an AJAX error, such as HTTP 500, 403, etc.
//    resourceName - (String, Required) the name of the resource being transmitted; this should match the key that the
//                   Ruby backend would look for in the `params` object, e.g. resourceName: 'band' for `params[:band]`
//    formEl - (jQuery, Required) the jQuery form element
//    model - (Backbone.Model, Required) the model which contains the form values, serialized via `toJSON()`
//    serializeOptions - (Object) argument passed to model.toJSON
//    extraData - (Object) additional data to be included with the Ajax request
//    httpMethod - (String) 'POST' by default. Corresponds with $.ajax methods
//    fvOptions - (Object) additional settings to pass to FormValidation.io plugin. see http://formvalidation.io/settings/
//                DO NOT include `fields` (this is provided as `validations` to RemoteFormValidation instead, see below)
//    validations - (Object, Required) the same structure that FormValidation.io expects in its `fields` setting,
//                  see http://formvalidation.io/settings/#field-settings
//    partialValidation - (Boolean, default is 'true') If true, extra `_validate_only` parameter is transmitted.
//    wbGenericFailureMsg - (String or `false` to disable) Passed to $.ajax call(s) to be used by global Ajax handlers.
//                          A basic default String is provided.

const defaults = {
  partialValidation: true,
  path: null,
  successCallback: null,
  failedCallback: null,
  errorCallback: null,
  resourceName: null,
  formEl: null,
  model: null,
  serializeOptions: {},
  extraData: null,
  httpMethod: 'POST',
  fvOptions: {
    framework: 'bootstrap',
    live: 'disabled',
    excluded: ':disabled',   // by default, include hidden fields or non-visible fields in the form
    row: {
      valid: null,
    },
  },
  wbGenericFailureMsg: "Sorry, we had trouble validating this form.",
}

export default class RemoteFormValidation {
  // Initializer
  //
  constructor(options) {
    this.options = _.extend({}, defaults, options)
    this.fvOptions = _.extend({}, defaults.fvOptions, this.options.fvOptions, { fields: this.options.validations })
    this.submitting = false // Maintain submitting state of form

    const formEl = this.options.formEl.formValidation(this.fvOptions)

    formEl.on('success.form.fv', function(e) {
      e.preventDefault()
      return false
    })

    formEl.on('submit', (e) => {
      e.preventDefault()
      e.stopPropagation()

      // HACKFIX: the only way I could get fV to re-validate client-side only fields upon resubmit was to resetForm
      this._fv.resetForm()

      this._fv.validate()  // Client-side validations (via formValidation plugin)
      this._validate()     // Server-side validations (here)

      return false
    })

    this._fv = formEl.data('formValidation')
  };

  // Returns - The FormValidation plugin corresponding with this instance
  get fvPlugin() {
    return this._fv
  }

  // (Private) Performs the remote validation call and parses the response.
  //
  // Fires `callback` option if one is provided.
  _validate(_e) {
    // Prevent repeat submit
    if (this.submitting){ return false }
    this.submitting = true

    const data = $.extend({}, this.options.extraData)
    data[this.options.resourceName] = this.options.model.toJSON(this.options.serializeOptions)

    // this flag tells the backend to ONLY process the request as validation
    if (this.options.partialValidation) {
      data['_validate_only'] = true
    }

    $.ajax({
      method: this.options.httpMethod,
      timeout: this.options.timeout,
      url: this.options.path,
      data: JSON.stringify(data),
      dataType: 'json',
      contentType: 'application/json',
      progressBar: false,
      statusCode: {
        // HACKFIX: Rails sends back a 201 with Content-Type="application/json" header and no response text, which
        // breaks jQuery (it tries to process the JSON response, finds nothing, and calls the `error` callback).
        // The fix proxies the 201 'error' response to the `done` callback (rearranges the args):
        201: (xhr) => {
          this._remoteValidateSuccess(xhr.responseText, xhr.status, xhr)
        },
      },
      wbGenericFailureMsg: this.options.wbGenericFailureMsg,
    })
      .done(this._remoteValidateSuccess.bind(this))
      .fail(this._remoteValidateError.bind(this))
      .always(() => { this.submitting = false }) // Clear the form submitting state to allow resubmission
  }

  // Private - Callback from the AJAX request when 2xx header is returned.
  //
  // xhr - XHR from remote validation
  _remoteValidateSuccess(_data, _status, xhr) {
    // FIXME: Clear all errors
    //
    if (_.isFunction(this.options.successCallback)) {
      this.options.successCallback(xhr)
    }
  }

  // Private - Callback from the AJAX request when non-2xx header is returned.
  _remoteValidateError(xhr) {
    const response = xhr.responseJSON

    switch (xhr.status) {
    case 422:  // validation response
      let hasErrors = false
      const flattenedErrors = FormsUtil.flattenErrors(response.errors, { namespace: this.options.resourceName })
      // Just because the response contains validation errors doesn't mean that those errors correspond to fields
      // we care about (see Partial Validation in Docs above). Iterate through each of the CONFIGURED fields,
      // skipping those that don't have wbRemote, and see if there are any associated error messages in the
      // JSON response. Field `name` attribute MUST match with the flattened version of the key in the JSON errors
      // response.
      _.each(this._fv.options.fields, function(validatorConfig, fieldKey) {
        if (!_.isObject(validatorConfig.validators.wbRemote)) {
          return // skip
        }

        const fieldElements = this._fv.getFieldElements(fieldKey)

        _.each(fieldElements, function(fieldEl) {
          const $fieldEl = $(fieldEl)
          const responseErrorKey = this._fieldNameToErrorKey($fieldEl.attr('name'), validatorConfig.validators.wbRemote)

          const errorMessages = flattenedErrors[responseErrorKey]
          if (errorMessages) {
            hasErrors = true
            this._fv.updateStatus($fieldEl, 'INVALID', 'wbRemote')
            this._fv.updateMessage($fieldEl, 'wbRemote', errorMessages.join(" "))
          } else {
            this._fv.updateStatus($fieldEl, 'VALID', 'wbRemote')
          }
        }.bind(this))
      }.bind(this))

      // Trigger callback depending on whether or not we had errors
      if (hasErrors || (!hasErrors && !this.options.partialValidation)) {
        if (_.isFunction(this.options.failedCallback)) {
          this.options.failedCallback(response)
        }
      } else {
        this._remoteValidateSuccess(null, null, xhr)
      }

      break

    case 201:
      break // see comment above on handling 201

    default: // 500 unexpected; 0 network error/timeout; 409 conflict
      if (_.isFunction(this.options.errorCallback)) {
        this.options.errorCallback(xhr)
      }
      break
    }
  }

  // Private - Converts a field name from Rails conventions to a format suitable for comparison with the remote
  // validation response.
  //
  // fieldName - (String) the `name` attribute of the <input> field
  // validatorSettings - (Object) any additional validator settings
  //
  // Returns - String
  _fieldNameToErrorKey(fieldName, validatorSettings) {
    // _attributes is a Rails things.
    let errorKey = fieldName.replace('_attributes', '')

    // For dropzone fields, associate file_cache fields with the `file` error key
    if (validatorSettings.dropzone) {
      errorKey = errorKey.replace('_cache', '')
    }

    if (validatorSettings.errorKey !== undefined){
      errorKey = validatorSettings.errorKey
    }

    return errorKey
  }

  // Destroys the Form Validation instance by removing existing fields first, destroying, and turning off form bindings
  destroy() {
    _.each(this._fv.options.fields, (_validators, fieldKey) => {
      this._fv.removeField(fieldKey)
    })

    // After we remove the fields, we destroy the RFV instance and turn off bindings
    this._fv.destroy()

    // Turn off bindings
    this.options.formEl.off()
  }
};
