import Vuex from 'vuex'
import * as actions from 'vuex/actions'
import * as getters from 'vuex/getters'
import mutations from 'vuex/mutations'

import account from 'vuex/modules/account'
import admin_users from 'vuex/modules/admin/admin_users'
import collection_view_store from 'vuex/modules/collection_view_store'
import custom_fields from 'vuex/modules/custom_fields'
import dashboard from 'vuex/modules/dashboard'
import document_assignments from 'vuex/modules/document_assignments'
import document_orderings from 'vuex/modules/document_orderings'
import document_submissions from 'vuex/modules/document_submissions'
import documents from 'vuex/modules/documents'
import employees from 'vuex/modules/employees'
import employments from 'vuex/modules/employments'
import everify_accounts from 'vuex/modules/everify_accounts'
import everify_cases from 'vuex/modules/everify_cases'
import groups from 'vuex/modules/groups'
import i9_inbox_assignments from 'vuex/modules/i9_inbox_assignments'
import managed_access_policy_templates from 'vuex/modules/managed_access_policy_templates'
import onboarding_submissions from 'vuex/modules/onboarding_submissions'
import permissions from 'vuex/modules/permissions'
import representable_fields_tree from 'vuex/modules/representable_fields_tree'
import tenant_select_accounts from 'vuex/modules/tenant_select_accounts'
import admin_downloads from 'vuex/modules/admin/admin_downloads'
import usage_reports from 'vuex/modules/superadmin/usage_reports'
import payroll_fields from 'vuex/modules/payroll_fields'
import standard_fields from 'vuex/modules/standard_fields'
import user from 'vuex/modules/user'
import webhooks from 'vuex/modules/webhooks'

const availableModules = {
  account,
  admin_users,
  admin_downloads,
  collection_view_store,
  custom_fields,
  dashboard,
  document_assignments,
  document_orderings,
  document_submissions,
  documents,
  employees,
  employments,
  everify_accounts,
  everify_cases,
  groups,
  i9_inbox_assignments,
  managed_access_policy_templates,
  onboarding_submissions,
  payroll_fields,
  permissions,
  representable_fields_tree,
  standard_fields,
  tenant_select_accounts,
  usage_reports,
  user,
  webhooks,
}

const initializeModules = (store) => {
  if (!store.__initialized) {
    const modules = store.availableModules
  
    Object.entries(modules).forEach(([moduleName, module]) => {
      const init = module.actions.init
      if (init) {
        store.dispatch(`${moduleName}/init`)
      }
    })
    store.__initialized = true
  }
}

function registerStoreModules (store) {
  // register all available Modules in the Vuex store
  for (const moduleName of Object.keys(store.availableModules)) {
    store.registerModule(moduleName, store.availableModules[moduleName])
  }
}

export function createStore() {
  const storeInstance = new Vuex.Store({
    state: {
      pageContext: {},
    },
    actions,
    getters,
    mutations,
    modules: {},  // Static modules only (cannot/shouldnot be unregistered)
  })
  storeInstance.availableModules = availableModules
  storeInstance.initializeModules = initializeModules

  return storeInstance
}

export function createStoreWithModules () {
  const storeInstance = createStore()
  
  registerStoreModules(storeInstance)
  
  return storeInstance
}
