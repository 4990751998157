<template>
  <ul class="nav navbar-nav navbar-left navbar-app">
    <li
      v-for="(item, index) in items"
      :key="index"
      :class="{ active: item.active }"
    >
      <a :href="item.href">{{ item.label }}</a>
    </li>
  </ul>
</template>
<script>
  export default {
    props: {
      items: {
        type: Array,
        required: true,
      },
    },
  }
</script>
