import UserImportCollectionView from 'views/user_imports/user_import_collection_view'
import UserImportsCollection from 'collections/user_imports_collection'

export default Marionette.LayoutView.extend({
  template: false,

  initialize: function() {
    this.$tableEl = $('#user-imports-table')
  },

  onBeforeRender: function() {
    if (this.$tableEl) {
      this.attachToListTable()

      this.addRegion("toolbarRegion", ".dt-toolbar")
      const toolbarView = new Marionette.ItemView({ template: "#user-imports-toolbar-tmpl" })
      this.toolbarRegion.show(toolbarView)
    }
  },

  attachToListTable: function() {
    this.$tableEl.DataTable({
      retrieve: true,
      destroy: true,
      sorting: [],
      columns: [
        /* 1 Date */ { type: 'date' },
        /* 2 Users */ null,
        /* 3 Status */ null,
        /* 4 Actions */ null,
        /* 5 Delete */ null,
      ],
      language: {
        emptyTable: `<div class="text-center"><h4>No Staff Imports have been created.</h4>` +
                    `<p><a href="${App.Util.getPageRootPath()}/new">Click here</a> to import your Staff.</p></div>`,
      },
    })

    App.UserImports.listTableCollectionView = new UserImportCollectionView()
    App.UserImports.listTableCollectionView.attachToTable(this.$tableEl, UserImportsCollection, function() {
      return {
        id: this.data("id"),
      }
    })
  },

  onBeforeDestroy: function() {
    if (App.UserImports.listTableCollectionView) {
      App.UserImports.listTableCollectionView.destroy()
      delete App.UserImports.listTableCollectionView
    }
  },
})
