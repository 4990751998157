<template>
  <ul class="nav navbar-nav navbar-right">
    <li>
      <button
        class="js-exit-kiosk-mode btn btn-link"
      >
        <WbIcon type="sign-out" />
        Exit Kiosk Mode
      </button>
    </li>
  </ul>
</template>
<script>
  import WbIcon from 'components/common/WbIcon'
  export default {
    name: 'kiosk-navbar-nav',
    components: {
      WbIcon,
    },
  }
</script>
