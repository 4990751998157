import { ajax } from 'jquery'
import { getRoute } from 'lib/routes'

const state = () => ({
  collection: [],
  pagination: null,
})

const getters = {
  collection: state => state.collection,
  pagination: state => state.pagination,
}

const mutations = {
  SET_COLLECTION: (state, assignments = []) => {
    state.collection = assignments
  },
  SET_PAGINATION: (state, pagination) => {
    state.pagination = pagination
  },
}

const actions = {
  fetchSubmissions: ({ commit }, { page, query, sort }) => {
    return new Promise((resolve, reject) => {
      ajax({
        url: getRoute('onboarding_submissions_path'),
        method: 'GET',
        data: {
          page,
          query,
          sort,
        },
      }).then(({ items, pagination }) => {
        commit('SET_COLLECTION', items)
        commit('SET_PAGINATION', pagination)
        resolve()
      }).fail(response => {
        commit('SET_COLLECTION', [])
        commit('SET_PAGINATION', null)
        reject(response)
      })
    })
  },
  acceptSubmission: ({ _commit }, { submissionId }) => {
    return new Promise((resolve, reject) => {
      ajax({
        url: getRoute('Api.accept_submission_path', { id: submissionId }),
        method: 'PATCH',
      }).then(() => {
        resolve()
      }).fail(response => {
        reject(response)
      })
    })
  },
  rejectSubmission: ({ _commit }, { submissionId, additional_details, reasons }) => {
    return new Promise((resolve, reject) => {
      ajax({
        url: getRoute('Api.reject_submission_path', { id: submissionId }),
        method: 'PATCH',
        data: {
          additional_details,
          reasons,
        },
      }).then((response) => {
        resolve(response)
      }).fail(response => {
        reject(response)
      })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
