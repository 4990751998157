import WizardStep from 'lib/wizard_step'
import Wizard from 'lib/wizard'
import WizardLayoutView from 'views/common/wizard_layout_view'
import Routes from 'lib/routes'
import StepStore from 'models/step_store'
import StepPersonalInfoView from 'views/document_submissions/i9/step_personal_info_view'
import StepCitizenshipView from 'views/document_submissions/i9/step_citizenship_view'
import DocumentSelectView from 'views/document_submissions/i9/document_select_view'
import StepPreparerView from 'views/document_submissions/i9/step_preparer_view'
import StepSignatureView from 'views/document_submissions/i9/step_signature_view'
import I9DocumentationWizardStep from 'models/document_submissions/i9_documentation_wizard_step'
import I9DocumentationUploadWizardStep from "models/document_submissions/i9_documentation_upload_wizard_step"
import Util from 'lib/util'
import SystemEvents from 'lib/system_events'
import EmployeeI9SubmittedEvent from 'models/system_events/employee_i9_submitted_event'

export default Marionette.LayoutView.extend({
  template: false,

  regions: {
    wizardRegion: "section.wizard-form",
  },

  initialize: function() {
    const personaSettings = this.options.personaSettings
    const documentationChoices = this.getOption('documentationChoices')
    const context = new StepStore()

    // REVIEW: Unfortunately, putting documentationChoices in 'global' context means it gets copied to EVERY individual
    // step context, but step contexts are only instantiated when the view is first needed @ runtime, so there is no
    // great way to inject the documentationChoices to just the step(s) that needs it :(
    const globalContext = context.register('global')

    globalContext.set('documentationChoices', documentationChoices)
    globalContext.set('personaSettings', personaSettings)

    this.wizard = new Wizard({
      steps: (this._getSteps()).filter((step) => step),
      model: this.model,
      context: context,
      onFinish: function(data) {
        const xhr = data // for clarity, `data` is any open-ended data, but in this case, it is xhr.

        let redirectLocation = undefined
        if (gon.current_account.remoteCountersignOnForEmployee && gon.current_account.autoAccept) {
          redirectLocation = Routes.i9_remote_countersign_begin_path
        } else {
          redirectLocation = Routes.staff_dashboard_path
        }

        Util.navigateAndCall(redirectLocation, function() {
          if (xhr.responseJSON) {
            /**
             * Create I-9 submitted event, sending the
             * document_verification_result as a payload
             */
            const systemEvent = new EmployeeI9SubmittedEvent(xhr.responseJSON.document_verification_result)

            Util.showFlashNotice("Your Form I-9 has been received.")
            SystemEvents.notify(systemEvent.payload())
          }
        })
      },
    })
  },

  onRender: function() {
    this.wizardRegion.show(new WizardLayoutView({ wizard: this.wizard }))
  },

  _getSteps: function() {
    const docUploadEnabled = gon.current_account.documentUploadEnabled
    const documentVerificationEnabled = gon.current_account.documentVerificationEnabled
    const stepPreparerViewStepsToRetreat = documentVerificationEnabled ? 2 : 1

    let steps
    if (docUploadEnabled) {
      steps = [
        new WizardStep({ view: StepPersonalInfoView, percent: 10 }),
        new WizardStep({ view: StepCitizenshipView, percent: 20 }),
        new WizardStep({ view: DocumentSelectView, percent: 20, memoizeAttachments: !documentVerificationEnabled}),
        new I9DocumentationUploadWizardStep({ percent: 30, canRetreatToSubStep: !documentVerificationEnabled}),
        new WizardStep({ view: StepPreparerView, percent: 10, stepsToRetreat: stepPreparerViewStepsToRetreat }),
        new WizardStep({ view: StepSignatureView, percent: 10 }),
      ]
    } else {
      steps = [
        new WizardStep({ view: StepPersonalInfoView, percent: 10 }),
        new WizardStep({ view: StepCitizenshipView, percent: 20 }),
        new I9DocumentationWizardStep({ percent: 50 }),
        new WizardStep({ view: StepPreparerView, percent: 10 }),
        new WizardStep({ view: StepSignatureView, percent: 10 }),
      ]
    }

    return steps
  },
})
