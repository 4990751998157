<template>
  <div>
    <div class="row">
      <div class="col-sm-7 col-sm-offset-4 col-md-offset-3">
        <p class="help-block" ref="instructions">{{ $locale.t('standard_fields.options_instructions', mappedFieldName, standardFieldName) }}</p>
        <p class="help-block error" v-if="!isValid && shouldShowErrors" ref="mapperFeedback">
          {{ $locale.t('standard_fields.select_value') }}
        </p>
      </div>
    </div>
    <div>
      <FormFieldSelect
        v-for="(option, index) in mappedFieldOptions"
        :class="{ 'has-error': errorsByOption[option.value] && shouldShowErrors }"
        :errors="errorsByOption[option.value]"
        :key="index"
        :label="option.label"
        :name="option.value"
        :options="standardFieldOptions"
        :value="getMappedOption(option.value)"
        @input="handleOptionChange(option.value, $event)"
        orientation="horizontal"
      />
    </div>
  </div>
</template>
<script>
  import FormFieldSelect from 'components/common/FormFieldSelect'
  export default {
    components: {
      FormFieldSelect,
    },
    props: {
      standardField: {
        type: Object,
        required: true,
      },
      mappedField: {
        type: Object,
        required: true,
      },
      shouldShowErrors: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        errorsByOption: {},
        isValid: true,
        mappedOptions: {},
      }
    },
    computed: {
      mappedFieldName () {
        return this.mappedField.label
      },
      mappedFieldOptions () {
        return (this.mappedField.ordered_choices || []).map(([label, value]) => {
          return { label, value }
        })
      },
      standardFieldOptions () {
        return (this.standardField.ordered_choices || []).map(([label, value]) => {
          return { label, value }
        })
      },
      standardFieldName () {
        return this.standardField.label
      },
    },
    created () {
      this.setMappedOptions()
      this.validate()
    },
    methods: {
      getMappedOption(fieldValue) {
        const map = this.standardField.reference_field_choices_map || {}
        return map[fieldValue]
      },
      setErrors () {
        const errorsByOption = {}
        this.mappedFieldOptions.forEach(({ value }) => {
          if (!this.mappedOptions[value]) {
            errorsByOption[value] = {
              incomplete: 'incomplete',
            }
          }
        })
        this.errorsByOption = errorsByOption
      },
      handleOptionChange(mappedFieldValue, standardFieldValue) {
        this.mappedOptions[mappedFieldValue] = standardFieldValue
        this.$emit('change', this.mappedOptions)
        this.validate()
      },
      setMappedOptions () {
        this.mappedOptions = this.standardField.reference_field_choices_map || {}
      },
      validateMappedOptions(options, mapped) {
        const keys = options.map(({ value }) => value)
        let isValid = true

        keys.forEach(key => {
          const mappedValue = mapped[key]
          isValid = isValid && !!mappedValue
        })

        return isValid
      },
      validate () {
        this.isValid = this.validateMappedOptions(this.mappedFieldOptions, this.mappedOptions)
        this.setErrors()
        this.$emit('validate', this.isValid)
      },
    },
  }
</script>
