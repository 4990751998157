import DateTime from 'lib/util/datetime'
export default class DownloadRequest {
  id
  description
  downloads_count
  complete_downloads_count
  user_id
  context
  created_at
  updated_at

  constructor ({ 
    id,
    user_id,
    context,
    complete_downloads_count,
    downloads_count,
    created_at,
    updated_at,
    description, 
  }) {
    this.id = id
    this.user_id = user_id
    this.context = context
    this.created_at = created_at
    this.downloads_count = downloads_count
    this.complete_downloads_count = complete_downloads_count
    this.updated_at = updated_at
    this.description = description
  }

  get name () {
    const formattedDate = DateTime.formatDateTime(this.created_at) || '--'
    return `Download ${formattedDate}`
  }
}
