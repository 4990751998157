/*
 * Container for displaying a WorkBright table of Staff document assignments for rehiring.
 * Similar to DocumentAssignmentListContainer but modified for the Rehire workflow.
 *
 */
<template>
  <div style="margin-top: -25px;">
    <FormFieldRadio
      label=" "
      :required="false"
      :options="formsGroupsOptions"
      :errors="formErrors"
      v-model="changeGroupsValue"
      name="forms_groups"
      orientation="vertical"
      class="ml-4"
    />
    <hr />
    <div class="assignment-root" v-bind:class="{ hidden: this.changeGroupsValue && this.changeGroupsValue == 'keep' }">
      <v-wait :for="loadingName" ref="wait">
        <template #waiting>
          <Spinner :showLongWaitMessage="true" ref="spinner" />
        </template>
        <div
          class="groups-filter"
          v-if="dataLoaded && canChangeGroups"
          ref="groupsFilter"
        >
          <div class="row">
            <div class="col-sm-4">
              <h4 class="text-strong">
                Filter Forms By Groups
                <PopoverIcon
                  title='Groups'
                  message='Selected are the Groups that this worker already belongs to. You can add or remove groups to this worker here.'
                />
              </h4>
            </div>
            <div class="col-sm-6">
              <WbMultiselect
                id="select-groups-dropdown"
                @input="values => setSelectedGroups(values)"
                :selectOptions="allGroupsOptions"
                :value="selectedGroupIds"
                :enableSearchField="true"
              />
            </div>
            <div class="col-sm-2">
              <button class="btn btn-info cancel-action" v-if="haveGroupsChanged" @click="handleClickClearFilters">
                <i class="fa fa-times-circle" />
                Reset
              </button>
            </div>
          </div>
        </div>
        <div class="wb-list assignment-list">
          <p class="mt-2">
            <span class="lead">
              New Forms
            </span>
            <PopoverIcon
              title='New Forms'
              message='These are all of the forms in the group(s) you added above. The staff member will be requested to complete all of these forms.'
            />
          </p>
          <a class="new-forms-toggle" @click="toggleNewAssignments()" href="javascript:void(0)">
            {{ pluralizedNewForms }} will be requested
            <i class="fa fa-caret-right ml-1" v-if="!showNewAssignments" />
            <i class="fa fa-caret-down ml-1" v-if="showNewAssignments" />
          </a>
          <ul class="list-group" ref="new-forms" v-if="showNewAssignments">
            <DocumentAssignmentListItem
              v-for="assignment in newAssignments"
              :key="assignment.id"
              :assignment="assignment"
              :groups="allGroupsOptions"
              :selectedGroupIds="selectedGroupIds"
              :collectionViewName="collectionViewName"
              :selectable="true"
              :disabled="true"
              :defaultSelected="true"
              :includeSecondaryActionMenu="false"
              :includeGroupsColumn="true"
              :clickableRow="false"
              :pendingStatus="'assignment_pending'"
            />
          </ul>
        </div>
        <div class="wb-list assignment-list" v-if="dataLoaded">
          <p class="mb-0">
            <span class="lead">
              Current Forms
            </span>
            <PopoverIcon
              title='Current Forms'
              message="Checked forms below indicate those that will be requested as a resubmission when Rehired, based on the configuration at the Form level. You can add or remove any form. If you check or uncheck a form, you are electing to override the configuration (at the Form level) for this particular worker."
            />
          </p>
          <div v-if="canChangeGroups">
            <small>
              Showing all forms in all groups selected above.
            </small>
          </div>
          <WbListHeader
            :filteredRowIds="filteredReassignmentIds"
            :availableSelections="filteredReassignments"
            :collectionViewName="collectionViewName"
            searchPlaceholder="Search Form Name"
            :selectable="true"
          >
            <template #bulkActions>
              New submissions will be requested for the selected forms
            </template>
          </WbListHeader>
          <p class="m-4" v-if="reassignments.length > 0 && filteredReassignments.length == 0">
            No matching assigned forms
          </p>
          <p class="m-4" v-if="reassignments.length == 0">
            No assigned forms exist for this staff member
          </p>
          <ul class="list-group" ref="current-forms">
            <DocumentAssignmentListItem
              v-for="assignment in filteredReassignments"
              :key="assignment.id"
              :assignment="assignment"
              :groups="allGroupsOptions"
              :selectedGroupIds="selectedGroupIds"
              :collectionViewName="collectionViewName"
              :selectable="true"
              :includeSecondaryActionMenu="false"
              :includeGroupsColumn="true"
              :clickableRow="false"
            />
          </ul>
        </div>
      </v-wait>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
  import * as mutate from 'vuex/mutation_types'
  import CollectionViewStore from 'vuex/modules/collection_view_store'
  import WbListHeader from 'components/common/WbListHeader'
  import DocumentAssignmentListItem from './../document_assignments/DocumentAssignmentListItem'
  import WbMultiselect from 'components/common/WbMultiselect'
  import FormFieldRadio from 'components/common/FormFieldRadio'
  import PopoverIcon from 'components/common/PopoverIcon'
  import Locale from 'lib/locale'
  import Util from 'lib/util'
  import Spinner from 'components/common/Spinner'
  import StringUtil from 'lib/util/string'

  // Vuex CollectionViewStore module name, stores metadata about collection (selected, filters, etc)
  const VUEX_COLLECTION_VIEW_NAME = 'collection_view_document_assignments'
  const BUILT_IN_DOC_IDS   = [ 'i9', 'w4', 'w9' ]
  const EMPLOYEE_DOC_IDS   = [ 'i9', 'w4' ]
  const CONTRACTOR_DOC_IDS = [ 'w9' ]

  // Uses the Vuex store to filter groups and documents:
  //    - 'filter_values' tracks the groups and assignments (and the employment dates for the review tab)
  //    - 'selected' tracks the documents assignments that have been selected (and will be requested from the employee)
  //    - internally, documents are used to map groups to assignments
  //
  //    collection_view_document_assignments: {
  //      filter_values: {
  //        selected_groups: [],
  //        new_assignments: [],
  //        employment_dates: {}
  //      },
  //      selected: []
  //    }

  export default {
    name: 'reassign-forms-container',
    components: {
      WbListHeader,
      DocumentAssignmentListItem,
      WbMultiselect,
      FormFieldRadio,
      PopoverIcon,
      Spinner,
    },

    data() {
      return {
        collectionViewName: VUEX_COLLECTION_VIEW_NAME,
        showNewAssignments: false,
        formErrors: {},
        options: {
          withGroups: [
            { label: 'Do not change groups and request new form submissions for all existing forms based on rehire default settings.', value: 'keep' },
            { label: 'Change groups and form assignments and/or override the rehire form default settings.', value: 'edit' },
          ],
          withoutGroups: [
            { label: 'Request new form submissions for all existing forms based on rehire default settings.', value: 'keep' },
            { label: 'Change form assignments and/or override the rehire form default settings.', value: 'edit' },
          ],
        },
        changeGroupsValue: 'keep',
        loadingName: 'resources_loading',
      }
    },

    computed: {
      formsGroupsOptions() {
        return this.canChangeGroups ? this.options.withGroups : this.options.withoutGroups
      },

      // Does the user have permission to add/remove the employee to/from groups? (Returns true/false)
      canChangeGroups() {
        return this.isPermitted('employee_group_memberships', this.employeeId, 'manage')
      },

      employeeId() {
        return this.pageContext.employee.id
      },

      employee() {
        return this.getEmployee(this.employeeId)
      },

      documentIds() {
        return _.map(this.documents, doc => doc.id)
      },

      documentAssignmentIds() {
        return _(this.documentAssignments).map(da => da.id)
      },

      assignmentDocumentIds() {
        return _(this.documentAssignments).map(da => da.document_id)
      },

      // The dropdown options for the list of groups
      allGroupsOptions() {
        return this.allGroups.map(group => { return { value: group.id, label: group.name }})
      },

      pluralizedNewForms() {
        return StringUtil.pluralize(this.newAssignments.length, 'new form')
      },

      selectedGroupIds() {
        return this.dataLoaded ? this.filterValues.selected_groups : []
      },

      // All the assignments currently assigned to the employee
      activeAssignments() {
        return _.where(this.documentAssignments, { active: true })
      },

      // Returns a list of assignments that have already been assigned to the employee, filtered by the selected groups
      // and filtered by the unlock_submission (request new submission) permission. If the user does not have permission
      // to request a new submission, we don't want to show the assignment.
      //
      // The user should have the option to select or unselect the assignments to indicate whether or not they should be
      // reassigned to the employee. (This list of assignments contains built-in documents.)
      reassignments() {
        return this.reassignableAssignments.concat(this.builtInAssignments).filter(a => {
          return this.canRequestAssignment(a.id)
        })
      },

      // Returns a list of assignments in newly added groups that have not yet been assigned to the employee. There may
      // not be document assignments for this employee for all of the documents in the selected groups so we create a
      // mock assignment for any missing assignments. The actual assignments will be created when we add the employee
      // to the group(s). Also, this list of assignments does not contain built-in documents.
      newAssignments() {
        return this.newAssignmentDocumentIds.map(docId => {
          let val = null
          const existingAssignment = this.assignmentForDocument(docId)
          if (!existingAssignment) {
            val = this.mockAssignment(docId)
          } else if (this.isAssignmentPermitted(existingAssignment)) {
            val = existingAssignment
          }
          return val
        }).filter(d => d)
      },

      // Reassignments filtered by the search term (or other filters)
      filteredReassignments() {
        return this.reassignments.filter(a => this.filterAssignment(a))
      },

      filteredReassignmentIds() {
        return this.filteredReassignments.map(a => a.id)
      },

      // Returns a list of assignments that can be reassigned (active assignments in the selected groups)
      reassignableAssignments() {
        return this.activeAssignments.filter(a => {
          return this.allSelectableDocuments.map(doc => doc.id).includes(a.document_id)
        })
      },

      // Returns a list of document IDs for assignments that can be reassigned (active assignments in the selected groups)
      reassignableDocumentIds() {
        return this.reassignableAssignments.map(a => a.document_id)
      },

      // Returns a list of documents for new assignments (documents in the selected groups that are not reassignments)
      newAssignmentDocuments() {
        return this.allSelectableDocuments.filter(doc => !this.reassignableDocumentIds.includes(doc.id))
      },

      newAssignmentDocumentIds() {
        return this.newAssignmentDocuments.map(doc => doc.id)
      },

      // Returns a list of all assignments that should be selected when groups are (re)selected. The user may select or
      // unselect some of these assignments but these are the default selections based on the document settings.
      selectedAssignmentsInSelectedGroups() {
        return this.reassignments.filter(a => {
          return this.selectedDocumentsInSelectedGroups.map(doc => doc.id).includes(a.document_id)
        }).concat(this.selectableBuiltInAssignments)
      },

      selectedAssignmentIds() {
        return this.selectedAssignmentsInSelectedGroups.map(a => a.id)
      },

      builtInDocumentIds() {
        const employeeType = this.employee.employee_type
        return [
          ... employeeType == 'employee'   ? EMPLOYEE_DOC_IDS   : [],
          ... employeeType == 'contractor' ? CONTRACTOR_DOC_IDS : [],
        ]
      },

      builtInAssignments() {
        return _(this.documentAssignments).filter(a => this.builtInDocumentIds.includes(a.document_id))
      },

      // Built-in documents should only be included if:
      //  - the admin has permission to request new submissions
      //  - the existing submission has expired and needs to be reassigned (only applies to I-9)
      selectableBuiltInAssignments() {
        return this.builtInAssignments.filter(a => {
          return this.canRequestAssignment(a.id) && this.reassignBuiltInForm(a)
        })
      },

      allDocumentsInSelectedGroups() {
        return _(this.documents).filter(doc => doc.group_ids.some(i => this.selectedGroupIds.includes(i)))
      },

      // Returns all the documents that are not in a group, except for built-in documents. (We add assignments for built-in documents later.)
      allDocumentsWithoutGroups() {
        return _(this.documents).filter(doc => doc.group_ids.length == 0 && !BUILT_IN_DOC_IDS.includes(doc.id))
      },

      allSelectableDocuments() {
        return this.allDocumentsInSelectedGroups.concat(this.allDocumentsWithoutGroups)
      },

      selectedDocumentsInSelectedGroups() {
        return this.allSelectableDocuments.filter(doc => doc.rehire_new_submission)
      },

      // Has the user changed the original set of groups?
      haveGroupsChanged() {
        return this.addedGroups.length || this.removedGroups.length
      },

      // New groups selected by the user
      addedGroups() {
        return this.selectedGroupIds.filter(g => !this.preselectedGroupIds.includes(g))
      },

      // Existing groups unselected by the user
      removedGroups() {
        return this.preselectedGroupIds.filter(g => !this.selectedGroupIds.includes(g))
      },

      // These are the "original" groups that an employee belonged to when the rehire process started. We use this list
      // of groups to know if any new groups have been selected and to reset the groups back to their original state.
      // This list should not be modified.
      preselectedGroupIds() {
        if (!!this.pageContext.groups) {
          return this.pageContext.groups.map(g => parseInt(g, 10))
        } else {
          return []
        }
      },

      searchTerm() {
        return this.filterValues.search
      },

      pageStatus() {
        return {
          groupsLoaded: this.pageContext.groupsLoaded,
          documentsLoaded: this.pageContext.documentsLoaded,
          assignmentsLoaded: this.pageContext.assignmentsLoaded,
          groupsPermissionsLoaded: this.pageContext.groupsPermissionsLoaded,
          assignmentPermissionsLoaded: this.pageContext.assignmentPermissionsLoaded,
        }
      },

      dataLoaded() {
        if (!this.pageContext) { return false }
        return Object.values(this.pageStatus).every(v => v)
      },

      ...mapGetters({
        pageContext: 'pageContext',
        allGroups: 'groups/all',
        getEmployee: 'employees/get',
        getDocument: 'documents/get',
        isPermitted: 'permissions/isPermitted',
        getSubmission: 'document_submissions/get',
        filterValues: `${VUEX_COLLECTION_VIEW_NAME}/filterValues`,
      }),

      ...mapState({
        documents: state => state['documents'].collection,
        documentAssignments: state => state['document_assignments'].collection,
        selected(state) {
          if (!state[this.collectionViewName]) { return }
          return state[this.collectionViewName].selected
        },
      }),
    },

    watch: {
      // Watch for changes to the selected groups
      selectedGroupIds: {
        handler(newValue) {
          // Update filterValues to track assignments
          this.updateNewAssignments()

          // Update the selected assignments whenever the selected groups change
          this.setSelectedAssignments()

          // Update the selected groups in the Rails form
          this.updateGroupsCallback(newValue)
        },
      },

      // Watch for changes to the list of documents and then (re)load document-assignments. This should only be
      // triggered when the component is created and the documents are fetched from the API.
      documentIds: {
        handler(newDocumentIds) {
          // If the documents have been loaded into Vuex, update the pageContext and load the document-assignments.
          if (newDocumentIds.length) {
            this.setPageContextKeys({ documentsLoaded: true })
            this.loadDocumentAssignments()
          }
        },
      },

      // Watch for changes to the selected document-assignments
      selected: {
        handler(newAssignmentIds) {
          // Update the selected assignments in the Rails form
          this.updateAssignmentsCallback(newAssignmentIds)
        },
      },

      dataLoaded: {
        handler(newDataLoaded) {
          // When data is being loaded, show spinner; when data has finished loading, remove spinner.
          newDataLoaded ? this.endLoading() : this.startLoading()
        },
      },
    },

    created() {
      this.startLoading()

      if (!this.$store._modulesNamespaceMap[`${this.collectionViewName}/`]) {
        this.$store.registerModule(this.collectionViewName, CollectionViewStore)
      }

      // This doesn't depend on loading groups first.
      this.setSelectedGroups(this.preselectedGroupIds)

      // Fetch all groups in the customer's account.
      this.fetchAllGroups().then(() => {
        this.setPageContextKeys({ groupsLoaded: true })
      }).catch((xhr) => {
        Util.genericAjaxError(Locale.t('errors.ajax.fetch', 'groups'), xhr)
      })

      // Load permissions for groups
      this.permissionsBulkAuthorize({
        resourceType: 'employee_group_memberships',
        resourceIds: [this.employeeId],
        actions: ['manage'],
      }).then(() => {
        this.setPageContextKeys({ groupsPermissionsLoaded: true })
      }).catch((xhr) => {
        Util.genericAjaxError(Locale.t('errors.ajax.fetch', 'permissions'), xhr)
      })

      // We need to load documents as a link between groups and assignments.
      this.fetchAllDocumentsPaginated()

      // Fetches all submissions for an employee
      this.fetchAllDocumentSubmissions({
        employeeId: this.employeeId,
      }).then(() => {
      }).catch((xhr) => {
        Util.genericAjaxError(Locale.t('errors.ajax.fetch', 'document_submissions'), xhr)
      })
    },

    beforeDestroy() {
      if (!this.$store._modulesNamespaceMap[`${this.collectionViewName}/`]) { return }

      // Clear selected BEFORE we unregister the dynamically created module
      this.$store.dispatch(`${this.collectionViewName}/reset`)
      this.$store.unregisterModule(this.collectionViewName)
    },

    methods: {
      toggleNewAssignments() {
        this.showNewAssignments = !this.showNewAssignments
      },

      // Find assignment associated with a document id
      assignmentForDocument(documentId) {
        return _(this.documentAssignments).find(a => a.document_id == documentId)
      },

      setSelectedGroups(groupIds) {
        this.$store.dispatch(`${this.collectionViewName}/setFilterValue`, {
          filter_key: 'selected_groups',
          value: groupIds.map(id => parseInt(id, 10)),
        })
      },

      loadDocumentAssignments() {
        this.documentAssignmentsBulkFind({
          employeeIds: [this.employeeId],
          documentIds: this.documentIds,
        }).then(() => {
          this.setPageContextKeys({ assignmentsLoaded: true })

          // Load permissions for document assignments
          this.permissionsBulkAuthorize({
            resourceType: 'document_assignments',
            resourceIds: this.documentAssignmentIds,
            actions: ['unlock_submission'],
          }).then(() => {
            this.setPageContextKeys({ assignmentPermissionsLoaded: true })
          }).catch((xhr) => {
            Util.genericAjaxError(Locale.t('errors.ajax.fetch', 'permissions'), xhr)
          })

        }).catch((xhr) => {
          Util.genericAjaxError(Locale.t('errors.ajax.fetch', 'document_assignments'), xhr)
        })
      },

      setSelectedAssignments() {
        // Don't auto-select assignments while using search box
        if (!this.searchTerm) {
          this.$store.dispatch(`${this.collectionViewName}/selectList`, this.selectedAssignmentIds)
        }
      },

      updateNewAssignments() {
        this.$store.dispatch(`${this.collectionViewName}/setFilterValue`, {
          filter_key: 'new_assignments',
          value: this.newAssignments.map(a => a.id),
        })
      },

      // Does the user have permission to request a new submission for the assignment? (Returns true/false)
      canRequestAssignment(assignmentId) {
        return this.isPermitted('document_assignments', assignmentId, 'unlock_submission')
      },

      isAssignmentPermitted(assignment) {
        return assignment ? this.canRequestAssignment(assignment.id) : false
      },

      // Determines whether or not an assignment matches the selected filter conditions. Any assignments that meet the
      // conditions return false (and are filtered out); any assignments that pass all checks return true.
      filterAssignment(assignment) {
        const document = this.getDocument(assignment.document_id)

        // Include the assignment if the document is in a selected group, is in no group, or is a built-in doc.
        const hasNoGroups = this.allDocumentsWithoutGroups.map(doc => doc.id).includes(document.id)
        const isBuiltInDoc = BUILT_IN_DOC_IDS.includes(document.id)
        const selectedGroups = this.filterValues.selected_groups
        const isDocumentInSelectedGroups = document.group_ids.some(groupId => selectedGroups.includes(groupId))
        if (!isBuiltInDoc && !hasNoGroups && !isDocumentInSelectedGroups) {
          return false
        }

        // If the user entered a search term, include the assignment only if the document name contains the search term.
        if (this.searchTerm && this.searchTerm.length > 0 && document.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) == -1) {
          return false
        }

        return true
      },

      handleClickClearFilters() {
        this.setSelectedGroups(this.preselectedGroupIds)
      },

      updateGroupsCallback(groupIds) {
        this.pageContext.updateGroupsCallback(groupIds)
      },

      updateAssignmentsCallback(assignmentIds) {
        this.pageContext.updateAssignmentsCallback(assignmentIds)
      },

      startLoading() {
        this.$wait.start(this.loadingName)
      },

      endLoading() {
        this.$wait.end(this.loadingName)
      },

      // The I-9 should be pre-selected if the existing submission is expired
      reassignBuiltInForm(assignment) {
        const builtInDocument = this.documents[assignment.document_id]

        if (assignment.document_id !== 'i9') {
          return builtInDocument.rehire_new_submission
        }

        const submissionId = assignment.current_submission_id
        const submission = this.getSubmission(submissionId)

        return builtInDocument.rehire_new_submission || submission?.is_expired
      },

      // A mock assignment that serves as a placeholder in the UI until the rehire
      // wizard is submitted and a real DocumentAssignment object is created by Rails.
      mockAssignment(documentId) {
        return {
          active: false,
          current_submission_id: null,
          document_id: documentId,
          employee_id: this.employeeId,
          id: null,
          optional: false,
          status: 'missing',
        }
      },

      ...mapMutations({
        setPageContextKeys: mutate.SET_PAGE_CONTEXT_KEYS,
      }),

      ...mapActions({
        fetchAllGroups: 'groups/fetchAll',
        fetchAllDocumentsPaginated: 'documents/fetchAllPaginated',
        documentAssignmentsBulkFind: 'document_assignments/bulkFind',
        fetchAllDocumentSubmissions: 'document_submissions/fetchAll',
        permissionsBulkAuthorize: 'permissions/bulkAuthorize',
      }),
    },
  }

</script>
