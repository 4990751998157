// This BaseSector object helps us define a common module that all sectors "inherit" from
// To use this, just call module w/ this BaseSector.definition *before* you re-define the module w/ custom overrides
//      App.module("Seasons", BaseSector.definition);
//      App.module("Seasons", function() { /* your custom definition */ });
export default function(Module, App) {
  Module.startWithParent = false

  // After all initializers have been run, start Backbone's history, attached to this sector at the root path
  // defined on the body (by the HTML template)
  Module.on("before:start", function() {
    // console.log("Before start!");
    if (Backbone.history.started){
      Backbone.history.stop()
      Backbone.history.handlers = []
    }

    // Pages may call Module.setMainPageLayout, which initializes these two variables so that we can pre-render
    // the page before the module even starts
    if (Module._layout !== undefined) {
      Module.renderMainPageLayout(Module._layout, Module._layoutOptions)
      delete Module._layout
      delete Module._layoutOptions
    }
  })

  Module.on("start", function() {
    // console.log(`Started`, Module);
    if (Backbone.history) {
      Backbone.history.start({
        pushState: true,
        root: $("body").attr("data-root-path"),
      })
    }
  })

  Module.on("stop", function() {
    // console.log("Stop!");
    Module.stopListening(App.vent)

    if (Backbone.history) {
      Backbone.history.stop()
      Backbone.history.handlers = []
    }
  })

  // Auto-cleanup of controller, router, and mainPageLayout if they exist
  Module.addFinalizer(function() {
    if (Module.controller) {
      if (Module.controller.finalize)
      {Module.controller.finalize()}
      delete Module.controller
    }
    if (Module.router)
    {delete Module.router}

    if (Module.mainPageLayout)
    {Module.mainPageLayout.destroy()}
  })

  Module.renderMainPageLayout = function(layout, options) {
    // By default the layout gets the full .content element so that you can bind triggers and stuff to it
    options = _({ el: '.content' }).extend(options)

    Module.mainPageLayout = new layout(options)
    Module.mainPageLayout.render()
    App.mainRegion.attachView(Module.mainPageLayout)
  }

  Module.setMainPageLayout = function(layout, options) {
    Module._layout = layout
    Module._layoutOptions = options
  }

  // TODO: This should be deprecated and anyone who wants to set up the main page AFTER the sector is started can
  // simply call renderMainPageLayout
  Module.initializeMainPageLayout = function(layout, options) {
    if (window.console && window.console.log) {
      window.console.log("DEPRECATED: Module.initializeMainPageLayout; use Module.setMainPageLayout instead BEFORE the module start call")
    }
    Module.renderMainPageLayout(layout, options)
  }
};
