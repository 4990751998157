// model - (AttachedFile) file to be viewed
// 
export default Marionette.ItemView.extend({
  getTemplate: function() {
    if (this.model.isImage()) {
      return "#file-view-image-tmpl"
    } else if (this.model.isPdf()) {
      return "#file-view-pdf-tmpl"
    } else {
      return "#file-view-unrecognized-tmpl"
    }
  },

  className: 'file-viewer',
})
