// Individual E-Verify Case

<template>
  <ListItem
    :collectionViewName="collectionViewName"
    :item="everifyCase.id"
    @click="handleViewCaseClick"
    v-highlight-on-change="everifyCase"
  >
    <!-- // slot -->
    <div class="wb-list__content">
      <EverifyCaseStatusIndicator
        :everifyCase="everifyCase"
        :tooltip="true"
      />
      <div v-if="showDeleteCaseButton" class="mr-2">
        <WbButton
          @click.stop="handleDeleteCaseClick"
          context="danger"
          :isLoading="isDeleteCaseButtonLoading"
          label="Delete Case"
          size="xs"
        />
      </div>
      <div class="wb-list__title mr-auto my-0">
        <span class="name">
          {{ employeeFullName }}
        </span>
        <span class="label label-default ml-2" v-if="caseClosed">
          Closed
        </span>
        <div class="wb-list__subtitle mr-auto my-0">
          Case ID: {{ everifyCase.case_id }}
          <span v-if="everifyCase.case_created_at">
            &#8901; Created:
            <WbSimpleDateTime :value="everifyCase.case_created_at" />
          </span>
        </div>
      </div>
      <div class="wb-list__column-timestamp">
        <WbSimpleDateTime :value="caseClosed" :tooltip-options="caseClosedTooltip" v-if="caseClosed" />
        <em class="everify-case-open" v-if="!caseClosed">
          {{ caseStatusTitle }}
        </em>
      </div>
      <dl
        class="wb-list__column-groups my-0"
        ref="deadline"
        v-if="shouldShowDeadline"
      >
        <dt>
          {{ deadlineLabel }}
        </dt>
        <dd>
          {{ caseDeadline }}
        </dd>
      </dl>
    </div>
    <template #secondaryActionsMenuItems>
      <DropdownListItem
        v-if="!everifyFailed"
        title="View Case"
        @click="handleViewCaseClick"
      />
      <DropdownListItem
        title="View I-9"
        :href="linkToI9"
        :disabled="!everifyCase.submission_id"
      />
    </template>
  </ListItem>
</template>

<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex'
  import * as mutate from 'vuex/mutation_types'
  import DateTime from 'lib/util/datetime'
  import ListItem from 'components/common/ListItem'
  import DropdownListItem from 'components/common/DropdownListItem'
  import WbSimpleDateTime from 'components/common/WbSimpleDateTime'
  import EverifyCaseStatusIndicator from 'components/everify/cases/EverifyCaseStatusIndicator'
  import Locale from 'lib/locale'
  import EverifyCaseDeadlineMixin from 'components/everify/cases/EverifyCaseDeadlineMixin'
  import WbButton from 'components/common/WbButton'
  import Util from 'lib/util'

  export default {
    name: 'everify-case-list-item',

    components: {
      ListItem,
      DropdownListItem,
      WbSimpleDateTime,
      EverifyCaseStatusIndicator,
      WbButton,
    },

    mixins: [
      EverifyCaseDeadlineMixin,
    ],

    props: {
      everifyCase: {
        type: Object,
        required: true,
      },

      collectionViewName: {
        type: String,
        required: false,
      },
    },

    data() {
      return {
        isDeleteCaseButtonLoading: false,
      }
    },

    computed: {
      employeeFullName() {
        const employee = this.getEmployee(this.everifyCase.employee_id)
        return employee ? this.$employee.fullName(employee) : '--'
      },

      linkToI9() {
        return this.$routes.admin_employee_submission_path({
          employee_id: this.everifyCase.employee_id,
          id: this.everifyCase.submission_id,
        })
      },

      caseStatus() {
        return this.everifyCase.case_status
      },

      caseStatusTitle() {
        return Locale.t(`everify.status.${this.caseStatus}.title`)
      },

      caseClosed() {
        const closedStatus = ['closed_authorized', 'closed_final_confirmation', 'closed_other', 'manually_closed'].includes(this.caseStatus)
        if (closedStatus && this.everifyCase.case_result_at) {
          return this.everifyCase.case_result_at
        }
      },

      caseClosable() {
        return ['employment_authorized', 'final_nonconfirmation', 'close_and_resubmit'].includes(this.caseStatus)
      },

      caseClosedTooltip() {
        if (this.everifyCase.case_result_at) {
          return {
            title: `Case was closed on ${DateTime.formatDateTime(this.everifyCase.case_result_at)}`,
            html: true,
          }
        }
      },

      everifyFailed() {
        // TODO: What is the case status/state that would allow users to re-run the E-Verify process?
        return [].includes(this.caseStatus)
      },

      // This should only be true for non-prod environments
      showDeleteCaseButton: () => gon.allow_case_delete,

      ...mapGetters({
        pageContext: 'pageContext',
        getEmployee: 'employees/get',
      }),
    },

    methods: {
      handleViewCaseClick() {
        return this.setPageContextKeys({ everify_case_id: this.everifyCase.id })
      },

      handleDeleteCaseClick() {
        this.isDeleteCaseButtonLoading = true
        this.deleteCase({ caseId: this.everifyCase.id })
          .then(() => {
            this.isDeleteCaseButtonLoading = false
            Util.showFlashNotice("The E-Verify case is being deleted")
            this.$emit('caseDeleted')
          }).catch((xhr) => {
            this.isDeleteCaseButtonLoading = false
            Util.genericAjaxError("An error occurred.", xhr)
          })
      },

      ...mapMutations({
        setPageContextKeys: mutate.SET_PAGE_CONTEXT_KEYS,
      }),

      ...mapActions({
        deleteCase: 'everify_cases/deleteCase',
      }),
    },
  }
</script>
