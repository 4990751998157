// Wizard Step
//
// Defines an individual step in a Wizard
//


//
// view - ItemView - current view being shown for the given step
// percentage - Integer percentage of the Wizard that this step represents
//              (e.g. Signature step is 15% of the total wizard)
export default Marionette.Object.extend({
  _view: null,
  _context: null,

  createView: function(context, model) {
    this._context = context

    const ViewClass = this.getOption('view')
    if (!ViewClass) {
      throw new Error('You did not specify a view')
    };

    this._view = new ViewClass({ context: context, model: model, stepOptions: this.options})
    return this._view
  },

  // Clear out the view from this step
  releaseView: function(){
    this._view = null
  },

  // Is this step finished?
  // Override if custom logic is needed
  isFinished: function() {
    return true
  },

  // Is this step at the start?
  // Override if custom logic is needed
  isStarting: function() {
    return true
  },

  // Step-specific validation
  // Defaults to calling validationCompleteCallback if not overridden
  //
  // validationCompleteCallback - callback if step is successful
  validate: function(validationCompleteCallback){
    if (_.isFunction(this._view.validate)) {
      this._view.validate(validationCompleteCallback)
    } else { // no validation, move along
      validationCompleteCallback(true)
    }
  },

  // Percentage of the wizard represented by this step
  //
  // returns - integer 0 to 100
  percentage: function(){
    return this.options.percent
  },

  // Percentage of this step completed
  // Defaults to 0, overridden in more complicated steps
  //
  // returns - integer 0 to 100
  completePercentage: function(){
    return 0
  },

  name: function(){
    return this.getOption('name')
  },

  stepsToRetreat: function() {
    return this.options.stepsToRetreat || 1
  },

  canRetreatToSubStep: function() {
    return true
  },
})
