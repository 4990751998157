<template>
  <div>
    <PageHeader
      title="Bulk Staff Update"
    />
    <BulkStaffUpdateWizard />
    <FieldSelector
      :fields="fields"
    />
  </div>
</template>
<script>
  import BulkStaffUpdateWizard from 'components/user_updates/BulkStaffUpdateWizard'
  import PageHeader from 'components/common/PageHeader'
  import FieldSelector from 'components/common/FieldSelector'
  export default {
    name: 'the-user-updates-new-root',
    components: {
      BulkStaffUpdateWizard,
      FieldSelector,
      PageHeader,
    },
    props: {
      fields: {
        type: Array,
        required: true,
      },
    },
  }
</script>
