export default Marionette.ItemView.extend({
  template: false,          // Template-less view ensures nothing gets rendered
  prerendered: true,

  // REVIEW [AJN]:
  // Catch prerendered views and don't let anything render. If we don't do this explicitly, Marionette will get confused with
  // views that have both `template` set and `prerendered === true`, e.g. a prerendered tableview with a dynamic title
  // (title is a `template` but gets prerendered set to true)
  _renderTemplate: function() {
    if (this.getOption('prerendered') === true)
    {return}

    Marionette.ItemView.prototype._renderTemplate.apply(this, arguments)
  },
})
