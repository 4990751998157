import { ajax } from 'jquery'
import { getRoute } from 'lib/routes'

export function createBulkStaffUpdate({fileUrl, fileCache, fileContentType}) {
  return ajax({
    type: 'POST',
    url: getRoute('user_updates_path'),
    data: {
      bulk_staff_update: {
        file_url: fileUrl,
        file_cache: fileCache,
        file_content_type: fileContentType,
      },
    },
  })
}
