<template>
  <div>
    <PageHeader
      :title="$locale.t('accounts.settings.header.title')"
      :lead="$locale.t('accounts.settings.header.description')"
      variant="bordered"
    />

    <form class="form-horizontal">
      <FormFieldSelect
        ref="staffType"
        name="default_employee_type"
        label="Default Staff Type"
        v-model="formData.default_employee_type"
        :options="staffTypeOptions"
        :errors="formErrors"
        :required="true"
        orientation="horizontal"
        fieldWidth="long-field"
      />

      <FormFieldToggle
        ref="startDateRequired"
        name="start_date_required"
        label="Start Date Required for Staff"
        v-model="formData.start_date_required"
        :errors="formErrors"
        :required="true"
        :hint="startDateToggleHint"
        :helpPopoverText="startDateHelpText"
        orientation="horizontal"
        id="start-date-required-toggle"
        @toggleChanged="handleStartDateToggle"
      />

      <FormFieldButtonGroup
        v-if="ri9SmsEnabled"
        ref="ri9Notifications"
        name="settings_ri9_notification_methods"
        label="Remote I-9 Notification Delivery Method"
        title=""
        :options="notificationOptions"
        v-model="selectedRi9NotificationMethod"
        :errors="formErrors"
        :required="true"
        :helpPopoverText="ri9NotificationsHelpText"
        orientation="horizontal"
        @selected="handleRi9NotificationSelection"
      />

      <FormFieldButtonGroup
        v-if="onboardingSmsEnabled"
        ref="onboardingNotifications"
        name="settings_onboarding_notification_methods"
        label="Onboarding Notification Method"
        title=""
        :options="notificationOptions"
        v-model="selectedOnboardingNotificationMethod"
        :errors="formErrors"
        :required="true"
        :helpPopoverText="onboardingNotificationHelpText"
        orientation="horizontal"
        @selected="handleOnboardingNotificationSelection"
      />

      <template v-if="embedded.enabled">
        <FormFieldInput
          ref="embeddedUrl"
          label="Embedded Redirect URL"
          name="settings_embedded_redirect_url"
          v-model="formData.settings_embedded_redirect_url"
          :errors="formErrors"
          :required="true"
          :hint="embeddedUrlHint"
          placeholder="myapp://forms-finished"
          type="text"
          orientation="horizontal"
        />

        <FormFieldInput
          ref="embeddedText"
          label="Embedded Redirect Text"
          name="settings_embedded_redirect_text"
          v-model="formData.settings_embedded_redirect_text"
          :errors="formErrors"
          :required="true"
          :hint="embeddedTextHint"
          placeholder="Return to App"
          type="text"
          orientation="horizontal"
        />

        <div class="row" ref="embeddedPreviewLink">
          <div class="col-sm-offset-4 col-md-offset-3 col-sm-7">
            <small>
              <a
                v-if="embedded.configured"
                :href="embeddedPreviewLinkPath"
                target="blank"
                ref="embeddedPreviewLink"
              >
                Preview 'Forms Complete' link
              </a>
              <span v-else ref="embeddedLinkMissing">
                'Forms Complete' preview link is available when 'Embedded redirect URL' is configured.
              </span>
            </small>
          </div>
        </div>
      </template>

      <div class="row breathe above">
        <div class="col-sm-offset-4 col-md-offset-3 col-md-7">
          <LaddaButton
            ref="submit"
            class="form-field"
            @click="saveSettings"
          >
            Save
          </LaddaButton>
          <a class="btn btn-default btn-form-cancel" :href="companyProfilePath">
            Cancel
          </a>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
  import { mapMutations } from 'vuex'
  import { SET_PAGE_CONTEXT_KEYS } from 'vuex/mutation_types'
  import PageHeader from 'components/common/PageHeader'
  import FormFieldSelect from 'components/common/FormFieldSelect'
  import FormFieldToggle from 'components/common/FormFieldToggle'
  import FormFieldButtonGroup from 'components/common/FormFieldButtonGroup'
  import FormFieldInput from 'components/common/FormFieldInput'
  import LaddaButton from 'components/common/LaddaButton'
  import Util from 'lib/util'

  export default {
    name: 'edit-account-settings-container',

    components: {
      PageHeader,
      FormFieldSelect,
      FormFieldToggle,
      FormFieldButtonGroup,
      FormFieldInput,
      LaddaButton,
    },

    props: {
      ri9SmsEnabled: {
        type: Boolean,
        required: false,
        default: false,
      },
      onboardingSmsEnabled: {
        type: Boolean,
        required: false,
        default: false,
      },
      settings: {
        type: Object,
        required: true,
      },
      employeeTypes: {
        type: Array,
        required: true,
      },
      notificationTypes: {
        type: Array,
        required: true,
      },
      everifyEnabled: {
        type: Boolean,
        required: false,
        default: false,
      },
      embedded: {
        type: Object,
        required: false,
      },
    },

    data () {
      return {
        formData: {},
        formErrors: {},
        startDateHelpText: this.$locale.t('accounts.settings.startDateRequired.helpText'),
        ri9NotificationsHelpText: this.$locale.t('accounts.settings.notificationsMethod.helpText'),
        onboardingNotificationHelpText: this.$locale.t('accounts.settings.onboardingNotificationMethod.helpText'),
        embeddedUrlHint: this.$locale.t('accounts.settings.embedded.urlHint'),
        embeddedTextHint: this.$locale.t('accounts.settings.embedded.textHint'),
        embeddedPreviewLinkHint: this.$locale.t('accounts.settings.embedded.previewLinkHint'),
        embeddedPreviewLinkPath: this.$routes.embedded_forms_complete_path,
        companyProfilePath: this.$routes.edit_company_path,
      }
    },

    computed: {
      staffTypeOptions() {
        return this.employeeTypes.map(([label, value]) => ({ label, value }))
      },

      notificationOptions() {
        return this.notificationTypes.map(([label, value]) => ({ label, value }))
      },

      startDateToggleHint() {
        if (this.everifyEnabled) {
          return this.$locale.t('accounts.settings.startDateRequired.hint')
        }
      },

      selectedRi9NotificationMethod() {
        return this.formData.settings_ri9_notification_methods[0]
      },

      selectedOnboardingNotificationMethod() {
        return this.formData.settings_onboarding_notification_methods[0]
      },
    },

    methods: {
      handleStartDateToggle(newValue) {
        this.formData.start_date_required = newValue
      },

      handleRi9NotificationSelection(newValue) {
        this.formData.settings_ri9_notification_methods = [newValue]
      },

      handleOnboardingNotificationSelection(newValue) {
        this.formData.settings_onboarding_notification_methods = [newValue]
      },

      setFormData() {
        this.formData = {...this.settings}
      },

      saveSettings() {
        this.formErrors = {}

        $.ajax({
          method: 'PATCH',
          url: this.$routes.account_settings_path,
          data: {
            account_settings: this.formData,
          },
        }).success((responseData, _status, xhr) => {
          if (xhr.responseJSON && xhr.responseJSON.errors) {
            this.formErrors = xhr.responseJSON.errors || {}
          } else {
            this.setPageContextKeys({accountSettings: responseData})
            Util.showFlashNotice("Account Settings Updated")
          }
        }).fail((xhr) => {
          Util.genericAjaxError("An error occurred.", xhr)
        }).always(() => this.$refs.submit && this.$refs.submit.stop())
      },

      ...mapMutations({
        setPageContextKeys: SET_PAGE_CONTEXT_KEYS,
      }),
    },

    watch: {
      settings () {
        this.setFormData()
      },
    },

    created() {
      this.setFormData()
    },
  }
</script>
