import Routes from 'lib/routes'

export default Marionette.LayoutView.extend({
  template: false,
  triggers: {
    'click .js-add-employee-group-inline': 'employeeGroups:new:inline',
    submit: {
      event: 'formSubmit',
      stopPropagation: false,
      preventDefault: false,
    },
    'click .js-specify-docs': {
      event: 'form:specifyDocs:buttonPress',
      stopPropagation: true,
      preventDefault: true,
    },
    'click .js-preview-signature': 'document:preview',
  },

  events: {
    'click .mergeItem': 'mergeItemClick',
    'click @ui.forceInboxCheckbox': 'showForceInboxWarning',
    'click .js-delete-form': 'superadminDeleteForm',
  },

  ui: {
    returningStaffOnlyIfMissingInput: "input[type=hidden]#document_required_return_employees_only_if_missing",
    returningStaffFrequencySelect: "#document_required_return_employees_frequency",
    bodyTypeInput: "input[type=hidden]#document_body_type",
    relativeDueDateInput: "input[type=hidden]#document_relative_due_date",
    relativeDueDateDaysInput: "#document_relative_due_date_days",
    relativeDueDateTypeSelect: "#document_relative_due_date_type",
    requiredReturningStaffCheckbox: "input[type='checkbox']#document_required_return_employees",
    submissionTypeSelect: "#document_submission_type",
    bodyTextEditor: "textarea.summernote",
    uploadOptionsPanel: ".upload-options-panel",
    signOptionsPanel: ".sign-options-panel",
    specifyFilesModal: ".specify-docs-modal",
    submissionFileTitlesInput: "input[type=hidden]#document_submission_properties_files",
    specifyFilesButton: ".js-specify-docs",
    bodyPdfFileInput: "input[type='file']#document_body_pdf",
    bodyPdfCacheInput: "input[type='hidden']#document_body_pdf_cache",
    previewObjectElement: ".doc-viewer",
    forceInboxCheckbox: '#document_force_inbox',
    instructionsInput: 'textarea#document_instructions',
    duplicateFileNamesAlert: ".duplicate-filenames-alert",
  },

  // FIXME: No need to do $(this.ui....) – just reference this.ui (leftover from before Marionette 2.x upgrade)
  onRender: function() {
    const self = this

    this.employeeGroupsMultiselect = $('#document_employee_group_ids.multiselect').multiselect({
      enableCaseInsensitiveFiltering: true,
    })

    this.ui.instructionsInput.summernote({
      height: 150,
      toolbar: [
        ['style', ['bold', 'italic', 'underline', 'superscript', 'subscript', 'strikethrough', 'clear']],
        ['fontsize', ['fontsize']],
        ['color', ['color']],
        ['table', ['table']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['insert', ['link', 'picture', 'video', 'hr']],
      ],
    })

    this.ui.bodyTextEditor.summernote({
      height: 300,
      toolbar: [
        ['style', ['style']],
        ['font', ['bold', 'italic', 'underline', 'superscript', 'subscript', 'strikethrough', 'clear']],
        ['fontname', ['fontname']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['height', ['height']],
        ['table', ['table']],
        ['insert', ['link', 'picture', 'hr']],
        ['view', ['fullscreen', 'codeview']],
        ['help', ['help']],
      ],
    })

    $(this.ui.bodyTextEditor).code($(this.ui.bodyTextEditor).val())

    // The Required For -> Returning Staff dropdown is a faux field which delegates its selection to the boolean
    // hidden element specified by returningStaffOnlyIfMissingInput. This code keeps things sync'd up.
    $(this.ui.returningStaffFrequencySelect).on('change', function(e) {
      $(self.ui.returningStaffOnlyIfMissingInput).val(e.target.value)
    })

    // Sync the body_type hidden field w/ the tab selection
    $('#body-type-panel a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
      $(self.ui.bodyTypeInput).val($(e.target).data("body-type"))
    })
    const bodyType = $(self.ui.bodyTypeInput).val() || 'pdf'
    $(`#body-type-panel a[data-body-type="${bodyType}"]`).tab('show')

    // Sync the relative_due_date hidden field with formula derived from the due date # days and before/after dropdown
    $(this.ui.relativeDueDateDaysInput).on('change', $.proxy(this.updateRelativeDueDateInput, this))
    $(this.ui.relativeDueDateTypeSelect).on('change', $.proxy(this.updateRelativeDueDateInput, this))

    // sync Returning Staff checkbox and whether or not the frequency dropdown is enabled
    $(this.ui.returningStaffFrequencySelect).prop('disabled', $(this.ui.requiredReturningStaffCheckbox).is(':checked'))
    $(this.ui.requiredReturningStaffCheckbox).on('change', function(e) {
      $(self.ui.returningStaffFrequencySelect).prop('disabled', $(this).is(':checked'))
    })

    this.updateSubmissionButtonsUI($(this.ui.submissionTypeSelect).val())
    $(this.ui.submissionTypeSelect).on('change', function(e) {
      const selectValue = e.target.value
      self.updateSubmissionButtonsUI(selectValue)
    })

    this.$el.find('.document-form').dzoperator({
      dzOptions: {
        presignURL: this.getOption('presignURL'),
        previewTemplate: $("#dropzone-template").html(),
        acceptedFiles: 'application/pdf',
        fileTypeErrorMsg: 'Sorry, you can only upload PDF files.',
      },
    })

    this.initializeSpecifyDocsModal()
    this.setSpecifyFilesButtonText()
    this.addCustomSummernoteMergeFieldsButton()

    // Remove entire merge tag on delete/backspace
    $( ".note-editable" ).keydown(function(e) {
      const cursorPosition = document.getSelection().anchorOffset

      if ( e.keyCode == 8 && cursorPosition > 0 ) {
        const selection = document.getSelection()
        const previousNode = selection.anchorNode
        const lastElementChild = previousNode.parentNode.lastElementChild
        // If previous node is an element, data will have a length of 1
        if (lastElementChild && previousNode.parentNode.lastChild.data && previousNode.parentNode.lastChild.data.length == 1)
        {previousNode.parentNode.removeChild(lastElementChild)}
      }
    })
  },

  updateRelativeDueDateInput: function(e) {
    let relativeDate
    if ($(this.ui.relativeDueDateTypeSelect).val() && $(this.ui.relativeDueDateDaysInput).val())
    {relativeDate = parseInt($(this.ui.relativeDueDateTypeSelect).val()) * parseInt($(this.ui.relativeDueDateDaysInput).val())}
    else
    {relativeDate = ""}

    $(this.ui.relativeDueDateInput).val(relativeDate)
  },

  updateSubmissionButtonsUI: function(submissionTypeVal) {
    if (submissionTypeVal == 'sign') {
      $(this.ui.uploadOptionsPanel).hide()
      $(this.ui.signOptionsPanel).show()
    } else if (submissionTypeVal == 'acknowledge') {
      $(this.ui.uploadOptionsPanel).hide()
      $(this.ui.signOptionsPanel).hide()
    } else {
      $(this.ui.uploadOptionsPanel).show()
      $(this.ui.signOptionsPanel).hide()
    }
  },

  onEmployeeGroupsNewInline: function() {
    App.globalVent.trigger('employee_groups:new:inline', { multiselectEl: this.employeeGroupsMultiselect })
  },

  onFormSubmit: function() {
    // Grab the HTML code from the summernote editor
    if ($(this.ui.bodyTypeInput).val() === 'text') {
      $(this.ui.bodyTextEditor).val($(this.ui.bodyTextEditor).code())
    }
  },

  onFormSpecifyDocsButtonPress: function() {
    this.ui.specifyFilesModal.modal({ show: true })
  },

  initializeSpecifyDocsModal: function() {
    this.ui.specifyFilesModal.one('show.bs.modal', () => {
      const fileTitles = (this.ui.submissionFileTitlesInput.val() || '').split("|")
      this.ui.specifyFilesModal.find(".upload_files.appender").appender({
        template: $('.files-appender-template').html(),
        addButton: $('.appender-add-button'),
        initialCollection: fileTitles,
      })
    })
    this.ui.specifyFilesModal.on('click', '.btn-primary', (e) => {
      // Avoid submitting the page form
      e.stopPropagation()
      e.preventDefault()

      const appender = this.ui.specifyFilesModal.find(".upload_files.appender").data('appender')
      const downcasedFilenames = _.map(appender.values(), (filename) => {
        return filename.trim().toLowerCase()
      })

      // Validate uniqueness of filenames given
      if (_.uniq(downcasedFilenames).length != downcasedFilenames.length) {
        this.ui.duplicateFileNamesAlert.removeClass('hidden')

        return
      }

      this.ui.duplicateFileNamesAlert.addClass('hidden')
      this.ui.submissionFileTitlesInput.val(appender.values().join("|"))
      this.ui.specifyFilesModal.modal('hide')
      this.setSpecifyFilesButtonText()
    })
  },

  setSpecifyFilesButtonText: function() {
    const fileTitles = $(this.ui.submissionFileTitlesInput).val() || ''
    const length = fileTitles.length > 0 ? fileTitles.split("|").length : 0
    $(this.ui.specifyFilesButton).find('.btn-title').html(`Multiple Files${
      length > 0 ? ` <span class="badge badge-light">${length}</span>` : '...'}`,
    )
  },

  // FIXME: Summernote may not have had an easy way to create a custom button when this was built in 2014 but looks like it exists now
  // https://summernote.org/deep-dive/#custom-button
  addCustomSummernoteMergeFieldsButton: function () {
    let customFieldMergeItems = ""
    let standardFieldMergeItems = ""

    // FIXME: remove hardcoded fields and pass these down as Employee Built In Fields
    // so that we don't have to reconstruct the field key as we are doing here and in PdfService.rb#replace_merge_fields :(
    const standardFields = ['Email', 'Full Name', 'First Name', 'Middle Name', 'Last Name', 'Preferred Name', 'Birthdate',
      'Address', 'Address Formatted - Multiline', 'Primary Phone', 'Gender', 'SSN', 'Hire Date', 'Start Date', 'End Date', 'Notification Start Date', 'Current Date', 'Company Name',
    ]
    $.each(standardFields, function(index, value) {
      standardFieldMergeItems +=
        `<li><a class="mergeItem" data-event="mergeField" data-value="a4s" id="${value
        }" style="cursor: pointer">${value}</a></li>`
    })

    $.each(gon.customFields, function(_index, value) {
      const escaped_value = _.escape(value.label)
      customFieldMergeItems +=
        `<li><a class="mergeItem" data-event="mergeField" data-value="${value.id}" id="${escaped_value
        }" style="cursor: pointer">${escaped_value}</a></li>`
    })
    // No custom fields to show
    if ( gon.customFields.length < 1 ) {
      customFieldMergeItems = "<li><a class=''>None</a></li>"
    }

    // Add merge field button and merge field list to summernote toolbar
    $(".note-editor .btn-group:last:eq(0)")
      .append(
        `<div class="note-merge-fields btn-group">`
        + `<button type="button" class="btn btn-default btn-sm btn-small dropdown-toggle" data-toggle="dropdown" style="cursor: pointer"> Merge Fields <b class="caret"></b></button>`
        + `<ul class="dropdown-menu">`
        + `<li class="dropdown-submenu">`
        + `<a tabindex="-1" href="javascript:void(0)">Standard Fields</a>`
        + `<ul class="dropdown-menu">${standardFieldMergeItems}</ul></li>`
        + `<li class="dropdown-submenu"><a tabindex="-1" href="javascript:void(0)">Custom Fields</a>`
        + `<ul class="dropdown-menu">${customFieldMergeItems}</ul></li></ul></div>`)
  },

  mergeItemClick: function(e) {
    const summernoteEditorArea = $('#body-editor .note-editable'),
      mergeField = e.target.id,
      mergeFieldName = this.translateFieldKeyName(mergeField),

      // FIXME: use BrowserSupport class to find out what browser we are dealing with
      msIE = window.navigator.userAgent.indexOf("MSIE "),
      browserIsIE = msIE > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./),
      browserIsIE10 = window.navigator.userAgent.indexOf("MSIE 10") > 0,
      mergeFieldValue = browserIsIE ? e.target.attributes[3].value : e.target.dataset.value,
      selection = document.getSelection(),
      cursorPos = selection.anchorOffset,
      currentNode = selection.anchorNode.nodeValue,
      toInsert = `{{${mergeField}}}`,
      toInsertWithHTML = `<strong><span class="merge-tag" contenteditable="false" data-name="${mergeFieldName}" id="${mergeFieldValue}">${toInsert}</span></strong>&#8203`,
      spanElement = document.createElement("span"),
      mergeTextNode = document.createTextNode(toInsert)

    let newContent, nodeElements

    if ( currentNode ) {
      newContent = currentNode.substring(0, cursorPos) + toInsert + currentNode.substring(cursorPos)
    }

    if ( browserIsIE10 ) {
      nodeElements = $(selection.focusNode.childNodes)
    } else if ( browserIsIE ) {
      nodeElements = $(selection.anchorNode.parentNode.childNodes)
    } else {
      nodeElements = $(selection.anchorNode.parentElement).contents()
    }

    if (summernoteEditorArea.html().length < 2) {
      // Summernote editor area is empty, just append and end with zero-space character so that the cursor is outside the span
      summernoteEditorArea.append(toInsertWithHTML)

      return
    }

    /*
    *  Verify we are within the summernote editor before inserting
    *  Since we allow the user to insert a field anywhere within the editor, instead of just appending it the end every time,
    *  we verify that the cursor is within the editor area before inserting.
    */

    // Check if any parents are the text editor, not just first-level, as nodes could be nested
    // If not, we aren't within the editor and show an alert dialog
    if ($(selection.focusNode).parents('.note-editable').length < 1) {
      // Warn the user why we didn't insert the field
      bootbox.alert({
        title: 'Alert',
        message: 'Before selecting a merge field, please place your cursor within the Form Body text editor area where you would like to insert it.',
        className: "modal-info",
      })

      return
    }

    // Check if currentNode is empty
    if (currentNode === null) {
      if (!browserIsIE) {
        spanElement.appendChild(mergeTextNode)
        selection.anchorNode.replaceChild(spanElement, selection.anchorNode.lastChild)
        // Loop through all nodes and replace the correct merge field
        nodeElements.each(function () {
          if ( this.nodeType == 1 && this.innerText == toInsert ) {
            $(this).replaceWith(toInsertWithHTML)
          }
        })

        return
      }

      // Construct merge tag
      // REVIEW: why can't we just use toInsertWithHTML
      spanElement.setAttribute("id", mergeFieldValue)
      spanElement.setAttribute("class", "merge-tag")
      spanElement.setAttribute("data-name", mergeFieldName)
      spanElement.appendChild(mergeTextNode)
      selection.anchorNode.appendChild(spanElement)

      // Add empty char so the cursor is outside the element
      const emptyChar = document.createTextNode(" ")
      selection.anchorNode.appendChild(emptyChar)

    } else {
      selection.anchorNode.nodeValue = newContent

      // FIXME: this loops through ALL nodes in the text editor and replaces the merge field it's looking for with HTML,
      // e.g. {{ First Name }} => <strong><span class="merge-tag" contenteditable="false" data-name="training_dates" id="9">{{Training Dates}}</span></strong>​
      nodeElements.each( function () {
        // nodeType 3 is an element
        if ( this.nodeType == 3 ) {
          const u = this.nodeValue
          const reg = `{{${mergeField}}}`
          $(this).replaceWith( u.replace( reg,toInsertWithHTML ))
        }
      })
    }
  },

  onDocumentPreview: function() {
    const $modal = $('#preview-modal')
    const params = { type: "POST" }

    if ($(this.ui.bodyTypeInput).val() === 'pdf') {
      if ($(this.ui.bodyPdfFileInput).val()) {
        // If we have a value for the actual file input, that means Dropzone was not enabled on this browser
        // and we did not receive a cached version of this file. For now, just display an error message.
        App.Util.errorDialog("Sorry, currently we do not support the Preview capability for your browser. We are working to " +
                              "resolve this in a future release.<br/><br/>For now, you will have to submit the form in order to see the preview. We " +
                              "apologize for this inconvenience.", "Browser Not Supported")
        return
      } else if (!$(this.ui.bodyPdfCacheInput).val() || $(this.ui.bodyPdfCacheInput).val().length == 0) {
        if (this.$el.find('.document-form').data('dzoperator').anyUploading())
        {App.Util.errorDialog("Please wait for the PDF file to finish uploading and then try again.", "PDF Still Uploading")}
        else
        {App.Util.errorDialog("You must provide a PDF file in order to view the preview.", "No PDF Uploaded")}
        return
      }

      $.extend(params, { body_pdf: this.ui.bodyPdfCacheInput.val() })
    } else if ($(this.ui.bodyTypeInput).val() === 'text') {
      const bodyText = $(this.ui.bodyTextEditor).code()
      if (!bodyText || bodyText.length == 0) {
        App.Util.errorDialog("You must provide some text in the form body in order to generate a preview PDF.", "No Form Body Provided")
        return
      }

      $.extend(params, { body_text: bodyText })
    }

    $.ajax({
      url: Routes.preview_documents_path,
      type: "POST",
      data: params,
      wbGenericFailureMsg: "Sorry, we couldn't generate a preview.",
    }).done((responseText) => {
      this.ui.previewObjectElement.attr('data', `data:application/pdfbase64,${responseText}`)
      $modal.one('show.bs.modal', function() {
        $modal.find('.modal-body').css('height', $(window).height() - 165)
      })
      $modal.modal({ show: true })
    })
  },

  // When the 'force Inbox' checkbox is unchecked, present a warning to the user.
  showForceInboxWarning: function() {
    if (this.ui.forceInboxCheckbox.is(':checked') || this._forceInboxWarningShown) {
      return
    }

    this._forceInboxWarningShown = true  // only show it once per page view

    bootbox.confirm({
      title: "Are you sure?",
      message: "Unchecking this box means that any submissions for this form will automatically <i>skip</i> the Inbox and be " +
                "marked as <strong>Accepted</strong> immediately.<br/><br/>" +
                "We highly recommend only unchecking this box for super-simple forms that don't really need any human review (such as " +
                "simple name & signature forms).",
      className: 'modal-danger',
      callback: function(result) {
        if (result === null) {
          this.ui.forceInboxCheckbox.prop('checked', true)  // Keep it checked
        }
      }.bind(this),
    })
  },

  superadminDeleteForm: function() {
    bootbox.confirm({
      title: "Are you sure?",
      message: "If not, bad things will happen.",
      className: 'modal-danger',
      callback: function(result) {
        if (result === false)
        {return}

        App.vent.trigger('document:destroy', gon.document_id)
      }.bind(this),
    })
  },

  // Returns snake-cased field name, e.g. First Name => first_name
  translateFieldKeyName: (fieldName) => {
    const keyName = fieldName.replace(/\W+/g, "_").toLowerCase().split(' ').join('_')

    // FIXME: This sucks I know :( - this feature needs love and as mentioned above, we need to send this data down from the controller using gon and EmployeeBuiltInFields
    if (keyName === 'preferred_name') {
      return 'nickname'
    }

    return keyName
  },
})
