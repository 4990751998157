import Util from 'lib/util'
import Routes from 'lib/routes'
import EmployeeSearch from 'components/employees/EmployeeSearch'
import { pureOrphanVue } from 'lib/vue/pureOrphanVue'

export default Backbone.Marionette.LayoutView.extend({
  template: "#kiosk-employee-selection-layout-view-tmpl",

  onRender: function() {
    pureOrphanVue(EmployeeSearch, 'component#vue-employee-search', {
      props: {
        value: null, // Unused, handled via callback
        route: Routes.search_kiosk_index_path,
        selectCallback: (event) =>  Turbolinks.visit(`${Util.getPageRootPath()}/${event.data.id}/edit_info`),
      },
    })
  },
})
