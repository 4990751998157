import PrerenderedTableCollectionView from 'views/common/prerendered_table_collection_view'
import UserImportRowItemView from 'views/user_imports/user_import_row_item_view'

export default PrerenderedTableCollectionView.extend({
  childView: UserImportRowItemView,

  onChildviewImportDelete: function(args) {
    App.vent.trigger('user_imports:delete', args.model.id)
  },
})
