<template>
  <div>
    <div v-if="showHelpVideo" ref="helpVideo">
      <p>Please take a moment to watch the following video before you begin.</p>
      <video
        class="video--responsive"
        :poster="this.$routes.Images.remote_i9_screen"
        :src="this.$routes.Videos.remote_i9_authorized_rep"
        controls="controls"
        playsinline="playsinline"
        preload="none"
        width="560"
        height="315"
      />
    </div>
    <hr />
    <div v-if="showNotReadyToStart" ref="notReady">
      <p>Sorry, this document is not ready for reverification.</p>
    </div>

    <div v-if="showSubmitGeoLocation" ref="geolocation">
      <wb-geolocation
        @wbGeolocationSuccess="handleGeolocationSuccess"
        @wbGeolocationError="handleGeolocationError"
      >
        <h4>Step 1: Confirm Location</h4>
        <p>{{ submitLocationInstructions }}</p>
        <p>If you are willing to proceed, click the Submit Your Location button.</p>
      </wb-geolocation>
    </div>

    <template v-if="showQrCode">
      <ScanQrCode
        v-if="authRepQrCodeScanEnabled"
        actor="authorized_representative"
        :stepNumber="1"
        :alertMessage="qrCodeAlertMessage"
        :qrCodeTarget="qrCodePayload"
        :remoteId="remoteReverifyId"
        action="reverify"
        ref="scanQrCode"
      >
        <template #instructions>
          <p>
            This process requires access to your camera. If you do not have access to a camera on this device, you can use a different device or {{ employeeFullName }} can choose a different reviewer.
          </p>
          <p>
            Select Begin QR Code Scan below to open your camera and scan the QR code that is being displayed on {{ employeeFullName }}’s device.
          </p>
        </template>
      </ScanQrCode>
      <ShowQrCode
        v-else
        actor="authorized_representative"
        :stepNumber="1"
        :alertMessage="qrCodeAlertMessage"
        :qrCodePayload="qrCodePayload"
        ref="showQrCode"
      >
        <template #instructions>
          <p>
            Please have <span class="block-translate">{{ employeeFullName }}</span> scan the following QR code to verify your mutual proximity.
          </p>
        </template>
      </ShowQrCode>
    </template>

    <div v-if="showCertifyIdentity" ref="certifyIdentity">
      <h4 class="breathe">Step 2: Certify Identity</h4>
      <form-field-input
        :errors="formErrors"
        label="Full Name"
        name="auth_rep_name"
        :required="true"
        type="text"
        v-model="formData.auth_rep_name"
      />
      <FormFieldCheckbox
        :errors="formErrors"
        gridColumns="col-xs-12"
        :label="certifyIdentityCheckboxLabel"
        name="auth_rep_identity_certification"
        :required="true"
        v-model="formData.auth_rep_identity_certification"
      />
      <wb-button
        @click="handleCertifyIdentityClick"
        ref="continueButton"
        label="Continue"
        :isLoading="isCertifyIdentityButtonLoading"
      />
    </div>

    <div v-if="showReviewDocuments" ref="reviewComponents">
      <h4>Step 3: Review Documents</h4>
      <ReviewDocumentSetContainer
        :documentationSet="reviewData.documentation_set"
        :employeeFullName="employeeFullName"
        :receiptInstructions="receiptInstructions"
        :reviewInstructions="reviewDocumentsInstructions"
        @wbDocumentationSetReviewStart="handleDocumentReviewStart"
        @wbDocumentationSetReviewReset="handleDocumentReviewReset"
        @wbDocumentationSetVerify="handleDocumentVerify"
        @wbDocumentationSetVerificationComplete="handleReverificationComplete"
      />
    </div>

    <div v-if="showSignaturePad" ref="signaturePad">
      <hr/>
      <p>
        Employee Name:
        <strong>{{this.employeeFullName}}</strong>
      </p>
      <p>
        List of Documents:
        <strong>{{documentSetSummary}}</strong>
      </p>
      <PerjuryStatement
        :statement="this.perjuryStatement"
      />
      <form @submit="handleReverifyAccepted">
        <SignaturePad
          v-model="auth_rep_signature"
          :errors="formErrors"
        >
          <template #subHeader>
            <div class="breathe d-flex justify-content-between">
              <div>
                <span>Name:</span>
                <strong>{{ authRepFullName }}</strong>
              </div>
              <button
                class="btn btn-link pt-0 pr-0"
                @click="handleEditIdentityClick"
                ref="editIdentity"
              >
                Edit Identity
              </button>
            </div>
          </template>
        </SignaturePad>
        <wb-button
          ref="submitReverifyButton"
          @click="handleReverifyAccepted"
          label="Complete Reverification"
          :isLoading="isReverifyButtonLoading"
        />
      </form>
    </div>

    <div v-if="showFinishedWithoutReverifying" ref="finishedWOReverify">
      <hr />
      <p>
        {{ $locale.t("remoteCountersign.reverify.finish_without_reverifying", this.employeeFullName) }}
      </p>
      <wb-button
        ref="submitDoneButton"
        @click="handleReverifyRejected"
        label="Finish"
        :isLoading="isDoneButtonLoading"
      />
    </div>

    <div v-if="showReverifyAccepted" ref="reverifyAccepted">
      <p>{{ $locale.t("remoteCountersign.reverify.reverify_accepted") }}</p>
      <p>{{ $locale.t("remoteCountersign.reverify.closeWindow") }}</p>
    </div>

    <div v-if="showReverifyRejected" ref="reverifyRejected">
      <p>
        {{ $locale.t("remoteCountersign.reverify.reverify_rejected") }}
      </p>
      <p>{{ $locale.t("remoteCountersign.reverify.closeWindow") }}</p>
    </div>
  </div>
</template>

<script>
  import SignaturePad from 'components/common/SignaturePad'
  import PerjuryStatement from "components/common/PerjuryStatement"
  import FormFieldCheckbox from "components/common/FormFieldCheckbox"
  import ReviewDocumentSetContainer from "components/documentation/ReviewDocumentSetContainer"
  import I9RemoteReverifyComponentMixin from "components/i9_remote_reverify/common/I9RemoteReverifyComponentMixin"
  import ScanQrCode from 'components/i9_remote_countersign/steps/ScanQrCode'
  import ShowQrCode from 'components/i9_remote_countersign/steps/ShowQrCode'

  export default {
    name: "i9-remote-reverify-auth-rep-container",

    components: {
      SignaturePad,
      PerjuryStatement,
      FormFieldCheckbox,
      ReviewDocumentSetContainer,
      ScanQrCode,
      ShowQrCode,
    },

    mixins: [I9RemoteReverifyComponentMixin],

    data() {
      return {
        identityCertified: false,
        documentSetMatched: null,
        auth_rep_signature: {
          signature: "",
          signature_name: "",
        },
        formData: {
          auth_rep_identity_certification: null,
          auth_rep_name: "",
          auth_rep_signature: "",
          document_review: null,
        },
        isCertifyIdentityButtonLoading: false,
        isReverifyButtonLoading: false,
        isDoneButtonLoading: false,
      }
    },

    computed: {
      authRepFullName: {
        get: function() { return this.formData.auth_rep_name },
        set: function(newValue) { this.formData.auth_rep_name = newValue },
      },

      authRepSignature: {
        get: function() { return this.formData.auth_rep_signature },
        set: function(newValue) { this.formData.auth_rep_signature = newValue },
      },

      employeeFullName() {
        return `${this.reviewData.first_name} ${this.reviewData.last_name}`
      },

      reviewData(){
        return this.pageContext.i9RemoteReverify.reviewData
      },

      receiptInstructions () {
        return this.$locale.t("remoteCountersign.reverify.document_receipt_instructions")
      },

      certifyIdentityCheckboxLabel() {
        return this.$locale.t("remoteCountersign.certify_identity_label", this.employeeFullName)
      },

      reviewDocumentsInstructions() {
        return this.$locale.t('remoteCountersign.review_document_instructions')
      },

      documentSetSummary(){
        return this.reviewData.documentation_set.map(d => d.document_title).join(', ')
      },

      showHelpVideo() {
        return this.showSubmitGeoLocation || this.qrVerifying
      },

      showSubmitGeoLocation(){
        return this.ready && this.remoteVerifying && this.locationNotAccepted && this.qrSecret === null
      },

      showQrCode(){
        return this.ready && this.remoteVerifying && this.qrVerifying
      },

      submitLocationInstructions(){
        return this.$locale.t("remoteCountersign.submit_location_instructions", this.employeeFullName)
      },

      perjuryStatement () {
        return this.$locale.t("remoteCountersign.perjuryStatement")
      },

      showCertifyIdentity() {
        return this.ready && this.remoteVerifying && (this.locationAccepted || this.qrScanMatched) && !this.identityCertified
      },

      showReviewDocuments() {
        return this.ready && this.remoteVerifying && (this.locationAccepted || this.qrScanMatched) && this.identityCertified
      },

      showSignaturePad(){
        return this.remoteVerifying && this.documentSetMatched === true
      },

      showFinishedWithoutReverifying(){
        return this.remoteVerifying && this.documentSetMatched === false
      },

      qrCodeAlertMessage() {
        return `We were unable to access your or ${this.employeeFullName}'s location.`
      },
    },

    watch: {
      "auth_rep_signature.signature": {
        immediate: true,
        handler(newValue) {
          this.authRepSignature = newValue
        },
      },
      authRepFullName: {
        immediate: true,
        handler(newValue) {
          this.auth_rep_signature.signature_name = newValue
        },
      },
    },

    methods: {
      handleCertifyIdentityClick() {
        this.isCertifyIdentityButtonLoading = true

        this.submitI9RemoteReverifyEvent('auth_rep_certify_identity')
          .then(({ success, errors }) => {
            this.identityCertified = success
            this.formErrors = errors || {}
          })
          .always(() => {
            this.isCertifyIdentityButtonLoading = false
          })
      },

      handleEditIdentityClick() {
        this.identityCertified = false
        this.documentSetMatched = null
      },

      handleDocumentReviewStart() {
        this.clearDocumentReviewData()
      },

      handleDocumentReviewReset(data) {
        this.formData.document_review = { ...data }

        this.submitI9RemoteReverifyEvent("reset_document_review")

        this.clearDocumentReviewData()
      },

      handleDocumentVerify(data){
        this.formData.document_review = {...data}
        this.submitI9RemoteReverifyEvent("submit_document_review")
        this.formData.document_review = null
      },

      handleReverificationComplete(data){
        this.documentSetMatched = data.verified
      },

      handleReverifyAccepted(event){
        if (event) { // user clicked enter in the name field
          event.preventDefault()
        }

        this.formData.auth_rep_name = this.auth_rep_signature.signature_name
        this.formData.auth_rep_signature = this.auth_rep_signature.signature

        this.isReverifyButtonLoading = true
        this.submitI9RemoteReverifyEvent("submit_reverify")
          .success(({ success, errors }) => {
            if (success) {
              this.reverifyStatus = "reverified"
            } else {
              this.formErrors = errors || {}
            }
          })
          .always(() => {
            this.isReverifyButtonLoading = false
          })
      },

      handleReverifyRejected(){
        this.isDoneButtonLoading = true
        this.submitI9RemoteReverifyEvent("submit_reverify_rejected")
          .success(({ success, errors }) => {
            if (success) {
              this.reverifyStatus = "reverify_not_matched"
            } else {
              this.formErrors = errors || {}
            }
          })
          .always(() => {
            this.isDoneButtonLoading = false
          })
      },

      clearDocumentReviewData() {
        this.formData.document_review = null
        this.documentSetMatched = null
      },
    },
  }
</script>
