import PrerenderedTableCollectionView from 'views/common/prerendered_table_collection_view'
import ReportRowItemView from 'views/reports/report_row_itemview'

export default PrerenderedTableCollectionView.extend({
  childView: ReportRowItemView,

  onChildviewReportDelete: function(args) {
    App.vent.trigger('report:delete', args.model)
  },
})
