export default Marionette.ItemView.extend({
  template: false,
  ui: {
    navExitKioskMode: '.js-exit-kiosk-mode',
  },
  triggers: {
    'click @ui.navExitKioskMode': 'exitKioskMode',
  },
  onExitKioskMode: function() {
    App.globalVent.trigger('kiosk:admin:command', '/', { exitKioskMode: 1 })
  },
})
