<template>
  <ul class="nav navbar-nav navbar-left">
    <li
      class="announcements active"
      :title="$locale.t('navbar.announcements')"
    >
      <a :href="$routes.announcements_path">
        <span>{{$locale.t('navbar.announcements')}}</span>
      </a>
    </li>
  </ul>
</template>
