/* global bootbox */
/* global moment */

import RemoteFormValidation from 'lib/remote_form_validation'
import BulkCountersignRowView from 'views/i9/bulk_countersign_row_view'
import Routes from 'lib/routes'
import FormsUtil from 'lib/util/forms'
import Constants from 'lib/constants'
import EmployerUpdateDocumentationView from 'views/i9/employer_update_documentation_view'
import Util from 'lib/util'
import WBRequestXHRDecorator from 'decorators/wb_request_xhr_decorator'

// Accordion list of employees to countersign
export default Backbone.Marionette.CompositeView.extend({
  template: '#i9-bulk-countersign-tmpl',
  childView: BulkCountersignRowView,
  childViewContainer: '.panel-group',
  childViewOptions: function(_model, index) {
    return {
      childIndex: index,
      alwaysExpanded: (this.collection.length == 1),
    }
  },

  ui: {
    form: 'form',
    btnBulkUpdateFirstDay: '.btn-bulk-update-first-day',
  },

  events: {
    'click @ui.btnBulkUpdateFirstDay': 'bulkUpdateFirstDay',
  },

  initialize: function() {
    this.documentationChoices = this.getOption('documentationChoices')
  },

  bulkUpdateFirstDay: function(){
    bootbox.prompt({
      title: "Enter the first day of employment:",
      inputType: 'date',
      buttons: {
        confirm: { label: 'Update' },
      },
      callback: function(date){
        if (!date) {return}
        this.collection.each(function(model){
          model.set('employee_start_date', moment(date, Constants.DATE_FORMAT))
        })
      }.bind(this),
    })
  },

  onRender: function() {
    const path = gon.is_edit_section_2 ? 
      Routes.onboarding_i9_edit_path : 
      Routes.onboarding_i9_sign_path

    new RemoteFormValidation({
      path: path,
      resourceName: 'i9_employer_review',
      model: this.model,
      formEl: this.ui.form,
      // Partial validation should only happen when it's countersign
      partialValidation: !gon.is_edit_section_2,
      validations: {
        startDates: {
          selector: '.start-date-input',
          validators: { wbRemote: {} },
          onStatus: this.onFieldValidationStatusChanged.bind(this),
        },
        lateReasons: {
          selector: '.late-reason',
          validators: { wbRemote: {} },
          onStatus: this.onFieldValidationStatusChanged.bind(this),
        },
      },
      fvOptions: {
        autoFocus: false,
      },
      successCallback: this.onRemoteValidateSuccess.bind(this),
      failedCallback: this.onRemoteValidateFailed.bind(this),
      errorCallback: this.onRemoteValidateError.bind(this),
    })
  },

  validate: function(callback) {
    this._wizardCallback = callback

    // By triggering submit, we kick off RemoteFormValidation
    this.ui.form.submit()
  },

  onRemoteValidateSuccess: function() {
    FormsUtil.clearFormInvalidNotification(this.ui.form)
    this._wizardCallback.call(this, true)
  },

  onRemoteValidateFailed: function() {
    FormsUtil.showFormInvalidNotification(this.ui.form, { scroll: true })
    this._wizardCallback.call(this, false)
  },

  onRemoteValidateError: function(xhr) {
    const xhrDecorated = WBRequestXHRDecorator(xhr)
    if (xhr.status == 409) {
      Util.ajaxErrorDialog(xhrDecorated.getFlashMessage('error'), xhr)
    }
  },

  onFieldValidationStatusChanged: function(e) {
    const $inputField = $(e.target)

    // Since this method is called once for every field in the row, the most efficient way to check if the whole row
    // itself is valid, is to use the DOM and look for has-errors on ANY of the form groups. This is a bit hacky,
    // but the only implication of this is UI.
    const rowValid = $inputField.closest('.row').find('.has-error').length == 0

    const submissionId = $inputField.data('submission-id')
    const model = this.collection.get(submissionId)
    model.set('_employer_review_fv_status', rowValid)
  },

  // Method triggered when user clicks 'Edit Documentation' in child view. Shows the Update Documentation modal view.
  // The reason this lives here is so that we can pass the modal view a reference to `this.documentationChoices`
  // without needing to pass that reference to all of the rows in the collection.
  //
  // eventData - Object
  //  view - (BulkCountersignRowView) the row in the collection
  //  model - (I9Submission) the model associated with the row
  onChildviewUpdateDocumentation: function(_childView, eventData) {
    const view = eventData.view
    const model = eventData.model

    // Deep clone the model so that if the user makes changes but then hits 'cancel', those changes are only applied
    // to the clone.
    // https://github.com/jashkenas/backbone/issues/2642
    const clone = new model.constructor(model.toJSON(), { parse: true })

    const modalView = new EmployerUpdateDocumentationView({
      model: clone,
      saveCallback: function(newModel) {
        // Update our model's documentation set with the data from the clone (aka newModel)
        model.set('documentation_set', newModel.documentationSet)
        view.showDocumentation(true)
      },
      documentationChoices: this.documentationChoices,
    })
    modalView.render()
  },

  onChildviewEditAdditionalInfo: function(_childView, eventData) {
    const model = eventData.model

    bootbox.prompt({
      title: "Form I-9 Additional Info",
      message: `Use this space to notate any additional information required for Form I-9. You may leave this space
                blank if the staff member's circumstances do not require additional notations.`,
      inputType: 'textarea',
      value: '',
      buttons: {
        confirm: { label: 'Save' },
      },
      callback: (value) => {
        if (value === null) {  // prompt dismissed
          return
        }

        // FIXME: Use ApiOperation here to encapsulate AJAX and model.set({}) call in one operation
        //
        model.set('additional_info', value)

        // Save to Document Submission
        $.ajax({
          method: 'PATCH',
          url: Routes.update_additional_info_i9_submission_path({id: model.get('id')}),
          data: {
            additional_info: model.get('additional_info'),
          },
          wbGenericFailureMsg: "Sorry, we couldn't update Additional Info.",
        }).fail((xhr) => {
          const xhrDecorated = WBRequestXHRDecorator(xhr)
          if (xhrDecorated.status == 409) {
            App.Util.ajaxErrorDialog(xhrDecorated.getFlashMessage('error'), xhr)
          }
        })
      },
    })
  },
})
