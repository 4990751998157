<template>
  <div class="container-md">
    <div class="text-right">
      <img :src="everifyBanner" height="80px" />
    </div>
    <EverifyAccountFormContainer :everifyAccount="everifyAccount" />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import EverifyAccountFormContainer from 'components/everify/accounts/EverifyAccountFormContainer'

  export default {
    name: 'the-everify-accounts-index-root',

    components: {
      EverifyAccountFormContainer,
    },

    computed: {
      everifyAccount() {
        return this.everifyAccounts.shift()
      },

      everifyBanner() {
        return this.$routes.Images.everify_banner
      },

      ...mapGetters({
        everifyAccounts: 'everify_accounts/all',
      }),
    },
  }
</script>
