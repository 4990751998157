import DocumentReorderingChildView from 'views/documents/document_reordering_child_view'

// Renders the list of documents which can be re-ordered via drag & drop.
// 
// collection - (DocumentCollection) initial collection of docs.
// 
// IMPORTANT: Once the user starts to re-order the docs, the internal Collection maintained by this CollectionView
// will not be updated to reflect the new sort order. Instead, we provide a `getOrderedKeys` to access the 
// sort order.
//--
// FIXME: Genericize this view as a reusable component 
export default Marionette.CollectionView.extend({
  tagName: "ol",
  className: "sortable-list",
  childView: DocumentReorderingChildView,

  initialize: function() {
    this.setDirty(false)
  },

  onRender: function() {
    // REVIEW: having to use these setTimeouts suck w/ third-party widgets sucks.
    setTimeout(function() {
      this.initSortable() 
    }.bind(this))
  },

  initSortable: function() {
    this.sortable = Sortable.create(this.$el[0], {
      animation: 100,
      ghostClass: 'ghost',
      
      onUpdate: function (e) {
        this.setDirty(true)
      }.bind(this),
    })
  },

  // Gets the document keys (IDs) in the new sort order
  // Returns - Array of Strings
  getOrderedKeys: function() {
    return this.sortable.toArray()
  },

  // Re-sorts the list alphabetically
  sortAlphabetically: function() {
    const resortedCollection = this.collection.sortBy('name')

    // The resortedCollection is a copy of the collection sorted alphabetically. We then extract each of the
    // document keys and tell Sortable to resort. (In Sortable, those keys are each <li> data-id attribute.)
    const newIdPositions = resortedCollection.map(function(model) { return model.id })
    this.sortable.sort(newIdPositions)
    
    this.setDirty(true)
  },

  // Whether or not the sort order has been changed by the user (dirty tracking)
  // 
  // Returns - Boolean
  isDirty: function() {
    return this._dirty
  },

  // Updates the dirty tracking flag
  // 
  // value - Boolean
  setDirty: function(value) {
    this._dirty = value
  },
})
