import Permission from 'models/policy_generator/permission'
import TemplatedPermissions from 'models/policy_generator/templated_permissions'


// Pre-built Field permission sets that can be applied quickly to fit the use case of most users
export default function(){
  TemplatedPermissions.call(this)
  this.templates = [
    {
      name: 'view_basic',
      description: 'Read-only access to basic profile info: Name, Address, Email and Phone',
      permissions: [
        { target_id: 'a4s_employee_email',   target_type: 'RepresentableField', action: 'view',   grant: true },
        { target_id: 'a4s_employee_address', target_type: 'RepresentableField', action: 'view',   grant: true },
        { target_id: 'a4s_employee_phone',   target_type: 'RepresentableField', action: 'view',   grant: true },
        { target_id: null,                   target_type: 'RepresentableField', action: 'view',   grant: false },
        { target_id: null,                   target_type: 'RepresentableField', action: 'update', grant: false },
      ],
    },
    {
      name: 'view_all',
      description: 'Read-only access to all profile info (including custom fields)',
      permissions: [
        { target_id: null, target_type: 'RepresentableField', action: 'view',   grant: true },
        { target_id: null, target_type: 'RepresentableField', action: 'update', grant: false },
      ],
    },
    {
      name: 'manage_all',
      description: 'View and change all profile info (including custom fields)',
      permissions: [
        { target_id: null, target_type: 'RepresentableField', action: 'view',   grant: true },
        { target_id: null, target_type: 'RepresentableField', action: 'update', grant: true },
      ],
    },
  ]
}
