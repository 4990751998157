import Routes from 'lib/routes'

export default {

  // Check if an I-9 submission has been countersigned more than 3 business days late.
  //
  // startDate (moment object) - The employee_start_date on an I-9
  //
  // Returns Boolean
  //  - true: the difference between startDate and today is more than three business days (holidays excluded)
  //  - false: the difference between startDate and today is within three business days (holidays excluded)
  isLateSubmission: function(startDate) {
    return new Promise(function(resolve, reject) {
      $.ajax({
        method: 'POST',
        url: Routes.onboarding_i9_late_submission_path,
        data: {
          start_date: startDate.format(),
        },
      }).done((response, status, xhr) => {
        resolve(response.body)
      }).fail((xhr) => {
        reject()
      })
    })
  },
}
