<template>
  <li :class="[canClick ? '' : 'disabled']">
    <a
      :href="hrefOrVoid"
      ref='link'
      @click="() => { if(canClick) $emit('click') }"
    >
      {{ title }}
      <slot v-if="hasSlotContent"/>
    </a>
  </li>
</template>


<script>
  import PermissionEnforcement from 'components/mixins/PermissionEnforcement'

  // A dropdown list item for a Bootstrap-3 style dropdown.
  // https://getbootstrap.com/docs/3.3/components/#dropdowns
  //
  // Content: can be set by *either* title (prop) or default slot.
  // Action: can take *either* href (prop) or @click callback applied to component
  //
  // Uses the optional PermissionRestriction mixin to disable it not permitted.
  export default {
    name: 'dropdown-list-item',
    mixins: [PermissionEnforcement],
    props: {
      href: {
        type: String,
        required: false,
      },
      title: {
        type: String,
        required: false,
      },
      disabled: {
        type: Boolean,
        required: false,
      },
    },
    data() {
      return {}
    },
    computed: {
      hasSlotContent() {
        return this.$slots.default
      },

      canClick() {
        return !this.disabled && this.isPermitted
      },

      restricted() {
        return !this.disabled && !this.isPermitted
      },

      hrefOrVoid() {
        const jsVoid = 'javascript:void(0)'
        return this.canClick ? this.href || jsVoid : jsVoid
      },
    },
    mounted() {
      if (!this.disabled && !this.isPermitted) {
        $(this.$el).tooltip({
          container: 'body',
          title: 'You are not permitted to perform this action.',
        })
      }
    },
  }
</script>
