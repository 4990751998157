<template>
  <table class="table table-striped">
    <thead>
      <tr>
        <th>
          Permission Policy
        </th>
        <th class="text-right">
          Admin Users
        </th>
        <th />
      </tr>
    </thead>
    <tbody>
      <ManagedAccessPolicyTemplatesTableRow
        v-for="(policy, index) in policies"
        :key="index"
        :policy="policy"
        @policySelected="handlePolicySelected"
      />
    </tbody>
  </table>
</template>
<script>
  import ManagedAccessPolicyTemplatesTableRow from 'components/admin/managed_access_policy_templates/ManagedAccessPolicyTemplatesTableRow'

  export default {
    components: {
      ManagedAccessPolicyTemplatesTableRow,
    },
    props: {
      policies: {
        type: Array,
        required: true,
      },
    },
    methods: {
      handlePolicySelected (policyId) {
        this.$emit('policySelected', policyId)
      },
    },
  }
</script>
