<template>
  <div>
    <BulkActivateContainer
      v-if="action == 'activate'"
      ref="activate"
      @hidden="cleanup"
      @success="handleSuccess"
    />

    <BulkDeactivateContainer
      v-if="action == 'deactivate'"
      ref="deactivate"
      @hidden="cleanup"
      @success="handleSuccess"
    />
  </div>
</template>

<script>
  import { mapActions, mapMutations } from 'vuex'
  import * as mutate from 'vuex/mutation_types'
  import BulkActivateContainer from 'components/employees/bulk_change_status/BulkActivateContainer'
  import BulkDeactivateContainer from 'components/employees/bulk_change_status/BulkDeactivateContainer'
  import CollectionViewStore from 'vuex/modules/collection_view_store'
  import Locale from 'lib/locale'
  import Util from 'lib/util'

  // Vuex CollectionViewStore module name, stores metadata about collection (selected, filters, etc)
  const VUEX_COLLECTION_VIEW_NAME = 'collection_view_activate_employees'

  export default {
    name: 'bulk-activate-orphan-container',
    components: {
      BulkActivateContainer,
      BulkDeactivateContainer,
    },
    props: {
      action: {
        type: String,
        required: true,
      },
      employeeIds: {
        type: Array,
        required: true,
      },
    },

    data() {
      return {
        collectionViewName: VUEX_COLLECTION_VIEW_NAME,
      }
    },

    methods: {
      handleSuccess() {
        location.reload()
      },

      cleanup() {
        this.$emit('clear-modal-data')
        this.$destroy()
      },

      ...mapMutations({
        setPageContextKeys: mutate.SET_PAGE_CONTEXT_KEYS,
      }),

      ...mapActions({
        employeesBulkShow: 'employees/bulkShow',
        permissionsBulkAuthorize: 'permissions/bulkAuthorize',
      }),
    },

    created: function(){
      if (!this.$store._modulesNamespaceMap[`${this.collectionViewName}/`]) {
        this.$store.registerModule(this.collectionViewName, CollectionViewStore)
      }
    },

    beforeDestroy() {
      // Clear selected BEFORE we unregister the dynamically created module
      this.$store.dispatch(`${this.collectionViewName}/reset`)
      this.$store.unregisterModule(this.collectionViewName)
    },

    mounted() {
      const loaded = { employees: false, permissions: false }

      const launchWhenFinished = () => {
        if (!loaded.employees || !loaded.permissions) { return }

        setTimeout(() => {
          this.$emit("success")

          this.setPageContextKeys({
            bulk_action_alert: true,
            collectionViewName: this.collectionViewName,
          })
        })
      }

      this.employeesBulkShow({
        employeeIds: this.employeeIds,
      }).then((employees) => {
        this.$store.dispatch(`${this.collectionViewName}/selectAll`, employees.map((e) => e.id))
        loaded.employees = true
        launchWhenFinished()
      }).catch((xhr) => {
        Util.genericAjaxError(Locale.t('errors.ajax.fetch', 'users'), xhr)
      })

      this.permissionsBulkAuthorize({
        resourceType: 'employees',
        resourceIds: this.employeeIds,
        actions: ['change_status'],
      }).then(() => {
        loaded.permissions = true
        launchWhenFinished()
      }).catch((xhr) => {
        Util.genericAjaxError(Locale.t('errors.ajax.fetch', 'permissions'), xhr)
      })
    },
  }
</script>
