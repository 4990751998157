import AsyncModalItemView from 'views/lib/async_modal_itemview'
import AddressFieldsItemView from 'views/common/forms/address_fields_item_view'

export default AsyncModalItemView.extend({
  behaviors: {
    RailsRemoteFormCallbacks: {},
  },

  ui: {
    form: 'form.edit-employee',
    submitButton: 'button[type="submit"]',
  },

  asyncItemViewUniquenessKey: 'edit_profile_info_modal_view',

  onModalShow: function () {
    this.addressFieldsView = new AddressFieldsItemView()
    this.addressFieldsView.render()
  },

  onRemoteFormSuccess: function(e, data, status, xhr) {
    App.Util.showAjaxFlashNotice(xhr)

    // Since this modal doesn't touch any of the custom fields stuff, delete the fields key from the server data so that it
    // doesn't try to re-initialize the fields collection
    delete data['custom_fields']
    this.model.set(data, { parse: true })

    this.hideModal()
  },

  onRemoteFormError: function(e, xhr) {
    if (xhr.getResponseHeader('X-Form-Errors')) {
      this.replaceModalContent(xhr.responseText)
    } else {
      // Reset UI to allow re-submit
      this.ui.submitButton.data('ladda').stop()
    }
  },

  onBeforeDestroy: function() {
    if (this.addressFieldsView) {
      this.addressFieldsView.destroy()
    }
  },
})
