export default class DownloadPart {
  id
  download_id
  download_url
  status
  expires_at
  context
  created_at
  updated_at

  constructor ({ 
    id,
    download_id,
    download_url,
    status,
    expires_at,
    context,
    created_at,
    updated_at,
  }) {
    this.id = id
    this.download_id = download_id
    this.download_url = download_url
    this.status = status
    this.expires_at = expires_at
    this.context = context
    this.created_at = created_at
    this.updated_at = updated_at
  }
}
