/**
 * This ItemView merely sets up the correct link (i.e. template) depending on model status. The ActiveSeasonInfoPanelItemView
 * will handle the actual click events.
 */
export default Marionette.ItemView.extend({
  getTemplate: function() {
    if (this.model.get('status') == 'active')
    {return '#change-status-deactivate-tmpl'}
    else
    {return '#change-status-reactivate-tmpl'}
  },

  modelEvents: {
    "change:status": "statusChanged",
  },

  onBeforeDestroy: function() {
    Marionette.TemplateCache.clear("#change-status-deactivate-tmpl")
  },

  statusChanged: function() {
    this.render()
    if (this.model.get('status') == 'active')
    {this.$el.find('a').tooltip()}
    else
    {this.$el.find('a').tooltip('destroy')}
  },
})
