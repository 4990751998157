<template>
  <span class="status-changed-at">
    <WbSimpleDateTime :value="statusChangedAt" v-if="statusChangedAt" />
  </span>
</template>

<script>
  import { mapState, mapGetters } from 'vuex'
  import WbSimpleDateTime from "components/common/WbSimpleDateTime"

  export default {
    name: 'document-submission-status-changed-at',
    components: {
      WbSimpleDateTime,
    },
    props: {
      submissionId: {
        type: Number,
        required: true,
      },
    },
    computed: {
      statusChangedAt() {
        return (this.submission && this.submission.status_changed_at) ? this.submission.status_changed_at : null
      },

      submission() {
        return this.getSubmission(this.submissionId)
      },

      ...mapGetters({
        getSubmission: 'document_submissions/get',
      }),

      ...mapState([
        'pageContext',
      ]),
    },
  }
</script>
