<template>
  <FormField
    :label="label"
    :name="name"
    :orientation="orientation"
    :required="required"
    :errors="errors"
    :hint="hint"
    :helpPopoverText="helpPopoverText"
  >
    <Toggle
      :value="value"
      :disabled="disabled"
      :onLabel="onLabel"
      :offLabel="offLabel"
      :onClass="onClass"
      :offClass="offClass"
      @toggleChanged="toggleChanged"
    />
  </FormField>
</template>

<script>
  import FormField from 'components/common/FormField'
  import Toggle from 'components/common/Toggle'
  import FormFieldConcern from 'mixins/FormFieldConcern'

  export default {
    name: 'form-field-toggle',
    components: {
      FormField,
      Toggle,
    },
    mixins: [FormFieldConcern],

    props: ['value', 'onLabel', 'offLabel', 'onClass', 'offClass'],

    methods: {
      toggleChanged(value) {
        this.$emit("toggleChanged", value)
      },
    },
  }
</script>
