<template>
  <table
    class="table table-striped"
    ref="inboxTable"
  >
    <thead>
      <tr>
        <th
          class="sortable"
          :class="getSortClassName('staff_member')"
          role="button"
          @click="handleSortAction('staff_member')"
        >
          Staff Member
        </th>
        <th
          class="sortable"
          :class="getSortClassName('form_name')"
          role="button"
          @click="handleSortAction('form_name')"
        >
          Form Name
        </th>
        <th
          class="sortable"
          :class="getSortClassName('type')"
          role="button"
          @click="handleSortAction('type')"
        >
          Type
        </th>
        <th
          :class="getSortClassName('received')"
          role="button"
          @click="handleSortAction('received')"
          class="sortable text-right"
        >
          Received
        </th>
        <th>
          <span class="sr-only">Review Submission</span>
        </th>
      </tr>
    </thead>
    <tbody>
      <DocumentSubmissionsInboxTableRow
        v-for="(submission, index) in submissions"
        :key="index"
        :submission="submission"
        @submissionSelected="handleSubmissionSelected"
      />
    </tbody>
  </table>
</template>
<script>
  import DocumentSubmissionsInboxTableRow from 'components/document_submissions/DocumentSubmissionsInboxTableRow'

  export default {
    components: {
      DocumentSubmissionsInboxTableRow,
    },
    props: {
      submissions: {
        type: Array,
        required: true,
      },
      sort: {
        type: Object,
        default: () => ({}),
      },
    },
    methods: {
      getSortClassName(field) {
        return this.sort.field === field ? [`sort-${this.sort.direction}`] : []
      },
      handleSortAction(field) {
        this.$emit('sort', field)
      },
      handleSubmissionSelected (submissionId) {
        this.$emit('submissionSelected', submissionId)
      },
    },
  }
</script>
