import WBRequestXHRDecorator from 'decorators/wb_request_xhr_decorator'

export default Marionette.Controller.extend({
  destroyDocument: function(id) {
    return $.ajax({
      method: 'DELETE',
      url: `/forms/${id}`,
      wbGenericFailureMsg: "Sorry, we couldn't remove this form.",
    }).done(function(data, status, jqXHR) {
      window.location.href = "/forms"
    }).fail( function(xhr, y, z) {
      const xhrDecorated = WBRequestXHRDecorator(xhr)
      if (xhrDecorated.status == 409) {
        App.Util.ajaxErrorDialog( xhrDecorated.getFlashMessage('error'), xhr)
      }
    })
  },
})
