import { getRoute } from 'lib/routes'
import { ajax } from 'jquery'

const state = () => ({
  collection: [],
  pagination: null,
})

const getters = {
  collection: state => state.collection,
  pagination: state => state.pagination,
}


const mutations = {
  SET_COLLECTION: (state, assignments = []) => {
    state.collection = assignments
  },
  SET_PAGINATION: (state, pagination) => {
    state.pagination = pagination
  },
}

const actions = {
  addEmployeeRecord: ({}, { id }) => {
    return new Promise((resolve, reject) => {
      ajax({
        method: 'PATCH',
        url: getRoute('add_employee_record_admin_user_path', { id }),
      })
        .then(() => {
          resolve()
        })
        .fail(xhr => reject(xhr))
    })
  },
  delete: ({}, { id }) => {
    return new Promise((resolve, reject) => {
      ajax({
        method: 'DELETE',
        url: `${getRoute('admin_users_path')}/${id}`,
      })
        .then(() => {
          resolve()
        })
        .fail(xhr => reject(xhr))
    })
  },
  fetch: ({ commit }, { page, admin_status, query }) => {
    return new Promise((resolve, reject) => {
      ajax({
        method: 'GET',
        url: getRoute('admin_users_list_path'),
        data: {
          page,
          admin_status,
          query,
        },
      })
        .then(({ items, pagination }) => {
          commit('SET_COLLECTION', items)
          commit('SET_PAGINATION', pagination)
          resolve()
        })
        .fail(xhr => reject(xhr))
    })
  },
  resendWelcomeEmail: ({ }, { id }) => {
    return new Promise((resolve, reject) => {
      ajax({
        method: 'POST',
        url: getRoute('admin_users_resend_welcome_email_path', { id }),
      })
        .then(() => {
          resolve()
        })
        .fail(xhr => reject(xhr))
    })
  },
  revoke: ({}, { id }) => {
    return new Promise((resolve, reject) => {
      ajax({
        method: 'PATCH',
        url: getRoute('revoke_admin_user_path', { id }),
      })
        .then(() => {
          resolve()
        })
        .fail(xhr => reject(xhr))
    })
  },
  create: ({}, { data }) => {
    return new Promise((resolve, reject) => {
      ajax({
        method: 'POST',
        url: getRoute('admin_users_path'),
        data: data,
      })
        .then(xhr => resolve(xhr))
        .fail(xhr => reject(xhr))
    })
  },
  update: ({}, { id, data }) => {
    return new Promise((resolve, reject) => {
      ajax({
        method: 'PUT',
        url: getRoute('admin_user_path', {id}),
        data: data,
      })
        .then(xhr => resolve(xhr))
        .fail(xhr => reject(xhr))
    })
  },
  promoteEmployee: ({}, { data }) => {
    return new Promise((resolve, reject) => {
      ajax({
        method: 'POST',
        url: getRoute('promote_employee_admin_users_path'),
        data: data,
      })
        .then((data, status, xhr) => resolve({data, status, xhr}))
        .fail(xhr => reject(xhr))
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
