import AsyncModalItemView from 'views/lib/async_modal_itemview'
import Auth from 'lib/util/auth'
import Routes from 'lib/routes'
import String from 'lib/util/string'
import NewEmploymentFormFields from 'components/employees/NewEmploymentFormFields'
import constants from 'lib/constants'
import { pureOrphanVue } from 'lib/vue/pureOrphanVue'

export default AsyncModalItemView.extend({
  asyncItemViewUniquenessKey: 'new_employee_modal_view',

  behaviors: {
    RailsRemoteFormCallbacks: {},
    // DISCUSS: It would be nice if CustomFieldManners was scoped to just the 'Custom Fields' tab pane
    CustomFieldsManners: {},
  },

  ui: {
    title: '.modal-header > h2',
    form: 'form',
    roleNameHiddenField: 'input[type="hidden"]#employee_rn',
    wizardFinishButton: '.btn-wizard-finish',
    wizardNextButton: '.btn-wizard-next',
    wizardPrevButton: '.btn-wizard-prev',
    addEmployeeGroupInlineLink: '.js-add-employee-group-inline',
    formWizard: '.wizard',
    employeeGroupsMultiselect: '.employee-groups.multiselect',
    formFieldStartDate: 'input[name="employee[new_employment_attributes][start_date]"]',
    formFieldEndDate: 'input[name="employee[new_employment_attributes][end_date]"]',
    formFieldHireDate: 'input[name="employee[new_employment_attributes][hire_date]"]',
    formFieldOnboardingStartDate: 'input[name="employee[new_employment_attributes][onboarding_start_date]"]',
    formFieldEmployeeType: 'select[name="employee[employee_type]"]',
    formFieldEmail: 'input[name="employee[user_attributes][email]"]',
    remoteI9EmploymentSettings: '.remote-i9-employment-settings',
  },

  bindings: {
    '.alert-crossover': {
      classes: {
        hidden: {
          observe: 'crossoverStatus',
          onGet: function (value) { return !(value === 'exists' && !window.gon.current_account.crossoverHandlingEnabled) },
        },
      },
    },
    '.alert-exists': {
      classes: {
        hidden: {
          observe: 'crossoverStatus',
          onGet: function(value) { return value !== 'exists_in_account' },
        },
      },
    },
    'span.alert-exists-employee-name': {
      observe: 'crossoverEmployeeProfile',
      onGet: function(employeeProfile) { return `${_.escape(employeeProfile.first_name)} ${_.escape(employeeProfile.last_name)}`},
    },
    '.btn-wizard-next': {
      classes: {
        disabled: {
          observe: 'crossoverStatus',
          onGet: function(value,event) {
            return value == 'exists_in_account' || event.view.model.get("loadingCrossoverStatus")
          },
        },
      },
    },
  },

  triggers: {
    'click @ui.addEmployeeGroupInlineLink': 'addEmployeeGroupInline',
    'change @ui.formFieldEmail': 'emailChanged',
    'change @ui.formFieldEmployeeType': 'employeeTypeChanged',
  },

  initialize: function(options) {
    this.model = new Backbone.Model({
      crossoverStatus: false,
      crossoverEmployeeProfile: {},
      loadingCrossoverStatus: false,
    })
    AsyncModalItemView.prototype.initialize.call(this, options)
  },

  onEmailChanged: function() {
    const email = this.ui.formFieldEmail.val()
    this.checkCrossover(email)
  },

  checkCrossover: function(email) {
    if (email && email.length > 0) {
      this.model.set({loadingCrossoverStatus: true})
      $("input[name='employee[user_attributes][email]']").after()

      $.ajax({
        method: 'GET',
        url: `${Routes.validate_new_email_employees_path}?email=${encodeURIComponent(email)}`,
        progressBar: false,
        wbGenericFailureMsg: "Sorry, an error occurred validating the email.",
      }).done( (response) => {
        if (response.status !== 'available') {
          if (response.status === 'exists' && !window.gon.current_account.crossoverHandlingEnabled) {
            this.addCrossoverStickitBindings()
          } else if (response.status !== 'exists') {
            this.addCrossoverStickitBindings()
          }
        }

        this.model.set({
          crossoverStatus: response.status,
          crossoverEmployeeProfile: response.employee_profile,
          loadingCrossoverStatus: false,
        })
      }).fail(() => {
        this.model.set({loadingCrossoverStatus: false})
      })
    }

    // If crossover, clear while we're checking
    if (this.model.get('crossoverStatus') != 'available') {
      this.model.set({crossoverStatus: false, crossoverEmployeeProfile: {}})
    }
  },

  onEmployeeTypeChanged: function() {
    const isEmployeeType = this.ui.formFieldEmployeeType.val() == 'employee'

    if (isEmployeeType) {
      this.ui.remoteI9EmploymentSettings.show()
    } else {
      this.ui.remoteI9EmploymentSettings.hide()
    }
  },

  onBeforeModalShow: function() {
    // Bind enter key
    this.$el.on('keypress', $.proxy(function(e) {
      if (e.keyCode == constants.KeyCode.KEY_RETURN) {
        this.ui.wizardNextButton.click()
        e.preventDefault()
        e.stopPropagation()
        return false
      }
    }, this))
    this.stickit()
  },

  onModalShow: function () {
    const ModalAddressFields = App.Views.Common.AddressFieldsItemView
    ModalAddressFields.prototype.el = '#new_employee .address-fields' // Constrain view UI selectors to modal
    this.addressFieldsView = new ModalAddressFields()
    this.addressFieldsView.render()
  },


  onRemoteFormSuccess: function(_e, data, _status, xhr) {
    App.Util.showAjaxFlashNotice(xhr)
    App.vent.trigger('employee:added', data)
    this.hideModal()
  },

  onRemoteFormError: function(_e, xhr) {
    if (xhr.getResponseHeader('X-Form-Errors')) {
      this.replaceModalContent(xhr.responseText)
    } else {
      // Modal content is not reset, but we need to re-enable the submit button manually
      this.ui.wizardFinishButton.data('ladda').stop()
    }

    this.stickit()
    // Reapply crossover bindings if applicable
    if (['exists', 'exists_in_account'].indexOf(this.model.get('crossoverStatus')) > -1) {
      this.addCrossoverStickitBindings()
    }
  },

  onRender: function() {
    if (this.getOption('title') && this.getOption('title').length > 0)
    {this.ui.title.text(this.getOption('title'))}

    this.ui.wizardFinishButton.hide()
    this.initializeWizard()

    this.ui.employeeGroupsMultiselect.multiselect({
      enableCaseInsensitiveFiltering: true,
      buttonWidth: '225px',
      numberDisplayed: 0,
    })
    if (this.getOption('roleName') && this.getOption('roleName').length > 0)
    {this.ui.roleNameHiddenField.val(this.getOption('roleName'))}

    this.onEmployeeTypeChanged()
  },

  initializeWizard: function() {
    this.ui.formWizard.bootstrapWizard({
      tabClass: null,
      nextSelector: this.ui.wizardNextButton,
      previousSelector: this.ui.wizardPrevButton,
      onTabShow: $.proxy(this.onTabShow, this),
    })
  },

  onTabShow: function() {
    const componentName = '#vue-new-employee-employment-form-fields'
    const bsWizard = this.ui.formWizard.data('bootstrapWizard')
    if (!bsWizard) {
      return
    }

    const total = bsWizard.navigationLength()
    const currentIdx = bsWizard.currentIndex()

    if (currentIdx >= total) {
      this.ui.wizardNextButton.hide()
      this.ui.wizardFinishButton.show().removeClass('disabled').css('display', '')
    } else {
      this.ui.wizardNextButton.show().css('display', '')
    }

    // Check that we haven't rendered the component before rendering again since this is a multi-step wizard.
    if ($(componentName).length) {
      this.employmentFields = pureOrphanVue(NewEmploymentFormFields, componentName, {
        props: {
          value: {
            start_date: this.ui.formFieldStartDate.val(),
            end_date: this.ui.formFieldEndDate.val(),
            hire_date: this.ui.formFieldHireDate.val(),
            onboarding_start_date: this.ui.formFieldOnboardingStartDate.val(),
          },
          updateCallback: (dates) => {
            this.ui.formFieldStartDate.val(dates.start_date)
            this.ui.formFieldEndDate.val(dates.end_date)
            this.ui.formFieldHireDate.val(dates.hire_date)
            this.ui.formFieldOnboardingStartDate.val(dates.onboarding_start_date)
          },
        },
      })
    }
  },

  onAddEmployeeGroupInline: function() {
    App.globalVent.trigger('employee_groups:new:inline', { multiselectEl: this.ui.employeeGroupsMultiselect })
  },

  onBeforeDestroy: function() {
    if (this.addressFieldsView) {
      this.addressFieldsView.destroy()
    }
  },

  authFormFieldContent: function(value, element) {
    if (Auth.isBlockedContent(value)){
      $(element).after()
      $(element).hide()
      $(element).after(Auth.blockedContentTag())
    } else {
      $(element).show()
      $(element).parent().find('.blocked-content').remove()
      return value
    }
  },

  addCrossoverStickitBindings: function() {
    const baseOptionsBinding = {updateModel: false, observe: 'crossoverEmployeeProfile'}
    const baseOptionsAttr = {name: 'disabled', observe: 'crossoverEmployeeProfile'}
    const baseOptionsAttrReadonly = {name: 'readonly', observe: 'crossoverEmployeeProfile'}

    this.addBinding(null, 'input[name="employee[employee_profile_attributes][first_name]"]', _({
      onGet: function(employeeProfile) { return employeeProfile.first_name },
      attributes: [_({onGet: function(employeeProfile) { return employeeProfile.first_name != undefined }}).extend(baseOptionsAttrReadonly)],
    }).extend(baseOptionsBinding))

    this.addBinding(null, 'input[name="employee[employee_profile_attributes][middle_name]"]', _({
      onGet: function(employeeProfile) { return employeeProfile.middle_name },
      // Using first_name on purpose for disable- if it's set, it means someone has had the chance to define the optional
      // middle_name field, which may not be present
      attributes: [_({onGet: function(employeeProfile) { return employeeProfile.first_name != undefined }}).extend(baseOptionsAttrReadonly)],
    }).extend(baseOptionsBinding))

    this.addBinding(null, 'input[name="employee[employee_profile_attributes][last_name]"]', _({
      onGet: function(employeeProfile) { return employeeProfile.last_name },
      attributes: [_({onGet: function(employeeProfile) { return employeeProfile.last_name != undefined }}).extend(baseOptionsAttrReadonly)],
    }).extend(baseOptionsBinding))

    this.addBinding(null, 'input[name="employee[employee_profile_attributes][address_attributes][street]"]', _({
      onGet: function(employeeProfile) { return employeeProfile.address ? employeeProfile.address.street : null },
      attributes: [_({onGet: function(employeeProfile) { return employeeProfile.address && String.present(employeeProfile.address.street) }}).extend(baseOptionsAttr)],
    }).extend(baseOptionsBinding))

    this.addBinding(null, 'input[name="employee[employee_profile_attributes][address_attributes][apt]"]', _({
      onGet: function(employeeProfile) { return employeeProfile.address ? employeeProfile.address.apt : null },
      // Using street on purpose for disable- if it's set, it means someone has had the chance to define the optional
      // apt field, which may not be present
      attributes: [_({onGet: function(employeeProfile) { return employeeProfile.address && String.present(employeeProfile.address.street) }}).extend(baseOptionsAttr)],
    }).extend(baseOptionsBinding))

    this.addBinding(null, 'input[name="employee[employee_profile_attributes][address_attributes][city]"]', _({
      onGet: function(employeeProfile) { return employeeProfile.address ? employeeProfile.address.city : null },
      attributes: [_({onGet: function(employeeProfile) { return employeeProfile.address && String.present(employeeProfile.address.city) }}).extend(baseOptionsAttr)],
    }).extend(baseOptionsBinding))

    this.addBinding(null, 'select[name="employee[employee_profile_attributes][address_attributes][country]"]', _({
      onGet: function(employeeProfile) {
        if (employeeProfile.address == undefined) { return 'US' }
        const countryCode = String.present(employeeProfile.address.country) ? employeeProfile.address.country : 'US'
        this.addressFieldsView.changeCountryByCode(countryCode)

        // Update the state field- this won't propagate correctly otherwise, since the field changed
        if (String.present(employeeProfile.address.state)){
          const stateSelect = $('select[name="employee[employee_profile_attributes][address_attributes][state]"]')
          stateSelect.val(employeeProfile.address.state)
          stateSelect.attr('disabled', true)
        }
        return countryCode
      },
      attributes: [_({onGet: function(employeeProfile) { return employeeProfile.address && String.present(employeeProfile.address.country) }}).extend(baseOptionsAttr)],
    }).extend(baseOptionsBinding))

    this.addBinding(null, 'select[name="employee[employee_profile_attributes][address_attributes][state]"]', _({
      onGet: function(employeeProfile) { return employeeProfile.address ? employeeProfile.address.state : null }.bind(this),
      attributes: [_({onGet: function(employeeProfile) { return employeeProfile.address && String.present(employeeProfile.address.state) }}).extend(baseOptionsAttr)],
    }).extend(baseOptionsBinding))

    this.addBinding(null, 'input[name="employee[employee_profile_attributes][address_attributes][zip]"]', _({
      onGet: function(employeeProfile) { return employeeProfile.address ? employeeProfile.address.zip : null },
      attributes: [_({onGet: function(employeeProfile) { return employeeProfile.address && String.present(employeeProfile.address.zip) }}).extend(baseOptionsAttr)],
    }).extend(baseOptionsBinding))

    this.addBinding(null, 'input[name="employee[employee_profile_attributes][phone]"]', _({
      onGet: function(employeeProfile) { return employeeProfile.primary_phone },
      attributes: [_({onGet: function(employeeProfile) { return String.present(employeeProfile.primary_phone) }}).extend(baseOptionsAttr)],
    }).extend(baseOptionsBinding))

    this.addBinding(null, 'select[name="employee[employee_profile_attributes][gender]"]', _({
      onGet: function(employeeProfile, event) { this.authFormFieldContent(employeeProfile.gender, $(event.selector)) },
      attributes: [_({onGet: function(employeeProfile) { return String.present(employeeProfile.gender) }}).extend(baseOptionsAttr)],
    }).extend(baseOptionsBinding))

    this.addBinding(null, 'input[name="employee[employee_profile_attributes][birthdate]"]', _({
      onGet: function(employeeProfile, event) {
        this.authFormFieldContent(employeeProfile.birthdate, $(event.selector).parent())
      },
      attributes: [_({onGet: function(employeeProfile) { return String.present(employeeProfile.birthdate) }}).extend(baseOptionsAttr)],
    }).extend(baseOptionsBinding))

    this.addBinding(null, 'input[name="employee[employee_profile_attributes][ssn]"]', _({
      onGet: function(employeeProfile, event) {
        this.authFormFieldContent(employeeProfile.ssn, $(event.selector))
      },
      attributes: [_({onGet: function(employeeProfile) { return String.present(employeeProfile.ssn) }}).extend(baseOptionsAttr)],
    }).extend(baseOptionsBinding))

    this.addBinding(null, 'input[name="employee[employee_profile_attributes][nickname]"]', _({
      onGet: function(employeeProfile) { return employeeProfile.nickname },
      attributes: [_({onGet: function(employeeProfile) { return String.present(employeeProfile.nickname) }}).extend(baseOptionsAttr)],
    }).extend(baseOptionsBinding))
  },
})
