<template>
  <a
    role="button"
    ref="adminCancelReverifyButton"
    id="admin-cancel-reverify-button"
    @click.prevent="handleCancelReverify"
    v-if="inProgress"
  >
    <WbIcon
      iconSet="fa"
      type="times"
    />
    Cancel Remote Reverify
  </a>
  <a
    role="button"
    ref="adminInitiateReverifyButton"
    class="admin-initiate-reverify-button"
    v-else-if="!supplementBFull && canRequestReverify"
    @click.prevent="handleBeginReverify"
  >
    <WbIcon
      iconSet="a4s-icon"
      type="signature-pen"
    />
    Request Remote Reverify
  </a>
</template>

<script>
  import { mapActions } from "vuex"
  import Util from 'lib/util'
  import WbIcon from 'components/common/WbIcon'

  export default {
    name: 'admin-initiate-reverify-button',
    components: {
      WbIcon,
    },
    props: {
      documentSubmission: {
        type: Object,
        required: true,
      },
      isRemoteReverificationI9Enabled: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data() {
      return {
        inProgress: this.documentSubmission.reverify_in_progress,
      }
    },
    computed: {
      supplementBFull () {
        return this.documentSubmission.supplement_b_full
      },
      canRequestReverify() {
        return this.isRemoteReverificationI9Enabled
      },
    },
    methods: {
      ...mapActions({
        cancelReverify: 'document_submissions/cancelReverify',
        beginReverify: 'document_submissions/beginReverify',
      }),
      async handleCancelReverify() {
        try {
          const xhr = await this.cancelReverify({ id: this.documentSubmission.id })
          if (xhr.errors) {
            Util.showFlashNotice(xhr.errors, {alertType: 'danger'})
          } else {
            this.inProgress = false
            location.reload()
          }
        } catch (xhr) {
          Util.genericAjaxError("An error occurred.", xhr)
        }
      },
      async handleBeginReverify() {
        try {
          const xhr = await this.beginReverify({ id: this.documentSubmission.id })
          if (xhr.errors) {
            Util.showFlashNotice(xhr.errors.supplement_b[0], {alertType: 'danger'})
          } else {
            this.inProgress = true
            /**
             * Not ideal, but as long as we have the whole submission page built
             * with different technologies (Slim, Backbone, Vue), we need to reload the
             * page here in order to have the permanent "Reverify Started" callout
             * showing up from the moment the user presses this button.
             *
             * It also avoids the user clicking this twice before reloading the page
             */
            location.reload()
          }
        } catch (xhr) {
          Util.genericAjaxError("An error occurred.", xhr)
        }
      },
    },
  }
</script>
