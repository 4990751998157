// Wizard Layout
//
// View for a wizard
import WizardProgressView from 'views/common/wizard_progress_view'
import WizardHistory from 'lib/wizard_history'
import Constants from 'lib/constants'


export default Marionette.LayoutView.extend({
  template: "#layout-wizard-tmpl",

  regions: {
    progressBar: '.wizard-progress',
    bodyRegion: '.wizard-body',
  },

  ui: {
    nextButton: '.next',
    prevButton: '.prev',
    transitionFadeElements: '#fade-on-transition',
  },

  events: {
    'click @ui.nextButton': 'nextStep',
    'click @ui.prevButton': 'triggerBack',
    keypress: 'keypress',
  },

  triggerBack: function() {
    this._history.back()
  },

  initialize: function() {
    this.wizard = this.getOption('wizard')

    this.bodyRegion.on('before:show', function(){
      this.updateButtons()
    }.bind(this))

    this._history = new WizardHistory(
      function(){return this.wizard.currentStepIdx()}.bind(this),
      this.prevStep.bind(this),
      this.$el)
  },

  onBeforeDestroy: function() {
    this._history.cleanup()
  },

  // Next on Enter key
  keypress: function(event) {
    if (event.keyCode == Constants.KeyCode.KEY_RETURN) {
      this.nextStep()
      event.stopPropagation()
      event.preventDefault()
    }
  },

  serializeData: function(){
    return {
      title: this.options.title,
    }
  },

  onRender: function() {
    this.progressBar.show(new WizardProgressView({wizard: this.wizard}))

    const wizardGlobalContext = this.wizard.stepStore.register('global')
    wizardGlobalContext.set({ submitButton: this.ui.nextButton })

    // When the layout is first rendered, show the first step:
    this.bodyRegion.show(this.wizard.viewForCurrentStep())
  },

  onShow: function() {
    $.runInitializers(this.$el)
    this.updateButtons()
  },

  // Transition to the next step
  nextStep: function() {
    if (this.wizard.canAdvance()) {
      this.wizard.advanceToNextStep(function(nextView){
        if (nextView) // only update the region if there's something different to show
        {this.bodyRegion.show(nextView)}

        this.ui.nextButton.data('ladda').stop()
        this.updateButtons()

        this._history.pushNextStep()
      }.bind(this), this.ui.transitionFadeElements)
    } else if (this.wizard.canFinish()) {
      this.wizard.finish(function invalidCallback() {
        // Validation error on finish
        this.ui.nextButton.data('ladda').stop()
        this.updateButtons()
      }.bind(this))
    }
  },

  // Transition to the prevous step
  prevStep: function(){
    if (this.wizard.canRetreat()) {
      const prevView = this.wizard.retreatToPrevStep(function(prevView){
        if (prevView) // only update the region if there's something different to show
        {this.bodyRegion.show(prevView)}
      }.bind(this), this.ui.transitionFadeElements)
    }
  },

  // Update button text and visibility
  updateButtons: function(){
    // Hide the Previous button at the start of the wizard
    if (this.wizard.canRetreat()){
      this.ui.prevButton.show()
    } else {
      this.ui.prevButton.hide()
    }

    // Update the Next button text to Finish at the end of the wizard
    const nextText = this.wizard.canAdvance() ? 'Next' : 'Finish'
    this.ui.nextButton.find('.ladda-label').text(nextText)
  },

})
