import { ajax } from 'jquery'
import { getRoute } from 'lib/routes'

export default function validateDocumentation(data) {
  return new Promise((resolve, reject) => {
    ajax({
      method: 'POST',
      timeout: 30000,
      url: getRoute('validate_attachment_i9_submissions_path'),
      data: JSON.stringify(data),
      dataType: 'json',
      contentType: 'application/json',
      progressBar: false,
      wbGenericFailureMsg: 'Sorry, we had trouble validating this form.',
    })
      .done(res => resolve(res))
      .fail(xhr => reject(xhr))
  })
}
