<template>
  <div>
    <h2>I-9 In-Person Review</h2>
    <I9RemoteCountersignEmployeeContainer :allowToStartOver="allowToStartOver" />
  </div>
</template>

<script>
  import I9RemoteCountersignEmployeeContainer from 'components/i9_remote_countersign/I9RemoteCountersignEmployeeContainer'
  export default {
    name: 'the-i9-remote-countersign-employee-root',
    components: {
      I9RemoteCountersignEmployeeContainer,
    },
    props: {
      allowToStartOver: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>
