import { mapGetters } from 'vuex'

// Mixin for disabling functionality based on permissions in Vuex.
//
// If component provides optional props, the `permitted` computed property describes if the action is permitted. UI or data
// can be disabled as desired in the included component.
export default {
  props: {
    permissionModel: {
      type: String,
      required: false,
    },
    permissionRecordId: {
      type: [Number, String], // String to accomodate built-in docs
      required: false,
    },
    permissionAction: {
      type: String,
      required: false,
    },
  },
  computed: {
    // Determines whether or not the action is allowed for the given record. If no `permissionModel` is provided,
    // enforcement does not take place and we return true. This diverges from isPermitted as well as backend behavior
    // to DENY by default.
    //
    // Returns (Boolean)
    isPermitted() {
      if (this.permissionModel == null) { return true }
      return this.checkPermission(this.permissionModel, this.permissionRecordId, this.permissionAction)
    },

    ...mapGetters({
      checkPermission: 'permissions/isPermitted',
    }),
  },
}
