import { mapActions, mapGetters } from 'vuex'

export default {
  methods: {
    async fetch(bypassCache) {
      try {
        this.startLoading()
        
        await this.dashboardFetch({
          source: this.source, 
          title: this.title,
          bypassCache: bypassCache,
        })
      } finally {
        this.stopLoading()
      }
      
    },

    startLoading () {
      this.$wait.start(this.source)
    },

    stopLoading () {
      this.$wait.end(this.source)
    },

    ...mapActions({
      dashboardFetch: 'dashboard/fetch',
    }),
  },

  computed: {
    chartData() {
      return this.widgetData ? this.widgetData.chartData : {}
    },

    lastUpdated() {
      return this.widgetData ? this.widgetData.lastUpdated : ''
    },

    errorMsg() {
      return this.widgetData ? this.widgetData.errorMsg || '' : ''
    },

    widgetData() {
      return this.dashboardGetSource(this.source)
    },

    ...mapGetters({
      dashboardGetSource: 'dashboard/getSource',
    }),
  },

  mounted() {
    this.fetch(false)
  },
}
