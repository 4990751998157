<template>
  <div>
    <h2>Form I-9 In Person Reverification</h2>
    <I9RemoteReverifyAuthRepContainer/>
  </div>
</template>

<script>
  import I9RemoteReverifyAuthRepContainer from "components/i9_remote_reverify/I9RemoteReverifyAuthRepContainer"

  export default {
    name: "the-i9-remote-reverify-auth-rep-root",
    components: {
      I9RemoteReverifyAuthRepContainer,
    },
  }
</script>
