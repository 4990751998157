export default Marionette.ItemView.extend({
  template: '#template-suggest-required-fields-view',
  collectionEvents: {
    change: 'changed',
  },
  changed: function(){
    this.render()
  },
  serializeData: function(){
    return {
      requiredFields: this.collection.required(),
      hasErrors: this.collection.unmatchedRequired().length > 0,
    }
  },
})
