<script>
  import WizardHistory from 'lib/wizard_history'
  import WizardHistoryNoBack from 'lib/wizard_history_no_back'

  export default {
    props: {
      // Enable back-button support for this wizard
      backButtonEnabled: {
        type: Boolean,
        default: true,
      },
    },

    data() {
      return {
        currentStepIdx: 0,
        navDirection: null,
        history: null,
      }
    },

    computed: {
      currentStepComponentName() {
        return this.steps[this.currentStepIdx].component
      },

      stepList() {
        const list = []
        this.steps.forEach((s) => {
          s.active = false

          if (list.length == 0 || list[list.length - 1].label != s.label) {
            list.push(s)
            s.active = this.steps[this.currentStepIdx].label == s.label
          }
        })

        return list
      },

      firstStep() {
        return this.currentStepIdx == 0
      },

      lastStep() {
        return this.currentStepIdx == this.steps.length - 1
      },

      nextLabel() {
        return this.lastStep ? 'Finish' : 'Next'
      },
    },

    mounted() {
      const historyKlass = this.backButtonEnabled ? WizardHistory : WizardHistoryNoBack
      this.history = new historyKlass(
        () => {return this.currentStepIdx},
        this._prevStep,
        $(this.$el),
      )

      this.stepChanged()
    },

    beforeDestroy() {
      this.history.cleanup()
    },


    methods: {
      nextStep() {
        if (!this.$refs.currentStep.valid()) {
          this.$refs.currentStep.invalidError()
          return
        }

        if (this.lastStep) {
          this.$refs.currentStep.beforeAdvance()
          this.done()
        } else {
          this.$refs.currentStep.beforeAdvance()

          this.navDirection = 'next'
          this.currentStepIdx += 1
          this.history.pushNextStep()
          this.stepChanged()
        }
      },

      // We let WizardHistory handle this through a callback to prevStep. This allows WizardHistory to keep track of where we are
      // and trigger a real browser back if we're at the start.
      handlePrevStep() {
        this.history.back()
      },

      // Return to the previous step. Avoid calling this directly, should be called through WizardHistory or alternately handler
      _prevStep() {
        if (this.currentStepIdx > 0) {
          this.navDirection = 'prev'
          this.currentStepIdx -= 1
        } else if (!this.backButtonEnabled) {
          // We backed up at the start of the wizard. Handled by the parent, not the WizardHistory
          this.$emit('prewizardBack')
        }
      },

      skipStep() {
        this.navDirection == 'prev' ? this.handlePrevStep() : this.nextStep()
      },

      // Callback on step change, implement if necessary
      stepChanged() {},
      done() {},
    },
  }
</script>
