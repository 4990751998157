export default Marionette.Controller.extend({
  initialize: function(options) {

  },

  deleteImport: function(id) {
    const user_import = App.UserImports.listTableCollectionView.collection.get(id)
    if (!user_import)
    {return}

    user_import.destroy({
      wait: true,
      wbGenericFailureMsg: "Sorry, we couldn't delete this import.",
    }).done(function(data, status, xhr) {
      App.Util.showAjaxFlashNotice(xhr)
    })
  },
})
