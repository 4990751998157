import PrerenderedTableRowItemView from 'views/common/prerendered_table_row_item_view'

export default PrerenderedTableRowItemView.extend({
  behaviors: {
    TableRowSelectable: {},
  },

  ui: {
    deleteAction: '.js-delete',
  },

  triggers: {
    'click @ui.deleteAction': 'report:delete',
  },
})
