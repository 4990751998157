import WebhooksController from 'src/controllers/webhooks_controller'

export default function(Sector, App, Backbone, Marionette, $, _) {
  Sector.addInitializer(function() {
    Sector.controller = new WebhooksController()

    Sector.listenTo(App.vent, 'webhooks:delete', function(id) {
      Sector.controller.deleteWebhook(id)
    })
  })
}
