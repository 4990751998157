import RepresentableField from 'models/representable_field'
import Routes from 'lib/routes'


const _RepresentableFieldCollection = Backbone.Collection.extend({
  model: RepresentableField,
})

// Static methods
_.extend(_RepresentableFieldCollection, {
  // Requests the list of fields from the server in "tree" format. See WorkbrightApi::Private::RepresentableFieldsController
  // for more details.
  //
  // filters - (Array) Strings representing filters to reduce the fields for the intended feature (e.g. 'importable',
  // 'required', and so on).
  //
  // Returns - jQuery Promise object
  fetchFieldsTree: function(filters) {
    let url = Routes.Api.Private.tree_fields_path

    if (filters) {
      url += `/${filters.join('/')}`
    }

    return $.ajax({
      url: url,
      progressBar: false,
      wbGenericFailureMsg: `We encountered an error while loading data needed for this page. We recommend refreshing
                            this page to ensure you don't encounter further unexpected behavior.`,
    })
  },
})

export default _RepresentableFieldCollection
