<template>
  <div class="d-flex pull-left mb-1" ref="i9ActionButtonsContainer">
    <WbButton
      ref="signSection2Button"
      :disabled="isSection2Disabled"
      @click="signSection2"
      class="mr-1"
    >
      <WbIcon
        iconSet="a4s-icon"
        type="signature-pen"
        class="fa-fw fa-lg"
      />
      <span class="btn-title">
        {{ signSection2ButtonText }}
      </span>
    </WbButton>
    <WbButton
      ref="editSection2Button"
      context="default"
      :disabled="isSection2Disabled"
      @click="editSection2"
      class="mr-1"
    >
      <WbIcon
        iconSet="fa"
        type="cog"
      />
      <span class="btn-title">
        {{ editSection2ButtonText }}
      </span>
    </WbButton>
    <WbButton
      ref="requestNewI9Button"
      context="default"
      :disabled="isSection2Disabled"
      @click="requestNewI9"
    >
      <WbIcon
        iconSet="a4s-icon"
        type="archived-file"
        class="fa-fw fa-lg"
      />
      <span class="btn-title">
        {{ requestNewI9ButtonText }}
      </span>
    </WbButton>
  </div>
</template>
<script>
  import StringUtil from 'lib/util/string'
  import WbIcon from 'components/common/WbIcon'
  import WbButton from 'components/common/WbButton'

  export default {
    components: {
      WbButton,
      WbIcon,
    },
    props: {
      selectionCount: {
        type: Number,
        required: true,
      },
      isLoading: {
        type: Boolean,
        required: true,
      },
    },
    emits: ['editSection2', 'signSection2', 'requestNewI9'],
    computed: {
      requestNewI9ButtonText() {
        const requestNewI9Text = 'Request New I9'
        return this.getButtonText(requestNewI9Text)
      },
      signSection2ButtonText() {
        const SIGN_TEXT = 'Sign Section 2'
        return this.getButtonText(SIGN_TEXT)
      },
      editSection2ButtonText() {
        const EDIT_TEXT = 'Edit Section 2'
        return this.getButtonText(EDIT_TEXT)
      },
      isSection2Disabled() {
        return this.selectionCount === 0 || this.isLoading
      },
    },
    methods: {
      getButtonText(inputText) {
        let text = ''
        if (this.selectionCount === 0) {
          text = (inputText)
        } else {
          const forms = StringUtil.pluralizeWord(this.selectionCount, 'form')
          text = (`${inputText} for (${this.selectionCount}) selected ${forms}`)
        }
        return text
      },
      requestNewI9() {
        this.$emit('requestNewI9')
      },
      signSection2() {
        this.$emit('signSection2')
      },
      editSection2() {
        this.$emit('editSection2')
      },
    },
  }
</script>
