<template>
  <tr :data-id="user.id">
    <td>
      {{ user.name }}
      <span
        v-if="user.is_cs"
        class="label label-default"
        title="You may delete WorkBright customer success admin accounts any time after your account setup is complete."
        ref="csLabel"
      >
        WorkBright Customer Success
      </span>
    </td>
    <td
      v-if="!isPendingAdmin"
      ref="staffRecord"
    >
      <span v-if="user.is_superadmin">
        --
      </span>
      <a
        v-else-if="user.is_employee"
        class="btn btn-default btn-xs"
        :href="employeePath"
      >
        <WbIcon type="user" />
        Open
      </a>
      <a
        v-else
        role="button"
        @click="handleCreateStaffMemberClick"
        :disabled="!isAddingEmployeeRecord"
      >
        Create Staff Member Record
      </a>
    </td>
    <td ref="email">
      {{ shouldShowEmail ? user.email : '--' }}
    </td>
    <td
      v-if="isPendingAdmin"
      ref="createdFrom"
    >
      {{ user.created_from }}
    </td>
    <td
      v-if="!isPendingAdmin"
      ref="accessLevel"
    >
      {{ accessLevelOrPolicy }}
    </td>
    <td class="text-right">
      <div class="dropdown">
        <WbButton
          context="default"
          class="btn-sm dropdown-toggle"
          data-toggle="dropdown"
        >
          <WbIcon
            type="caret-down"
            class="fa-fw right"
          />
        </WbButton>
        <ul
          class="dropdown-menu dropdown-menu-right"
          role="menu"
        >
          <li v-if="canEditAdmin">
            <a
              :href="$routes.edit_admin_user_path({ id: user.id })"
              ref="editAdmin"
            >
              Edit Admin
            </a>
          </li>
          <li v-if="!user.is_confirmed">
            <a
              role="button"
              @click="handleResendWelcomeEmail"
              :disabled="isResendingWelcomeEmail"
              ref="resendWelcomeEmail"
            >
              Resend Welcome Email
            </a>
          </li>
          <li v-if="user.is_employee">
            <a
              role="button"
              class="danger"
              @click="handleRevokeAdminClick"
              ref="removeAdminAccess"
            >
              Remove Admin Access
            </a>
          </li>
          <li v-if="!user.is_employee">
            <a
              class="danger"
              role="button"
              @click="handleDeleteAdminClick"
              ref="deleteAdmin"
            >
              Delete Admin
            </a>
          </li>
        </ul>
        <div class="text-left">
          <DeleteAdminModal
            :user="user"
            @adminUpdate="handleAdminUpdate"
          />
          <RevokeAdminAccessModal
            :user="user"
            @adminUpdate="handleAdminUpdate"
          />
        </div>
      </div>
    </td>
  </tr>
</template>
<script>
  import DeleteAdminModal from 'components/admin/admin_users/DeleteAdminModal'
  import RevokeAdminAccessModal from 'components/admin/admin_users/RevokeAdminAccessModal'
  import Util from 'lib/util'
  import WbIcon from 'components/common/WbIcon'
  import WbButton from 'components/common/WbButton'
  import { mapActions, mapMutations } from 'vuex'

  export default {
    components: {
      DeleteAdminModal,
      RevokeAdminAccessModal,
      WbButton,
      WbIcon,
    },
    props: {
      isPendingAdmin: {
        type: Boolean,
      },
      managedPermissionsEnabled: {
        type: Boolean,
        default: false,
      },
      user: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        isResendingWelcomeEmail: false,
        isAddingEmployeeRecord: false,
      }
    },
    computed: {
      canEditAdmin () {
        return !this.user.is_cs
      },
      deleteAdminModalKey () {
        return `deleteAdminModal-${this.user.id}`
      },
      employeePath () {
        return this.$routes.employee_path({ id: this.user.employee_id })
      },
      revokeAdminModalKey () {
        return `revokeAdminModal-${this.user.id}`
      },
      shouldShowEmail () {
        return this.isPendingAdmin || !this.user.is_cs
      },
      shouldShowStaffProfileLink () {
        return this.is_employee
      },
      accessLevelOrPolicy () {
        return this.managedPermissionsEnabled ? this.user.policy_name : this.user.access_level
      },
    },
    methods: {
      ...mapActions({
        fetchResendWelcomeEmail: 'admin_users/resendWelcomeEmail',
        fetchAddEmployeeRecord: 'admin_users/addEmployeeRecord',
      }),
      ...mapMutations({
        setPageContextKeys: 'SET_PAGE_CONTEXT_KEYS',
      }),
      async handleCreateStaffMemberClick () {
        this.isAddingEmployeeRecord = true

        try {
          await this.fetchAddEmployeeRecord({ id: this.user.id })

          this.$emit('update')
        } catch {
          Util.showFlashNotice('Sorry, we could not create the staff record at this time.')
        } finally {
          this.isAddingEmployeeRecord = false
        }
      },
      handleDeleteAdminClick () {
        const keys = {}
        keys[this.deleteAdminModalKey] = true
        this.setPageContextKeys(keys)
      },
      handleAdminUpdate () {
        this.$emit('update')
      },
      handleRevokeAdminClick () {
        const keys = {}
        keys[this.revokeAdminModalKey] = true
        this.setPageContextKeys(keys)
      },
      async handleResendWelcomeEmail () {
        this.isResendingWelcomeEmail = true

        try {
          await this.fetchResendWelcomeEmail({ id: this.user.id })
        } catch {
          Util.showFlashNotice('Sorry, we were not able to resend the welcome email at this time.')
        } finally {
          this.isResendingWelcomeEmail = false
        }
      },
    },
  }
</script>
