import PubSub from 'lib/pub_sub'
import RemoteFormValidation from 'lib/remote_form_validation'
import FormsUtil from 'lib/util/forms'
import Util from 'lib/util'
import Routes from 'lib/routes'
import SelectInput from 'models/select2_input'
import SelectFieldView from 'views/common/select_field_view'
import NewAccountForm from 'models/superadmin/new_account_form'

export default class NewAccountView extends Marionette.LayoutView.extend({
  template: false,
  resourceName: 'account',

  ui: {
    form: 'form',
    submitButton: 'button[type="submit"]',
    cancelButton: '.cancel-btn',
  },

  regions: {
    cloneAccountFieldRegion: '#account-clone-field',
  },

  events: {
    'click @ui.cancelButton': '_cancelClick',
  },

  bindings: {
    'select#account_account_type': 'account_type',
    'input#account_name': 'name',
    'input#account_subdomain': 'subdomain',
    'input#account_permissions_feature': 'permissions_feature',
    'input#account_salesforce_id': 'settings_salesforce_id',
  },
}) {
  initialize() {
    this.model = new NewAccountForm()
    this._subscribeToPubSubEvents()
  }

  onRender() {
    this._initFieldViews()
    this.stickit()
    this._initRemoteFormValidation()

    $.runInitializers(this.$el)
  }

  _cancelClick() {
    Turbolinks.visit(Routes.accounts_path)
  }

  _initFieldViews() {
    const cloneAccountFieldView = new SelectFieldView({
      model: new SelectInput({
        field: {
          resourceName: this.resourceName,
          key: 'clone_account_id',
          label: 'Clone from existing',
          required: false,
          dataSearch: true,
          hint: 'All documents will be cloned from this existing account',
        },
        selectOptions: gon.accounts,
        value: null,
      }),
    })
    this.cloneAccountFieldRegion.show(cloneAccountFieldView)
    this.listenTo(cloneAccountFieldView, 'action:field_changed', this._onCloneAccountInputChange.bind(this))
  }

  // Manually update model when this field changes (stickit binding not available due to custom component)
  _onCloneAccountInputChange(field) {
    this.model.set({ clone_account_id: field.get('value') })
  }

  _initRemoteFormValidation() {
    // Initialize validators
    const formValidators = {}
    _.each(this.model.attributes, (val, attr) => {
      formValidators[`${this.resourceName}[${attr}]`] = { validators: { wbRemote: {} } }
    })

    new RemoteFormValidation({
      formEl: this.ui.form,
      partialValidation: false,
      httpMethod: 'POST',
      resourceName: this.resourceName,
      model: this.model,
      extraData: {
        pubsub_channel: this._pubSubChannel.name,
      },
      validations: formValidators,
      successCallback: this.onRemoteValidateSuccess.bind(this),
      failedCallback: this.onRemoteValidateFailed.bind(this),
      errorCallback: this.onRemoteValidateError.bind(this),
      path: Routes.accounts_path,
    })
  }

  _subscribeToPubSubEvents() {
    this._pubSubChannel = PubSub.subscribeToUserChannel({
      topic: 'clone_account',
      unique: true,
    })

    this._pubSubChannel.bind('clone_complete_with_no_documents', this._accountCloneSuccessCallback.bind(this))
    this._pubSubChannel.bind('document_clone_complete', this._accountCloneSuccessCallback.bind(this))
    this._pubSubChannel.bind('document_clone_started', this._accountCloneStartedCallback.bind(this))
  }

  onRemoteValidateSuccess(xhr) {
    FormsUtil.clearFormInvalidNotification(this.ui.form)

    this.submitBtnProgress('Creating account...')

    // Controller will return 201 if creating account without cloning from an existing account
    // That way we can do nothing and let ladda spin until account cloning is complete.
    if (xhr.status == 201) {
      Util.navigateAndShowAjaxFlashNotice(Routes.accounts_path, xhr)
    }
  }

  onRemoteValidateFailed(xhr) {
    this._stopLadda()
    FormsUtil.showFormInvalidNotification(this.ui.form)
  }

  onRemoteValidateError(xhr) {
    this._stopLadda()
    FormsUtil.showFormInvalidNotification(this.ui.form)
  }

  _accountCloneStartedCallback() {
    this.submitBtnProgress('Cloning documents...')
  }

  _accountCloneSuccessCallback() {
    this.submitBtnProgress('Finishing...')

    $(document).one("page:change", () => {
      NProgress.done()
      Util.showFlashNotice('Account created successfully')
      NProgress.remove()
    })

    NProgress.start()
    Turbolinks.visit(Routes.accounts_path)
  }

  _stopLadda() {
    this.ui.submitButton.data('ladda').stop()
  }

  onBeforeDestroy() {
    if (this._pubSubChannel) {
      PubSub.unsubscribe(this._pubSubChannel)
    }
  }

  // Changes submit button text for progress updates
  submitBtnProgress(text) {
    this.ui.submitButton.find('.ladda-label').text(text)
  }
};
