<template>
  <div>
    <BulkActionsDropdown
      @show-bulk-action="showBulkActionModal"
      :canDownload="canDownload"
    />

    <bulk-activate-orphan-container
      v-if="statusChangeAction"
      :action="statusChangeAction"
      :employeeIds="selectedEmployees"
      @clear-modal-data="clearModalData"
    />

    <BulkUpdateEmploymentsOrphanContainer
      v-if="showUpdateEmployment"
      :employeeIds="selectedEmployees"
      @clear-modal-data="clearModalData"
    />

    <BulkUpdateCustomFieldsOrphanContainer
      v-if="showUpdateCustomFields"
      :employeeIds="selectedEmployees"
      @clear-modal-data="clearModalData"
    />

    <BulkDownloadsOrphanContainer
      v-if="showDownloadSubmissions"
      :employeeIds="selectedEmployees"
      @clear-modal-data="clearModalData"
    />

    <BulkResetAssignmentsOrphanContainer
      v-if="showResetAssignments"
      :employeeIds="selectedEmployees"
      @clear-modal-data="clearModalData"
    />

    <BulkUpdateEmployeeGroupsOrphanContainer
      v-if="employeeGroupsAction"
      :action="employeeGroupsAction"
      :employeeIds="selectedEmployees"
      @clear-modal-data="clearModalData"
    />
  </div>
</template>

<script>
  import BulkActionsDropdown from 'components/common/BulkActionsDropdown'
  import BulkUpdateEmployeeGroupsOrphanContainer from 'components/employees/bulk_update_employee_groups/BulkUpdateEmployeeGroupsOrphanContainer'
  import BulkResetAssignmentsOrphanContainer from 'components/employees/bulk_reset_assignments/BulkResetAssignmentsOrphanContainer'
  import BulkUpdateCustomFieldsOrphanContainer from 'components/employees/bulk_update_custom_fields/BulkUpdateCustomFieldsOrphanContainer'
  import BulkDownloadsOrphanContainer from 'components/employees/bulk_downloads/BulkDownloadsOrphanContainer'
  import BulkEmployeeChangeStatusOrphanContainer from 'components/employees/bulk_change_status/BulkEmployeeChangeStatusOrphanContainer'
  import BulkUpdateEmploymentsOrphanContainer from 'components/employments/BulkUpdateEmploymentsOrphanContainer'
  import * as mutate from 'vuex/mutation_types'
  import { mapMutations } from 'vuex'

  export default {
    name: "bulk-actions",
    components: {
      BulkActionsDropdown,
      "bulk-activate-orphan-container": BulkEmployeeChangeStatusOrphanContainer,
      BulkUpdateEmploymentsOrphanContainer,
      BulkUpdateCustomFieldsOrphanContainer,
      BulkDownloadsOrphanContainer,
      BulkResetAssignmentsOrphanContainer,
      BulkUpdateEmployeeGroupsOrphanContainer,
    },
    props: {
      selectedEmployees: {
        type: Array,
        required: true,
      },

      canDownload: {
        type: Boolean,
        required: true,
      },
    },

    data() {
      return {
        collectionViewName: 'collection_view_staff_list',
        statusChangeAction: null,
        showUpdateEmployment: false,
        showUpdateCustomFields: false,
        showDownloadSubmissions: false,
        showResetAssignments: false,
        employeeGroupsAction: null,
      }
    },

    methods: {
      showBulkActionModal(bulkAction) {
        switch (bulkAction) {
          case 'activate-staff':
            this.statusChangeAction = 'activate'
            break
          case 'deactivate-staff':
            this.statusChangeAction = 'deactivate'
            break
          case 'update-employment':
            this.showUpdateEmployment = true
            break
          case 'update-custom-fields':
            this.showUpdateCustomFields = true
            break
          case 'download-submissions':
            if (this.canDownload)
            {this.showDownloadSubmissions = true}
            break
          case 'reset-assignments':
            this.showResetAssignments = true
            break
          case 'add-to-group':
            this.employeeGroupsAction = 'add'
            break
          case 'remove-from-group':
            this.employeeGroupsAction = 'remove'
            break
        }
      },

      clearModalData() {
        this.statusChangeAction = null
        this.showUpdateEmployment = false
        this.showUpdateCustomFields = false
        this.showDownloadSubmissions = false
        this.showResetAssignments = false
        this.employeeGroupsAction = null
      },

      ...mapMutations({
        setPageContextKeys: mutate.SET_PAGE_CONTEXT_KEYS,
      }),
    },
  }
</script>
