// A question-circle icon with clickable popover text bubble

<template>
  <span>
    <label>
      <a
        class="info-popover"
        :class="popoverClassName"
        v-popover="{ title, content: message }"
        role="button"
        tabindex="0"
        trigger="focus"
      >
        <WbIcon :type="iconType" />
      </a>
    </label>
  </span>
</template>

<script>
  import WbIcon from 'components/common/WbIcon'
  const CONFIG_BY_TYPE = {
    help: {
      icon: 'question-circle',
    },
    danger: {
      icon: 'exclamation-circle',
      className: 'popover-danger',
    },
  }

  export default {
    name: 'popover-icon',
    components: {
      WbIcon,
    },
    props: {
      message: {
        type: String,
        required: true,
      },
      title: {
        type: String,
        required: true,
      },
      type: {
        type: String,
        default: 'help',
      },
    },
    computed: {
      iconType () {
        return CONFIG_BY_TYPE[this.type].icon
      },
      popoverClassName () {
        return CONFIG_BY_TYPE[this.type].className || ''
      },
    },
  }
</script>
