// Standard WB way of outputting a date/time stamp

<template>
  <span>
    <span v-if="valid">
      <time class="wb-simple" ref="wbsimple">
        {{ dateTimeSimple }}
      </time>
      <span class="text-muted" v-if="includeTimeago">
        (
        <time class="timeago time-dist" :datetime="this.value" ref="timeago" />
        )
      </span>
    </span>
    <span v-else>
      {{ invalidMessage }}
    </span>
  </span>
</template>

<script>
  import moment from 'moment-timezone'
  import DateTime from 'lib/util/datetime'
  require('timeago')


  export default {
    name: 'wb-simple-date-time',
    props: {
      // Date/time value in ISO 8601 format
      value: {
        type: String,
      },

      // Options passed directly to Bootstrap $.tooltip
      tooltipOptions: {
        type: Object,
        required: false,
        default: () => { return {} },
      },

      includeTimeago: {
        type: Boolean,
        required: false,
        default: false,
      },

      invalidMessage: {
        type: String,
        required: false,
        default: 'Invalid date',
      },

      // Optional date format
      format: {
        type: String,
        required: false,
      },

      // Should the tooltip include a timestamp?
      includeTimestamp: {
        type: Boolean,
        required: false,
        default: true,
      },
    },

    computed: {
      // Uses calendar format (defined in moment.calendarFormat; see moment.js)
      dateTimeSimple() {
        return this.format
          ? this.momentObj.format(this.format)
          : this.momentObj.calendar()
      },

      valid() {
        return this.value !== undefined && this.momentObj.isValid()
      },

      momentObj() {
        return moment(this.value)
      },

      tooltipDate() {
        if (this.includeTimestamp)
        {return DateTime.formatDateTimeExt(this.value)}
        else
        {return DateTime.formatDateExt(this.value)}
      },
    },

    watch: {
      value: function(value) {
        this.$nextTick(() => {
          // Reinitialize tooltip
          $(this.$refs.wbsimple).tooltip('destroy')
          setTimeout(this.initTooltip, 500)  // HACK: destroy is async. give it some time to finish before re-init.

          // Update timeago if present
          if (this.includeTimeago) {
            $(this.$refs.timeago).timeago('update', value)
          }
        })
      },
    },

    mounted() {
      this.initTooltip()
      this.initTimeago()
    },

    methods: {
      initTooltip() {
        const tooltipOptions = Object.assign({}, {
          container: 'body',
          title: this.tooltipDate,
        }, this.tooltipOptions)
        $(this.$refs.wbsimple).tooltip(tooltipOptions)
      },

      initTimeago() {
        if (!this.includeTimeago) {
          return
        }

        $(this.$refs.timeago).timeago()
      },
    },
  }
</script>
