import PrerenderedTableCollectionView from 'views/common/prerendered_table_collection_view'
import DocumentRowItemView from 'views/documents/document_row_item_view'

export default PrerenderedTableCollectionView.extend({
  childView: DocumentRowItemView,

  onChildviewDocumentDestroy: function(args) {
    bootbox.confirm({
      title: 'Are you sure?',
      message: `<div class="alert alert-danger">If you delete <strong>${_.escape(args.model.get('name'))}</strong>,
                you will also delete any submission associated with it. There is no way to revert this action.</div>`,
      buttons: {
        cancel: {
          label: 'Cancel',
          className: 'btn-default',
        },
        confirm: {
          label: 'Delete',
          className: 'btn-danger',
        },
      },
      callback: (value) => {
        // Cancel chosen
        if (!value) { return };

        App.vent.trigger('document:destroy', args.model.id)
      },
    })
  },
})
