import Constants from 'lib/constants'

export default class AlienCitizenshipFieldsView extends Backbone.Marionette.ItemView.extend({
  template: '#i9-form-citizenship-alien-tmpl',

  ui: {
    arnInput: 'input[name="i9_submission[alien_reg_number]"]',
    i94AdmissionNumberInput: 'input[name="i9_submission[i94_admission_number]"]',
  },

  bindings: {
    'select.arn-type': 'arn_type',
    'input[name="i9_submission[alien_exp_date]"]': 'alien_exp_date',
    'input[name="i9_submission[alien_reg_number]"]': 'alien_reg_number',
    'input[name="i9_submission[i94_admission_number]"]': 'i94_admission_number',
    'input[name="i9_submission[foreign_passport_number]"]': 'foreign_passport_number',
    'select[name="i9_submission[country_of_issuance]"]': 'country_of_issuance',
  },
}) {
  initialize() {
    this.fvPlugin = this.getOption('rfv').fvPlugin
  }

  onRender() {
    this.stickit()
    $.runInitializers(this.$el)

    this.ui.arnInput.inputmask('Regex', { regex: Constants.I9_ARN_INPUTMASK })
  }

  onShow() {
    // alien_arn_options is a virtual field which receives the validation error messages for blank (no options picked)
    // and exclusivity (only one option allowed).
    this.fvPlugin.addField('i9_submission[alien_arn_options]', {
      validators: { wbRemote: {} },
      row: '.arn-options-wrapper',  // in order to highlight all options
    })

    this.fvPlugin.addField('i9_submission[alien_exp_date]', { validators: { wbRemote: {} } })
    this.fvPlugin.addField('i9_submission[alien_reg_number]', {
      validators: { wbRemote: {} },
      err: '.i9_submission_alien_reg_number .input-wrapper',  // so error message is not compressed due to multifield
    })
    this.fvPlugin.addField('i9_submission[i94_admission_number]', { validators: { wbRemote: {} } })
    this.fvPlugin.addField('i9_submission[foreign_passport_number]', { validators: { wbRemote: {} } })
    this.fvPlugin.addField('i9_submission[country_of_issuance]', { validators: { wbRemote: {} } })
  }

  onBeforeDestroy() {
    this.fvPlugin.removeField('i9_submission[alien_arn_options]')
    this.fvPlugin.removeField('i9_submission[alien_exp_date]')
    this.fvPlugin.removeField('i9_submission[alien_reg_number]')
    this.fvPlugin.removeField('i9_submission[i94_admission_number]')
    this.fvPlugin.removeField('i9_submission[foreign_passport_number]')
    this.fvPlugin.removeField('i9_submission[country_of_issuance]')
  }
}
