<template>
  <div>
    <h4>What will be the name of the new policy?</h4>
    <div class="row">
      <div class="col-md-6 row">
        <FormFieldInput
          ref="policyName"
          label="Policy Name"
          class="form-group-tight give-room-above"
          v-model="localPolicy.name"
          orientation="horizontal"
          type="text"
          name="name"
          hint="Policy names cannot be changed after they are created."
          helpPopoverText="Permission policies determine what access an Admin User has to employee data fields, forms submissions, and admin actions they are allowed to take within WorkBright. Permission policies apply to groups. Groups are assigned per Admin User and determine what Staff Members the Admin User will have access to."
          :errors="localPolicy.errors"
          :disabled="isEditing"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import BaseStep from "components/permissions/steps/BaseStep"
  import FormFieldInput from "components/common/FormFieldInput"

  export default {
    name: "StepName",

    components: {
      FormFieldInput,
    },

    extends: BaseStep,

    props: {
      isEditing: {
        type: Boolean,
        default: () => (false),
      },
    },

    data: function(){
      return {
        localPolicy: this.policy,
      }
    },

    methods: {
      valid: function() {
        // We need a policy name from the user to move forward.
        // To avoid adding unnecessary load to our servers, we're handling
        // the validation here.
        return !!this.localPolicy.name
      },

      invalidError: function() {
        if (!this.valid()) {
          this.$set(this.localPolicy.errors, "name", ["Policy Name is required"])
        }
      },
    },
  }
</script>
