import Scope from 'models/policy_generator/scope'
import Permission from 'models/policy_generator/permission'

// User Permission Set
// Permissions within a particular scope
class UserPermissionSet {
  constructor(name, scope, options = {}) {
    this.name = name

    this.scope = scope || new Scope()
    this.permissions = []
    this.appendPermissions(options.permissions || [])

    // Group permission set has View Employee by default
    if (this.name == 'group') {
      this.appendPermission(new Permission({ target_id: null, target_type: 'Employee', action: 'view', grant: true }))
    }
  }

  find(target_id, target_type, action) {
    return this.permissions.find((p) =>
      p.target_id == target_id &&
      p.target_type == target_type &&
      p.action == action,
    )
  }

  findWhere(query) {
    return this.permissions.filter((p) => {
      let match = true
      Object.keys(query).forEach((queryKey) => {
        if (p[queryKey] != query[queryKey]) { match = false }
      })
      return match
    })
  }

  appendPermission(permission) {
    const existingPermission = this.find(permission.target_id, permission.target_type, permission.action)
    if (existingPermission) {
      existingPermission.grant = permission.grant
    } else {
      this.permissions.push(permission)
    }

    this._mapDependencies()
  }

  appendPermissions(permissions) {
    permissions.forEach((permission) => this.appendPermission(permission))
  }

  // Find permissions by definitions, or create if not found
  findOrCreatePermissionsFromDefinitions(definitions) {
    const permissions = definitions.map((definition) => {
      let permission = this.find(definition.target_id, definition.target_type, definition.action)
      if (!permission) {
        permission = new Permission(definition)
        this.appendPermission(permission)
      }
      return permission
    })

    return permissions
  }

  togglePermission(permission, grant) {
    // Prevent granting if parent is not granted
    if (grant && permission.disabled()) {
      throw new Error("Cannot activate permission")
      return
    }

    permission.grant = grant

    // Disallow any dependencies if turned off
    if (!grant) {
      permission.dependents.forEach((d) => d.grant = false )
    }
  }

  removeWhere(query) {
    this.permissions = this.permissions.filter((p) => {
      let match = true
      Object.keys(query).forEach((key) => {
        if (query[key] != p[key]) { match = false };
      })
      return !match
    })
  }

  remove(permission) {
    this.permissions.splice(this.permissions.indexOf(permission), 1)
  }

  toJSON() {
    return {
      scope: this.scope ? this.scope.toJSON() : null,
      permissions: this.permissions.map((p) => p.toJSON()),
    }
  }

  _mapDependencies() {
    // REVIEW: Right now, dependencies are all based around viewing: 'view' and 'view_submission'.
    // It doesn't make sense that you can upload or delete something you can't view.
    // If requirements become sophisticated (e.g. can't delete unless you can update) or unrelated to viewing a record
    // (can't delete an employee unless you can create one), a different scheme may be necessary.

    // For any permission
    this.permissions.forEach((permission) => {
      if (['view', 'view_submission'].indexOf(permission.action) >= 0) { return } // Skip view permissions.
      if (permission.dependentOn) { return } // Dependency already mapped

      // Find the parent permission
      const parentPermission =
        this.find(permission.target_id, permission.target_type, 'view') ||
        this.find(permission.target_id, permission.target_type, 'view_submission')

      // Establish the dependency
      if (parentPermission) {
        parentPermission.addDependent(permission)
      }
    })
  }
}

export default UserPermissionSet
