<template>
  <DashboardWidget
    :widgetTitle="widgetTitle"
    :errorMsg="errorMsg"
    :lastUpdated="lastUpdated"
    @refresh="fetch(true)"
  >
    <v-wait :for="source">
      <template #waiting>
        <Spinner :showLongWaitMessage="true" />
      </template>
      <WbBreakdownChart
        v-if="!errorMsg.length && chartData"
        :legendData="legendData"
        :chartData="chartData"
        :listViewPath="this.$routes.onboarding_i9_index_path"
        modelNameHumanized="Form I-9"
        @section-click="handleSectionClick"
      />
    </v-wait>
  </DashboardWidget>
</template>

<script>
  import WbBreakdownChart from 'components/common/WbBreakdownChart'
  import { BREAKDOWN_VARIANTS } from 'components/admin/dashboard/BreakdownChartConstants'
  import Spinner from 'components/common/Spinner'
  import DashboardWidget from 'components/admin/dashboard/DashboardWidget'
  import UrlUtil from 'lib/util/url'
  import DashboardFetch from 'components/mixins/DashboardFetch'

  const widgetTitle = 'Form I-9 Summary'
  const source = 'dashboard_form_i9_query'

  export default {
    name: 'form-i9-summary-container',
    components: {
      WbBreakdownChart,
      Spinner,
      DashboardWidget,
    },
    mixins: [DashboardFetch],
    data() {
      return {
        widgetTitle: widgetTitle,
        source: source,
        legendData: [
          {
            legend_key: 'countersigned',
            message: 'countersigned and complete',
            path: `${UrlUtil.urlWithParams(this.$routes.onboarding_i9_index_path, {
              filter: 'countersigned',
            })}`,
            variant: BREAKDOWN_VARIANTS.COMPLETE,
          },
          {
            legend_key: 'pending',
            message: 'awaiting review of Employee Section 1',
            path: `${UrlUtil.urlWithParams(this.$routes.onboarding_i9_index_path, {
              filter: 'pending',
            })}`,
            variant: BREAKDOWN_VARIANTS.NEEDS_ATTENTION,
          },
          {
            legend_key: 'pending_countersign',
            message: 'awaiting Employer Section 2 completion',
            path: `${UrlUtil.urlWithParams(this.$routes.onboarding_i9_index_path, {
              filter: 'pending_countersign',
            })}`,
            variant: BREAKDOWN_VARIANTS.NEEDS_ATTENTION,
          },
          {
            legend_key: 'late_missing_rejected',
            message: 'missing or rejected and late',
            path: `${UrlUtil.urlWithParams(this.$routes.onboarding_i9_index_path, {
              filter: 'missing_rejected',
              expiration: 'late_missing_rejected',
            })}`,
            variant: BREAKDOWN_VARIANTS.NEEDS_IMMEDIATE_ATTENTION,
          },
        ],
      }
    },

    methods: {
      handleSectionClick(section) {
        Turbolinks.visit(section.path)
      },
    },
  }
</script>
