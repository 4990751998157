<template>
  <div
    class="panel panel-default fields-panel"
    v-if="hasSufficientData"
    ref="panel"
  >
    <div class="panel-heading">
      <h4 class="panel-title">Form Responses</h4>
    </div>
    <table
      class="table table-responsive panel-info-table"
    >
      <tbody>
        <tr
          v-for="(item, index) in responses"
          :key="index"
        >
          <th
            scope="row"
          >
            {{  item.label }}
          </th>
          <td>
            {{ item.value }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
  export default {
    props: {
      responses: {
        type: Array,
      },
    },
    computed: {
      hasSufficientData () {
        return this.responses?.length
      },
    },
  }
</script>
