<template>
  <div>
    <ScheduleOnboardingModal :employee="employee" :employment="employment" />
    <div class="panel panel-simple">
      <div class="panel-heading with-action-buttons">
        <h3 class="panel-title">
          Notification Status
        </h3>
      </div>
      <div class="panel-body">
        <p ref="status-message">
          {{ statusMessage }}
        </p>
      </div>
      <table class="table info-table panel-info-table">
        <tbody>
          <tr class="info-item">
            <td class="col-md-5">
              Notification Start Date:
            </td>
            <td class="col-md-7">
              <WbSimpleDateTime
                v-if="!isOnboardingStartDateRestricted"
                :value="employment.onboarding_start_date || ''"
                invalidMessage='Unscheduled'
                :format="$constants.DATE_FORMAT"
                ref="notification-start"
              />
              <BlockedContentIcon v-else />
              <div class="float-right">
                <WbButton
                  label="Schedule"
                  context="primary"
                  @click="schedule"
                  ref="scheduleButton"
                  permissionModel="employee_seasons"
                  :permissionRecordId="employment.id"
                  permissionAction="manage"
                >
                  Schedule Notifications
                </WbButton>
              </div>
            </td>
          </tr>
          <tr class="info-item">
            <td class="col-md-5">
              Initial Notification Sent At:
            </td>
            <td class="col-md-7">
              <WbSimpleDateTime
                :value="employment.onboarding_email_sent_at || ''"
                :invalidMessage="emailNotSent"
                :format="$constants.DATE_FORMAT"
                ref="notification-sent"
              />
              <PopoverIcon
                title="Initial Notification Sent At"
                message="This is the date when the first notification is sent to the staff member. It is set when the first notification is sent and does not change unless the staff member is deactivated or their notifications are turned off."
              />
            </td>
          </tr>
          <tr class="info-item">
            <td class="col-md-5">
              Currently Receiving Notifications:
            </td>
            <td class="col-md-7" ref="receiving-notifications">
              <div v-if="active">
                {{ employment.onboarding_now ? 'Yes' : 'Not Yet' }}
              </div>
              <div v-else>
                No
              </div>
            </td>
          </tr>
          <tr
            ref="ri9-notification-methods"
            v-if="shouldShowRI9NotificationMethodsToggle"
            class="info-item"
          >
            <td class="col-md-5">
              Remote I-9 Notification Method:
            </td>
            <td class="col-md-7">
              <div class="d-flex">
                <EmployeeNotificationMethodsToggle
                  :disabled="isLoadingRI9NotificationPreferences"
                  type="ri9"
                  :value="employeeRI9NotificationMethods"
                  @input="handleRI9NotificationChange"
                />
                <PopoverIcon
                  v-if="!hasPhoneNumber"
                  class="pt-1"
                  title="No Staff Phone Number"
                  type="danger"
                  :message="$locale.t('employees.notificationMethods.popoverText')"
                />
              </div>
            </td>
          </tr>
          <tr
            ref="onboarding-notification-methods"
            v-if="shouldShowOnboardingNotificationMethodsToggle"
            class="info-item"
          >
            <td class="col-md-5">
              Onboarding Notification Method:
            </td>
            <td class="col-md-7">
              <div class="d-flex">
                <EmployeeNotificationMethodsToggle
                  :disabled="isLoadingOnboardingNotificationPreferences"
                  type="onboarding"
                  :value="employeeOnboardingNotificationMethods"
                  @input="handleOnboardingNotificationChange"
                />
                <PopoverIcon
                  v-if="!hasPhoneNumber"
                  class="pt-1"
                  title="No Staff Phone Number"
                  type="danger"
                  :message="$locale.t('employees.notificationMethods.popoverText')"
                />
              </div>
            </td>
          </tr>
          <tr
            v-if="hasValidSmsStatus"
            class="info-item"
            ref="sms-status"
          >
            <td class="col-md-5">
              Text Messaging Status:
            </td>
            <td class="col-md-7">
              {{ smsStatus }}
            </td>
          </tr>
          <tr class="info-item">
            <td class="col-md-5">
              Last Sign In:
            </td>
            <td class="col-md-7">
              <WbSimpleDateTime
                :value="currentSignInAt || ''"
                invalidMessage="Never signed in"
                :format="$constants.DATE_FORMAT"
                ref="last-sign-in"
              />
              <div class="float-right" v-if="allowResend">
                <!-- // TODO: Sometimes visible when you cannot resend, like no email even sent to user
                  // TODO: Hide stuff if not permitted, here and everywhere -->
                <LaddaButton @click="resend" ref="resendButton">
                  Resend Welcome {{ preferredNotificationName }}
                </LaddaButton>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex'
  import * as mutate from 'vuex/mutation_types'
  import Constants from 'lib/constants'
  import EmployeeSmsStatusMixin from 'components/mixins/employee/EmployeeSmsStatusMixin'
  import Util from 'lib/util'
  import LaddaButton from 'components/common/LaddaButton'
  import ScheduleOnboardingModal from 'components/employees/ScheduleOnboardingModal'
  import WbButton from 'components/common/WbButton'
  import WbSimpleDateTime from 'components/common/WbSimpleDateTime'
  import PopoverIcon from 'components/common/PopoverIcon'
  import BlockedContentIcon from 'components/common/BlockedContentIcon'
  import EmployeeNotificationMethodsToggle from 'components/employees/notification/EmployeeNotificationMethodsToggle'
  import auth from 'lib/util/auth'
  import moment from 'moment'

  export default {
    name: 'onboarding-status-panel',
    components: {
      BlockedContentIcon,
      WbButton,
      LaddaButton,
      ScheduleOnboardingModal,
      WbSimpleDateTime,
      PopoverIcon,
      EmployeeNotificationMethodsToggle,
    },

    props: {
      employeeId: {
        requred: true,
        type: Number,
      },
      currentSignInAt: {
        requred: true,
        type: String,
      },
    },

    data () {
      return {
        isLoadingRI9NotificationPreferences: false,
        isLoadingOnboardingNotificationPreferences: false,
      }
    },
    computed: {
      allowResend() {
        return this.active
          && this.osdRelative == -1
          && !this.signedIn
          && !!this.employment.onboarding_email_sent_at
      },

      statusMessage() {
        const firstName = this.employee.first_name
        const dateStr = this.osdMoment.format(Constants.DATE_FORMAT)
        let message = ''

        // Active; Onboarding not scheduled; Not signed in
        if (this.active && this.osdRelative == 0 && !this.signedIn) {
          message = `${firstName} is not scheduled to start receiving notifications.`
        // Active; Onboarding not scheduled; Not signed in
        } else if (this.active && this.osdRelative == 0 && this.signedIn) {
          message = `${firstName} is not scheduled to start receiving notifications. They have previously signed in, but will not receive further notifications until scheduled.`
        // Active; Onboarding scheduled in the past; Not signed in
        } else if (this.active && this.osdRelative == -1 && !this.signedIn) {
          message = `${firstName} began receiving notifications ${dateStr}.`
        // Active; Onboarding scheudled in the past; Signed in
        } else if (this.active && this.osdRelative == -1 && this.signedIn) {
          message = `${firstName} began receiving notifications ${dateStr}.`
        // Active; Onboarding scheduled in the future; Not signed in
        } else if (this.active && this.osdRelative == 1 && !this.signedIn) {
          message = `${firstName} will begin receiving notifications ${dateStr}. They will not receive notifications until this date.`
        // Active; Onboarding scheduled in the future; Signed in
        } else if (this.active && this.osdRelative == 1 && this.signedIn) {
          message = `${firstName} will begin receiving notifications ${dateStr}. They have previously signed in, but will not receive further notifications until this date.`
        // Inactive
        } else if (!this.active) {
          message = `${firstName} is inactive and will not receive notifications.`
        }
        return message
      },

      employee() {
        return this.employeesGet(this.employeeId)
      },

      employment() {
        return this.employmentsFindBy({ employee_id: this.employeeId })[0] || {}
      },

      hasPhoneNumber() {
        return !!(this.employee && this.employee.phone)
      },

      active() {
        return this.employment.status == 'active'
      },

      isOnboardingStartDateRestricted() {
        return auth.isBlockedContent(this.employment.onboarding_start_date)
      },

      // Relative onboarding_start_date
      // Earlier or today: -1, Later than today: 1, Unscheduled: 0
      osdRelative() {
        return this.osdMoment.isValid() ?
          (this.osdMoment.startOf('day') <= moment().startOf('day') ? -1 : 1)
          : 0
      },

      osdMoment() {
        return moment(this.employment.onboarding_start_date)
      },

      signedIn() {
        return !!this.currentSignInAt
      },

      pendingNotification() {
        // If the onboarding email hasn't been sent yet, is it due to be sent today?
        return !this.employment.onboarding_email_sent_at && this.osdMoment.startOf('day').isSame(moment().startOf('day'))
      },

      emailNotSent() {
        return (this.pendingNotification ? 'Sending soon...' : 'Not Sent')
      },

      shouldShowRI9NotificationMethodsToggle() {
        return this.currentAccount.ri9SmsEnabled
      },

      employeeRI9NotificationMethods() {
        return this.employee.ri9_notification_methods || undefined
      },

      shouldShowOnboardingNotificationMethodsToggle() {
        return this.currentAccount.onboardingSmsEnabled
      },

      employeeOnboardingNotificationMethods() {
        return this.employee.onboarding_notification_methods || undefined
      },

      // Returns either 'Email' or 'Text' depending on employee's onboarding_notification_methods
      preferredNotificationName() {
        const preferredMethods = this.employeeOnboardingNotificationMethods
        if (preferredMethods && preferredMethods.includes('sms')) {
          return 'Text'
        } else {
          return 'Email'
        }
      },

      ...mapGetters({
        currentAccount: 'account/current',
        employeesGet: 'employees/get',
        employmentsFindBy: 'employments/findBy',
      }),
    },

    methods: {
      async handleRI9NotificationChange (values) {
        this.isLoadingRI9NotificationPreferences = true
        await this.saveNotificationMethodsByType(values, 'ri9')
        this.isLoadingRI9NotificationPreferences = false
      },

      async handleOnboardingNotificationChange (values) {
        this.isLoadingOnboardingNotificationPreferences = true
        await this.saveNotificationMethodsByType(values, 'onboarding')
        this.isLoadingOnboardingNotificationPreferences = false
      },

      async saveNotificationMethodsByType (values, type) {
        try {
          await this.saveNotificationMethods({
            employeeId: this.employeeId,
            notificationMethods: values,
            notificationType: `${type}_notification_methods`,
          })
          Util.showFlashNotice(this.$locale.t(`employees.${type}NotificationMethods.update.success`))
        } catch (e) {
          Util.genericAjaxError("An error occurred.", e)
        }
      },

      resend() {
        this.resendWelcomeEmail({ employeeId: this.employeeId }).
          then(() => {
            Util.showFlashNotice(`Welcome ${this.preferredNotificationName.toLowerCase()} resent to ${this.employee.email}`)
          }).catch((xhr) => {
            Util.genericAjaxError('An error occurred resending the welcome email.', xhr)
          }).finally(() => this.$refs.resendButton.stop())
      },

      schedule() {
        this.setPageContextKeys({ schedule_onboarding: true })
      },

      ...mapActions({
        resendWelcomeEmail: 'employees/resendWelcomeEmail',
        saveNotificationMethods: 'employees/saveNotificationMethods',
      }),

      ...mapMutations({
        setPageContextKeys: mutate.SET_PAGE_CONTEXT_KEYS,
      }),
    },

    mixins: [
      EmployeeSmsStatusMixin,
    ],
  }
</script>
