<script>
  import { mapGetters } from 'vuex'

  export default {
    props: {
      policy: {
        type: Object,
      },

      navDirection: {
        type: String,
        required: false,
        default: 'next',
      },

      firstName: {
        type: String,
        required: false,
      },
    },

    computed: {
      groupNames() {
        const permissionSet = this.policy.groupPermissionSet()
        const selectedGroupIds = permissionSet.scope.value
        return selectedGroupIds.map((id) => this.getGroup(id).name)
      },

      ...mapGetters({
        getGroup: 'groups/get',
      }),
    },

    methods: {
      valid() {
        return true
      },

      // Show any invalid messaging
      invalidError() {
      },

      beforeAdvance() {},
    },
  }
</script>
