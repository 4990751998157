import UserImportPageHeader from 'components/user_imports/UserImportPageHeader'
import { pureOrphanVue } from 'lib/vue/pureOrphanVue'

export default Marionette.LayoutView.extend({
  template: '#layout-new-user-import',
  regions: {
    uploaderRegion: '.uploader',
  },

  onRender: function() {
    pureOrphanVue(UserImportPageHeader, "component.vue-user-import-header", {
      props: {
        wizardStep: 'upload',
      },
    })
  },
})
