<template>
  <div class="document-submission-index-root">
    <div class="d-flex justify-content-between align-items-center">
      <PageHeader
        title="Inbox"
      />
      <WbSearchInput
        class="pull-right"
        :label="null"
        placeholder="Search"
        @input="handleSearchInput"
        :autofocus="true"
        :value="query"
        :throttle="true"
        ref="search"
      />
    </div>
    <Spinner v-if="isLoading" ref="loader" />
    <template v-else>
      <template v-if="hasSufficientData">
        <DocumentSubmissionsInboxTable
          ref="inboxTable"
          :submissions="submissions"
          :sort="sort"
          @sort="handleSortUpdate"
          @submissionSelected="handleSubmissionSelected"
        />
        <WbListPaginationFooter
          ref="pagination"
          :previousPage="previousPage"
          :nextPage="nextPage"
          :lastPage="lastPage"
          :totalItems="totalItems"
          :indexStart="indexStart"
          :indexEnd="indexEnd"
          @pageChange="handlePageChange"
        />
      </template>
      <AlertMessage
        v-else
        variant="info"
        message="There are currently no items to display"
        ref="noDataMessage"
      />
    </template>
    <DocumentSubmissionSlideout
      ref="DocumentSubmissionSlideout"
      :shown="shouldShowModal"
      :submissionId="selectedSubmissionId"
      :allowDownload="allowDownload"
      @hidden="handleModalHiddden"
      @reviewed="openNextSubmission"
    />
  </div>
</template>
<script>
  import AlertMessage from 'components/common/AlertMessage'
  import DocumentSubmissionsInboxTable from 'components/document_submissions/DocumentSubmissionsInboxTable'
  import DocumentSubmissionSlideout from 'components/document_submissions/DocumentSubmissionSlideout'

  import PageHeader from 'components/common/PageHeader'
  import PersistedPageState from 'lib/persisted_page_state'
  import Spinner from 'components/common/Spinner'
  import WbSearchInput from 'components/common/WbSearchInput'
  import WbListPaginationFooter from 'components/common/WbListPaginationFooter'
  import { mapActions, mapGetters } from 'vuex'
  import Util from 'lib/util'

  const DEFAULT_SORT_DIR = 'asc'
  const DEFAULT_SORT_FIELD = 'staff_member'

  export default {
    name: 'the-document-submissions-inbox-root',
    components: {
      AlertMessage,
      DocumentSubmissionsInboxTable,
      DocumentSubmissionSlideout,
      PageHeader,
      Spinner,
      WbListPaginationFooter,
      WbSearchInput,
    },
    data () {
      return {
        isLoading: false,
        persistedState: new PersistedPageState(),
        qsUpdate: 0,
        query: '',
        selectedSubmissionId: null,
      }
    },
    computed: {
      ...mapGetters({
        currentAccount: 'account/current',
        submissions: 'onboarding_submissions/collection',
        pagination: 'onboarding_submissions/pagination',
      }),
      allowDownload () {
        return !this.currentAccount.disableDownloadAllFormsEnabled
      },
      hasSufficientData () {
        return this.submissions && this.submissions.length > 0
      },
      indexEnd () {
        let val = 0
        if (this.pagination) {
          val = Math.min(this.indexStart + this.pagination.per_page - 1, this.totalItems)
        }
        return val
      },
      indexStart () {
        let val = 0
        if (this.pagination) {
          val = (this.pagination.page - 1) * this.pagination.per_page + 1
        }
        return val
      },
      lastPage () {
        return this.pagination?.total_pages
      },
      nextPage () {
        return this.pagination?.next_page
      },
      sortDirection: {
        get() {
          this.qsUpdate
          return this.persistedState.fetchPageState('sort_dir') || DEFAULT_SORT_DIR
        },
        set(newValue) {
          this.persistedState.savePageState('sort_dir', newValue)
          this.qsUpdate++
        },
      },
      sortField: {
        get() {
          this.qsUpdate
          return this.persistedState.fetchPageState('sort') || DEFAULT_SORT_FIELD
        },
        set(newValue) {
          this.persistedState.savePageState('sort', newValue)
          this.qsUpdate++
        },
      },
      sort() {
        return {
          direction: this.sortDirection,
          field: this.sortField,
        }
      },
      page: {
        get () {
          this.qsUpdate
          return this.persistedState.fetchFromQueryString('page') || 1
        },
        set (newValue) {
          this.persistedState.savePageState('page', newValue)
          this.qsUpdate++
        },
      },
      previousPage () {
        return this.pagination?.previous_page
      },
      totalItems () {
        return this.pagination?.total_items
      },
      shouldShowModal () {
        return this.selectedSubmissionId !== null
      },
    },
    watch: {
      query(newValue) {
        if (newValue.length >= 2 || newValue.length == 0) {
          this.requestAssignments()
        }
      },
    },
    created () {
      this.requestAssignments()
    },
    methods: {
      ...mapActions({
        fetchSubmissions: 'onboarding_submissions/fetchSubmissions',
      }),
      handleSortUpdate(field) {
        this.page = 1
        if (field === this.sort.field) {
          this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc'
        } else {
          this.sortField = field
          this.sortDirection = DEFAULT_SORT_DIR
        }
        this.requestAssignments()
      },
      handlePageChange (page) {
        this.page = page
        this.requestAssignments()
      },
      handleSearchInput(value) {
        this.page = 1
        this.query = value
      },
      handleModalHiddden () {
        this.selectedSubmissionId = null
      },
      handleSubmissionSelected (submissionId) {
        this.selectedSubmissionId = submissionId
      },
      async requestAssignments () {
        this.isLoading = true
        try {
          await this.fetchSubmissions({
            page: this.page,
            sort: this.sort,
            query: this.query,
          })
        } catch (xhr) {
          Util.genericAjaxError('An error occurred fetching submissions.', xhr)
        } finally {
          this.isLoading = false
        }
      },
      openNextSubmission(currentSubmissionId) {
        const currentIndex = this.submissions.findIndex((el) => el.id == currentSubmissionId)
        const nextIndex = currentIndex + 1
        if (nextIndex < this.submissions.length) {
          this.handleSubmissionSelected(this.submissions[nextIndex].id)
        } else {
          this.$refs.DocumentSubmissionSlideout.handleModalHide()
        }
      },
    },
  }
</script>
