import I9Submission from 'models/document_submissions/i9_submission'

export default class EmployerReviewForm extends Backbone.Model.extend({
  defaults: {
    employer_title: '',
    employer_first_name: '',
    employer_last_name: '',
    business_name: '',
    business_street: '',
    business_city: '',
    business_state: '',
    business_zip: '',
    signature_data_uri: '',
    late_submission_ids: null,
    used_alt_procedure: '0', // need this default or it comes through as true. :shrug:
  },
}) {
  parse(data) {
    // Deep serialize submissions
    const submissionModels = _(data.submissions).map(function(submission) {
      return new I9Submission(submission, { parse: true })
    })
    data.submissions = new Backbone.Collection(submissionModels)

    return data
  }

  toJSON(options) {
    const attrs = _.clone(this.attributes)

    // the submissions collection contains full I9 models. But when submitting this form, we only really need a
    // handful of fields.
    attrs.submissions = this.get('submissions').map(function(submission) {
      // NOTE: We defer to the submission.toJSON() so that we don't have to redo any specialization serialization,
      // e.g. converting `moment` objects to proper ISO strings.
      const submissionJSON = submission.toJSON()

      return {
        id: submissionJSON.id,
        employee_start_date: submissionJSON.employee_start_date,
        employer_certify_date: submissionJSON.employer_certify_date,
        update_profile_start_date: submissionJSON.update_profile_start_date,
        additional_info: submissionJSON.additional_info,
        citizenship_designation: submissionJSON.citizenship_designation,
        late_reason: submissionJSON.late_reason,
        late_reason_other: submissionJSON.late_reason_other,
        used_alt_procedure: attrs.used_alt_procedure, // just a special top-level attribute
      }
    })

    return attrs
  }
}
