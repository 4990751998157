import { getRoute } from 'lib/routes'
import Vue from 'vue'

function state() {
  return {
    tree: [],
    collection: {},
  }
};

const getters = {
  get: (state) => (field_key) => {
    return state.collection[field_key]
  },

  // Filter to Custom Fields list
  customFields: (state) => {
    const node = state.tree.find((n) => n.label == 'Custom Profile Fields')
    return node ? node.items : []
  },

  tree: (state) => {
    return state.tree
  },
}

const mutationTypes = {
  SET_TREE: 'SET_TREE',
  SET_FIELD_RECORD: 'SET_FIELD_RECORD',
}

const mutations = {
  [mutationTypes.SET_TREE]: (state, tree) => {
    state.tree = tree
  },

  [mutationTypes.SET_FIELD_RECORD]: (state, field) => {
    Vue.set(state.collection, field.field_key, field)
  },
}

const actions = {
  loadInitialData: ({ commit }, tree) => {
    // Commit the entire tree
    commit(mutationTypes.SET_TREE, tree)

    // Traverse the tree and load up each field_key into the collection for fast searching
    // Non-recusrive tree traversal algorithm
    let nodes = tree.slice() // Load the nodes array with the trunk node
    while (nodes.length > 0) { // Anything left in the nodes array?
      const node = nodes.shift() // Take the first item from the nodes array
      if (node.items && node.items.length > 0) {
        nodes = nodes.concat(node.items) // This is a branch. Load all items into the nodes array
      } else {
        commit(mutationTypes.SET_FIELD_RECORD, node) // This is a leaf RepresentableField
      }
    }
  },

  fetch: ({ dispatch }, filters) => {
    const route = `${getRoute('Api.Private.tree_fields_path')}/${filters.join('/')}`

    return new Promise((resolve, reject) => {
      $.ajax({
        method: 'GET',
        url: route,
      }).then((tree) => {
        dispatch(
          'loadInitialData',
          tree,
        )
        resolve()
      }).fail((xhr) => {
        reject(xhr)
      })
    })
  },
}


export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
