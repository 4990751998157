<template>
  <WbSearchInput
    :label='label'
    :placeholder='placeholder'
    :autofocus='autofocus'
    v-model="value"
    @input="handleSearchEntry"
  />
</template>

<script>
  import BaseFilter from 'components/common/collection_view_filters/BaseFilter'
  import WbSearchInput from 'components/common/WbSearchInput'

  export default {
    name: 'search-filter',
    components: {
      WbSearchInput,
    },
    extends: BaseFilter,
    props: {
      label: {
        type: String,
        default: 'Search',
      },

      placeholder: {
        type: String,
        default: 'Search',
      },

      autofocus: {
        type: Boolean,
        default: true,
      },

      collectionViewName: {
        type: String,
        required: true,
      },
    },

    methods: {
      handleSearchEntry(entry) {
        this.$emit("search-input", entry)
      },
    },
  }
</script>
