<template>
  <tr>
    <td>
      {{ policy.name }}
      <span
        v-if="policy.is_default"
        class="label label-default"
        ref="defaultLabel"
      >
        Default
      </span>
    </td>
    <td
      class="text-right"
      ref="count">
      {{ policyCount }}
    </td>
    <td class="text-right">
      <WbButton
        context="default"
        class="btn-sm"
        @click="handlePreviewClick(policy.id)"
      >
        Preview
      </WbButton>
    </td>
    <td>
      <div class="dropdown">
        <WbButton
          context="default"
          class="btn-sm dropdown-toggle"
          data-toggle="dropdown"
        >
          <WbIcon
            type="caret-down"
            class="fa-fw right"
          />
        </WbButton>
        <ul class="dropdown-menu">
          <li>
            <a :href="$routes.managed_access_policy_template_edit_path({id: policy.id})">Edit</a>
          </li>
        </ul>
      </div>
    </td>
  </tr>
</template>
<script>
  import WbIcon from 'components/common/WbIcon'
  import WbButton from 'components/common/WbButton'

  export default {
    components: {
      WbButton,
      WbIcon,
    },
    props: {
      policy: {
        type: Object,
        required: true,
      },
    },
    computed: {
      policyCount () {
        return this.policy.count || "--"
      },
    },
    methods: {
      handlePreviewClick (policyId) {
        this.$emit('policySelected', policyId)
      },
    },
  }
</script>
