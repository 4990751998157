// Represents a HTML <select> field
// Renders options as <option value="{value}">{name}</option>
//
//    model - Select2Input, select2_input.js
export default Marionette.ItemView.extend({
  template: '#select-field-input-tmpl',

  bindings: {
    '.select-input': 'value',
  },

  modelEvents: {
    change: 'fieldChanged',
  },

  initialize: function () {
    this.selectOptions = this.model.get('selectOptions')
  },

  onRender: function() {
    this.stickit()

    this.fieldChanged()
    $.runInitializers(this.$el)
  },

  fieldChanged: function () {
    this.trigger('action:field_changed', this.model)
  },

  templateHelpers: {
    getFieldChoicesAsOptionsForSelect: function (options) {
      let html = ""

      _.defaults(options, { includeEmptyOption: true })

      if (options.includeEmptyOption && !_.isEmpty(this.selectOptions)) {
        html += "<option value=''></option>"
      }

      _(this.selectOptions).each(function(item) {
        html += `<option value="${_.escape(item.value || item.id)}">${_.escape(item.name)}</option>`
      })

      return html
    },
  },
})
