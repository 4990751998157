<template>
  <div class="row">
    <div class="col-lg-9">
      <p class="give-room-above">
        {{ $locale.t('integrations.adp.config.mappingOptions') }}
      </p>
      <div class="panel gray-bg basic pt-4 mt-0" @click="clearErrors">
        <h4>{{ $locale.t('integrations.adp.config.internalPayroll.title') }}</h4>
        <p>{{ $locale.t('integrations.adp.config.internalPayroll.description') }}</p>
        <FormFieldRadio
          v-model="formData.payroll_internal_mapping"
          :options="internalPayrollOptions"
          :required="true"
          :errors="errors"
          name="payroll_internal_mapping"
          ref="internalFieldOptions"
          orientation="vertical"
        />
      </div>
      <div class="panel gray-bg basic pt-4" @click="clearErrors">
        <h4>{{ $locale.t('integrations.adp.config.staffPayroll.title') }}</h4>
        <p>{{ $locale.t('integrations.adp.config.staffPayroll.description') }}</p>
        <FormFieldRadio
          v-model="formData.payroll_staff_mapping"
          :options="staffPayrollOptions"
          :required="true"
          :errors="errors"
          name="payroll_staff_mapping"
          ref="staffFieldOptions"
          orientation="vertical"
        />
      </div>
      <div class="text-right">
        <LaddaButton ref="submit" @click="submit">
          {{ $locale.t('common.buttons.saveAndContinue') }}
        </LaddaButton>
      </div>
    </div>
  </div>
</template>

<script>
  import FormFieldRadio from 'components/common/FormFieldRadio'
  import LaddaButton from 'components/common/LaddaButton'
  import Util from 'lib/util'

  export default {
    name: 'the-adp-payroll-form-options-root',

    components: {
      FormFieldRadio,
      LaddaButton,
    },

    props: {
      integration: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        formData: {
          payroll_staff_mapping: null,
          payroll_internal_mapping: null,
        },
        staffPayrollOptions: [
          {
            label: this.$locale.t('integrations.adp.config.staffPayroll.options.workbrightForm.optionText'),
            popoverTitle: this.$locale.t('integrations.adp.config.staffPayroll.options.workbrightForm.popoverTitle'),
            popoverText: this.$locale.t('integrations.adp.config.staffPayroll.options.workbrightForm.popoverText'),
            value: false,
            html: true,
          },
          {
            label: this.$locale.t('integrations.adp.config.staffPayroll.options.customForm.optionText'),
            popoverTitle: this.$locale.t('integrations.adp.config.staffPayroll.options.customForm.popoverTitle'),
            popoverText: this.$locale.t('integrations.adp.config.staffPayroll.options.customForm.popoverText'),
            value: true,
            html: true,
          },
        ],
        internalPayrollOptions: [
          {
            label: this.$locale.t('integrations.adp.config.internalPayroll.options.workbrightStandardFields.optionText'),
            popoverTitle: this.$locale.t('integrations.adp.config.internalPayroll.options.workbrightStandardFields.popoverTitle'),
            popoverText: this.$locale.t('integrations.adp.config.internalPayroll.options.workbrightStandardFields.popoverText'),
            value: false,
            html: true,
          },
          {
            label: this.$locale.t('integrations.adp.config.internalPayroll.options.customFields.optionText'),
            popoverTitle: this.$locale.t('integrations.adp.config.internalPayroll.options.customFields.popoverTitle'),
            popoverText: this.$locale.t('integrations.adp.config.internalPayroll.options.customFields.popoverText'),
            value: true,
            html: true,
          },
        ],
        errors: {},
      }
    },

    computed: {
      integrationID() {
        return this.integration.id
      },

      isStaffOptionBlank() {
        return this.formData.payroll_staff_mapping == null
      },

      isInternalOptionBlank() {
        return this.formData.payroll_internal_mapping == null
      },

      hasErrors() {
        return Object.keys(this.errors).length
      },
    },

    created() {
      // Assign existing selections when component loads
      this.formData.payroll_internal_mapping = this.integration.data.payroll_internal_mapping
      this.formData.payroll_staff_mapping = this.integration.data.payroll_staff_mapping
    },

    methods: {
      validate() {
        // Add errors if either field is blank
        if (this.isStaffOptionBlank) {
          this.errors = {...this.errors, payroll_staff_mapping: ['Please select an option']}
        }

        if (this.isInternalOptionBlank) {
          this.errors = {...this.errors, payroll_internal_mapping: ['Please select an option']}
        }
      },

      submit() {
        this.validate()
        // If there are errors, don't submit form
        if (this.hasErrors) {
          this.$refs.submit.stop()
          return
        }

        $.ajax({
          url: this.$routes.integration_mapping_options_path({ id: this.integrationID }),
          method: 'PATCH',
          data: {
            integration_options: this.formData,
          },
        }).then((_xhr) => {
          this.$refs.submit.stop()
          Util.showFlashNotice(this.$locale.t('integrations.adp.config.messages.optionsSaved'))
          location.reload()
        }).fail((xhr) => {
          this.$refs.submit.stop()
          if (xhr.responseJSON && xhr.responseJSON.errors) {
            this.errors = xhr.responseJSON.errors || {}
          } else {
            Util.genericAjaxError(this.$locale.t('integrations.adp.config.messages.optionsNotSaved'), xhr)
          }
        })
      },

      clearErrors() {
        this.errors = {}
      },
    },
  }
</script>
