<template>
  <WbPanel :title="title" id="feature-request-form" ref="featureRequestPanel">
    <p ref="description">{{ description }}</p>
    <FormFieldInput
      ref="email"
      label="Email Address"
      v-model="formData.email"
      :errors="errors"
      class="form-group-tight give-room-above"
      name="email"
      type="text"
    />

    <FormFieldCheckbox
      ref="authorizedAdmin"
      label="By checking this box, I acknowledge I am authorized to make changes to my WorkBright account on behalf of my organization."
      v-model="formData.authorizedAdmin"
      :errors="errors"
      name="authorizedAdmin"
      gridColumns="col-xs-12"
      @input="handleCheckbox"
    />

    <WbButton
      ref="submit"
      label="Submit Request"
      @click="submitEmail"
    />
  </WbPanel>
</template>

<script>
  import FormFieldInput from 'components/common/FormFieldInput'
  import FormFieldCheckbox from 'components/common/FormFieldCheckbox'
  import WbButton from 'components/common/WbButton'
  import Util from 'lib/util'
  import WbPanel from 'components/common/WbPanel'
  import { ajax } from 'jquery'

  export default {
    name: 'feature-request-form',

    components: {
      FormFieldInput,
      FormFieldCheckbox,
      WbButton,
      WbPanel,
    },

    props: {
      title: {
        type: String,
        required: false,
      },
      description: {
        type: String,
        required: false,
      },
      featureName: {
        type: String,
        required: true,
      },
    },

    data() {
      return {
        isAuthorized: false,
        formData: {
          email: null,
          authorizedAdmin: false,
        },
        errors: {},
      }
    },

    methods: {
      handleCheckbox(checkedState) {
        this.errors = {}
        this.isAuthorized = checkedState
      },

      submitEmail() {
        this.clearErrors()

        if (!this.isAuthorized) {
          this.errors = { authorizedAdmin: ["Please confirm that you are authorized to make this request."] }
        } else {
          ajax({
            method: 'POST',
            url: this.$routes.feature_requests_accounts_path,
            data: {
              feature_name: this.featureName,
              email_address: this.formData.email,
            },
          }).success((_xhr) => {
            Util.showFlashNotice("Thank you for your request! A WorkBright team member will be in touch soon.")
          }).fail(xhr => {
            if (xhr.responseJSON && xhr.responseJSON.errors) {
              this.errors = xhr.responseJSON.errors || {}
            } else {
              Util.genericAjaxError(this.$locale.t('errors.ajax.generic'), xhr)
            }
          })
        }
      },

      clearErrors() {
        this.errors = {}
      },
    },
  }
</script>
