import UserImportPageHeader from 'components/user_imports/UserImportPageHeader'
import { pureOrphanVue } from 'lib/vue/pureOrphanVue'

export default Marionette.LayoutView.extend({
  template: '#layout-admin-users-column-match',
  regions: {
    sheetColumnsRegion: '.sheet-columns',
    submitSheetRegion: '.submit-sheet',
    suggestRequiredFieldsRegion: '.suggest-required-fields',
    suggestUnmatchedColumnsRegion: '.suggest-unmatched-columns',
  },

  onRender() {
    pureOrphanVue(UserImportPageHeader, "component.vue-user-import-header", {
      props: { wizardStep: 'columns'},
    })
  },
})
