<template>
  <div>
    <div v-if="showHelpVideo" ref="helpVideo">
      <p>
        Please take a moment to watch the following video before you begin.
      </p>
      <video class="video--responsive" :poster="remoteI9PosterRoute" controls="controls" playsinline="playsinline" preload="none" width="560" height="315" :src="remoteI9VideoRoute" />
    </div>
    <hr />
    <div v-if="showNotReadyToStart" ref="notReady">
      <p>Sorry, this document is not ready to be countersigned.</p>
    </div>

    <div v-if="showSubmitGeoLocation" ref="geolocation">
      <wb-geolocation
        @wbGeolocationSuccess="handleGeolocationSuccess"
        @wbGeolocationError="handleGeolocationError"
      >
        <h4>Step 1: Confirm Location</h4>
        <p v-html="submitLocationInstructions" />
        <p>If you are willing to proceed, click the Submit Your Location button.</p>
      </wb-geolocation>
    </div>

    <template v-if="isQrStep">
      <ScanQrCode
        v-if="authRepQrCodeScanEnabled"
        actor="authorized_representative"
        :stepNumber="1"
        :alertMessage="qrCodeAlertMessage"
        :qrCodeTarget="qrCodePayload"
        :remoteId="remoteCountersignId"
        action="countersign"
        ref="scanQrCode"
      >
        <template #instructions>
          <p>
            This process requires access to your camera. If you do not have access to a camera on this device, you can use a different device or {{ employee_full_name }} can choose a different I-9 reviewer.
          </p>
          <p>
            Select Begin QR Code Scan below to open your camera and scan the QR code that is being displayed on {{ employee_full_name }}’s device.
          </p>
        </template>
      </ScanQrCode>
      <ShowQrCode
        v-else
        actor="authorized_representative"
        :stepNumber="1"
        :alertMessage="qrCodeAlertMessage"
        :qrCodePayload="qrCodePayload"
        ref="showQrCode"
      >
        <template #instructions>
          <p>
            Please have <span class="block-translate">{{ employee_full_name }}</span> scan the following QR code to verify your mutual proximity.
          </p>
        </template>
      </ShowQrCode>
    </template>

    <div v-if="showCertifyIdentity" ref="certifyIdentity">
      <h4 class="breathe">Step 2: Certify Identity</h4>
      <FormFieldInput
        :errors="formErrors"
        label="Full Name"
        name="auth_rep_name"
        :required="true"
        type="text"
        v-model="formData.auth_rep_signature.signature_name"
      />
      <FormFieldCheckbox
        :errors="formErrors"
        gridColumns="col-xs-12"
        :label="certificationLabel"
        name="auth_rep_certification"
        :required="true"
        v-model="formData.auth_rep_certification"
      >
        <span v-html="certificationLabel" />
      </FormFieldCheckbox>

      <ladda-button
        @click="handleCertifyClick"
        ref="continueButton"
      >
        Continue
      </ladda-button>
    </div>

    <div v-if="showReviewDocuments" ref="reviewDocuments">
      <h4>Step 3: Review Documents</h4>
      <ReviewDocumentSetContainer
        :documentationSet="reviewableDocumentationSet"
        :employeeFullName="employee_full_name"
        :receiptInstructions="receiptInstructions"
        :reviewInstructions="reviewDocumentsInstructions"
        @wbDocumentationSetReviewStart="handleDocumentReviewStart"
        @wbDocumentationSetReviewReset="handleDocumentReviewReset"
        @wbDocumentationSetVerify="handleDocumentVerify"
        @wbDocumentationSetVerificationComplete="handleVerificationComplete"
      />
    </div>
    <div v-if="showSignaturePad" ref="signaturePad">
      <hr />
      <p>
        Employee Name:
        <strong class="block-translate">{{this.employee_full_name}}</strong>
      </p>
      <p>
        List of Documents:
        <strong>{{documentSetSummary}}</strong>
      </p>
      <PerjuryStatement
        :statement="this.perjuryStatement"
      />
      <form @submit="handleCountersignAccepted">
        <SignaturePad
          v-model="formData.auth_rep_signature"
          :errors="formErrors"
        >
          <template #subHeader>
            <div class="breathe d-flex justify-content-between">
              <div>
                <span>Name:</span>
                <strong class="block-translate">{{ authRepName }}</strong>
              </div>
              <button
                class="btn btn-link pt-0 pr-0"
                @click="handleEditIdentityClick"
                ref="editIdentity"
              >
                Edit Identity
              </button>
            </div>
          </template>
        </SignaturePad>
        <ladda-button ref="submitCountersignButton" @click="handleCountersignAccepted">
          Complete Verification
        </ladda-button>
      </form>
    </div>

    <div v-if="showFinishedWithoutCountersigning" ref="finishedWOCountersign">
      <hr />
      <p>
        By clicking Finish below, you are stating that the documents provided by <span class="block-translate">{{ this.employee_full_name }}</span> do not match.
      </p>
      <ladda-button ref="submitDoneButton" @click="handleCountersignRejected">
        Finish
      </ladda-button>
    </div>

    <div v-if="showCountersignAccepted" ref="countersignAccepted">
      <p>{{ $locale.t('remoteCountersign.verification_finished') }}</p>
    </div>

    <div v-if="showCountersignRejected" ref="countersignRejected">
      <p>
        {{ $locale.t('remoteCountersign.verification_rejected') }}
      </p>
    </div>

    <div v-if="showInstructions" ref="instructions">
      <hr />
      <a href="https://www.uscis.gov/sites/default/files/files/form/i-9instr.pdf" target="_blank">
        Click here for detailed instructions on I-9 review
      </a>
    </div>
  </div>
</template>

<script>
  import I9RemoteCountersignComponentMixin from 'components/i9_remote_countersign/common/I9RemoteCountersignComponentMixin'
  import ScanQrCode from 'components/i9_remote_countersign/steps/ScanQrCode'
  import ShowQrCode from 'components/i9_remote_countersign/steps/ShowQrCode'
  import ReviewDocumentSetContainer from 'components/documentation/ReviewDocumentSetContainer'
  import SignaturePad from 'components/common/SignaturePad'
  import FormFieldInput from 'components/common/FormFieldInput'
  import FormFieldCheckbox from 'components/common/FormFieldCheckbox'
  import PerjuryStatement from 'components/common/PerjuryStatement'
  import { getRoute } from 'lib/routes'

  export default {
    name: 'i9-remote-countersign-auth-rep-container',

    components: {
      FormFieldCheckbox,
      FormFieldInput,
      PerjuryStatement,
      ReviewDocumentSetContainer,
      ScanQrCode,
      ShowQrCode,
      SignaturePad,
    },
    // NOTE: the mixin
    // - imports modules
    // - registers components
    // - defines data/computed/mounted
    mixins: [I9RemoteCountersignComponentMixin],

    data() {
      return {
        isCertified: false,
        employee_full_name: null,
        documentSetMatched: null,
        formData: {
          auth_rep_certification: null,
          // snake case because this is form data that is sent back to rails
          document_review: null,
          auth_rep_signature: {
            signature: '',
            signature_name: '',
          },
        },
      }
    },

    computed: {
      authRepName() {
        return this.formData.auth_rep_signature.signature_name
      },

      employeeFullNameHTML () {
        return `<span class="block-translate">${this.employee_full_name}</span>`
      },

      certificationLabel() {
        return this.$locale.t('remoteCountersign.certify_identity_label', this.employee_full_name)
      },

      submitLocationInstructions(){
        return this.$locale.t('remoteCountersign.submit_location_instructions', this.employeeFullNameHTML)
      },

      receiptInstructions () {
        return 'You may use a receipt as proof that you can provide a physical document at a later date. If you are using a receipt in place of one of the documents listed below, please select the title of that document to proceed. When requested to upload a photo of the document, instead upload a photo of your receipt. For Document Title, put the word "Receipt" in front of the Document Title (For example, "Receipt- Drivers License"). A list of eligible receipts and the timeframe to enter for Expiration Date can be found on the USCIS website.'
      },

      reviewDocumentsInstructions(){
        return this.$locale.t('remoteCountersign.review_document_instructions')
      },

      documentSetSummary(){
        return this.pageContext.i9RemoteCountersign.i9Data.documentation_set.map(d => d.document_title).join(', ')
      },

      perjuryStatement () {
        return this.$locale.t('remoteCountersign.perjuryStatement')
      },

      qrCodeAlertMessage() {
        return `We were unable to access your or ${this.employee_full_name}'s location.`
      },

      remoteI9PosterRoute () {
        return getRoute('Images.remote_i9_screen')
      },

      remoteI9VideoRoute () {
        return getRoute('Videos.remote_i9_authorized_rep')
      },

      reviewableDocumentationSet() {
        return this.pageContext.i9RemoteCountersign.i9Data.documentation_set.filter(d => d.document_title !== 'N/A')
      },

      showHelpVideo() {
        return this.showSubmitGeoLocation || this.qrVerifying
      },

      showSubmitGeoLocation(){
        return this.ready && this.remoteVerifying && this.locationNotAccepted && this.qrSecret === null
      },

      showQrCode(){
        return this.ready && this.remoteVerifying && this.qrVerifying
      },

      showCertifyIdentity(){
        return this.ready && this.remoteVerifying && (this.locationAccepted || this.qrScanMatched) && !this.isCertified
      },

      showReviewDocuments(){
        return this.ready && this.remoteVerifying && (this.locationAccepted || this.qrScanMatched) && this.isCertified
      },

      showSignaturePad(){
        return this.remoteVerifying && this.documentSetMatched === true
      },

      showFinishedWithoutCountersigning(){
        return this.remoteVerifying && this.documentSetMatched === false
      },

      showInstructions(){
        return !(this.showCountersignAccepted || this.showCountersignRejected)
      },
    },

    created() {
      this.employee_full_name = `${this.pageContext.i9RemoteCountersign.i9Data.first_name} ${this.pageContext.i9RemoteCountersign.i9Data.last_name}`
    },

    methods: {
      handleCertifyClick() {
        this.$refs.continueButton.start()
        this.sendI9RemoteCountersignEvent('auth_rep_certify_identity').then(({ success, errors }) => {
          this.isCertified = success
          this.formErrors = errors || {}
          this.$refs.continueButton.stop()
        })
      },

      handleEditIdentityClick() {
        this.isCertified = false
        this.documentSetMatched = null
      },

      handleDocumentReviewStart() {
        this.clearDocumentReviewData()
      },

      handleDocumentReviewReset(data) {
        this.formData.document_review = { ...data }

        this.sendI9RemoteCountersignEvent("reset_document_review")

        this.clearDocumentReviewData()
      },

      handleDocumentVerify(data){
        this.formData.document_review = {...data}
        this.sendI9RemoteCountersignEvent("submit_document_review")
        this.formData.document_review = null
      },

      handleVerificationComplete(data){
        this.documentSetMatched = data.verified
      },

      handleCountersignAccepted(event){
        if (event) { // user clicked enter in the name field
          event.preventDefault()
        }
        this.sendI9RemoteCountersignEvent("submit_countersign").success(({ success, errors }) => {
          if (success) {
            this.certifyStatus = "certify_complete"
          } else {
            this.formErrors = errors || {}
          }
          this.$refs.submitCountersignButton.stop()
        })
      },

      handleCountersignRejected(){
        this.sendI9RemoteCountersignEvent("submit_countersign_rejected").success(({ success, errors }) => {
          this.$refs.submitDoneButton.stop()
          if (success) {
            this.certifyStatus = "certify_not_matched"
          } else {
            this.formErrors = errors || {}
          }
        })
      },

      clearDocumentReviewData() {
        this.formData.document_review = null
        this.documentSetMatched = null
      },
    },
  }
</script>
