<template>
  <RadioToggle
    title="Filter:"
    :value="expiration"
    :options="selectedRadioToggleOptions"
    ref="toggle"
    @selected="handleFilterChange"
    v-if="selectedRadioToggleOptions" />
</template>

<script>
  import RadioToggle from 'components/common/RadioToggle'

  export default {
    components: {
      RadioToggle,
    },
    props: {
      filter: {
        type: String,
        required: true,
      },
      expiration: {
        type: String,
        default: 'all',
      },
    },
    data() {
      return {
        radioToggleOptions: {
          pending_countersign: [
            { label: 'All', value: 'all' },
            { label: 'Past Due/Late', value: 'late_pending_countersign' },
            { label: 'Due within 3 days', value: 'due_soon_pending_countersign' },
          ],
          missing_rejected: [
            { label: 'All', value: 'all' },
            { label: 'Past Due/Late', value: 'late_missing_rejected' },
            { label: 'Due within 3 days', value: 'due_soon_missing_rejected' },
          ],
          reverify: [
            { label: 'All', value: 'all' },
            { label: 'Expired', value: 'reverify_late' },
            { label: 'Expiring within 30 days', value: 'reverify_soon' },
            { label: 'Expiring 30+ days', value: 'reverify_future' },
          ],
        },
      }
    },
    computed: {
      selectedRadioToggleOptions() {
        return this.radioToggleOptions[this.filter] || null
      },
    },
    methods: {
      handleFilterChange(selection) {
        this.$emit('selected', selection)
      },
    },
  }

</script>
