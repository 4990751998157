<template>
  <div v-if="hasSufficientData" class="mt-4">
    <ul ref="logContent" class="list-group wb-list">
      <ListItem
        :secondaryActions="false">
        <div class="wb-list__content">
          <div class="wb-list__title-slideout">
            <div class="mb-3">
              <WbSimpleDateTime ref="logDate" :value="resolvedDate" />
            </div>
            <div>
              <strong ref="logStatus">
                {{ verificationStatus }}
              </strong>
            </div>
            <div ref="adminName" v-if="adminName">
              by {{ adminName }}
            </div>
          </div>
        </div>
      </ListItem>
    </ul>
  </div>
</template>
<script>
  import Constants from 'lib/constants'
  import ListItem from 'components/common/ListItem'
  import WbSimpleDateTime from 'components/common/WbSimpleDateTime'
  import {
    getDocumentVerificationLabel,
  } from "decorators/document_verification_decorator"

  export default {
    components: {
      ListItem,
      WbSimpleDateTime,
    },

    props: {
      submission: {
        type: Object,
        required: true,
      },
    },

    data () {
      return {
        dateFormat: Constants.DATE_EXT_NO_WEEKDAY_FORMAT,
        isLoading: false,
      }
    },

    computed: {
      adminName () {
        return this.submission.document_verification_resolution?.actor_name
      },

      resolvedDate () {
        return this.submission.document_verification_resolution?.resolved_at
      },

      hasSufficientData () {
        return !!this.adminName && !!this.resolvedDate
      },

      verificationStatus () {
        return getDocumentVerificationLabel(this.submission.document_verification_result)
      },
    },
  }
</script>
