import DocumentListTableCollectionView from 'views/documents/document_list_table_collection_view'

export default Marionette.LayoutView.extend({
  template: false,

  initialize: function() {
    this.$tableEl = this.$el.find('table#documents')
  },

  onBeforeRender: function() {
    if (this.$tableEl) {
      this.attachToListTable(this.options.gonCollection)

      this.addRegion("toolbarRegion", ".dt-toolbar")
      const toolbarView = new Marionette.ItemView({ template: "#documents-toolbar-tmpl" })
      this.toolbarRegion.show(toolbarView)
      toolbarView.prerendered = true // after showing, turn on prerendered so it doesn't get removed from the DOM
    }
  },

  attachToListTable: function(gonCollection) {
    const emptyTableAppend = `<p><a href="${App.Util.getPageRootPath()}/new">Click here</a> to create your first form.</p>`
    const columnsForTable = [
      /* 0 Name */ null,
      /* 1 Groups */ null,
      /* 2 Deadline */ { dataSort: 10 },
      /* 3 Submission Type */ null,
      /* 4 Optional? */ { type: "html", searchable: false, dataSort: 11 },
      /* 5 Internal Only? */ { type: "html", searchable: false, dataSort: 12 },
      /* 6 Exclude from progress bars? */ { type: "html", searchable: false, dataSort: 13 },
      /* 7 Prefill New Submission? */ { type: "html", searchable: false, dataSort: 14 },
      /* 8 Request New Submission on Rehire? */ { type: "html", searchable: false, dataSort: 15 },
      /* 9 Actions */ null,
      /* 10 DATA - Deadline */ { visible: false },
      /* 11 DATA - Optional? */ { type: "numeric", visible: false },
      /* 12 DATA - Internal?? */ { type: "numeric", visible: false },
      /* 13 DATA - Exclude from Progress Bars */ { type: "numeric", visible: false },
      /* 14 DATA - Prefill New Submission? */ { type: "numeric", visible: false },
      /* 15 DATA - Request New Submission on Rehire? */ { type: "numeric", visible: false },
    ]

    this.$tableEl.dataTable({
      retrieve: true,
      destroy: true,
      sorting: [], // No initial sort
      columns: columnsForTable,
      columnDefs: [
        { sortable: false,
          targets: [1, 9], /* No sort for columns: Groups, Actions */
        },
      ],
      language: {
        emptyTable: `<div class="text-center"><h4>You haven't created any forms yet.</h4>${emptyTableAppend}</div>`,
      },
    })

    App.Documents.listTableCollectionView = new DocumentListTableCollectionView()
    const collection = new App.Collections.DocumentCollection(gonCollection)
    App.Documents.listTableCollectionView.attachToTableWithCollection(this.$tableEl, collection)
  },

  onBeforeDestroy: function() {
    if (App.Documents.listTableCollectionView) {
      App.Documents.listTableCollectionView.destroy()
      delete App.Documents.listTableCollectionView
    }

    Marionette.TemplateCache.clear("#documents-toolbar-tmpl")
  },
})
