import FilterRowView from 'views/reports/filter_row_view'
import Filter from 'models/filter'
import SimpleEmptyView from 'views/common/simple_empty_view'

export default class ReportFiltersView extends Marionette.CompositeView.extend({
  template: "#filters-view-template",
  childView: FilterRowView,
  childViewContainer: "#filters",
  emptyView: SimpleEmptyView,
  emptyViewOptions: { message: 'No filters have been added' },

  ui: {
    addFilterButton: '#js-add-filter',
  },

  events: {
    'click @ui.addFilterButton': 'addFilter',
  },
}) {
  // Marionette callback which allows options to be passed to each childView in the collection. We use this for
  // propogating the filtering fieldsTree to each row.
  childViewOptions(model, index) {
    return { fieldsTree: this.getOption('fieldsTree') }
  }

  addFilter() {
    // Add a new, blank filter to the collection. The user selects the field after the row is rendered.
    this.collection.add(new Filter())
  }

  // Converts the collection of Filter objects into data objects suitable for JSON transport
  getFiltersDataForTransport() {
    return this.collection.map(function(filter) {
      return {
        field: filter.get('field').get('field_key'),
        op: filter.get('op'),
        value: filter.get('value'),
      }
    })
  }

  // Runs through each row and determines if it is valid. If any row is invalid, the UI will present as so, and this method will
  // return false. If all rows are valid, this method returns true.
  validate() {
    this.children.invoke('onBeforeValidation')

    let anyErrors = false

    this.collection.each(function(filter) {
      if (!filter.isValid())
      {anyErrors = true}
    })

    return !anyErrors
  }
}
