export default Marionette.ItemView.extend({
  template: '#template-suggest-unmatched-columns-view',
  collectionEvents: {
    change: 'changed',
  },
  changed: function(){
    this.render()
  },
  serializeData: function(){
    return {
      unmatchedMessage: this.unmatchedMessage(),
      hasErrors: this.collection.unmatched().length > 0,
    }
  },

  // Message indicating the outstanding SheetColumns that need to be matched to
  // Fields to continue
  //
  // Returns string
  unmatchedMessage: function(){
    const columnNames = _.map(this.collection.unmatched().models, function(column){
      return column.get('name')
    })

    const maxPreview = 3
    let message = columnNames.slice(0,3).join(', ')
    if (columnNames.length > maxPreview){
      message += `, and ${columnNames.length - maxPreview} more`
    }
    message += columnNames.length == 1 ? ' is ' : ' are '
    message += ' not assigned.'

    return message
  },
})
