import PrerenderedTableRowItemView from 'views/common/prerendered_table_row_item_view'

export default PrerenderedTableRowItemView.extend({
  behaviors: {
    TableRowSelectable: {},
  },

  ui: {
    destroyDocument: '.js-destroy-document',
  },

  triggers: {
    'click @ui.destroyDocument': {
      event: 'document:destroy',
      stopPropagation: true,
      preventDefault: true,
    },
  },

  onShow: function() {
    // If the dataset is empty, this row won't have a model
    if (!this.model) {return}

    this.renderArchivedState(this.model.get("archived"))
  },
  
  archivedChanged: function(model, value) {
    this.renderArchivedState(value)
    // bubble up to collectionview
    this.trigger("model:change:archived")
  },

  renderArchivedState: function(value) {
    this.$el.toggleClass("archived", value)
    this.$el.find(".action-col .archive-text").text(value ? "Unarchive" : "Archive")
  },
})
