import SwitchableInputValueView from 'views/common/switchable_input_value_view'

export default class FilterValueView extends SwitchableInputValueView.extend({
  modelEvents: {
    // Re-render ourselves when the field or op changes:
    'change:field': 'render',
    'change:op': 'render',
  },

  // Field type map – mostly the same as the default in SwitchableInputValueView with the noted exceptions.
  _fieldTypeMap: function () {
    return {
      TextField: "string",
      MultilineTextField: "string", // Overrides parent (we don't have room for a textarea in a filter row)
      DateField: "date",
      NumberField: "number",
      CurrencyField: "currency",
      DropdownField: "select",
      RadioField: "select",         // Overrides parent (we don't have room for radio buttons in a filter row)
      CheckboxField: "checkbox",
      MultiCheckboxField: "multiselect",
    }
  },
}) {
  onRender() {
    // Initialize stickit here because the value inputs are dynamic, based on the field that is selected
    this.stickit()

    $.runInitializers(this.$el)

    this.$el.toggle(!this.model.isUnaryOp())
  }
}
