import I9DocumentationChoice from 'models/document_submissions/i9_documentation_choice'

// Collection of all possible I-9 documentation choices (not to be confused with I9DocumentationSet, which reflects
// the specific documents that the user has chosen)
export default Backbone.Collection.extend({
  model: I9DocumentationChoice,

  // Get list of documents by I-9 list
  //
  // listID: string 'A', 'B', 'C'
  //
  // return Array of documents
  getList: function(listID){
    return this.where({ list_key: listID })
  },

  // Find a document choice by documentation key
  // Includes 'additional_choice' documents
  //
  // documentationKey: string
  //
  // returns - Backbone.Collection or null if no matching documentation choices found
  findByDocumentationKey: function(documentationKey) {
    const allDocs = _.flatten(this.map(function(d){
      let arr = [d]
      const additionalDocs = d.get('additional_docs')
      if (additionalDocs && additionalDocs.length) {
        arr = arr.concat(additionalDocs.models)
      }
      return arr
    }))

    return new Backbone.Collection(allDocs).findWhere({ key: documentationKey }) || null
  },
})
