// FIXME: This should live alongside I9, I9DocumentationSet, etc.

// Represents an attachment on I9 submissions (i.e. a document as part of the documentation_set).
//
// Instances of this model MAY include a property called docSetChoice, which is a reference to the original
// I9DocumentationChoice that was used to create this I9Attachment instance. This is an object member, not a Backbone
// property (i.e. it is accessed via `instance.docSetChoice` as opposed to `instance.get(...)`). It is only available
// during editing operations - creating a new I9 as well as an employer updating documentation.
export default class I9Attachment extends Backbone.Model.extend({

}) {
  // Constructor
  initialize() {
    this.on('change:receipt', this.onChangeReceipt)
  }

  fileFront() {
    if (!this.get('file_front_url') || this.get('file_front_url').length == 0) {
      return null
    }

    return {
      url: this.get('file_front_url'),
      filename: this.get('file_front_filename'),
      content_type: this.get('file_front_content_type'),
    }
  }

  fileBack() {
    if (!this.get('file_back_url') || this.get('file_back_url').length == 0) {
      return null
    }

    return {
      url: this.get('file_back_url'),
      filename: this.get('file_back_filename'),
      content_type: this.get('file_back_content_type'),
    }
  }

  clearFiles() {
    this.set({
      file_front_url: null,
      file_front_filename: null,
      file_front_content_type: null,
      file_front_cache: null,
      file_back_url: null,
      file_back_filename: null,
      file_back_content_type: null,
      file_back_cache: null,
    })
  }

  // Simply returns if attachment identifier is front or back
  attachmentSideFromFileName(identifier) {
    if (!identifier) {return null}
    const front = this.get('file_front_filename') == identifier
    const back = this.get('file_back_filename') == identifier
    if (front == true) {return 'front'}
    if (back == true) {return 'back'}

    return null
  }

  // Updates I-9 Attachment with edited version of same file
  // Specifically updates _url, _cache, _filename, _content_type as they are only keys that are changed when editing attachments
  updateEditedAttachment(options) {
    const side = this.attachmentSideFromFileName(options.originalFileIdentifier)

    const updatedData = {}
    updatedData[`file_${side}_url`] = options.updatedUrl
    updatedData[`file_${side}_cache`] = options.updatedFileName
    updatedData[`file_${side}_filename`] = options.updatedFileName
    updatedData[`file_${side}_content_type`] = options.updatedContentType

    // Update the model
    this.set(updatedData)
  }

  // Handles updating the document title and expiration date when attachment is marked/unmarked as receipt.
  onChangeReceipt(_model, value, _options) {
    const documentTitle = this.get('document_title') || ''
    const RECEIPT_REGEXP = /receipt/i
    const docSetChoice = this.docSetChoice

    if (value) {
      // All receipts require an expiration date, so clear any existing N/A values
      if (this.get('expiration_date') == 'N/A') {
        this.set('expiration_date', '')
      }

      // Prefix receipt
      if (!documentTitle.match(RECEIPT_REGEXP) && (docSetChoice && docSetChoice.shouldPrependReceiptText)) {
        this.set('document_title', `Receipt - ${documentTitle}`)
      }
    } else {
      // Convenience: if we know the docSetChoice does not require an expiration date, go ahead and re-autofill with N/A
      if (docSetChoice && !docSetChoice.isExpirationDateRequired) {
        this.set('expiration_date', 'N/A')
      }

      // Clear out the word Receipt
      if (documentTitle.match(RECEIPT_REGEXP)) {
        // Matches the word receipt surrounding by whitespace and optional hyphen
        // https://regex101.com/r/cuGca3/1
        this.set('document_title', documentTitle.replace(/receipt(\s*\-?\s*)?/i, ''))
      }
    }
  }
}
