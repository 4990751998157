// Utility methods related miscellanous email functionality
export default {
  // Undelivered Email Warning Icon
  // Returns - DOM element for undelivered warning icon
  undeliveredWarningIcon: function(val) {
    return $('<i>').addClass('email-bounce-warning')
      .attr('aria-hidden', true)
      .attr('data-toggle', 'tooltip')
      .attr('data-placement', 'bottom')
      .attr('title', 'The last email we sent to this employee has bounced or been rejected. Please double-check the email address.')
  },
}
