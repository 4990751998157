class MiscActionSummaryPresenter {
  constructor(permissionSet) {
    this.permissionSet = permissionSet
  }

  display() {
    return this.permissions().length > 0
  }

  permissions() {
    return this.permissionSet.permissions.filter((p) => {
      return !(p.target_type == 'RepresentableField' ||
               p.target_type == 'Document' && p.action != 'manage')
    })
  }
}

export default MiscActionSummaryPresenter
