export default Marionette.Behavior.extend({
  defaults: {
    checkForModel: true,
  },

  events: {
    "click td": "clickRow",
  },

  clickRow: function(e) {
    if (this.options.checkForModel && (!this.view.model || !this.view.model.id))
    {return}

    const $target = $(e.target).closest('td')
    if ($target.hasClass('row-selector'))
    {return}

    this.view.triggerMethod('row:click')
  },
})
