import EmployeeGroupMembershipRowItemView from 'views/employees/employee_groups_membership_row_item_view'
import EmployeeGroupMembership from 'models/employee_group_membership'
import Animations from 'lib/util/animations'

export default Marionette.CollectionView.extend({
  childView: EmployeeGroupMembershipRowItemView,

  addEmployeeGroup: function() {
    // Start from the full list of employee groups (set in this.options by the ShowLayout. typically corresponds to a
    // window.gon variable); then, reject the ones that are already applied to this employee (this.collection). Finally
    // map the remaining list to a set of inputOptions that bootbox can handle.
    // Also reject any archived groups
    const employeeGroups = _(this.options.employeeGroups).reject(function(group) {
      return this.collection.findWhere({ group_id: group.id }) || group.archived
    }, this).map(function(group) {
      return { text: _.escape(group.name), value: group.id }
    })

    if (employeeGroups.length == 0) {
      bootbox.alert({
        title: "No Groups Left",
        message: "This staff member has already been added to all of the available groups.",
        className: "modal-info",
      })
      return
    }

    const self = this
    const employeeModel = this.getOption('employee')
    bootbox.prompt({
      title: "Add staff member to group:",
      inputType: 'select',
      inputOptions: employeeGroups,
      callback: function(value) {
        if (value === null)
        {return}

        const employeeGroupMembership = new EmployeeGroupMembership({
          employee_id: employeeModel.id,
          group_id: value,
        })

        self.collection.create(employeeGroupMembership, {
          wait: true,
          wbGenericFailureMsg: "Sorry, we couldn't add this staff member to the group.",
          success: function(model, response, options) {
            const view = self.children.findByModel(model)
            Animations.temporaryHighlight($('td', view.$el))
          },
        })
      },
      buttons: {
        confirm: { label: "Add" },
      },
    })
  },

  onChildviewEmployeeGroupDelete: function(args) {
    // ARCHITECT: deciding to just manipulate the data here, instead of passing to App.vent and then responding
    // in controller. App.vent just feels like overkill for this type of operation.
    // NOTE: employee group membership is (as of this note) not part of window.gon employee data JSON, so not
    // doing any sort of manipulation there either to remove it.
    const employeeGroupMembership = args.model

    employeeGroupMembership.destroy({
      wait: true,
      wbGenericFailureMsg: "Sorry, we couldn't remove this staff member from the group.",
    })
      .done(function(data, status, xhr) {
        App.Util.showAjaxFlashNotice(xhr)
      })
  },

  onBeforeDestroy: function() {
    Marionette.TemplateCache.clear("#employee-group-tmpl")
  },
})
