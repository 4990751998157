import Routes from 'lib/routes'

const state = () => {
  return {
    employeePayrollFields: [],
  }
}
const getters = {
  employeePayrollFields: state => state.employeePayrollFields,
}
const actions = {
  updateEmployeePayrollFields ({ commit }, { payroll_fields, employee_id }) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: Routes.Api.bulk_update_employee_standard_fields({employee_id}),
        method: 'PATCH',
        data: {
          payroll_fields,
        },
      }).then(response => {
        const fields = response.payroll_fields
        commit('setEmployeePayrollFields', fields)
        resolve()
      }).fail(response => {
        reject(response)
      })
    })
  },
}
const mutations = {
  setEmployeePayrollFields: (state, fields) => {
    state.employeePayrollFields = fields
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
