import PrerenderedTableCollectionView from 'views/common/prerendered_table_collection_view'
import WebhookRowItemView from 'views/webhooks/webhook_row_item_view'

export default PrerenderedTableCollectionView.extend({
  childView: WebhookRowItemView,

  onChildviewWebhookDelete: function(args) {
    App.vent.trigger('webhooks:delete', args.model.id)
  },
})
