import AddressFieldsItemView from 'views/common/forms/address_fields_item_view'

export default Marionette.LayoutView.extend({
  template: false,

  onRender: function() {
    this.addressFieldsView = new AddressFieldsItemView()
    this.addressFieldsView.render()
  },

  onBeforeDestroy: function() {
    if (this.addressFieldsView) {
      this.addressFieldsView.destroy()
    }
  },
})
