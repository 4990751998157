<template>
  <div class="table-group-fields" v-if="display">
    <table class="table table-bordered table-condensed">
      <tbody>
        <tr>
          <th width="70%">
            Action
          </th>
          <th width="30%">
            Approved
          </th>
        </tr>
        <tr v-for="(permission, index) in permissions" :key="index">
          <td>
            {{ permission.actionName() }}
          </td>
          <GrantCell
            :key="index"
            :action="permission.action"
            :permissions="[permission]"
            :target-id="permission.target_id"
          />
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import GrantCell from 'components/permissions/summary/GrantCell'
  import MiscActionSummaryPresenter from 'presenters/policy_generator/misc_action_summary_presenter.js'

  export default {
    name: 'misc-action-summary',

    components: {
      GrantCell,
    },

    props: {
      permissionSet: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        presenter: null,
      }
    },

    computed: {
      permissions() {
        return this.presenter.permissions()
      },

      display() {
        return this.presenter.display()
      },
    },

    created() {
      this.presenter = new MiscActionSummaryPresenter(this.permissionSet)
    },
  }
</script>
