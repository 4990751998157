import FormFieldInput from "components/common/FormFieldInput"
import WbButton from "components/common/WbButton"
import WbGeolocation from "components/common/WbGeolocation"
import { mapGetters, mapState } from "vuex"
import { getRoute } from "lib/routes"
import Util from "lib/util"
import PubSub from "lib/pub_sub"

export default {
  components: {
    FormFieldInput,
    WbButton,
    WbGeolocation,
  },

  data() {
    return {
      ready: null,
      remoteReverifyId: null,
      reverifyStatus: null,
      locationAccepted: null,
      qrSecret: null,
      pubSubChannel: null,
      pubSubChannelName: null,
      formData: {
        actor: null,
        event_type: null,
        geolocation_position: null,
        qr_secret_matched: null,
        auth_rep_phone: null,
      },
      formErrors: {},
    }
  },

  computed: {
    ...mapState([
      'pageContext',
    ]),

    authRepQrCodeScanEnabled () {
      return this.currentAccount.authRepQrCodeScanEnabled
    },

    employeeScansQrCodeEnabled () {
      return !this.authRepQrCodeScanEnabled
    },

    showNotReadyToStart(){
      return !this.ready
    },

    locationNotAccepted(){
      return !this.locationAccepted
    },

    unverified(){
      return this.reverifyStatus == "unverified"
    },

    documentsUpdated(){
      return this.reverifyStatus == "documents_updated"
    },

    remoteVerifying() {
      return this.reverifyStatus == "remote_verifying"
    },

    showReverifyAccepted(){
      return this.reverifyStatus == "reverified"
    },

    showReverifyRejected(){
      return this.reverifyStatus == "reverify_not_matched"
    },

    qrVerifying(){
      return this.qrSecret !== null && !this.qrScanMatched
    },

    qrScanMatched(){
      return this.qrSecret !== null && this.formData.qr_secret_matched === true
    },

    qrCodePayload(){
      const urlParams = {
        id: this.remoteReverifyId,
        qr_secret: this.qrSecret,
        ...this.qrCodePayloadUrlParams,
      }
      return getRoute(this.qrCodePayloadUrlName, urlParams)
    },

    qrCodePayloadUrlName(){
      return this.authRepQrCodeScanEnabled ? "remote_reverify_authrep_qr_code_verification_url" : "remote_reverify_qr_code_verification_url"
    },

    qrCodePayloadUrlParams(){
      return this.authRepQrCodeScanEnabled ? {subdomain: this.currentAccount.subdomain} : {host: window.location.host}
    },

    qrCodeAlertMessage() {
      return "We were unable to access your or your reviewer's location."
    },

    ...mapGetters({
      currentAccount: 'account/current',
    }),
  },

  methods: {
    submitI9RemoteReverifyEvent(eventType){
      this.formData.event_type = eventType

      return $.ajax({
        method: "POST",
        url: this.$routes.i9_remote_reverify_event_path({id: this.remoteReverifyId}),
        data: {
          i9_remote_reverify: {...this.formData},
        },
      })
    },

    handleSubmitLocation() {
      this.submitI9RemoteReverifyEvent("submit_location")
        .success(json => {
          if (json.success){
            this.locationAccepted = true
          } else {
            this.formErrors = json.errors || {}

            if (json.qr_secret){
              this.qr_secret = json.qr_secret
            }
          }
        })
    },

    handleGeolocationSuccess(position) {
      // We cannot pass the GeolocationPosition object as data to jQuery ajax
      // Explanation here: https://github.com/WorkBright/Rails-App/pull/2983
      const geoPosition =
        {
          coords: {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          },
        }
      this.formData.geolocation_position = geoPosition
      this.handleSubmitLocation()
    },

    handleGeolocationError(errorMessage) {
      this.submitI9RemoteReverifyEvent("submit_location_failed")
        .success(json => {
          this.qrSecret = json.qr_secret
        })
        .always(() => {
          Util.showFlashNotice(errorMessage)
        })
    },

    handleQrCodeMatched(data){
      this.formData.qr_secret_matched = data.qrCodeSecretMatched
    },

    handleAuthRepLocationFailed(data){
      // need to include the QR secret or it won't be able to match
      this.qrSecret = data.qrSecret
    },

    handleAuthRepComplete(data){
      if (data.listeningActor == this.formData.actor) {
        this.reverifyStatus = "reverified"
      }
    },

    handleAuthRepRejected(data){
      if (data.listeningActor == this.formData.actor) {
        this.reverifyStatus = "reverify_not_matched"
      }
    },
  },

  created() {
    this.ready = this.pageContext.i9RemoteReverify.ready
    this.formData.actor = this.pageContext.i9RemoteReverify.actor
    this.remoteReverifyId = this.pageContext.i9RemoteReverify.id
    this.reverifyStatus = this.pageContext.i9RemoteReverify.reverifyStatus
    this.qrSecret = this.pageContext.i9RemoteReverify.qrSecret
    this.formData.qr_secret_matched = this.pageContext.i9RemoteReverify.qr_secret_matched
    this.pubSubChannelName = this.pageContext.i9RemoteReverify.pubSubChannelName

    this.pubSubChannel = PubSub.subscribeToRemoteReverifyChannel({
      remoteReverifyId: this.remoteReverifyId,
      channelName: this.pubSubChannelName,
    })
    this.pubSubChannel.bind('wbQrCodeMatched', this.handleQrCodeMatched.bind(this))
    this.pubSubChannel.bind('wbAuthRepReverifyComplete', this.handleAuthRepComplete.bind(this))
    this.pubSubChannel.bind('wbAuthRepLocationFailed', this.handleAuthRepLocationFailed.bind(this))
    this.pubSubChannel.bind('wbAuthRepReverifyRejected', this.handleAuthRepRejected.bind(this))
  },
}
