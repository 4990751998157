<template>
  <div>
    <div class="wizard-body">
      <div v-if="bulkStaffUpdate" ref="spreadsheetColumns">
        <p>Spreadsheet Columns</p>
        <ul>
          <li v-for="([position, column]) in Object.entries(bulkStaffUpdate.columns_map)" v-bind:key="position">
            {{ column.name }}
          </li>
        </ul>
      </div>
      <FileUpload v-else
        ref="fileUpload"
        :error="errorFor('file')"
        :required="true"
        @file-remove="handleUploadedFileRemove"
        @upload-success="handleUploadSuccess"
      />
    </div>
    <div class="wizard-footer">
      <div class="text-right">
        <WbButton ref="nextButton"
          class="next"
          context="primary"
          @click="handleNext"
        >
          Next
        </WbButton>
      </div>
    </div>
  </div>
</template>

<script>
  import { createBulkStaffUpdate } from 'services/admin/BulkStaffUpdatesService'
  import FileUpload from 'components/forms/FileUpload'
  import Util from 'lib/util'
  import WbButton from 'components/common/WbButton'

  export default {
    name: 'file-upload-step',
    components: {FileUpload, WbButton},
    data() {
      return {
        bulkStaffUpdate: null,
        errors: {},
        uploadedFile: {
          contentType: null,
          key: null,
          url: null,
        },
      }
    },
    methods: {
      errorFor (fieldName) {
        if (!this.errors[fieldName]?.length) { return null }
        return this.errors[fieldName].join(' ')
      },
      handleNext() {
        createBulkStaffUpdate({
          fileUrl: this.uploadedFile.url,
          fileCache: this.uploadedFile.key,
          fileContentType: this.uploadedFile.contentType,
        }).success((bulkStaffUpdate) => {
          this.bulkStaffUpdate = bulkStaffUpdate
        }).fail(xhr => {
          if (xhr.responseJSON?.errors) {
            this.errors = xhr.responseJSON.errors || {}
          } else {
            Util.genericAjaxError('An error occurred trying to create this Bulk Staff Update', xhr)
          }
        })
      },
      handleUploadSuccess(uploadedFile) {
        this.uploadedFile = uploadedFile
      },
      handleUploadedFileRemove() {
        this.uploadedFile.key = null
        this.uploadedFile.url = null
        this.uploadedFile.contentType = null
      },
    },
  }
</script>
