import Animations from 'lib/util/animations'
import PrerenderedItemView from 'views/common/prerendered_item_view'

export default PrerenderedItemView.extend({
  ui: {
    documentSelect: "#document-selection",
    employeeProfileFields: "#employee-profile-fields-section",
    employeeCustomFields: "#employee-custom-fields-section",
    documentFields: "#document-fields-section",
    allDocumentFields: ".document-field",
    insertableField: ".field",
    fieldTypeOption: ".field-type-option",
    fieldTypeMenu: "#field-type-dropdown",
    clearFieldsLink: "#clear-all-fields",
    listEl: '.sortable-list',
  },

  triggers: {
    'click @ui.clearFieldsLink': {
      event: "fields:clear",
      stopPropagation: true,
      preventDefault: true,
    },
    'click @ui.addFieldsButton': {
      event: "field:add",
      stopPropagation: true,
      preventDefault: true,
    },
    'change @ui.documentSelect': 'documentChange',
    'change @ui.insertableField': 'insertableFieldChange',
  },

  events: {
    "click @ui.fieldTypeOption": "fieldChange",
  },

  initialize: function() {
    const self = this
    Sortable.create(this.options.list[0], {
      animation: 100,
      ghostClass: 'ghost',

      filter: ".js-remove, .js-edit",
      onFilter: function (evt) {
        const item = evt.item,
          ctrl = evt.target

        // Click on remove button
        if (Sortable.utils.is(ctrl, ".js-remove")) {
          // HACKFIX: Clear tooltip if it exists
          if ($(item).data('bs.tooltip')) {
            $(item).tooltip('destroy')
          }

          item.parentNode.removeChild(item)
        }
      },
    })
  },

  onRender: function () {
    this.initializeFields()
  },

  fieldChange: function (e) {
    const selectedOption = e.currentTarget.id
    this.ui.employeeProfileFields.toggle(selectedOption === "employee-builtin-fields-option")
    this.ui.employeeCustomFields.toggle(selectedOption === "employee-custom-fields-option")
    this.ui.documentFields.toggle(selectedOption === "document-fields-option")
    this.ui.allDocumentFields.hide()
  },

  initializeFields: function () {
    this.ui.employeeProfileFields.hide()
    this.ui.employeeCustomFields.hide()
    this.ui.documentFields.hide()
  },

  onDocumentChange: function () {
    this.ui.allDocumentFields.hide()
    $(`#document_fields_for_document_${this.ui.documentSelect.val()}`).show()
  },

  onAppendToList: function (value, label) {
    const listItem = $('<li>').addClass('sortable-list-item')
    const removeLink = $('<a>').addClass('js-remove icon-action icon-action-danger appender-remove-action sortable-list-remove-icon')
      .attr("tabindex", -1)
      .attr('href', 'javascript:void(0)')
      .append($('<i>').addClass('fa fa-times-circle'))
    const itemLabel = $('<span>').addClass('item-label').text(label)
    listItem[0].setAttribute('data-id', value)
    listItem.append(removeLink)
    listItem.append(itemLabel)
    this.ui.listEl.append(listItem)

    const newListItem = $(".sortable-list-item:last")

    // Scroll them to the bottom of the list where the new list item was appended
    // This behavior is especially useful when the list is longer than the page, and we currently do not show any
    // alerts that the list item was added
    $("html, body").animate({ scrollTop: this.ui.listEl.prop("scrollHeight") }, 25)

    Animations.temporaryHighlight(newListItem)
  },

  onFieldAdd: function () {
    const selectedOptionValue = $('.field').filter(':visible:last').val()

    switch (selectedOptionValue) {
    case 'all_fields':
      this.bulkAddFields()
      break
    case 'all_i9_documentation_fields':
      this.bulkAddFields(/i9_att/)
      break
    default:
      const label = $('.field:visible:last :selected').text()
      this.onAppendToList(selectedOptionValue, label)
    }
  },

  // Selects all fields or fields that only match regex
  //
  // matchingRegex - regex matching fields you want to add
  bulkAddFields: function (matchingRegex) {
    const optionCollection = $('.field:visible:last').children("option")

    // Loop through select tag options to add
    _.each(optionCollection, (option, idx) => {
      const fieldValue = $(option).val()
      const fieldLabel = $(option).text()
      const nonAddableField = _.contains(['', 'all_fields', 'all_i9_documentation_fields'], fieldValue)

      if (nonAddableField) {
        return
      }

      if (!matchingRegex) {
        this.onAppendToList(fieldValue, fieldLabel)
      } else if (matchingRegex && fieldValue.match(matchingRegex)) {
        this.onAppendToList(fieldValue, fieldLabel)
      }
    })
  },

  onInsertableFieldChange: function () {
    this.onFieldAdd()
    // Clear select afterward
    $('.field:visible:last').val("")
  },

  onFieldsClear: function () {
    $(this.options.list[0]).empty()
  },

  // Applies field styling to indicate error.
  // NOTE: There is currently no way to 'undo' this styling, but as of this writing the only real use-case of this is
  // when a field is no longer available and there is no remedy for that except for removing the field.
  indicateFieldError: function(fieldKey) {
    $(`.sortable-list-item[data-id="${fieldKey}"]`)
      .addClass('error')
      .tooltip({
        title: 'This field is no longer available.',
        placement: 'right',
        container: 'body',
      })
  },
})
