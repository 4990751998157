// Require Manually-Vendored Libraries
// This should be moved to NPM where possible
window.Sortable = require('vendor/sortable.min.js')
window.NProgress = require('vendor/nprogress.js')        // THIS FILE WAS MODIFIED!
require('vendor/jquery.transit.js')                      // THIS FILE WAS MODIFIED!
require('vendor/jquery.color-2.1.2.min.js')
require('vendor/jquery.bootstrap.wizard.min.js')
require('vendor/canvasToBlob.min.js')

// Extensions
require('vendor/vendor_extensions/bootstrap_validators/bootstrapValidator.signaturePad.js')
require('vendor/vendor_extensions/bootbox.upgrades.js')
require('vendor/vendor_extensions/bootstrap.upgrades.js')
require('vendor/vendor_extensions/jquery.upgrades.js')
