export default Marionette.LayoutView.extend({
  template: false,
  triggers: {
    'click .js-edit-untracked-prior-tenure': "editUntrackedPriorSeasons",
  },

  bindings: {
    '.info-untracked-prior-tenure': 'untracked_prior_tenure',
  },

  initialize: function() {
    this.stickit()
  },

  onEditUntrackedPriorSeasons: function() {
    bootbox.prompt({
      title: "Enter the number of seasons not tracked in WorkBright:",
      inputType: 'number',
      value: this.model.get('untracked_prior_tenure'),
      callback: $.proxy(function(value) {
        if (value === null || value.length == 0) {
          return // user canceled or entered nothing
        }

        this.model.updateUntrackedPriorSeasons(value)
      }, this),
    })
  },
})
