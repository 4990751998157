<template>
  <div>
    <div v-if="customFields.length > 0">
      <p>Please choose the field you would like to update for these {{employeeIds.length}} staff:</p>
      <div class="input-wrapper long-field">
        <WbMultiselect
          :selectOptions="fields"
          :enableSearchField="true"
          :multiple="false"
          placeholder="Select a Field"
          v-model="fieldKey"
        />
      </div>
    </div>
    <div v-else>
      <div class="alert alert-warning">
        No Custom Fields have been set up yet.
        <a :href="$routes.new_custom_field_path">Create a Custom Field.</a>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import WbMultiselect from 'components/common/WbMultiselect'

  export default {
    name: 'step-select-field',

    components: {
      WbMultiselect,
    },

    props: {
      employeeIds: {
        type: Array,
        required: true,
      },
    },

    data() {
      return {
        fieldKey: null,
      }
    },

    computed: {
      fields() {
        return this.customFields.map((cf) => { return { value: cf.field_key, label: cf.label } })
      },

      ...mapGetters({
        customFields: 'representable_fields_tree/customFields',
        getField: 'representable_fields_tree/get',
      }),
    },

    methods: {
      valid() {
        return this.fieldKey
      },

      invalidError() {
        this.errorMessage = 'You must select a document to continue.'
      },

      beforeAdvance() {
        this.$emit('updateData', { field: this.getField(this.fieldKey) })
      },
    },
  }
</script>
