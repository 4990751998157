function initPopover(el, binding) {
  const {
    html,
    placement,
    content,
    title,
  } = binding.value
  const $el = $(el)
  $el.popover('destroy') // Remove existing tooltip

  const popoverText = binding.value || $el.data('content')

  if (!popoverText || popoverText == '') { return } // Don't show a popover without content


  $el.popover({
    container: 'body',
    content,
    title,
    placement,
    html: !!html,
    trigger: 'focus',
  })
}

export default {
  mounted: (el, binding) => {
    initPopover(el, binding)
  },

  updated: (el, binding) => {
    initPopover(el, binding)
  },
}
