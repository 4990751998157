export default Marionette.ItemView.extend({
  el: '.address-fields',
  template: false,

  ui: {
    countryField: '.country-field',
    zipField: '.zip-field',
    stateField: '.state-field-area',
  },

  events: {
    'change @ui.countryField': 'changeCountry',
  },

  onRender: function () {
    this.changeCountry()
  },

  changeCountry: function (e) {
    // Default to no country if employee does not have a country, new employees will default to US according to EmployeesController
    const value = (e !== undefined ? e.target.value : '')
    const addressValue = this.ui.countryField.val() || value
    this.changeCountryByCode(addressValue)
  },

  changeCountryByCode: function(countryCode) {
    let templatePrefix
    switch (countryCode) {
    case 'US':
      templatePrefix = 'us'
      this.updateZipPlaceholderText('Zip')
      break
    case 'CA':
      templatePrefix = 'ca'
      this.updateZipPlaceholderText('Postal')
      break
    default:
      templatePrefix = 'general'
      this.updateZipPlaceholderText('Postal')
    }
    this.ui.stateField.html( $(`#${templatePrefix}-address-state-field-template`).html() )
  },

  updateZipPlaceholderText: function (text) {
    this.ui.zipField.attr('placeholder', `${text} Code`)
  },
})
