import BrowserSupport from 'lib/browser_support'
import { supportsTrueHover } from 'lib/supports_true_hover'

function checkBrowserSupport() {
  const browserSupport = new BrowserSupport()
  if (!browserSupport.isSupported) {
    const noSupportHTML = `
      <div class="content">
        <div class="panel jumbo-panel" id="no-support-panel">
          <header>
            <h2>Sorry, your browser is not supported.</h2>
        </header>
        <p>
          As much as we strive to support older technologies, your browser is truly out of date, and much of
          the functionality on WorkBright will not work correctly on it.
        </p>

        <p>
          In order to use the site properly, please update ${browserSupport.browserName} to the latest version, or
          download Google Chrome using the link below.
        </p>

        <p>
          <a href="http://www.google.com/chrome/" target="_new">
            <img src="/images/browser_chrome.gif" style="display: inline-block; margin-right: 10px;" />
            Click here to download Google Chrome
          </a>
        </div>
      </div>
    `
    setTimeout(function() {
      document.getElementById('main').innerHTML = noSupportHTML
    })
    
  }

  $('html').toggleClass('mq4-hover', supportsTrueHover())
}

if (document.readyState != 'loading') {
  checkBrowserSupport()
} else {
  document.addEventListener('DOMContentLoaded', checkBrowserSupport)
}
