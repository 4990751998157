<template>
  <div class="tenant-select-list-container" v-if="queryResults.length > 0">
    <h4 class="text-center breathe">
      Choose an Organization
    </h4>
    <div class="list-group">
      <a
        class="list-group-item"
        :class="{ disabled: checkIsAccountDisabled(account) }"
        :href="accountPath(account)"
        v-for="account in queryResults"
        :key="account.id"
      >
        <div class="d-flex align-items-center">
          <div class="w-100 pr-1">
            <h4 class="list-group-item-heading">
              {{ account.name }}
            </h4>
            <div class="d-sm-flex justify-content-sm-between">
              <p class="list-group-item-text">
                {{ getHostname(account) }}
              </p>
            </div>
            <div class="account-access-metadata mt-1 small">
              <span v-if="account.last_access" class="date-last-access">
                Last accessed
                <WbSimpleDateTime :value="account.last_access" />
              </span>
              <span v-if="account.expires_at">
                ·
                <span v-if="account.access" class="date-expires">
                  Expires
                  <WbSimpleDateTime :value="account.expires_at" />
                </span>
                <span v-else class="date-expired">
                  Expired
                  <WbSimpleDateTime :value="account.expired_at" />
                </span>
              </span>
            </div>
          </div>
          <i class="fa fa-chevron-right fa-lg text-muted" v-if="account.access" />
          <button
            class="btn btn-primary"
            @click="gainAccess(account)"
            v-if="isSuperadmin && !account.access"
          >
            Access
          </button>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
  import WbSimpleDateTime from 'components/common/WbSimpleDateTime'
  import { mapMutations, mapState } from 'vuex'
  import * as mutate from 'vuex/mutation_types'

  export default {
    name: 'tenant-select-results-list',
    components: {
      WbSimpleDateTime,
    },

    props: {
      queryResults: {
        type: Array,
        default: [],
      },
    },

    computed: {
      isSuperadmin() {
        return this.pageContext.current_user_role == 'superadmin'
      },

      ...mapState({
        pageContext: 'pageContext',
      }),
    },

    methods: {
      accountPath(account) {
        if (this.isSuperadmin && !account.access) {
          return 'javascript:void(0)'
        }

        return this.$routes.root_url({ subdomain: account.subdomain })
      },

      checkIsAccountDisabled(account) {
        return this.isSuperadmin && !account.access
      },

      gainAccess(account) {
        // Open the choose reason modal
        this.setPageContextKeys({ access_for_account: account })
      },

      getHostname({ subdomain }) {
        return new URL(this.$routes.root_url({ subdomain })).hostname
      },

      ...mapMutations({
        setPageContextKeys: mutate.SET_PAGE_CONTEXT_KEYS,
      }),
    },
  }

</script>
