<template>
  <BulkResetAssignments
    v-if="collectionViewName"
    :employeeIds="employeeIds"
    :collectionViewName="collectionViewName"
    @hidden="hide"
  />
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex'
  import * as mutate from 'vuex/mutation_types'
  import BulkResetAssignments from 'components/employees/bulk_reset_assignments/BulkResetAssignments'
  export default {
    name: 'bulk-reset-assignments-container',

    components: {
      BulkResetAssignments,
    },

    computed: {
      collectionViewName() {
        return this.pageContext.bulkResetAssignmentsCollectionName
      },

      employeeIds() {
        return [this.pageContext.employee.id]
      },

      ...mapGetters({
        pageContext: 'pageContext',
      }),
    },

    methods: {
      hide() {
        this.deletePageContextKey('bulkResetAssignmentsCollectionName')
      },

      ...mapMutations({
        setPageContextKeys: mutate.SET_PAGE_CONTEXT_KEYS,
        deletePageContextKey: mutate.DELETE_PAGE_CONTEXT_KEY,
      }),
    },

    watch: {
      collectionViewName: function(value) {
        if (!value) { return }
        setTimeout(() => this.setPageContextKeys({ bulkResetAssignments: true }))
      },
    },
  }
</script>
