<template>
  <div v-if="isValidFileType">
    <Toolbar
      v-if="allowDownload"
      reverse
      ref="download"
    >
      <a
        class="btn btn-default"
        target="_self"
        download
        :href="downloadPath">
        <WbIcon
          iconSet="fa"
          type="download"
        />
        Download {{ downloadType }}
      </a>
    </Toolbar>
    <div v-if="isImage">
      <img :src="fileUrl" width='100%'/>
    </div>
    <div
      v-else
      class="pdfjs-wrapper"
    >
      <iframe
        class="doc-viewer"
        :src="pdfViewerUrl"
        width="100%"
      />
    </div>
  </div>
  <div v-else ref="noPreview">
    No Preview Available for this File Type
  </div>
</template>

<script>
  import Toolbar from 'components/common/Toolbar'
  import WbIcon from 'components/common/WbIcon'

  export default {
    name: 'file-viewer',
    components: {
      Toolbar,
      WbIcon,
    },

    props: {
      allowDownload: {
        type: Boolean,
        default: false,
      },
      fileUrl: {
        type: String,
        required: true,
      },
    },

    computed: {
      downloadPath () {
        return `${this.fileUrl}?download=1`
      },

      downloadType () {
        return this.isPdf ? 'PDF' : 'Image'
      },

      fileType() {
        // Example url for image stored on S3: https://wb-development.s3-us-west-1.amazonaws.com/p/submission_components/3/file_front_1a46715e-f04d-4423-965c-0e14c315b39d.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAI3B5UM7OBHRU3GWA%2F20190911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20190911T184153Z&X-Amz-Expires=120&X-Amz-SignedHeaders=host&X-Amz-Signature=d947b7f0338d73307dc82c98f4c3b1ea4a8a3c73f469b6f64aa6e718a21399cb
        // We parse this by dropping the parameters from the url (.split(/\?/)[0]) and then taking the file extension at the end of the file name (.split('.').pop)
        return this.fileUrl && this.fileUrl.split(/\?/)[0].split('.').pop().trim()
      },

      isImage() {
        return ['jpg', 'jpeg', 'png', 'gif'].includes(this.fileType)
      },

      isValidFileType () {
        return this.isImage || this.isPdf
      },

      isPdf() {
        return this.fileType == 'pdf'
      },

      pdfViewerUrl() {
        return `/pdfjs/viewer.html?file=${encodeURIComponent(this.fileUrl)}&download=${this.allowDownload}&print=${this.allowDownload}`
      },
    },
  }
</script>
