import AdminUsersController from 'controllers/admin_users_controller'


export default function(Sector, App, Backbone, Marionette, $, _) {

  Sector.addInitializer(function() {
    Sector.controller = new AdminUsersController()

    Sector.listenTo(App.vent, 'admin_users:revoke', function(id) {
      Sector.controller.revokeAdminUser(id)
    })

    Sector.listenTo(App.vent, 'admin_users:destroy', function(id) {
      Sector.controller.destroyAdminUser(id)
    })

    Sector.listenTo(App.vent, 'admin_users:add_employee_record', function(id) {
      Sector.controller.addEmployeeRecordAdminUser(id)
    })
  })

  // A global listener for permissions policy generator completion
  Sector.listenTo(App.globalVent, 'admin_users:policy_retrieved', function(options) {
    const admin = options.adminUser

    Turbolinks.visit(options.adminUser.meta.origin_url)

    // Inception listener to know when we have the correct currentSector so we can populate the form with the admin data above
    Sector.listenTo(App.vent, 'admin_users:form_layout_ready', function () {
      App.currentSector.mainPageLayout.populateForm(admin)
    })
  })
}
