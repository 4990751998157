<template>
  <div>
    <hr />
    <h3>Remote Countersign Settings</h3>
    <small>"Remote I-9 Reverification Active", "Allow Family Members to Countersign", "Minimum Age to
      Countersign", and "Allow Staff to Initiate I-9 Resubmission" only apply if "Remote I-9 Verification Active" is checked</small>
    <FormFieldCheckbox
      label="Remote I-9 Verification Active"
      name="remote_countersign_active"
      :errors="errors"
      helpPopoverText="If checked, remote verification will be enabled. This setting must be checked in order to apply the other I-9 Remote Verification settings."
      v-model="formData.remote_countersign_active"
    />
    <FormFieldCheckbox
      label="Remote I-9 Reverification Active"
      name="remote_reverification_active"
      :errors="errors"
      v-model="formData.remote_reverification_active"
    />
    <FormFieldCheckbox
      label="Allow Family Members to Countersign"
      name="allow_family_to_countersign"
      :errors="errors"
      helpPopoverText="If checked, family members of an employee are permitted to perform remote I-9 verification."
      v-model="formData.allow_family_to_countersign"
    />
    <FormFieldCheckbox
      label="Allow Staff to Initiate I-9 Resubmission"
      name="allow_staff_to_initiate_resubmission"
      :errors="errors"
      :hint="staffInitiateResubmissionHintText"
      v-model="formData.allow_staff_to_initiate_resubmission"
    />
    <FormFieldNumber
      label="Minimum Age to Countersign"
      name="minimum_age_to_countersign"
      :errors="errors"
      helpPopoverText="If present, this value is the minimum age a person must be in order to perform remote I-9 verification."
      v-model="formData.minimum_age_to_countersign"
      orientation="horizontal"
    />
  </div>
</template>

<script>
  import FormFieldCheckbox from 'components/common/FormFieldCheckbox'
  import FormFieldNumber from 'components/common/FormFieldNumber'

  export default {
    name: 'remote-countersign-settings',
    components: {
      FormFieldCheckbox,
      FormFieldNumber,
    },

    props: {
      initialData: {
        type: Object,
        required: true,
      },
      errors: {
        type: Object,
        required: true,
      },
    },

    emits: [
      'update',
    ],

    data() {
      return {
        formData: {
          ...this.initialData,
        },
      }
    },

    computed: {
      staffInitiateResubmissionHintText() {
        return this.$locale.t('documents.help_text.staff_initiate_resubmission_hint')
      },
    },

    watch: {
      formData: {
        handler(newValue) {
          this.$emit('update', newValue)
        },
        deep: true,
      },
    },
  }
</script>
