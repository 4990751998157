// Flatten a Backbone model to an object.
// Recursively flatten over nested Backbone Models and Collections
function flattenModel(model) {
  const modelFlattened = {}
  Object.keys(model.attributes).forEach((key) => {
    modelFlattened[key] = backboneFlatten(model.get(key))
  })
  return modelFlattened
}

function flattenCollection(collection) {
  return collection.map(flattenModel)
}

function backboneFlatten(object) {
  if (object instanceof Backbone.Model) {
    return flattenModel(object)
  } else if (object instanceof Backbone.Collection) {
    return flattenCollection(object)
  }
  return object
}

export { backboneFlatten }
