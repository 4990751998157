// Status name, icon, and tooltip for an E-Verify case

<template>
  <div
    class="everify-case-status-indicator"
    :class="iconClass"
    v-tooltip.html="tooltip ? statusText : ''"
  >
    <WbIcon
      type="fw"
      class="status-icon"
    />
    <span
      class="everify-status"
      v-if="!tooltip"
      ref="statusText"
    >
      {{ statusText }}
    </span>
  </div>
</template>

<script>
  import WbIcon from 'components/common/WbIcon'

  export default {
    name: 'everify-case-status-indicator',

    components: {
      WbIcon,
    },

    props: {
      everifyCase: {
        type: Object,
        required: true,
      },

      // Display status text as tooltip only
      tooltip: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    computed: {
      iconClass() {
        return `status-${this.everifyCase.case_status}`
      },

      statusText() {
        return this.$locale.t(`everify.status.${this.everifyCase.case_status}.title`)
      },
    },
  }
</script>
