<template>
  <div class="dropdown">
    <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
      Bulk Actions
      <span class="ml-3 caret"/>
    </button>
    <ul class="dropdown-menu" aria-labelledby="dropdownBulkActionsMenu">
      <li class="dropdown-header js-bulk-employment-status-header">
        Employment Status
      </li>
      <li>
        <a class="js-bulk-activate-employees"
          href="javascript:void(0)"
          @click="$emit('show-bulk-action', 'activate-staff')"
        >
          Activate Staff
        </a>
      </li>
      <li>
        <a class="js-bulk-deactivate-employees"
          href="javascript:void(0)"
          @click="$emit('show-bulk-action', 'deactivate-staff')"
        >
          Deactivate Staff
        </a>
      </li>
      <li>
        <a class="js-bulk-update-employment-dates"
          href="javascript:void(0)"
          @click="$emit('show-bulk-action', 'update-employment')"
        >
          Update Employment Dates
        </a>
      </li>

      <li class="divider js-bulk-change-status-divider" role="separator"/>

      <li class="dropdown-header">
        Staff Data
      </li>
      <li>
        <a class="js-bulk-update-custom-fields"
          href="javascript:void(0)"
          @click="$emit('show-bulk-action', 'update-custom-fields')"
        >
          Update Custom Fields
        </a>
      </li>
      <li v-if="canDownload">
        <a class="js-bulk-download-submissions"
          href="javascript:void(0)"
          @click="$emit('show-bulk-action', 'download-submissions')"
        >
          Download Form Submissions
        </a>
      </li>

      <li class="divider" role="separator"/>

      <li class="dropdown-header">
        Sorting and Form Assignment
      </li>
      <li>
        <a class="js-bulk-reset-assignments"
          href="javascript:void(0)"
          @click="$emit('show-bulk-action', 'reset-assignments')"
        >
          Request New Submissions
        </a>
      </li>
      <li>
        <a class="js-bulk-add-employees-to-group"
          href="javascript:void(0)"
          @click="$emit('show-bulk-action', 'add-to-group')"
        >
          Add Staff to Group
        </a>
      </li>
      <li>
        <a class="js-bulk-remove-employees-from-group"
          href="javascript:void(0)"
          @click="$emit('show-bulk-action', 'remove-from-group')"
        >
          Remove Staff from Group
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'bulk-actions-dropdown',

    props: {
      canDownload: {
        type: Boolean,
        required: true,
      },
    },
  }
</script>
