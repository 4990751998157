<template>
  <EditCompanyProfileContainer
    ref="editProfileContainer"
    :companyProfileData="pageContext.companyProfileData"
    :einFieldVisible="pageContext.einFieldVisible"
    :formErrors="errors"
    :states="pageContext.states"
    :timezones="pageContext.timezones"
    @wb-save-company-profile="saveCompanyProfile"
  />
</template>

<script>
  import EditCompanyProfileContainer from 'components/admin/EditCompanyProfileContainer'
  import Util from 'lib/util'
  import { mapGetters } from 'vuex'


  export default {
    name: 'the-edit-company-profile-root',

    components: {
      EditCompanyProfileContainer,
    },

    data () {
      return {
        errors: {},
      }
    },

    computed: {
      ...mapGetters({
        pageContext: 'pageContext',
      }),
    },

    methods: {
      saveCompanyProfile(data){
        $.ajax({
          method: 'PATCH',
          url: this.$routes.company_path,
          data: {
            account: {...data},
          },
        }).success((_data, _status, xhr) => {
          if (xhr.responseJSON && xhr.responseJSON.errors) {
            this.errors = xhr.responseJSON.errors || {}
            this.$refs.editProfileContainer.$refs.submit.stop()
          } else {
            Util.navigateAndShowFlashNotice(this.$routes.edit_company_path, "Company Profile Updated")
          }
        }).fail((xhr) => {
          Util.genericAjaxError("An error occurred.", xhr)
          this.$refs.editProfileContainer.$refs.submit.stop()
        })
      },
    },
  }
</script>
