import Permission from 'models/policy_generator/permission'

// Pre-built permission sets that can be applied quickly to fit the use case of most users
// Abstract, extended to define specific sets
export default function(){
  // Find the permissions for a particular template
  //
  // name - string, template name
  //
  // Returns array of Permissions, null if not found
  this.permissionsForTemplate = function(name){
    const template = _.find(this.templates, function(template){return template.name == name})
    if (template){
      return _.map(template.permissions, function(permission){
        return new Permission(permission)
      })
    } else {
      return null
    }
    return template ? template : null
  }

  // Given an array of Permissions, find a matching template if it exists
  //
  // permission: Array of Permission
  //
  // returns: template Object, or null
  this.findMatchingTemplate = function(permissions) {
    if (!permissions) {return}

    let matchingTemplate = null
    this.templates.forEach((template) => {
      if (matchingTemplate) {return}

      const templatePermissions = this.permissionsForTemplate(template.name)
      if (templatePermissions.length != permissions.length) {return}

      const templatePermissionKeys = templatePermissions.map((p) => p.key()).sort()
      const permissionKeys = permissions.map((p) => p.key()).sort()
      if (templatePermissionKeys.every((k, idx) => k == permissionKeys[idx])) {matchingTemplate = template}
    })

    return matchingTemplate
  }
}
