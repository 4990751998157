/* global Sortable */
import TextInputSelect2View from 'views/common/forms/text_input_select2_view'

export default class UdfFormLayoutView extends Marionette.LayoutView.extend({
  template: false,

  ui: {
    form: 'form.field-form',
    labelInput: '.field-label-input',
    fieldTypeSelect: 'select.field-type',
    fieldTypeHiddenField: 'input[type="hidden"].field-type',
    fieldChoicesAppender: '.field-choices-appender',
    appenderAddButton: '.appender-add-button',
    choicesFormGroup: '.choices-form-group',
    choicesHiddenField: 'input[type="hidden"].field-choices',
    choiceAppenderTemplate: '.choice-appender-template',
    includeOtherChoiceCheckbox: '.field-allows-other-writein',
    fieldRequiredCheckbox: 'input.field-required',

    // UI for building choices list from PDF field name
    pdfFieldName: 'input[name="document_field[pdf_field_name]"]',
    matchingFieldsHint: '.matching-fields',
    matchingFieldsAdd: '.add-matching-fields',
    matchingFieldsCount: '.matching-fields-count',
  },

  triggers: {
    'change @ui.fieldTypeSelect': 'fieldTypeSelectChanged',
    'change @ui.fieldRequiredCheckbox': 'fieldRequiredCheckboxChanged',
    'submit @ui.form': {
      event: 'formSubmit',
      stopPropagation: false,
      preventDefault: false,
    },
  },
}) {
  initialize(options) {
    // Remove any AcroFields that are mapped automatically
    this.acroFields = _.reject(
      this.getOption('acroFields'),
      function(field){
        return _.select(['a4s', 'udf', 'fudf', 'doc'], function(prefix){
          return field.name.indexOf(`${prefix}_`) == 0 || field.type == 'signature'
        }).length > 0
      })

    // PDF Fields that have already been mapped
    this.mappedPdfFields = this.options.mappedPdfFields || []

    this.pdfFieldName = ''
  }

  onRender() {
    // Set up appender
    const choices = (this.ui.choicesHiddenField.val() || '').split("|")
    this.ui.fieldChoicesAppender.appender({
      template: this.ui.choiceAppenderTemplate.html(),
      addButton: this.ui.appenderAddButton,
      createDefaultRowsIfEmpty: true,
      numDefaultRowsToCreate: 2,
      initialCollection: choices,
    })

    // Render the Choices section if this field is a 'choiceable' field
    this.renderChoicesSection()

    // Appender steals the focus away, so we force it manually:
    this.ui.labelInput.focus()

    this.ui.matchingFieldsHint.hide()

    this.renderPdfFieldSelectList()

    setTimeout(function(){$.runInitializers(this.$el)}.bind(this))
  }

  onDestroy() {
    if (this.textInputView) {this.textInputView.destroy()}
  }

  // Render the PDF Select2/Text Editor list
  renderPdfFieldSelectList(){
    const inputEl = $('.document_field_pdf_field_name')[0]
    if (!inputEl) {return}

    const mappedPdfFields = this.mappedPdfFields

    // Build choice labels as: "Name (Label)
    // e.g. "a4s_employee_birthdate_us (Birth Date)"
    let selectChoices = _(this.acroFields).map(
      function(field){
        let label = field.name
        if (field.label && field.label.length) {label += ` (${field.label})`}
        return {
          label: label,
          value: field.name,
          checked: mappedPdfFields.indexOf(field.name) != -1,
        }
      },
    )
    selectChoices = _.sortBy(selectChoices, 'checked')

    // Build the control
    this.textInputView = new TextInputSelect2View({
      el: inputEl,
      choices: selectChoices,
      textInputHint: 'Modify PDF AcroField name, or ',
      textInputLink: '<i class=\'fa fa-caret-down\' /> select a different field from the list.',
      selectHint: 'Select the corresponding AcroForm field, or ',
      selectLink: '<i class=\'fa fa-pencil\' /> manually edit the name.',
      checkedHint: 'indicates that the PDF field has already been used.',
    })
    this.textInputView.render()

    // FIXME: Unable to get these fields to bind through triggers
    this.ui.matchingFieldsAdd.click(this.addMatchingChoices.bind(this))
    this.ui.pdfFieldName.on('change', this.onPdfFieldNameChange.bind(this))
    this.ui.pdfFieldName.on('input', this.onPdfFieldNameChange.bind(this))
  }

  // it'll either be a select or a hidden field which has the fieldType value
  getFieldType() {
    if (this.ui.fieldTypeSelect.length > 0)
    {return this.ui.fieldTypeSelect.val()}
    else
    {return this.ui.fieldTypeHiddenField.val()}
  }

  onFieldRequiredCheckboxChanged () {
    const fieldType = this.getFieldType()

    const isRequired = this.ui.fieldRequiredCheckbox.is(':checked')
    const isSingleCheckbox = (fieldType === "CheckboxField")

    if (isRequired && isSingleCheckbox == true && $("body").attr("class").indexOf("edit") < 1) {
      bootbox.alert({
        title: "Alert",
        message: "Requiring a Single Checkbox field will force the box to be checked before a user can continue.",
        className: "modal-info",
      })
    }
  }

  onFieldTypeSelectChanged () {
    this.renderChoicesSection()
    this.showChoicesHint()
  }

  onFormSubmit() {
    if (this.isFieldWithChoicesSelected()) {
      const appender = this.ui.fieldChoicesAppender.data('appender')
      this.ui.choicesHiddenField.val(appender.values().join("|"))
    } else {
      this.ui.choicesHiddenField.val('')
    }
  }

  renderChoicesSection() {
    // When switching fields, also perform the logic as if SingleCheckbox had been selected and they hit the Required
    // checkbox directly
    this.onFieldRequiredCheckboxChanged()

    const isFieldWithChoices = this.isFieldWithChoicesSelected()
    this.ui.appenderAddButton.toggle(isFieldWithChoices)
    this.ui.choicesFormGroup.toggle(isFieldWithChoices)

    // TBD: maybe support this on Dropdown field as well?? Prob not Multicheckbox.
    this.ui.includeOtherChoiceCheckbox.toggle(this.getFieldType() == "RadioField")

    // Make field choices sortable
    const el = document.getElementById('reorderable-list')
    const sortable = Sortable.create(el, {
      handle: '.choice-handle',
    })
  }

  isFieldWithChoicesSelected() {
    const fieldType = this.getFieldType()
    return _(["MultiCheckboxField", "DropdownField", "RadioField"]).contains(fieldType)
  }

  // Handle PDF Field Name change
  onPdfFieldNameChange(event){
    this.pdfFieldName = event.target.value
    this.showChoicesHint()
  }

  // Show/Hide the Choices Hint label
  showChoicesHint() {
    const matchingFieldCount = this.matchingChoices().length

    // Show the hint if:
    // - PDF Field Name is present
    // - Field type is Multicheckbox
    // - PDF Field Name is the prefix to any fields
    if (this.pdfFieldName.length > 0 && this.isFieldWithChoicesSelected() && matchingFieldCount > 0){
      this.ui.matchingFieldsCount.html(matchingFieldCount)
      this.ui.matchingFieldsHint.show()
    } else {
      this.ui.matchingFieldsHint.hide()
    }
  }

  // Add all choices that match the PDF Field Name to the choices list
  addMatchingChoices() {
    this.ui.matchingFieldsHint.hide()

    const appender = this.ui.fieldChoicesAppender.data('appender')
    appender.deleteAllRows()

    const fieldType = this.getFieldType()

    _.each(this.matchingChoices(), function(choice){
      let choiceName = null
      switch (fieldType){
      case 'MultiCheckboxField':
        choiceName = choice.name.slice(this.pdfFieldName.length + 1)
        break
      default:
        choiceName = choice.name
        break
      }
      appender.addRow(choiceName)
    }.bind(this))
  }

  // Identify all AcroFields that begin with the PDF Field name
  // (e.g. Field Name: "Had_", matches "Had_None", "Had_Asthma", "Had_Hypertension", ...)
  //
  // returns - Array of ArcoFields
  matchingChoices() {
    const fieldNamePrefix = this.pdfFieldName
    if (fieldNamePrefix.length == 0) {return []}

    let choices = []

    switch (this.getFieldType()){
    case 'MultiCheckboxField':
      // MultiCheckbox fields look for fields matching the prefix. Each checkbox is a unique field.
      choices = _.select(this.acroFields, function(field){
        const prefixWithUnderscore = `${fieldNamePrefix  }_`
        return (field.name.indexOf(prefixWithUnderscore) == 0) && (field.name.length > prefixWithUnderscore.length)
      })
      break
    case 'DropdownField':
    case 'RadioField':
      // Dropdown and Radio fields have explicitly defined choices
      var field = _.findWhere(this.acroFields, {name: fieldNamePrefix})
      if (field && field.choices && field.choices.length) {choices = field.choices}
    default:
      break
    }

    return choices
  }
}
