<template>
  <Panel variant="snapshot">
    <template v-slot:heading>
      <div class="panel-title">
        <h3>Action Required</h3>
        <h4>Duplicate Case Alert</h4>
      </div>
    </template>
    <template v-slot:body>
      <p class="alert alert-warning">A duplicate continue reason must be provided to continue creating this case.</p>
      <p>
        A Duplicate Cases Found alert appears for a case that contains information found in one or more cases created within the last 365 days under the same employer account. There may be valid reasons for to create a duplicate case, such as if the previous case contains incorrect information. Review the situation and decide whether to continue with the present case.
      </p>
      <p>
        To continue this case, select a reason below:
      </p>
      <I9DuplicateReasonForm
        :everifyCase="everifyCase"
        @update="handleUpdate"
      />
    </template>
  </Panel>
</template>

<script>
  import Panel from 'components/common/Panel'
  import I9DuplicateReasonForm from 'components/admin/document_submissions/I9DuplicateReasonForm'
  import Util from 'lib/util'

  export default {
    components: {
      Panel,
      I9DuplicateReasonForm,
    },

    props: {
      everifyCase: {
        type: Object,
        required: true,
      },
    },

    methods: {
      handleUpdate() {
        this.closeModal()
        Util.showFlashNotice("Request is processing, case will automatically update when the request has finished.")
      },

      closeModal() {
        this.$emit('close')
      },
    },
  }
</script>
