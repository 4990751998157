// Child view for use with DocumentReorderingCollectionView
// 
// model - (Document)
export default Marionette.ItemView.extend({
  template: "#document-reordering-child-view-tmpl",
  tagName: "li",
  className: "sortable-list-item",

  attributes: function() {
    return { 
      // this ID attribute is needed by Sortable jQuery plugin
      'data-id': this.model.id,
    }
  },
})
