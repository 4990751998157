<template>
  <WbWorkflowStep
    :stepNumber="1"
    title="Confirm Location"
  >
    <WbGeolocation
      buttonText="Share My Location"
      @wbGeolocationSuccess="handleGeolocationSuccess"
      @wbGeolocationError="handleGeolocationError"
    >
      <p>
        To complete this form, you will need to select a person to review your documents. This person must:
      </p>
      <ul>
        <li>Be at least {{ minimumAgeToCountersign }} years of age</li>
        <li>Have access to a separate camera-enabled smartphone</li>
        <li>Be able to log in to an internet-connected device</li>
        <li>Be able to meet In person with you</li>
        <li>Be willing to sign that they have reviewed your document(s) and that they appear genuine and to relate to you.</li>
      </ul>
      <p>UNDER PENALTY OF PERJURY. YOU CANNOT SIGN YOUR OWN FORM I-9 SECTION 2.</p>
      <p>When you are ready, click the button to share your location.</p>
    </WbGeolocation>
  </WbWorkflowStep>
</template>
<script>
  import WbWorkflowStep from 'components/common/WbWorkflowStep'
  import WbGeolocation from 'components/common/WbGeolocation'

  export default {
    components: {
      WbGeolocation,
      WbWorkflowStep,
    },
    props: {
      minimumAgeToCountersign: {
        type: Number,
        required: true,
      },
    },
    emits: [
      'geolocationSuccess',
      'geolocationError',
    ],
    methods: {
      handleGeolocationSuccess (position) {
        this.$emit('geolocationSuccess', position)
      },
      handleGeolocationError (errorMessage) {
        this.$emit('geolocationError', errorMessage)
      },
    },
  }
</script>
