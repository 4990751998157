<template>
  <div class="policy-generator">
    <hr />
    <WbWizard
      @previous="handlePrevStep"
      @next="nextStep"
      :loading="isLoading"
      :nextLabel="nextLabel"
    >
      <template #steps>
        <WbOrderedList>
          <WbOrderedListItem
            v-for="(step, index) in stepList"
            :key="index"
            :active="step.active"
          >
            {{ step.label }}
          </WbOrderedListItem>
        </WbOrderedList>
      </template>
      <template #body>
        <component
          :is="currentStepComponentName"
          :policy="policy"
          :firstName="firstName"
          @skip="skipStep"
          ref="currentStep"
        />
      </template>
    </WbWizard>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import StepSelectGroups from 'components/permissions/steps/StepSelectGroups'
  import StepProfileFields from 'components/permissions/steps/StepProfileFields'
  import StepProfileFieldsCustomize from 'components/permissions/steps/StepProfileFieldsCustomize'
  import StepDocuments from 'components/permissions/steps/StepDocuments'
  import StepDocumentsCustomize from 'components/permissions/steps/StepDocumentsCustomize'
  import StepEmployeeManagement from 'components/permissions/steps/StepEmployeeManagement'
  import StepOtherPeople from 'components/permissions/steps/StepOtherPeople'
  import StepOtherActions from 'components/permissions/steps/StepOtherActions'
  import StepSummary from 'components/permissions/steps/StepSummary'
  import BaseWizard from 'components/common/BaseWizard'
  import PolicySanitizer from 'models/policy_generator/policy_sanitizer'
  import Util from 'lib/util'
  import WbOrderedList from 'components/common/WbOrderedList'
  import WbOrderedListItem from 'components/common/WbOrderedListItem'
  import WbWizard from 'components/common/WbWizard'

  export default {

    name: 'permissions-wizard',

    components: {
      StepSelectGroups,
      StepProfileFields,
      StepProfileFieldsCustomize,
      StepDocuments,
      StepDocumentsCustomize,
      StepEmployeeManagement,
      StepOtherPeople,
      StepOtherActions,
      StepSummary,
      WbOrderedList,
      WbOrderedListItem,
      WbWizard,
    },
    mixins: [BaseWizard],

    props: {
      policy: {
        type: Object,
        required: true,
      },

      firstName: {
        type: String,
        required: true,
      },
    },

    data: () => {
      return {
        steps: [
          { component: 'step-select-groups', label: 'Select groups' },
          { component: 'step-profile-fields', label: 'Profile Fields' },
          { component: 'step-profile-fields-customize', label: 'Profile Fields' },
          { component: 'step-documents', label: 'Forms' },
          { component: 'step-documents-customize', label: 'Forms' },
          { component: 'step-employee-management', label: 'Employee Management' },
          { component: 'step-other-people', label: 'Other People' },
          { component: 'step-other-actions', label: 'Other Actions' },
          { component: 'step-summary', label: 'Summary' },
        ],
        currentStepIdx: 0,
        navDirection: null,
        history: null,
        isLoading: false,
      }
    },

    created() {
      this.loadPermissionsResources()
    },

    methods: {
      ...mapActions({
        fetchPermissionPolicies: 'permissions/fetchPermissionPolicies',
      }),

      done() {
        this.$emit('done')
      },

      async loadPermissionsResources () {
        this.isLoading = true
        
        try {
          await this.fetchPermissionPolicies()

          const policySanitizer = new PolicySanitizer(this.policy, this.$store)
          
          this.$emit('update:policy', policySanitizer.sanitize())
        } catch (xhr) {
          Util.genericAjaxError(this.$locale.t("errors.ajax.generic"), xhr)
        } finally {
          this.isLoading = false
        }
      },
    },
  }
</script>
