import PrerenderedItemView from 'views/common/prerendered_item_view'
import EmployeeGroupsListTableCollectionView from 'views/employees/employee_groups_list_table_collection_view'
import SimpleTableEmptyView from 'views/common/simple_table_empty_view'

export default PrerenderedItemView.extend({
  ui: {
    showArchivedGroupsWrapper: '#js-show-archived-groups',
    showArchivedGroups: '#js-show-archived-groups input',
    archivedNotShownMessage: '.js-archived-not-shown-message',
  },

  events: {
    'click .js-add-employee-group-action': 'addEmployeeGroup',
    'click @ui.showArchivedGroups': 'toggleArchivedGroups',
  },

  initialize: function() {
    const employee = this.model

    this.archivedEmployeeGroups = this.model.get('groups').filter((g) => { return g.get('archived') })

    this.employeeGroupsCollectionView = new EmployeeGroupsListTableCollectionView({ 
      el: '#employee-groups-info-table',
      collection: employee.get('groups'),
      emptyView: SimpleTableEmptyView,
      emptyViewOptions: { message: "This employee is not part of any groups.", colspan: '2' },
      employeeGroups: window.gon.employeeGroups,  // all employee groups available (for adding new ones)
      employee: employee,
    })
  },

  addEmployeeGroup: function() {
    const proxyTo = this.employeeGroupsCollectionView
    return $.proxy(proxyTo.addEmployeeGroup, proxyTo)()
  },

  onRender: function() {
    this.employeeGroupsCollectionView.render()

    if (this.archivedEmployeeGroups.length == 0) {
      this.ui.showArchivedGroupsWrapper.addClass('disabled')
      this.ui.showArchivedGroups.prop('disabled', true)
      this.ui.showArchivedGroupsWrapper.tooltip({
        title: 'This employee is not a member of any archived groups.',
      })
    }

    this.updateVisibleEmployeeGroups(false)
  },

  onBeforeDestroy: function() {
    this.employeeGroupsCollectionView.destroy()
  },

  // Click handler for toggling visibility of archived EmployeeGroups
  toggleArchivedGroups: function() {
    const showArchivedGroups = this.ui.showArchivedGroups.is(':checked')
    this.updateVisibleEmployeeGroups(showArchivedGroups)
  },

  // Update the list of EmployeeGroups to reflect new filtering state
  updateVisibleEmployeeGroups: function(showArchivedGroups) {
    // Filter by detaching and appending the archived elements.
    // Simply hiding/showing didn't allow the table CSS to hide the top border on the first element,
    // if the first element is a hidden group. detach() lets us keep the JS bindings, vs remove().
    if (showArchivedGroups) {
      this.$el.find('table').append(this.removedArchivedGroupEls)
      this.ui.archivedNotShownMessage.hide()
    } else {
      this.removedArchivedGroupEls = this.$el.find('.archived').detach()

      const archivedCount = this.archivedEmployeeGroups.length
      if (archivedCount > 0) {
        this.ui.archivedNotShownMessage.show()
        // Message: "(<X> archived group<s> not shown.)"
        this.ui.archivedNotShownMessage.text(`(${archivedCount} archived group${archivedCount == 1 ? '' : 's'} not shown)`)
      }
    }
  },
})
