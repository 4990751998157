// Individual documentation choice (not to be confused with an I9Attachment, which reflects the specific data entered
// or uploaded by the user for a given documentation choice).
export default class I9DocumentationChoice extends Backbone.Model.extend({}) {
  // Parses deeply-nested `additional_docs`
  parse(response, _options) {
    if (!_.isEmpty(response.additional_docs)) {
      response.additional_docs = new Backbone.Collection(response.additional_docs, { model: I9DocumentationChoice })
    }
    return response
  }

  // Gets the hint for a particular field from the `hints` structure, if available
  //
  // fieldName - (String) the name of the field
  //
  // Returns - String or null
  getHint(fieldName) {
    const hints = this.get('hints')
    if (!hints) {
      return null
    }

    return hints[fieldName]
  }

  // Whether or not a back file is necessary, as determined by the presence of `back_file: false` in the JSON structure.
  //
  // Returns - Boolean
  get isBackFileRequired() {
    return (this.get('back_file') !== false)
  }

  // Whether or not Persona is able to verify this document choice
  //
  // Returns - Boolean
  get isPersonaSupported() {
    return (this.get('persona_supported') === true)
  }

  // Whether or not a front file is necessary, as determined by the presence of `front_file: false` in the JSON structure.
  //
  // Returns - Boolean
  get isFrontFileRequired() {
    return (this.get('front_file') !== false)
  }

  // Whether or not listing countries as issuing authority is necessary, as determined by the presence of `countries_as_issuing_authority: true` in the JSON structure.
  //
  // Returns - Boolean
  get hasCountriesAsIssuingAuthority() {
    return (this.get('countries_as_issuing_authority') === true)
  }

  // Whether or not an expiration date is required, as determined by the presence of `expiration_date: false` in the JSON
  //
  // Returns - Boolean
  get isExpirationDateRequired() {
    return (this.get('expiration_date') !== false)
  }

  // Whether or not a choice has multiple options for a prefilled field, e.g. multiple issuing authorities
  //
  // fieldName - (String) the name of the field
  //
  // Returns - Boolean
  hasMultiplePrefilledValues(fieldName) {
    return _.isArray(this.get(fieldName))
  }

  // Whether or not the document overall is optional
  //
  // Returns - Boolean
  get isOptional() {
    return (this.get('optional') === true)
  }

  // Whether or not the word Receipt should be prepended to doc title
  //
  // Returns - Boolean
  get shouldPrependReceiptText() {
    return (this.get('key') !== 'other_acceptable_receipt')
  }

  // Gets the input mask to use with `mask` option of jquery.inputmask plugin
  //
  // Returns - String or null if no format provided
  get documentNumberInputMask() {
    const numFormat = this.get('document_number_format')
    if (!numFormat) {
      return null
    }

    return numFormat.input_mask
  }

  // Whether or not citizenship is valid for documentation choice
  //
  // citizenshipDesignation - (String) the citizenship type, e.g. (citizen, permanent_resident, etc.)
  //
  // Returns - Boolean
  validCitizenship(citizenshipDesignation) {
    return _.contains(this.get('citizenships'), citizenshipDesignation)
  }
}
