// Remove any missing data from a policy
class PolicySanitizer {
  constructor(policy, store) {
    this.store = store
    this.policy = policy
  }

  sanitize() {
    this._removeGroups()

    return this.policy
  }

  // Remove any non-existent groups listed in PermissionSet scopes
  _removeGroups() {
    this.policy.permissionSets.forEach((permissionSet) => {
      const scope = permissionSet.scope

      // Any groups to remove in this scope?
      if (!scope || scope.field_key != 'a4s_employee_groups' || !scope.value || scope.value.length == 0) { return }

      // Filter to groups that exist
      scope.value = scope.value.filter((groupId) => this.store.getters['groups/get'](groupId))
    })
  }
}

export default PolicySanitizer
