<template>
  <div>
    <h4>{{ stepHeader }}</h4>
    <div class="form-horizontal">
      <div class="form-group clearfix" v-for="(permission, index) in generalPermissions" :key="index">
        <PermissionsToggleWide :permission="permission" :label="true" v-on:permissionChanged="permissionChanged" />
      </div>
    </div>
  </div>
</template>

<script>
  import BaseStep from 'components/permissions/steps/BaseStep'
  import PermissionsToggleWide from 'components/permissions/common/PermissionsToggleWide'
  import GeneralPermissions from 'models/policy_generator/general_permissions'

  export default {
    name: 'step-other-actions',

    components: {
      PermissionsToggleWide,
    },
    extends: BaseStep,

    props: {
      managedPolicy: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      generalPermissions() {
        const gp = new GeneralPermissions()
        return this.policy.otherPermissionSet().findOrCreatePermissionsFromDefinitions(
          gp.BASELINE_DEFINITIONS,
        )
      },

      stepHeader() {
        let headerText = null

        if (this.managedPolicy){
          headerText = this.$locale.t('permissions.other_actions_step.managed.stepHeader')
        }
        else {
          headerText = this.$locale.t('permissions.other_actions_step.stepHeader', this.firstName)
        }

        return headerText
      },
    },

    methods: {
      permissionChanged(permission, grant) {
        const permissionSet = this.policy.otherPermissionSet()
        permissionSet.togglePermission(permission, grant)
      },
    },
  }
</script>
