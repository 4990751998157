<template ref="panelView">
  <div>
    <table
      class="table table-responsive info-table panel-info-table"
      v-if="customFieldsInternal.length > 0"
      ref="internalFields"
    >
      <thead>
        <tr class="info-sub-header">
          <th>Internal Fields</th>
          <th class="text-right">
            <small>(hidden from staff member)</small>
          </th>
        </tr>
      </thead>
      <tbody ref="internalFieldRows">
        <ProfileRow
          v-for="(field, index) in customFieldsInternal"
          :key="index"
          :label="field.label"
          :value="field.value_decorated"
        />
      </tbody>
    </table>
    <table
      class="table table-responsive info-table panel-info-table"
      v-if="customFieldsNotInternal.length > 0"
      ref="otherFields"
    >
      <thead>
        <tr class="info-sub-header">
          <th>Other Fields</th>
          <th class="text-right">
            <small>(completed by staff member)</small>
          </th>
        </tr>
      </thead>
      <tbody >
        <ProfileRow
          v-for="(field, index) in customFieldsNotInternal"
          :key="index"
          :label="field.label"
          :value="field.value_decorated"
        />
      </tbody>
    </table>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import ProfileRow from 'components/employees/ProfileRow'

  export default {
    name: 'custom-fields-panel-view',

    components: {
      ProfileRow,
    },

    computed: {
      employee() {
        return this.getEmployee(this.pageContext.employeeId)
      },

      customFields() {
        return this.employee.custom_fields
      },

      customFieldsInternal() {
        return this.customFields.filter((c) => {
          return this.getCustomField(c.id).internal
        })
      },

      customFieldsNotInternal() {
        return this.customFields.filter((c) => {
          return !this.getCustomField(c.id).internal
        })
      },

      ...mapGetters({
        pageContext: 'pageContext',
        getEmployee: 'employees/get',
        getCustomField: 'custom_fields/get',
      }),
    },
  }
</script>
