<template>
  <div>
    <component
      ref="field"
      :is="componentName"
      :label="field.label"
      :name="name"
      :errors="errors"
      :options="options"
      orientation="horizontal"
      :value="value"
      :hint="field.description"
      :placeholder="placeholderValue"
      :disabled="disabled"
      :required="required"
      @input="handleChange"
    />
  </div>
</template>

<script>
  import FormFieldInput from 'components/common/FormFieldInput'
  import FormFieldTextArea from 'components/common/FormFieldTextArea'
  import FormFieldNumber from 'components/common/FormFieldNumber'
  import FormFieldDate from 'components/common/FormFieldDate'
  import FormFieldRadio from 'components/common/FormFieldRadio'
  import FormFieldCheckbox from 'components/common/FormFieldCheckbox'
  import FormFieldCurrency from 'components/common/FormFieldCurrency'
  import FormFieldDropdown from 'components/common/FormFieldDropdown'
  import FormFieldMultiCheckbox from 'components/common/FormFieldMultiCheckbox'

  export default {
    name: 'form-field-variable',

    components: {
      FormFieldInput,
      FormFieldTextArea,
      FormFieldDate,
      FormFieldRadio,
      FormFieldCheckbox,
      FormFieldNumber,
      FormFieldCurrency,
      FormFieldDropdown,
      FormFieldMultiCheckbox,
    },

    props: {
      field: {
        type: Object,
        required: true,
      },

      name: {
        type: String,
        require: false,
      },

      value: {},

      errors: {
        type: Object,
        required: false,
      },

      disabled: {
        type: Boolean,
        required: false,
      },

      required: {
        type: Boolean,
      },
    },

    computed: {
      componentName() {
        return {
          TextField: 'form-field-input',
          MultilineTextField: 'form-field-text-area',
          NumberField: 'form-field-number',
          CurrencyField: 'form-field-currency',
          DateField: 'form-field-date',
          DropdownField: 'form-field-dropdown',
          RadioField: 'form-field-radio',
          CheckboxField: 'form-field-checkbox',
          MultiCheckboxField: 'form-field-multi-checkbox',
        }[this.field.type]
      },

      options() {
        if (this.field.ordered_choices) {
          return this.field.ordered_choices.map((o) => { return { label: o[0], value: o[1] } })
        } else {
          return null
        }
      },

      placeholderValue() {
        return this.disabled ? null : this.field.placeholder
      },
    },

    methods: {
      handleChange(value) {
        this.$emit('input', value)
      },
    },
  }
</script>
