<template>
  <div>
    <h2>I-9 In-Person Review</h2>
    <I9RemoteCountersignAuthRepContainer />
  </div>
</template>

<script>
  import I9RemoteCountersignAuthRepContainer from 'components/i9_remote_countersign/I9RemoteCountersignAuthRepContainer'
  export default {
    name: 'the-i9-remote-countersign-auth-rep-root',
    components: {
      I9RemoteCountersignAuthRepContainer,
    },
  }
</script>
