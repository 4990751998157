import EmployeeGroupRowItemView from 'views/employee_groups/employee_group_row_item_view'
import EmployeeGroupListTableCollectionView from 'views/employee_groups/employee_group_list_table_collection_view'
import EmployeeGroupsCollection from 'collections/employee_group_collection'

export default Marionette.Controller.extend({
  initialize: function(options) {
  },

  attachToListTable: function($tableEl, gonCollection) {
    this.listTableCollectionView = new EmployeeGroupListTableCollectionView()

    const collection = new EmployeeGroupsCollection(gonCollection)
    this.listTableCollectionView.attachToTableWithCollection($tableEl, collection)
  },

  finalize: function() {
    if (this.listTableCollectionView) {
      this.listTableCollectionView.destroy()
    }
  },

  toggleArchiveEmployeeGroup: function(id, value) {
    const collectionView = this.listTableCollectionView

    const employeeGroup = this.listTableCollectionView.collection.get(id)
    if (!employeeGroup || employeeGroup.get("archived") === value)
    {return} // noop

    const valueToString = (value ? 'archive' : 'unarchive')

    employeeGroup.save({
      archived: value,
    }, {
      patch: true,
      wait: true,
      wbGenericFailureMsg: `Sorry, we couldn't ${valueToString} this group.`,
    }).done(function(data, status, jqXHR) {
      App.Util.showAjaxFlashNotice(jqXHR)
    })
  },

  deleteEmployeeGroup: function(id) {
    const collectionView = this.listTableCollectionView
    const employeeGroup = this.listTableCollectionView.collection.get(id)
    if (!employeeGroup)
    {return}

    employeeGroup.destroy({
      wait: true,
      wbGenericFailureMsg: "Sorry, we couldn't delete this group.",
    }).done(function(data, status, jqXHR) {
      App.Util.showAjaxFlashNotice(jqXHR)
    })
  },
})
