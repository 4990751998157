/* SubheaderRegion –
 *
 */
export const SubheaderRegion = Marionette.Region.extend({
  el: "header#subheader",

  showAjaxFlash: function(message, options) {
    App.Util.showAjaxFlashInTarget($("#ajax-flash"), message, options)
  },

  isPresent: function() {
    return $(this.el).length > 0
  },
})

/* MainContainerRegion –
 *
 */
export const MainContainerRegion = Marionette.Region.extend({
  el: "#main",
})
