import Permission from 'models/policy_generator/permission'
import GeneralPermissions from 'models/policy_generator/general_permissions'
import Policy from 'models/policy_generator/policy'

// Construct UserPermission model from imported JSON
export function PolicyUnmarshaller(permissionSets) {
  const policy = new Policy()

  const groupPermissionSet = policy.groupPermissionSet()
  const baselinePermissionSet = policy.baselinePermissionSet()
  const otherPermissionSet = policy.otherPermissionSet()

  const initialGroupPermissionSet = permissionSets.find((p) => p.scope && p.scope.field_key == 'a4s_employee_groups')
  const initialBaselinePermissionSet = permissionSets.find((p) => !p.scope)

  // Configure Group Permission Set
  if (initialGroupPermissionSet) {
    groupPermissionSet.scope.value = initialGroupPermissionSet.scope.value
    groupPermissionSet.appendPermissions(initialGroupPermissionSet.permissions.map((p) => new Permission(p)))
  }

  // Configure Other Permission Set
  const gp = new GeneralPermissions()
  const otherDefinitions = gp.BASELINE_DEFINITIONS
  let otherPermissions
  if (initialBaselinePermissionSet) {
    otherPermissions = otherDefinitions.map((def) => {
      return initialBaselinePermissionSet.permissions.find((p) => {
        return p.target_id == def.target_id &&
                p.target_type == def.target_type &&
                p.action == def.action
      })
    }).filter((p) => !!p)
    otherPermissionSet.appendPermissions(otherPermissions.map((p) => new Permission(p)))
  }

  // Configure Baseline Permission Set
  if (initialBaselinePermissionSet) {
    const baselinePermissions = initialBaselinePermissionSet.permissions.filter((p) => {
      let partOfSet = true
      otherPermissions.forEach((op) => {
        if (op.target_id == p.target_id && op.target_type == p.target_type && op.action == p.action) {
          partOfSet = false
        }
      })
      return partOfSet
    })
    baselinePermissionSet.appendPermissions(baselinePermissions.map((p) => new Permission(p)))
  }

  return policy
}
