<template>
  <DashboardWidget
    :widgetTitle="title"
    :errorMsg="errorMsg"
    :lastUpdated="lastUpdated"
    @refresh="fetch(true)"
  >
    <v-wait :for="source">
      <template #waiting>
        <Spinner :showLongWaitMessage="true" />
      </template>
      <p class="wb-card__section-title">
        Sign-in activity
      </p>
      <WbBreakdownChart
        v-if="!errorMsg.length && chartData && chartData.session_progress"
        :legendData="legendData.session_progress"
        :chartData="chartData.session_progress"
        :listViewPath="activeStaffPath"
        :modelNameHumanized="modelName"
        :tooltipWarning="chartTooltipWarning"
        @section-click="handleSessionProgressSectionClick"
      />
      <hr />
      <p class="wb-card__section-title">
        Required Forms
      </p>
      <WbBreakdownChart
        v-if="!errorMsg.length && chartData && chartData.form_progress"
        :legendData="legendData.form_progress.required_non_internal"
        :chartData="chartData.form_progress.required_non_internal"
        :listViewPath="activeStaffRequiredFormsPath"
        :modelNameHumanized="modelName"
        :tooltipWarning="chartTooltipWarning"
        @section-click="handleRequiredNonInternalFormProgressSectionClick"
      />
      <hr />
      <p class="wb-card__section-title">
        Required Internal Forms
      </p>
      <WbBreakdownChart
        v-if="!errorMsg.length && chartData && chartData.form_progress"
        :legendData="legendData.form_progress.required_internal"
        :chartData="chartData.form_progress.required_internal"
        :listViewPath="activeStaffPath"
        :modelNameHumanized="modelName"
        :tooltipWarning="chartTooltipWarning"
        @section-click="handleRequiredInternalFormProgressSectionClick"
      />
    </v-wait>
  </DashboardWidget>
</template>

<script>
  import WbBreakdownChart from 'components/common/WbBreakdownChart'
  import Spinner from 'components/common/Spinner'
  import DashboardWidget from 'components/admin/dashboard/DashboardWidget'
  import DashboardFetch from 'components/mixins/DashboardFetch'
  import { BREAKDOWN_VARIANTS } from 'components/admin/dashboard/BreakdownChartConstants'

  export default {
    name: 'forms-summary-container',
    components: {
      WbBreakdownChart,
      Spinner,
      DashboardWidget,
    },
    mixins: [DashboardFetch],

    data() {
      return {
        title: 'Staff Progress Summary',
        source: 'dashboard_required_forms_summary',
        modelName: 'active hire',
        activeStaffPath: `${this.$routes.employees_path}?employment_status=active`,
        activeStaffRequiredFormsPath: `${this.$routes.employees_path}?employment_status=active&onboarding_progress[]=missing_all_forms&onboarding_progress[]=in_progress&onboarding_progress[]=finished`,
        chartTooltipWarning: "We can't show you these staff just yet, but it's coming soon.",
        legendData: {
          form_progress: {
            required_non_internal: [
              {
                legend_key: 'fulfilled',
                message: 'with all forms complete',
                path: this.staffListFilterPath('&onboarding_progress[]=finished'),
                variant: BREAKDOWN_VARIANTS.COMPLETE,
              },
              {
                legend_key: 'incomplete',
                message: 'started forms, with incomplete forms',
                path: this.staffListFilterPath('&onboarding_progress[]=in_progress'),
                variant: BREAKDOWN_VARIANTS.IN_PROGRESS,
              },
              {
                legend_key: 'not_started',
                message: 'not started forms, with all forms missing',
                path: this.staffListFilterPath('&onboarding_progress[]=missing_all_forms'),
                variant: BREAKDOWN_VARIANTS.NEEDS_ATTENTION,
              },
            ],

            required_internal: [
              {
                legend_key: 'fulfilled',
                message: 'with all forms complete',
                path: this.staffListFilterPath('&internal_onboarding_progress[]=finished'),
                variant: BREAKDOWN_VARIANTS.COMPLETE,
              },
              {
                legend_key: 'incomplete',
                message: 'with incomplete forms',
                path: this.staffListFilterPath('&internal_onboarding_progress[]=in_progress'),
                variant: BREAKDOWN_VARIANTS.IN_PROGRESS,
              },
              {
                legend_key: 'not_started',
                message: 'with all forms missing',
                path: this.staffListFilterPath('&internal_onboarding_progress[]=missing_all_forms'),
                variant: BREAKDOWN_VARIANTS.NEEDS_ATTENTION,
              },
            ],
          },

          session_progress: [
            {
              legend_key: 'signed_in',
              message: 'signed in',
              path: this.staffListFilterPath('&last_login=anytime'),
              variant: BREAKDOWN_VARIANTS.COMPLETE,
            },
            {
              legend_key: 'not_signed_in',
              message: 'not yet signed in',
              path: this.staffListFilterPath('&last_login=never'),
              variant: BREAKDOWN_VARIANTS.NEEDS_ATTENTION,
            },
          ],
        },
      }
    },

    methods: {
      staffListFilterPath(filterName) {
        return `${this.$routes.employees_path}?employment_status=active${filterName}`
      },

      handleSessionProgressSectionClick(section){
        this.findSectionAndFollowPath(this.legendData.session_progress, section)
      },

      handleRequiredNonInternalFormProgressSectionClick(section){
        this.findSectionAndFollowPath(this.legendData.form_progress.required_non_internal, section)
      },

      handleRequiredInternalFormProgressSectionClick(section) {
        this.findSectionAndFollowPath(this.legendData.form_progress.required_internal, section)
      },

      findSectionAndFollowPath(progress, section){
        Turbolinks.visit(progress.find(e => e.legend_key == section.legend_key).path)
      },
    },
  }
</script>
