// I-9 Form Signature Step
import RemoteFormValidation from 'lib/remote_form_validation'
import SignaturePadView from 'views/common/forms/signature_pad_view'
import Routes from 'lib/routes'
import Constants from 'lib/constants'
import Util from 'lib/util'


export default Backbone.Marionette.ItemView.extend({
  template: '#i9-form-step-signature-tmpl',
  ui: {
    integratedSignaturePad: '.integrated-signature-pad',
    form: 'form',
  },

  onShow() {
    // Run later in the UI event loop, after the wizard step has fully faded in
    setTimeout(() => {
      const signaturePadView = new SignaturePadView({ el: this.ui.integratedSignaturePad[0], model: this.model })
      signaturePadView.render()

      new RemoteFormValidation({
        partialValidation: false,
        path: Routes.staff_i9_submission_path,
        resourceName: 'i9_submission',
        model: this.model,
        formEl: this.ui.form,
        validations: {
          "i9_submission[signature_data_uri]": { excluded: false, validators: {  wbRemote: {} } },
        },
        successCallback: (xhr) => {
          this.validationCompleteCallback(true, xhr)
        },
        failedCallback: () => {
          NProgress.remove()

          this.validationCompleteCallback(false)
        },
        errorCallback: (xhr) => this.onRemoteValidateError(xhr),
      })
    }, Constants.CSS_FADE_DURATION)
  },

  validate(validationCompleteCallback) {
    this.validationCompleteCallback = validationCompleteCallback

    // Because this step will process the final I9 if validation succeeds, let's show an NProgress bar all the way
    // until the page redirects.
    NProgress.start()

    this.ui.form.submit()
  },

  onRemoteValidateError(xhr) {
    NProgress.remove()

    if (xhr.status == 302) {
      Util.navigateAndShowModalNotice(Routes.staff_dashboard_path, xhr)
      return
    }

    this.validationCompleteCallback(false)
  },
})
