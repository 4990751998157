<template>
  <SlideoutPanel
    ref="DocumentSubmissionSlideout"
    :largeModal="true"
    size="xl"
    :title="title"
    watchPageContextVariable="DocumentSubmissionSlideout"
    @modalHidden="handleModalHide"
  >
    <Spinner
      ref="Loader"
      v-if="isLoading"
    />
    <template
      v-else
    >
      <DocumentSubmission
        ref="DocumentSubmission"
        v-if="hasSufficientData"
        :submission="submission"
        :employee="employee"
        @reviewed="handleReviewed"
        :rejectionReasonModalKey="rejectionReasonModalKey"
        :allowDownload="allowDownload"
      />
      <div
        class="alert"
        v-else
      >
        Could not load submission
      </div>
    </template>
  </SlideoutPanel>
</template>
<script>
  import DocumentSubmission from 'components/document_submissions/DocumentSubmission'
  import Spinner from 'components/common/Spinner'
  import SlideoutPanel from 'components/common/SlideoutPanel'
  import Util from 'lib/util'

  import {
    mapActions,
    mapGetters,
    mapMutations,
  } from 'vuex'
  import {
    DELETE_PAGE_CONTEXT_KEY,
    SET_PAGE_CONTEXT_KEYS,
  } from 'vuex/mutation_types'

  export default {
    components: {
      DocumentSubmission,
      Spinner,
      SlideoutPanel,
    },

    props: {
      shown: {
        type: Boolean,
        default: false,
      },
      submissionId: {
        type: [String, Number],
      },
      allowDownload: {
        type: Boolean,
        default: false,
      },
    },

    data () {
      return {
        isLoading: false,
        rejectionReasonModalKey: 'i9_submission_rejection_reason',
      }
    },

    computed: {
      ...mapGetters({
        getSubmission: 'document_submissions/get',
        getEmployee: 'employees/get',
      }),
      hasSufficientData () {
        return !!this.submission
      },
      submission () {
        return this.getSubmission(this.submissionId)
      },
      employeeId () {
        return this.submission?.employee_id
      },
      employee() {
        return this.getEmployee(this.employeeId)
      },
      title () {
        return this.submission?.document_name || ''
      },
    },

    watch: {
      shown () {
        if (this.shown) {
          this.show()
        } else {
          this.hide()
        }
      },
      submissionId (newVal) {
        if (newVal) {
          this.fetchData()
        }
      },
    },

    methods: {
      ...mapActions({
        fetchSubmission: 'document_submissions/fetchSubmission',
        employeesBulkShow: 'employees/bulkShow',
      }),
      ...mapMutations({
        setPageContextKeys: SET_PAGE_CONTEXT_KEYS,
        deletePageContextKey: DELETE_PAGE_CONTEXT_KEY,
      }),
      async fetchData () {
        this.isLoading = true

        try {
          await this.fetchSubmission(this.submissionId)
          await this.fetchEmployee()
        } catch (_e) {
          Util.genericAjaxError("An error occurred.", _e)
        } finally {
          this.isLoading = false
        }
      },
      handleModalHide () {
        this.hide()
      },
      hide () {
        this.deletePageContextKey(this.rejectionReasonModalKey)
        this.deletePageContextKey('DocumentSubmissionSlideout')
        this.$emit('hidden')
      },
      show () {
        this.setPageContextKeys({DocumentSubmissionSlideout: true})
      },
      async fetchEmployee () {
        await this.employeesBulkShow({
          employeeIds: [this.employeeId],
        }).catch(() => {
          // Error! Close the slideout
          this.deletePageContextKey('DocumentSubmissionSlideout')
        })
      },
      handleReviewed (currentSubmissionId) {
        this.$emit('reviewed', currentSubmissionId)
      },
    },

  }
</script>
