import SignaturePadView from 'views/common/forms/signature_pad_view'
import W4WorksheetModalView from './w4_worksheet_modal_view'

// W4 Submission form
export default Marionette.LayoutView.extend({
  template: false,
  ui: {
    openPersonalAllowancesWorksheetButton: '.btn-open-personal-allowances-worksheet',
    worksheetModalEl: '.w4-allowances-worksheet-modal',
    numAllowancesInput: '#w4_submission_num_allowances',
  },
  events: {
    'click @ui.openPersonalAllowancesWorksheetButton': 'handleOpenPersonalAllowancesWorksheetClick',
  },

  initialize() {
    // Initialize the worksheet data. If the user submitted the form and the server re-rendered (e.g. due to validatione
    // errors), we need to pull the values from the <input> tags. We can use the hueristic of whether or not A == '1'
    // to determine whether this is a blank worksheet or a re-render (as of 2018 W4, A is always '1' and cannot be
    // changed by the user).
    this.worksheetDataModel = new Backbone.Model({
      a: $('#w4_submission_personal_allowances_worksheet_a').val()        || 1,
      b: $('#w4_submission_personal_allowances_worksheet_b').val()        || null,
      c: $('#w4_submission_personal_allowances_worksheet_c').val()        || null,
      d: $('#w4_submission_personal_allowances_worksheet_d').val()        || null,
      e_income: $('#w4_submission_personal_allowances_worksheet_e_income').val() || null,
      e_number: $('#w4_submission_personal_allowances_worksheet_e_number').val() || null,
      e: $('#w4_submission_personal_allowances_worksheet_e').val()        || null,
      f_income: $('#w4_submission_personal_allowances_worksheet_f_income').val() || null,
      f_number: $('#w4_submission_personal_allowances_worksheet_f_number').val() || null,
      f: $('#w4_submission_personal_allowances_worksheet_f').val()        || null,
      g: $('#w4_submission_personal_allowances_worksheet_g').val()        || null,
      h: $('#w4_submission_personal_allowances_worksheet_h').val()        || 1,
    })
  },

  onRender() {
    this._signaturePadView = new SignaturePadView({ el: $('.integrated-signature-pad')[0] })
    this._signaturePadView.render()
  },

  onBeforeDestroy() {
    if (this._signaturePadView) {
      this._signaturePadView.destroy()
    }
  },

  // Open worksheet modal and hook into the "Done" event to update the allowances
  handleOpenPersonalAllowancesWorksheetClick(e) {
    e.preventDefault()
    e.stopPropagation()

    // NOTE: W4WorksheetModalView will destroy itself whenever the bootstrap modal is closed. Thus, we don't need
    // to retain a reference to this instance, and we can safely re-render and re-listenTo events
    const worksheetModalView = new W4WorksheetModalView({
      el: this.ui.worksheetModalEl[0],
      model: this.worksheetDataModel,
    })
    this.listenTo(worksheetModalView, 'worksheet:saved', this.updateAllowancesFromWorksheet.bind(this))
    worksheetModalView.render()
  },

  // Callback when worksheet is saved (and not canceled)
  updateAllowancesFromWorksheet(worksheetModal) {
    this.ui.numAllowancesInput.val(worksheetModal.get('h'))
    this.worksheetDataModel = worksheetModal
  },
})
