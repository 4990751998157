import Permission from 'models/policy_generator/permission'
import TemplatedPermissions from 'models/policy_generator/templated_permissions'


// Pre-built Document permission sets that can be applied quickly to fit the use case of most users
export default function(){
  TemplatedPermissions.call(this)
  this.templates = [
    {
      name: 'view_all',
      description: 'Read-only access to all submissions',
      permissions: [
        { target_id: null, target_type: 'Document', action: 'view_submission',    grant: true },
        { target_id: null, target_type: 'Document', action: 'unlock_submission',  grant: false },
        { target_id: null, target_type: 'Document', action: 'accept_submission',  grant: false },
        { target_id: null, target_type: 'Document', action: 'upload_submission',  grant: false },
        { target_id: null, target_type: 'Document', action: 'edit_attachments',   grant: false },
      ],
    },
    {
      name: 'lock_and_accept',
      description: 'View all submissions and be able to only Request New Submissions and Accept/Reject',
      permissions: [
        { target_id: null, target_type: 'Document', action: 'view_submission',    grant: true },
        { target_id: null, target_type: 'Document', action: 'unlock_submission',  grant: true },
        { target_id: null, target_type: 'Document', action: 'accept_submission',  grant: true },
        { target_id: null, target_type: 'Document', action: 'upload_submission',  grant: false },
        { target_id: null, target_type: 'Document', action: 'edit_attachments',   grant: false },
      ],
    },
    {
      name: 'manage_all',
      description: 'View all submissions and perform all actions',
      permissions: [
        { target_id: null, target_type: 'Document',   action: 'view_submission',        grant: true },
        { target_id: null, target_type: 'Document',   action: 'unlock_submission',      grant: true },
        { target_id: null, target_type: 'Document',   action: 'accept_submission',      grant: true },
        { target_id: null, target_type: 'Document',   action: 'upload_submission',      grant: true },
        { target_id: null, target_type: 'Document',   action: 'edit_attachments',       grant: true },

        // I9 needs to be defined explicitly
        { target_id: 'i9', target_type: 'Document',   action: 'view_submission',        grant: true },
        { target_id: 'i9', target_type: 'Document',   action: 'unlock_submission',      grant: true },
        { target_id: 'i9', target_type: 'Document',   action: 'accept_submission',      grant: true },
        { target_id: 'i9', target_type: 'Document',   action: 'upload_submission',      grant: true },
        { target_id: 'i9', target_type: 'Document',   action: 'countersign_submission', grant: true },
        { target_id: 'i9', target_type: 'Document',   action: 'edit_attachments',       grant: true },
      ],
    },
  ]
}
