<template>
  <blockquote v-if="!accepted" ref="rejectionReasonBlockquote">
    <i class="text-muted" v-if="!rejectionReason && !this.rejectionReasons.length" ref="noReasonGiven">No reason
      given.</i>
    <span ref="rejectionReasonsDetails" v-else>
      {{ rejectionReasonsDetails }}
    </span>
  </blockquote>
</template>

<script>
  export default {
    name: 'rejection-reason-blockquote',
    props: {
      accepted: {
        required: true,
      },
      employeeName: {
        required: true,
      },
      rejectionReason: {
        required: true,
        default: null,
      },
      rejectionReasons: {
        required: true,
        type: Array,
        default: () => [],
      },
    },
    computed: {
      rejectionReasonsDetails() {
        if (this.rejectionReason) {
          return this.rejectionReason
        } else {
          return this.rejectionReasons.map((code) => {
            return this.$locale.t(`document_submissions.rejection_reasons.${code}`, this.employeeName)
          }).join(' and ')
        }
      },
    },
  }
</script>
