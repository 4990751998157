<template>
  <span>
    <WbIcon
      v-if="icon"
      :type="icon"
      class="mr-1"
      :class="classNames"
    />
    <span v-html="label" />
  </span>
</template>
<script>
  import Constants from 'lib/constants'
  import DateTime from 'lib/util/datetime'
  import WbIcon from 'components/common/WbIcon'
  import i9StatusConfiguration from 'data/i9StatusConfiguration'

  const ASSIGNMENT_STATUSES = ['missing', 'pending', 'rejected']

  export default {
    components: {
      WbIcon,
    },
    props: {
      assignment: {
        type: Object,
        required: true,
      },
    },
    computed: {
      certifyDate () {
        const date = this.assignment.employer_certify_date
        return date ? DateTime.formatDate(date) : Constants.DATA_MISSING_VALUE
      },
      certifyStatus () {
        return this.assignment.submission?.certify_status
      },
      certifierName () {
        return this.assignment.employer_full_name || Constants.DATA_MISSING_VALUE
      },
      classNames () {
        return this.config.classNames || []
      },
      config () {
        return i9StatusConfiguration[this.type] || {}
      },
      icon () {
        return this.config.icon
      },
      isPaperUpload () {
        return this.assignment.submission?.paper_upload || false
      },
      isRemoteCountersign () {
        return this.assignment.countersignable_remotely || false
      },
      label () {
        let label =  Constants.DATA_MISSING_VALUE
        if (this.config.label) {
          if (this.certifyNotMatched) {
            const mismatchedDocumentTitles = this.assignment.submission.mismatched_document_titles.join(', ')
            label = this.$locale.t(this.config.label, mismatchedDocumentTitles)
          } else {
            label = this.$locale.t(this.config.label, { certifierName: this.certifierName, certifyDate: this.certifyDate })
          }
        }

        return label
      },
      certifyNotMatched() {
        return this.type === 'certify_not_matched' || this.type === 'certify_not_matched_remote'
      },
      shouldDisplayCertifyStatus () {
        return !ASSIGNMENT_STATUSES.includes(this.status)
      },
      status () {
        return this.assignment.status
      },
      type () {
        let type
        if (this.isPaperUpload) {
          type = 'paper'
        } else if (this.shouldDisplayCertifyStatus) {
          type = this.certifyStatus
          if (this.isRemoteCountersign) {
            type = `${type}_remote`
          }
        } else {
          type = this.status
        }
        return type
      },
    },
  }
</script>
