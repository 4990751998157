import { createVueWait } from 'vue-wait'

// WorkBright configuration for VueWait
// We create Vue applications in a few spots (main app, orphans, tesitng, etc)
// so useful to define in one place.
const wait = new createVueWait({
  useVuex: true,                  // Uses Vuex to manage wait state
  vuexModuleName: 'wait',         // Vuex module name
  registerComponent: true,        // Registers `v-wait` component
  componentName: 'v-wait',         // <v-wait> component name, you can set `my-loader` etc.    
})

export default wait
