<template>
  <label
    v-if="label"
    :class="{ 'control-label col-sm-4 col-md-3 col-lg-3' : horizontal }"
    :for="name"
  >
    <abbr v-if="required" title="" data-original-title="required">
      *
    </abbr>
    <span v-html="label" />
    <HelpPopover
      v-if="helpPopoverText"
      ref="popover"
      :content="helpPopoverText"
      :title="helpPopoverTitle || label"
    />
  </label>
</template>

<script>
  import FormFieldConcern from 'mixins/FormFieldConcern'
  import HelpPopover from 'components/common/HelpPopover'

  export default {
    name: 'form-label',
    components: {
      HelpPopover,
    },
    mixins: [FormFieldConcern],   
  }
</script>
