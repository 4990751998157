<template>
  <UserImportPageHeader wizardStep='complete' />
</template>

<script>
  import UserImportPageHeader from 'components/user_imports/UserImportPageHeader'

  export default {
    name: 'the-user-imports-complete-root',
    components: {
      UserImportPageHeader,
    },
  }
</script>
