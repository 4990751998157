<template>
  <div
    class="panel"
    :class="classNames"
  >
    <div class="panel-heading" v-if="hasHeading">
      <slot name="heading" />
    </div>
    <div class="panel-body">
      <slot name="body" />
    </div>
    <div class="panel-footer" v-if="hasFooter">
      <slot name="footer" />
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      variant: {
        type: String,
        validator: value => {
          // The value must match one of these strings
          return [
            'snapshot',
            'primary',
            'active-success',
            'danger',
          ].includes(value)
        },
      },
    },

    computed: {
      classNames () {
        const value = []

        if (this.variant) {
          value.push(`panel-${this.variant}`)
        }
        return value
      },
      hasFooter () {
        return !!this.$slots.footer
      },
      hasHeading () {
        return !!this.$slots.heading
      },
    },
  }
</script>
