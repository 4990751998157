import PrerenderedItemView from 'views/common/prerendered_item_view'

export default PrerenderedItemView.extend({
  behaviors: {
    CustomFieldsManners: {},
  },

  initialize: function() {
  },

  onBeforeRender: function() {
  },
})
