<template>
  <div>
    <FormFieldDate
      name='hire_date'
      label='Hire Date'
      v-model="values.hire_date"
      :errors="errors"
      :orientation="orientation"
      :placeholder="placeholder"
      :helpPopoverText="this.$locale.t('employments.help_text.hire_date')"
    />
    <FormFieldDate
      name='start_date'
      label='Start Date'
      v-model="values.start_date"
      :errors="errors"
      :orientation="orientation"
      :placeholder="placeholder"
      :helpPopoverText="this.$locale.t('employments.help_text.start_date')"
    />
    <FormFieldDate
      name='end_date'
      label='End Date'
      v-model="values.end_date"
      :errors="errors"
      :orientation="orientation"
      :placeholder="placeholder"
      :helpPopoverText="this.$locale.t('employments.help_text.end_date')"
    />
  </div>
</template>

<script>
  import FormFieldDate from 'components/common/FormFieldDate'

  export default {
    name: 'employment-dates',

    components: {
      FormFieldDate,
    },

    props: {
      // Object with multiple dates: hire_date, start_date, and end_date
      // Each date property can be a string or null
      value: {
        type: Object,
        required: false,
      },
      errors: {
        type: Object,
        required: false,
      },
      orientation: {
        type: String,
        required: false,
      },
      placeholder: {
        type: String,
        required: false,
      },
    },

    data() {
      return {
        values: this.value,
      }
    },
  }
</script>
