import Routes from 'lib/routes'
import FormFieldInput from 'components/common/FormFieldInput'
import LaddaButton from 'components/common/LaddaButton'
import Constants from 'lib/constants'

export default {

  components: {
    FormFieldInput,
    LaddaButton,
  },

  data() {
    return {
      formData: {
        phone: null,
      },
      formErrors: {},
    }
  },

  methods: {
    // Sometimes phone is from
    // - mfaData (passed in from controller)
    // - formData (entered by user; not yet saved)
    sendOtpViaSms(phone){
      return $.ajax({
        method: 'POST',
        url: Routes.mfa_send_otp_via_sms_path,
        data: {phone},
      })
    },

    deferredActionPostSms(deferredAction){
      setTimeout(() => deferredAction(), Constants.SMS_TIMEOUT)
    },
  },
}
