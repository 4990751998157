import * as mutate from 'vuex/mutation_types'
import { mutationTypes as baseCollectMutationTypes } from 'vuex/mixins/base_vuex_collection_module'
import { backboneFlatten } from 'lib/backbone_flatten'
import ProfileInfoPanelItemView from 'views/employees/profile_info_panel_item_view'
import EmployeeGroupsPanelItemView from 'views/employees/employee_groups_panel_item_view'
import EmployeeCustomFieldsPanelItemView from 'views/employees/employee_custom_fields_panel_item_view'

export default Marionette.LayoutView.extend({
  template: false,
  regions: {
    profileInfoPanel: "#profile-info-panel",
    employeeGroupsPanel: "#employee-groups-panel",
    customFieldsPanel: "#custom-fields-panel",
  },

  initialize: function() {
    window.vuexStore.commit(mutate.SET_PAGE_CONTEXT_KEYS, { employeeId: this.model.get('id') })
    window.vuexStore.dispatch('custom_fields/loadInitialData', this.options.customFields)

    // Vue transition: Marionette is expecting employee as a Backbone model, Vue is expecting an employee in Vuex.
    // Bind to change in Backbone and update Marionette
    const setEmployee = () => {
      window.vuexStore.commit(`employees/${baseCollectMutationTypes.SET_RECORD}`, backboneFlatten(this.model))
    }
    this.listenTo(this.model, 'change', setEmployee)
    this.listenTo(this.model.get('custom_fields'), 'change', setEmployee) // We have to explicitly bind to changes on the sub model
    setEmployee() // initialize
  },

  onRender: function() {
    this.profileInfoPanel.attachView(new ProfileInfoPanelItemView({ el: this.profileInfoPanel.el, model: this.model }))

    const employeeGroupsPanelView = new EmployeeGroupsPanelItemView({ el: this.employeeGroupsPanel.el, model: this.model })
    employeeGroupsPanelView.render()  // manually render since the view.`el` is in its own parent region
    this.employeeGroupsPanel.attachView(employeeGroupsPanelView)

    this.customFieldsPanel.attachView(new EmployeeCustomFieldsPanelItemView({ el: this.customFieldsPanel.el, model: this.model }))
  },
})
