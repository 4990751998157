import EmployeeDocumentAssignmentsTableCollectionView from 'views/documents/employee_document_assignments_table_collection_view'
import EmployeeDocumentsCollection from 'collections/employee_documents_collection'

export default Marionette.LayoutView.extend({
  template: false,
  initialize: function() {
  },

  onBeforeRender: function() {
    if (this.options.$tableEl) {
      this.options.$tableEl.DataTable({
        retrieve: true,
        destroy: true,
        sorting: [1, 'asc'],
        columns: [
          /* 0 Checkbox */ null,
          /* 1 Name */ null,
          /* 2 Deadline */ null,
          /* 4 Submission Status */ { type: 'html' },
          /* 5 Received At */ { type: 'date' },
          /* 5 Tags */ null,
          /* 6 Action */ { searchable: false, width: '55px' },
        ],
        language: {
          emptyTable: '<div class="text-center"><h4>No one is assigned this form this season.</h4></div>',
        },
      })

      App.Documents.listTableCollectionView = new EmployeeDocumentAssignmentsTableCollectionView()
      App.Documents.listTableCollectionView.attachToTable(this.options.$tableEl, EmployeeDocumentsCollection, function() {
        if (!$(this).data("submissionId") || $(this).data("submissionId").length == 0)
        {return {}}
        else
        {return { id: $(this).data("submissionId"),
          documentId: $(this).data("documentId"),
          employeeId: $(this).data("employeeId"),
          submissionUrl: $(this).data("href"),
        }}
      })
    }
  },

  onBeforeDestroy: function() {
    if (this.listTableCollectionView) {
      this.listTableCollectionView.destroy()
      delete this.listTableCollectionView
    }
  },
})
