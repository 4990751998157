<template>
  <table class="table table-striped">
    <thead>
      <tr>
        <th>
          Name
        </th>
        <th
          v-if="!isPendingAdmin"
          ref="staffHeader"
        >
          Staff Member File
        </th>
        <th>
          Email
        </th>
        <th
          v-if="isPendingAdmin"
          ref="createdHeader"
        >
          Created From
        </th>
        <th
          v-if="!isPendingAdmin"
          ref="accessLevelHeader"
        >
          {{ accessLevelTitle }}
        </th>
        <th />
      </tr>
    </thead>
    <tbody>
      <AdminUsersTableRow
        v-for="(admin, index) in users"
        :key="index"
        :isPendingAdmin="isPendingAdmin"
        :user="admin"
        :managedPermissionsEnabled="managedPermissionsEnabled"
        @update="handleUpdate"
      />
    </tbody>
  </table>
</template>
<script>
  import { mapGetters } from 'vuex'
  import AdminUsersTableRow from 'components/admin/admin_users/AdminUsersTableRow'

  export default {
    components: {
      AdminUsersTableRow,
    },
    props: {
      isPendingAdmin: {
        type: Boolean,
      },
      users: {
        type: Array,
        required: true,
      },
    },
    computed: {
      ...mapGetters({
        currentAccount: 'account/current',
      }),
      managedPermissionsEnabled () {
        return this.currentAccount.managedPermissions
      },
      accessLevelTitle () {
        return this.managedPermissionsEnabled ? 'Permission Policy' : 'Access Level'
      },
    },
    methods: {
      handleUpdate () {
        this.$emit('update')
      },
    },
  }
</script>
