import TemplatedDocumentPermissions from 'models/policy_generator/templated_document_permissions'
import TargetGroupedPermissions from 'models/policy_generator/target_grouped_permissions'
import Permission from 'models/policy_generator/permission'

const TARGET_TYPE = 'Document'
const VIEW_ACTION = 'view_submission'

class StepDocumentsCustomizePresenter {
  constructor(permissionSet) {
    this.permissionSet = permissionSet
  }

  matchingTemplate() {
    const templatedPermissions = new TemplatedDocumentPermissions()
    return templatedPermissions.findMatchingTemplate(this.permissionSet.findWhere({ target_type: TARGET_TYPE }))
  }

  groupedPermissions() {
    const gp = new TargetGroupedPermissions(this.permissionSet, { target_type: TARGET_TYPE })
    return gp.groupedPermissions
  }

  addPermission(document) {
    if (this.permissionSet.find(document.id, TARGET_TYPE, VIEW_ACTION)) { return } // NOP if already exists

    const newPermissions = [
      // TODO: Get this from a list somewhere?
      new Permission({
        target_id: document.id,
        target_type: TARGET_TYPE,
        action: 'view_submission',
        grant: false,
      }),
      new Permission({
        target_id: document.id,
        target_type: TARGET_TYPE,
        action: 'unlock_submission',
        grant: false,
      }),
      new Permission({
        target_id: document.id,
        target_type: TARGET_TYPE,
        action: 'accept_submission',
        grant: false,
      }),
      new Permission({
        target_id: document.id,
        target_type: TARGET_TYPE,
        action: 'upload_submission',
        grant: false,
      }),
      new Permission({
        target_id: document.id,
        target_type: TARGET_TYPE,
        action: 'edit_attachments',
        grant: false,
      }),
    ]
    if (document.id == 'i9') {
      newPermissions.push(
        new Permission({
          target_id: document.id,
          target_type: TARGET_TYPE,
          action: 'countersign_submission',
          grant: false,
        }))
    }

    this.permissionSet.appendPermissions(newPermissions)
  }

  removePermission(documentId) {
    this.permissionSet.removeWhere({ target_type: TARGET_TYPE, target_id: documentId })
  }

}

export default StepDocumentsCustomizePresenter
