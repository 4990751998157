import PrerenderedTableRowItemView from 'views/common/prerendered_table_row_item_view'

export default PrerenderedTableRowItemView.extend({
  behaviors: {
    TableRowSelectable: {},
    TableRowClickable: { checkForModel: true },
  },

  onShow: function() {
    // If the dataset is empty, this row won't have a model
    if (!this.model) {return}

    if (this.model.id)
    {this.$el.addClass('hover-clickable text-strong')}
  },

  onRowClick: function() {
    Turbolinks.visit(this.model.get('submissionUrl'))
  },
})
