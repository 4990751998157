<template>
  <div>
    <hr />
    <h3>Rehire Options</h3>
    <small>
      These options only apply when rehiring a previous staff member. They are the defaults during rehire for this form.
    </small>
    <FormFieldCheckbox
      label="Request new submission"
      name="request_new_submission_on_rehire"
      :errors="errors"
      v-model="formData.request_new_submission_on_rehire"
    />
  </div>
</template>

<script>
  import FormFieldCheckbox from 'components/common/FormFieldCheckbox'

  export default {
    name: 'rehire-options',
    components: {
      FormFieldCheckbox,
    },

    props: {
      initialData: {
        type: Object,
        required: true,
      },
      errors: {
        type: Object,
        required: true,
      },
    },

    emits: [
      'update',
    ],

    data() {
      return {
        formData: {
          ...this.initialData,
        },
      }
    },

    watch: {
      formData: {
        handler(newValue) {
          this.$emit('update', newValue)
        },
        deep: true,
      },
    },
  }
</script>
