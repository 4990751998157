import { getRoute } from 'lib/routes'
import { ajax } from 'jquery'

const state = () => {
  return {
    fieldsById: {},
  }
}

const getters = {
  fieldsById: state => state.fieldsById,
}

const mutations = {
  SET_FIELDS: (state, payload) => {
    state.fieldsById = payload
  },

  SET_FIELD_BY_ID: (state, { id, standardField }) => {
    state.fieldsById[id] = standardField
  },
}

const actions = {
  loadInitialData: ({ commit }, fields) => {
    const fieldsById = {}

    fields.map(field => {
      fieldsById[field.id] = field
    })

    commit('SET_FIELDS', fieldsById)
  },
  saveMapping: ({ commit }, { standardFieldKey, mappedFieldKey, mappedOptions }) => {
    const url = getRoute('standard_field_path', { id: standardFieldKey })

    return new Promise((resolve, reject) => {
      ajax({
        method: 'PATCH',
        url,
        data: {
          mapping: {
            key: mappedFieldKey,
            options: mappedOptions,
          },
        },
      }).then(standardField => {
        commit('SET_FIELD_BY_ID', { id: standardField.id, standardField })
        resolve()
      }).fail(({responseJSON}) => {
        reject(responseJSON?.errors)
      })
    })
  },
  deleteMapping: ({ commit }, { standardFieldKey }) => {
    const url = getRoute('standard_field_delete_mapping_path', { id: standardFieldKey })

    return new Promise((resolve, reject) => {
      ajax({
        method: 'PATCH',
        url,
      }) .then(standardField => {
        commit('SET_FIELD_BY_ID', { id: standardField.id, standardField})
        resolve()
      }).fail(response => {
        reject(response)
      })
    })
  },
}

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
}
