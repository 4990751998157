// I-9 Form Documentation Step
//
// Multipart Step:
// Step 0    - Select Documentation              DocumentSelectView
// Step 1..n - Upload/Edit Specific Attachment   DocumentAttachmentView
//
import DocumentSelectView from 'views/document_submissions/i9/document_select_view'
import EditAttachmentView from 'views/document_submissions/i9/edit_attachment_view'


export default Marionette.LayoutView.extend({
  template: "#i9-documentation-tmpl",
  className: 'i9-documentation',
  ui: {
    error: '.error',
  },
  regions: {
    docsPane: '.documentation-container',
  },

  initialize: function() {
    this.context = this.getOption('context')
  },

  onShow: function() {
    this.showSubStep()
  },

  showSubStep: function() {
    const action = this.context.get('action')

    let subView = null
    if (!action || action.display == 'select_documents'){
      subView = new DocumentSelectView({ model: this.model.documentationSet,
        documentationChoices: this.context.get('documentationChoices'),
        citizenshipDesignation: this.model.get('citizenship_designation'),
        alienRegNumber: this.model.get('alien_reg_number'),
        context: this.context })

    } else if (action.display == 'upload_document') {
      const doc = this.model.documentationSet.at(action.index)
      subView = new EditAttachmentView({ model: doc, context: this.context })
    }

    this.docsPane.show(subView)
  },

  // Pass validation to substeps, if present
  validate: function(validationCompleteCallback){
    if (this.docsPane.currentView.validate){
      this.docsPane.currentView.validate(validationCompleteCallback)
    } else {
      validationCompleteCallback(true)
    }
  },
})
