// A running list of routes that our JS code assumes will exist that we can fall back on for unit tests

const emptyRouteGetter = () => {}

export default {
  admin_employee_submission_path: emptyRouteGetter,
  employee_assignments_path: emptyRouteGetter,
  employee_path: emptyRouteGetter,
  employee_resend_welcome_email_path: emptyRouteGetter,
  everify_cases_path: '#',
  new_admin_employee_document_submission_path: emptyRouteGetter,
  search_employees_path: '',
  root_url: () => "https://test.workbright.com",
  tenant_select_search_path: emptyRouteGetter,
  sso_config_path: emptyRouteGetter,
  Api: {
    current_submission_employee_document_assignment_path: emptyRouteGetter,
    employee_employment_path: emptyRouteGetter,
    historical_employee_document_assignment_path: emptyRouteGetter,
    submission_path: emptyRouteGetter,
    staff_list_slideout_employee_path: emptyRouteGetter,
  },
  Images: {
    logo: '//assets.wb.test/images/logo_mark_responsive.svg',
  },
  External: {
    aws_dropzone_url: emptyRouteGetter,
    everify_cases_url: emptyRouteGetter,
    everify_login_url: emptyRouteGetter,
    everify_web_services_password_helpdesk_url: emptyRouteGetter,
    w4_irs_tax_withholding_estimator_url: emptyRouteGetter,
  },
}
