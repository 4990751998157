<template>
  <div id="ri9_document_set_container">
    <DocumentReview
      v-for="(doc, index) in documentationSetMeta"
      v-bind:key="index"
      :document="doc"
      :employeeFullName="employeeFullName"
      :receiptInstructions="receiptInstructions"
      :reviewInstructions="reviewInstructions"
      @reviewReset="() => { handleReviewReset(index) }"
      @reviewStart="() => { handleReviewStart(index) }"
      @verify="(match, mismatchReasons) => { handleVerify(index, match, mismatchReasons) }"
    />
  </div>
</template>

<script>
  import DocumentReview from 'components/documentation/DocumentReview'

  export default {
    name: 'review-document-set-container',

    components: {
      DocumentReview,
    },

    props: {
      documentationSet: {
        type: Array,
        required: true,
      },
      employeeFullName: {
        type: String,
        required: true,
      },
      receiptInstructions: {
        type: String,
      },
      reviewInstructions: {
        type: String,
      },
    },

    data () {
      return {
        documentationSetMeta: null,
      }
    },

    computed: {
      isFullyReviewed () {
        // if every doc has been reviewed (i.e. match is true/false but not null)
        return this.documentationSetMeta.every(doc => doc.match !== null)
      },
      isVerified () {
        return this.documentationSetMeta.every(doc => doc.match)
      },
    },

    created () {
      this.setMeta()
    },

    methods: {
      handleReviewStart (index) {
        this.documentationSetMeta[index].reviewing = true

        this.$emit('wbDocumentationSetReviewStart')
      },

      handleReviewReset (index) {
        this.documentationSetMeta[index].reviewing = true

        this.$emit('wbDocumentationSetReviewReset', { document_set_index: index })
      },

      handleVerify (index, match, mismatch_reasons) {
        this.documentationSetMeta[index].reviewing = false
        this.documentationSetMeta[index].match = match

        if (!match) {
          this.documentationSetMeta[index].mismatch_reasons = mismatch_reasons
        }

        this.$emit('wbDocumentationSetVerify', { document_set_index: index, match, mismatch_reasons })

        if (this.isFullyReviewed) {
          this.handleDone()
        }
      },

      handleDone () {
        this.$emit('wbDocumentationSetVerificationComplete', {
          verified: this.isVerified,
        })
      },

      setMeta () {
        // init meta arrays to the same length as this.documentation_set
        this.documentationSetMeta = this.documentationSet.map((doc, zeroBasedIndex) => {
          return {
            id: `${zeroBasedIndex}`,
            reviewing: false,
            match: null,
            data: doc, // reference, but we are not changing any initial data
          }
        })
      },
    },
  }
</script>
