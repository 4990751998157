<template>
  <div class="pull-right historical-submission-alert" v-if="!this.isCurrentSubmission">
    <div class="alert alert-warning" role="alert">
      <p>
        <i class="a4s-icon a4s-icon-archived-file" />
        You are viewing a historical submission.
      </p>
      <div class="mt-3">
        <a class="btn btn-info js-open-historical-submissions" href="javascript:void(0)">
          View all submissions
        </a>
        <div class="tooltip-wrapper" ref="tooltipWrapper">
          <LaddaButton ref="setAsCurrentSubmissionBtn" @click="handleSetAsCurrent" :disabled="disabled">
            Set as Current Submission
          </LaddaButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import PermissionEnforcement from 'components/mixins/PermissionEnforcement'
  import LaddaButton from 'components/common/LaddaButton'
  import Util from 'lib/util'
  import Locale from 'lib/locale'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'historical-submission-alert',
    components: {
      LaddaButton,
    },
    mixins: [PermissionEnforcement],

    props: {
      submissionId: {
        type: Number,
        required: true,
      },
    },

    computed: {
      disabled() {
        if (!this.submission) {
          return true
        }

        return this.submission.status !== 'accepted' || !this.isPermitted
      },

      submission() {
        return this.getSubmission(this.submissionId)
      },

      isCurrentSubmission() {
        const assignments = this.assignmentFindBy({ current_submission_id: this.submissionId })
        return !!assignments[0]
      },

      // Finds the document assignment from the Vuex store, it does not matter if this submission is current or not,
      // which is then used to set the assignment as current
      //
      // Returns single matching assignment record.
      assignment() {
        return this.assignmentFindBy({
          document_id: this.submission.document_id,
          employee_id: this.submission.employee_id,
        })[0]
      },

      ...mapGetters({
        getSubmission: 'document_submissions/get',
        assignmentFindBy: 'document_assignments/findBy',
        getDocument: 'documents/get',
      }),
    },

    methods: {
      handleSetAsCurrent() {
        // If already current, we don't continue
        if (this.isCurrentSubmission) {
          return
        }

        this.setAsCurrent({
          document_assignment: this.assignment,
          new_current_submission: this.submission,
        }).then(assignment => {
          const documentName = this.getDocument(assignment.document_id).name
          Util.showFlashNotice(Locale.t('assignments.setAsCurrent.success', documentName))
        }).catch(xhr => {
          Util.genericAjaxError(Locale.t('assignments.setAsCurrent.fail'), xhr)
        })
      },

      ...mapActions({
        setAsCurrent: 'document_assignments/setAsCurrent',
      }),
    },

    mounted() {
      if (!this.isPermitted) {
        $(this.$refs.tooltipWrapper).tooltip({
          container: 'body',
          title: 'You are not permitted to perform this action.',
        })
      }
    },
  }
</script>
