// This concern DRYs up some of the duplication in I9RemoteCountersignEmployeeContainer and I9RemoteCountersignAuthRepContainer

import FormFieldInput from 'components/common/FormFieldInput'
import LaddaButton from 'components/common/LaddaButton'
import WbGeolocation from 'components/common/WbGeolocation'
import { mapGetters, mapState } from 'vuex'
import Util from 'lib/util'
import PubSub from 'lib/pub_sub'
import EmbeddedService from 'lib/embedded_service'
import { getRoute } from 'lib/routes'
import { ajax } from 'jquery'

export default {
  components: {
    FormFieldInput,
    LaddaButton,
    WbGeolocation,
  },

  data() {
    return {
      ready: null,
      remoteCountersignId: null,
      locationAccepted: null,
      qrSecret: null,
      certifyStatus: null,
      pubSubChannel: null,
      pubSubChannelName: null,
      showHelp: null,
      formData: {
        // snake case because this is form data that is sent back to rails
        actor: null,
        event_type: null,
        geolocation_position: null,
        qr_secret_matched: null,
        auth_rep_phone: null,
      },
      formErrors: {},
    }
  },

  computed: {
    ...mapState([
      'pageContext',
    ]),

    authRepQrCodeScanEnabled () {
      return this.currentAccount.authRepQrCodeScanEnabled
    },

    employeeScansQrCodeEnabled () {
      return !this.authRepQrCodeScanEnabled
    },

    showNotReadyToStart(){
      return !this.ready
    },

    locationNotAccepted(){
      return !this.locationAccepted
    },

    remoteVerifying(){
      return this.certifyStatus == "certify_remote_verifying"
    },

    showCountersignAccepted(){
      return this.certifyStatus == "certify_complete"
    },

    showCountersignRejected(){
      return this.certifyStatus == "certify_not_matched"
    },

    qrScanMatched(){
      return this.qrSecret !== null && this.formData.qr_secret_matched === true
    },

    qrVerifying(){
      return this.qrSecret !== null && !this.qrScanMatched
    },

    qrCodePayload(){
      const urlParams = {
        id: this.remoteCountersignId,
        qr_secret: this.qrSecret,
        ...this.qrCodePayloadUrlParams,
      }

      return getRoute(this.qrCodePayloadUrlName, urlParams)
    },

    qrCodePayloadUrlName(){
      return this.authRepQrCodeScanEnabled ? "i9rc_authrep_qr_code_verification_url" : "i9rc_qr_code_verification_url"
    },

    qrCodePayloadUrlParams(){
      return this.authRepQrCodeScanEnabled ? {subdomain: this.currentAccount.subdomain} : {host: window.location.host}
    },

    isQrStep(){
      return this.remoteVerifying && this.qrVerifying
    },

    ...mapGetters({
      currentAccount: 'account/current',
    }),
  },

  methods: {
    sendI9RemoteCountersignEvent(eventType){
      this.formData.event_type = eventType
      return ajax({
        method: 'POST',
        url: this.$routes.i9rc_event_path({id: this.remoteCountersignId}),
        data: {
          i9rc: { ...this.formData },
        },
      })
    },

    handleSubmitLocation(){
      this.sendI9RemoteCountersignEvent("submit_location").success(response => {
        if (response.success) {
          this.locationAccepted = true
        } else {
          this.formErrors = response.errors || {}
          // NOTE: a qr_secret is returned when GeolocationProximityStrategy fails.
          // At this point, the employee needs to scan the QR code on the auth reps phone.
          if (response.qr_secret) {
            this.qrSecret = response.qr_secret
          }
        }
      })
    },

    handleGeolocationSuccess(position){
      // We cannot pass the GeolocationPosition object as data to jQuery ajax
      // Explanation here: https://github.com/WorkBright/Rails-App/pull/2983
      const geoPosition =
        {
          coords: {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          },
        }
      this.formData.geolocation_position = geoPosition
      this.handleSubmitLocation()
    },

    handleGeolocationError(errorMessage){
      this.sendI9RemoteCountersignEvent("submit_location_failed").success( (json) => {
        this.qrSecret = json.qr_secret
      }).always(() => {
        Util.showFlashNotice(errorMessage)
        // FIXME: are we doing something like this?
        // this.showHelp = true;
      })
    },

    handleQrCodeMatched(data){
      this.formData.qr_secret_matched = data.qrCodeSecretMatched
    },

    handleAuthRepLocationFailed(data){
      // need to include the QR secret or it won't be able to match
      this.qrSecret = data.qrSecret
    },

    handleAuthRepCountersign(data){
      if (data.listeningActor == this.formData.actor){
        this.certifyStatus = "certify_complete"
        EmbeddedService.postMessage(data.embeddedEvent)
      }
    },

    handleAuthRepCountersignRejected(data){
      if (data.listeningActor == this.formData.actor)
      {this.certifyStatus = "certify_not_matched"}
    },

    subscribeToPubSub(remoteCountersignId, pubSubChannelName) {
      /**
       * We need to reset the Pusher instances to an empty array, because
       * by default, Pusher won't subscribe to a new channel if there is
       * already one active. By setting this to an empty array, we force
       * Pusher to initialize one.
       */
      window.Pusher.instances = []

      this.pubSubChannel = PubSub.subscribeToRemoteCountersignChannel({
        remoteCountersignId: remoteCountersignId,
        channelName: pubSubChannelName,
      })

      this.pubSubChannel.bind('wbQrCodeMatched', this.handleQrCodeMatched.bind(this))
      this.pubSubChannel.bind('wbAuthRepCountersign', this.handleAuthRepCountersign.bind(this))
      this.pubSubChannel.bind('wbAuthRepLocationFailed', this.handleAuthRepLocationFailed.bind(this))
      this.pubSubChannel.bind('wbAuthRepCountersignRejected', this.handleAuthRepCountersignRejected.bind(this))
    },
  },

  created() {
    this.ready = this.pageContext.i9RemoteCountersign.ready
    this.formData.actor = this.pageContext.i9RemoteCountersign.actor
    this.remoteCountersignId = this.pageContext.i9RemoteCountersign.id
    this.certifyStatus = this.pageContext.i9RemoteCountersign.certifyStatus
    this.qrSecret = this.pageContext.i9RemoteCountersign.qrSecret
    this.formData.qr_secret_matched = this.pageContext.i9RemoteCountersign.qr_secret_matched
    this.pubSubChannelName = this.pageContext.i9RemoteCountersign.pubSubChannelName

    this.subscribeToPubSub(this.remoteCountersignId, this.pubSubChannelName)
  },

  beforeDestroy() {
    this.pubSubChannel.unsubscribe()
  },
}
