export default Marionette.ItemView.extend({
  template: '#template-validation-message-view',
  ui: {
    startButton: '.btn-start-import',
  },
  events: {
    'click @ui.startButton': 'startImport',
  },
  serializeData: function(){
    return {
      employeeCount: this.pluralizeRows(),
      errors: this.model.errors(),
    }
  },
  pluralizeRows: function(){
    const count = this.model.employeeCount()
    return `${count} ${count == 1 ? 'row' : 'rows'}`
  },
  startImport: function(){
    App.vent.trigger('admin_user_import:show_import')
  },
})
