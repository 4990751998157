<template>
  <div>
    <WbModal
      id="bulk-update-custom-fields-container-modal"
      ref="modal"
      title="Update a Custom Field"
      :watchPageContextVariable="collectionViewName"
      :locked="loading"
      @modalHidden="$emit('hidden')"
    >
      <v-wait :for="loadingName">
        <template #waiting>
          <Spinner :showLongWaitMessage="true" />
        </template>
        <component
          :is="currentStepComponentName"
          :nav-direction="navDirection"
          @skip="skipStep"
          ref="currentStep"
          :employeeIds="employeeIds"
          :collectionViewName="collectionViewName"
          :permissionName="permissionName"
          :field="field"
          :errors="errors"
          @updateData="handleStepUpdateData"
        />
      </v-wait>
      <template #footer>
        <button class='btn btn-default' @click="hide" :disabled="loading">Cancel</button>
        <button class="btn btn-primary next" data-style="expand-left" data-disable="true" :disabled="loading" @click="nextStep">{{ nextLabel }}</button>
      </template>
    </WbModal>
  </div>
</template>

<script>
  import { mapMutations } from 'vuex'
  import WbModal from 'components/common/WbModal'
  import BulkActionListAlert from 'components/common/BulkActionListAlert'
  import BaseWizard from 'components/common/BaseWizard'
  import Spinner from 'components/common/Spinner'
  import StepSelectField from 'components/employees/bulk_update_custom_fields/StepSelectField'
  import StepValidate from 'components/employees/bulk_update_custom_fields/StepValidate'
  import StepConfirm from 'components/employees/bulk_update_custom_fields/StepConfirm'
  import PubSubUtil from 'lib/pub_sub_wrapped_action'
  import Util from 'lib/util'

  export default {

    name: 'bulk-update-custom-fields-container',

    components: {
      BulkActionListAlert,
      Spinner,
      StepSelectField,
      StepValidate,
      StepConfirm,
      WbModal,
    },
    mixins: [BaseWizard],

    props: {
      collectionViewName: {
        type: String,
        required: true,
      },
    },

    data() {
      return {
        loadingName: 'bulk_update_custom_fields_loading',
        field: null,
        value: null,
        errors: null,
        steps: [
          { component: 'step-select-field', label: 'Select Custom Field' },
          { component: 'step-validate', label: 'Validation' },
          { component: 'step-confirm', label: 'Confirm' },
        ],
      }
    },

    computed: {
      permissionName() {
        return this.field ? `update_field_${this.field.field_key}` : ''
      },

      employeeIds() {
        return this.$store.getters[`${this.collectionViewName}/selected`]
      },

      loading() {
        return this.$wait.is(this.loadingName)
      },
    },

    methods: {
      hide() {
        this.$refs.modal.hide()
      },

      stepChanged() {
        switch (this.currentStepComponentName) {
          case 'step-select-field':
            break

          case 'step-validate':
            this.authorize()
            break

          case 'step-confirm':
            // No one allowed to modify, close
            if (this.employeeIds.length == 0) {
              this.hide()
            }
            break
          default:
            break
        };
      },

      handleStepUpdateData(data) {
        Object.keys(data).forEach((key) => {
          this[key] = data[key]
        })
      },

      authorize() {
        this.startLoading()

        // Fetch permissions
        // This is using a specific policy check method for bulk Custom Field updates, rather than the
        // generic Permissions controller API. The reason is that:
        // 1) It was already built
        // 2) It's a different format, checking the ability to update a particular CustomField for a
        //    particular Employee
        // But, essentially, I'm only here to make this component compatible with Vue for use with the
        // new Staff page, and this seemed like a larger overhaul than I'd like for something that's working
        //
        // FIXME: Use Permissions Controller insteadd
        $.ajax({
          method: 'POST',
          url: this.$routes.policy_check_bulk_actions_custom_fields_path,
          data: {
            field_key: this.field,
            employee_ids: this.employeeIds,
          },
        }).then((data) => {
          // Data returns back {allowed: [1,2,3], forbidden: [4,5,6]}
          // Load in allowed permissions, we can ignore forbidden, as it's implied
          const records = {}
          data.allowed.forEach((e) => {
            records[e.id] = {}
            records[e.id][this.permissionName] = true
          })

          // Load in the permissions
          this.permissionsLoadBatch({ modelName: 'employees', records })
        }).always(() => {
          this.endLoading()
        })
      },

      startLoading() {
        this.$wait.start(this.loadingName)
      },

      endLoading() {
        this.$wait.end(this.loadingName)
      },

      done() {
        this.startLoading()
        this.errors = null

        PubSubUtil.pubSubWrappedAction(
          'bulk_update_custom_field',
          (channelName, reject) => {
            $.ajax({
              method: 'PATCH',
              url: this.$routes.bulk_update_bulk_actions_custom_fields_path,
              data: {
                pubsub_channel: channelName,
                bulk_action: {
                  employee_ids: this.employeeIds,
                  field: this.field,
                  value: this.value,
                },
              },
            })
              .fail((xhr) => reject(xhr))
          },
          () => {
            this.endLoading()
            setTimeout(this.hide)
            this.$emit('success')
            Util.showFlashNotice(this.$locale.t('custom_fields.update.success'))
          },
        ).catch((xhr) => {
          if (xhr && xhr.responseJSON && xhr.responseJSON.errors) {
            // Field error case
            this.errors = xhr.responseJSON.errors.values
          } else if (xhr) {
            Util.genericAjaxError(this.$locale.t('custom_fields.update.fail'), xhr)
          } else {
            // Unknown error
            Util.errorDialog(this.$locale.t('custom_fields.update.fail'), 'Update error')
          }
          this.endLoading()
        })
      },

      ...mapMutations({
        permissionsLoadBatch: 'permissions/LOAD_BATCH',
      }),
    },
  }
</script>
