import * as BaseCollectionModule from 'vuex/mixins/base_vuex_collection_module'
import { ajax } from 'jquery'
import { getRoute } from 'lib/routes'


function state() {
  return {
    collection: {},
  }
};

const getters = {
  ...BaseCollectionModule.getters,
}

const mutations = {
  ...BaseCollectionModule.mutations,
}

const actions = {
  ...BaseCollectionModule.actions,

  // Get all Documents in an account
  //
  fetchAll: ({ dispatch }) => {
    return new Promise((resolve, reject) => {
      ajax({
        method: 'GET',
        url: getRoute('Api.documents_path'),
        dataType: 'json',
        contentType: 'application/json',
      }).then((documents) => {
        dispatch('documents/loadInitialData', documents.documents, { root: true })
        resolve()
      }).fail((xhr) => {
        reject(xhr)
      })
    })
  },

  // Get all Documents in an account by collating them from paginated requests
  //
  fetchAllPaginated: ({ dispatch }) => {
    return fetchAndLoadDocuments(getRoute('Api.documents_path'), [], dispatch)
  },
}

// Fetch documents from API endpoint. If there are multiple pages, recursively request the endpoint and collate the
// documents until the last page has been reached. Once the documents are collated, load them into Vuex.
function fetchAndLoadDocuments(url, documents, dispatch) {
  return new Promise((resolve, reject) => {
    ajax({
      method: 'GET',
      url: url,
      dataType: 'json',
      contentType: 'application/json',
    }).then((response) => {
      documents = documents.concat(response.documents)
      const nextPageUrl = response._links.next && response._links.next.href
      if (nextPageUrl) {
        return fetchAndLoadDocuments(nextPageUrl, documents, dispatch)
      } else {
        dispatch('documents/loadInitialData', documents, { root: true })
        return documents
      }
    }).then((result) => {
      resolve(result)
    }).fail((xhr) => {
      reject(xhr)
    })
  })
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
