import { filter, isMatch, pick, toArray } from 'underscore'
import Vue from 'vue'

// Getter functions which are universal for any module storing a collection in their `state`.
export const getters = {
  // Returns a record based on its ID
  //
  // id [String or Numeric] - identifier of the record in the collection
  //
  // Returns [Object] - record in collection
  get: (state) => (id) => {
    const collection = state.collection
    return Array.isArray(collection)
      ? collection.find((record) => record.id === id)
      : collection[id]
  },

  // Returns a set of objects based on their IDs. Missing IDs are silently ignored
  //
  // ids [Array] - collection of IDs to return
  //
  // Returns [Array] - records found
  getBatch: (state) => (ids) => {
    const collection = state.collection
    return Array.isArray(collection)
      ? collection.filter((record) => ids.some((id) => id === record.id))
      : toArray(pick(collection, ids))
  },

  // Returns all records containing a specific value for attributes given. This uses a type equality check (===)
  // so the type of the `value` provided must match exactly the type stored in the record.
  // `isMatch` definition: https://underscorejs.org/#isMatch
  //             source: https://github.com/jashkenas/underscore/blob/d5fe0fd4060f13b40608cb9d92eda6d857e8752c/underscore.js#L1177
  //
  // attributes [Object] -  object of key/values to match on
  //
  // Returns [Array] - matching records
  findBy: (state) => (attributes) => {
    const collection = state.collection
    return Array.isArray(collection)
      ? collection.filter((record) => isMatch(record, attributes))
      : filter(collection, record => isMatch(record, attributes))
  },
}

export const mutationTypes = {
  SET_RECORD: 'SET_RECORD',
  MERGE_INTO_RECORD: 'MERGE_INTO_RECORD',
}

export const mutations = {
  // Add or update a record in the collection in its entirety
  //
  // payload [Array/Object] - the record to be set, assumed to be identified with `id` key
  [mutationTypes.SET_RECORD]: ({ collection }, payload) => {
    if (Array.isArray(collection)) {
      const index = collection.findIndex((record) => record.id === payload.id)
      if (index) {
        Vue.set(collection, index, payload)
      } else {
        collection.push(payload)
      }
    } else {
      Vue.set(collection, payload.id, payload)
    }
  },

  // Merges a set of data into an existing record of the collection. Keys of the payload that already exist in the
  // record will be overwritten; new keys will become reactive; and all other keys of the original record will remain
  // untouched.
  //
  // payload [Object] - the record is identified by `id`, all other keys are merged in
  [mutationTypes.MERGE_INTO_RECORD]: ({ collection }, payload) => {
    const index = Array.isArray(collection)
      ? collection.findIndex((record) => record.id === payload.id)
      : payload.id
    for (const key in payload) {
      Vue.set(collection[index], key, payload[key])
    }
  },
}

export const actions = {
  loadInitialData: ({ commit }, collection) => {
    for (const record of collection) {
      commit(mutationTypes.SET_RECORD, record)
    }
  },
}
