export default Backbone.Marionette.ItemView.extend({
  template: '#wizard-progress-tmpl',
  percentage: 0,

  initialize: function(){

    // Updated on Wizard's progressChange event
    this.options.wizard.on('progressChange', function(percentage) {
      this.percentage = percentage
      this.render()
    }.bind(this))

  },

  serializeData: function(){
    return {
      percentage: this.percentage,
    }
  },

})
