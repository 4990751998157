<template>
  <div class="wb-multiselect wb-multiselect-search">
    <Multiselect
      v-model="localValue"
      :internal-search="false"
      label="label"
      track-by="value"
      :close-on-select="closeOnSelect"
      :loading="isLoading"
      :multiple="multiple"
      :placeholder="placeholder"
      :searchable="true"
      :taggable="false"
      :options="selectOptions"
      :preserveSearch="true"
      @search-change="throttleSetResults"
      @input="handleInput"
    >
      <template #option="props">
        <div>
          <div class="text-strong">{{ props.option.label }}</div>
          <div class="small">{{ props.option.desc }}</div>
        </div>
      </template>
      <template #noResult>
        <span>No results found</span>
      </template>
      <template #noOptions>
        <span>Please enter 3 or more characters</span>
      </template>
    </Multiselect>
  </div>
</template>
<script>
  import Constants from 'lib/constants'
  import Multiselect from 'vue-multiselect'
  import { ajax } from 'jquery'
  import { throttle } from 'underscore'
  const MIN_QUERY_LENGTH = 3

  export default {
    name: 'wb-search',
    components: {
      Multiselect,
    },

    props: {
      closeOnSelect: {
        type: Boolean,
        default: true,
      },

      multiple: {
        type: Boolean,
        default: false,
      },

      placeholder: {
        type: String,
        required: false,
      },

      route: {
        type: String,
        required: true,
      },

      // Takes Response from server and returns array of matches
      // in format { label: '', value: '' }
      responseProcessor: {
        type: Function,
        required: true,
      },

      value: {
        type: [Number, String, Array],
      },
    },

    data() {
      return {
        resultsByQuery: {},
        localValue: null,
        isLoading: null,
        selectOptions: [],
        uniqueOptions: {},
      }
    },

    watch: {
      value () {
        this.setLocalValue()
      },
    },

    created () {
      if (Array.isArray(this.value)) {
        this.selectOptions = this.value
        this.value.forEach(option => this.uniqueOptions[option.value] = option)
      }
      this.setLocalValue()
    },

    methods: {
      handleInput(option) {
        let value
        if (Array.isArray(option)) {
          value = option
        } else {
          value = option?.value
        }
        this.$emit('input', value)
      },

      throttleSetResults: throttle(function(query) {
        this.setResults(query)
      }, Constants.DEFAULT_REQUEST_THROTTLE),

      setResults(query) {
        if (query.length >= MIN_QUERY_LENGTH) {
          if (this.resultsByQuery[query.toLowerCase()]) {
            this.selectOptions = this.resultsByQuery[query.toLowerCase()]
          } else {
            this.fetchResults(query)
          }
        }
      },
      fetchResults(query) {
        this.isLoading = true

        if (this._xhr) {
          this._xhr.abort()
        }

        this._xhr = ajax({
          url: this.route,
          dataType: 'json',
          progressBar: false,
          data: {
            query,
          },
        })

        this._xhr.then(response => {
          this.processResponse(response, query)
        }).fail(() => {
          this.selectOptions = []
        }).always(() => {
          this.isLoading = false
        })
      },
      processResponse (response, query) {
        const options = this.responseProcessor(response)
        this.resultsByQuery[query.toLowerCase()] = options
        options.forEach(option => this.uniqueOptions[option.value] = option)
        this.selectOptions = options
      },

      setLocalValue () {
        if (Array.isArray(this.value)) {
          this.localValue = this.value.map(option => this.uniqueOptions[option.value])
        } else {
          this.localValue = this.uniqueOptions[this.value]
        }
      },
    },
  }
</script>
