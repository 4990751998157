import { createVueApp, mountApp } from 'lib/vue/createVueApp'

export function pureOrphanVue (component, mountPoint, data = {}, options = {}) {
  const app = createVueApp(component, {
    propsData: data.props,
    removeElementOnDestroy: options.removeElementOnDestroy,
  })

  const target = document.querySelector(mountPoint)
  const vNode = mountApp(app, target)

  return vNode
}

