<!-- Represents a modal that displays items that are not permitted or actionable for this action -->
<template>
  <WbModal
    ref="modal"
    :watchPageContextVariable="this.contextVariable"
    title="Important"
    @modalShow="handleModalShow"
    @cancelled="$emit('cancelled')"
  >
    <BulkActionListAlertDetails
      ref="bulkActionListAlertDetails"
      :collectionModule="collectionModule"
      :collectionViewName="collectionViewName"
      :displayNameLookupFn="displayNameLookupFn"
      :permissionModule="permissionModule"
      :permissionName="permissionName"
      :actionMethod="actionMethod"
      :actionErrorMessage="actionErrorMessage"
      @allowableActions="handleAllowableActions"
    />
    <template #footer>
      <div v-if="allowableActions" ref="allowableActions">
        <button class="btn btn-default" @click="handleCancel">Cancel</button>
        <LaddaButton ref="submitButton" @click="handleContinue">Next</LaddaButton>
      </div>
      <div v-else ref="selectDifferentRows">
        <button class="btn btn-primary" @click="handleCancel">Select Different Rows</button>
      </div>
    </template>
  </WbModal>
</template>

<script>
  import WbModal from 'components/common/WbModal'
  import BulkActionListAlertDetails from 'components/common/BulkActionListAlertDetails'
  import LaddaButton from 'components/common/LaddaButton'
  import { mapMutations } from 'vuex'
  import { DELETE_PAGE_CONTEXT_KEY } from 'vuex/mutation_types'

  export default {
    name: 'bulk-action-list-alert',
    components: {
      WbModal,
      BulkActionListAlertDetails,
      LaddaButton,
    },

    props: {
      // The module needed to load data from the store
      collectionModule: {
        type: String,
        required: true,
      },

      // The name of the collection store related metadata for the list
      collectionViewName: {
        type: String,
        required: true,
      },

      // A callback function that will return the list item name
      // This could be as simple as a function returning `.name` or using a relation to get the title/name of a record
      displayNameLookupFn: {
        type: Function,
        required: true,
      },

      // The module to check permissions against
      permissionModule: {
        type: String,
        required: true,
      },

      // Note: The following may need to accept arrays if multiple perms and actions need to be checked in the future
      permissionName: {
        type: String,
        required: true,
      },

      // The action you are trying to take on the model
      actionMethod: {
        type: [String, Function],
        required: true,
      },

      // The error message displayed that explains why some employees/documents can't be updated
      actionErrorMessage: {
        type: String,
        required: true,
      },
    },

    emits: ['continue', 'cancelled'],

    data() {
      return {
        contextVariable: 'bulk_action_alert',
        allowableActions: false,
      }
    },

    methods: {
      handleModalShow(e) {
        if (this.$refs.bulkActionListAlertDetails.allPermittedAndActionable) {
          // Don't allow modal to open if all selected are permitted and actionable
          e.preventDefault()

          // Skip this modal
          this.$emit('continue', this.$refs.bulkActionListAlertDetails.permittedAndActionableIds)

          // The key will still exist since we aren't calling hide()
          this.deletePageContextKey(this.contextVariable)
        }
      },

      handleCancel() {
        this.hideModal()
        this.$emit('cancelled')
      },

      handleContinue() {
        this.$refs.bulkActionListAlertDetails.filter()
        this.$emit('continue', this.$refs.bulkActionListAlertDetails.permittedAndActionableIds)
        this.hideModal()
      },

      hideModal() {
        this.$refs.modal.hide()
      },

      handleAllowableActions(allowed) {
        this.allowableActions = allowed
      },

      ...mapMutations({
        deletePageContextKey: DELETE_PAGE_CONTEXT_KEY,
      }),
    },
  }
</script>
