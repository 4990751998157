<template>
  <div
    class="toolbar"
    :class="{ 'flex-row-reverse': reverse }"
  >
    <slot />
  </div>
</template>
<script>
  export default {
    props: {
      reverse: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>
