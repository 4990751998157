<template>
  <div class="wizard">
    <div class="row">
      <div class="col-md-2 hidden-xs">
        <slot name="steps" />
      </div>
      <div class="col-md-10">
        <slot name="messages" />
        <WbCard>
          <Spinner
            v-if="loading"
            ref="loader"
          />
          <div
            v-else
            class="wizard-body"
            ref="body"
          >
            <slot name="body" />
            <div class="wizard-footer">
              <WbButton
                context="info"
                class="previous"
                size="lg"
                @click="handlePrevious"
                ref="previousButton"
              >
                Previous
              </WbButton>
              <WbButton
                context="primary"
                class="next"
                size="lg"
                @click="handleNext"
                ref="nextButton"
              >
                {{ nextLabel }}
              </WbButton>
            </div>
          </div>
        </WbCard>
      </div>
    </div>
  </div>
</template>
<script>
  import Spinner from 'components/common/Spinner'
  import WbButton from 'components/common/WbButton'
  import WbCard from 'components/common/WbCard'

  export default {
    components: {
      Spinner,
      WbButton,
      WbCard,
    },
    props: {
      loading: {
        type: Boolean,
        default: false,
      },
      nextLabel: {
        type: String,
        default: 'Next',
      },
    },
    methods: {
      handleNext () {
        this.$emit('next')
      },
      handlePrevious () {
        this.$emit('previous')
      },
    },
  }
</script>
