<template>
  <FormField
    :label="label"
    :name="name"
    :orientation="orientation"
    :required="required"
    :errors="errors"
    :hint="hint"
    :helpPopoverText="helpPopoverText"
  >
    <RadioToggle
      :title="title"
      :value="value"
      :options="options"
      :buttonSize="buttonSize"
      @selected="changeSelection"
    />
  </FormField>
</template>

<script>
  import FormField from 'components/common/FormField'
  import RadioToggle from 'components/common/RadioToggle'
  import FormFieldConcern from 'mixins/FormFieldConcern'

  export default {
    name: 'form-field-button-group',
    components: {
      FormField,
      RadioToggle,
    },
    mixins: [FormFieldConcern],

    props: {
      title: {
        type: String,
      },
      value: {
        type: String,
      },
      options: {
        type: Array,
      },
      buttonSize: {
        type: String,
      },
    },

    methods: {
      changeSelection(selection) {
        this.$emit('selected', selection)
      },
    },
  }
</script>
