export default Backbone.Model.extend({

  initialize: function(){
    const alreadyStarted = (this.get('state') == 'import')
    this.set('started', alreadyStarted)

    this.set('completed', false)
  },

  // Subscribe to notifications about updates in the import state.
  // Sets internal states and posts events on change
  //
  // Returns nothing
  subscribe: function(){
    const userImportId = this.get('id')

    const channel = App.PubSub.subscribeToUserChannel({ topic: `admin_user_import.${userImportId}`, unique: false })
    channel.bind('bulk_import_started', () => {
      this.set('started', true)
    })
    channel.bind('import_complete', () => {
      this.set('completed', true)
      App.vent.trigger('admin_user_import:import_complete', userImportId)
    })
  },

  // API request to kick off import.
  //
  // Returns true if started. Returns false if already started.
  import: function(){
    if (this.get('started')) {return false}

    const url = `/user_imports/${this.id}/start_import`
    $.ajax({url: url, type: 'POST'})
    return true
  },
})
