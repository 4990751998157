<template>
  <DashboardWidget
    :widgetTitle="widgetTitle"
    :errorMsg="errorMsg"
    :lastUpdated="lastUpdated"
    @refresh="fetch(true)"
  >
    <v-wait :for="source">
      <template #waiting>
        <Spinner :showLongWaitMessage="true" />
      </template>
      <EVerifyStagingAlertMessage
        v-if="everifyStagingEnabled"
        ref="stagingEverifyDashboardAlertMessage"
      />
      <WbBreakdownChart
        v-if="!errorMsg.length && chartData"
        :legendData="legendData"
        :chartData="chartData"
        :listViewPath="allCasesPath"
        modelNameHumanized="E-Verify case"
      />
    </v-wait>
  </DashboardWidget>
</template>

<script>
  import WbBreakdownChart from 'components/common/WbBreakdownChart'
  import Spinner from 'components/common/Spinner'
  import DashboardWidget from 'components/admin/dashboard/DashboardWidget'
  import UrlUtil from 'lib/util/url'
  import {EVERIFY_STATUSES, FILTER_EVERIFY_STATUSES} from 'components/admin/dashboard/EverifyCaseStatusConstants'
  import DashboardFetch from 'components/mixins/DashboardFetch'
  import {BREAKDOWN_VARIANTS_FOR_STATUS} from "components/admin/dashboard/BreakdownChartConstants"
  import EVerifyStagingAlertMessage from 'components/everify/EverifyStagingAlertMessage'
  import { mapGetters } from 'vuex'

  const widgetTitle = 'E-Verify Summary'
  const source = 'dashboard_everify_query'
  const getDashboardData = (pathFn => {
    const data = []
    FILTER_EVERIFY_STATUSES.forEach(status => {
      data.push({
        legend_key: status.value,
        message: status.label,
        path: pathFn([status.value]),
        variant: BREAKDOWN_VARIANTS_FOR_STATUS[status.value],
      })
    })
    return data
  })

  export default {
    name: 'everify-summary-container',
    components: {
      EVerifyStagingAlertMessage,
      WbBreakdownChart,
      Spinner,
      DashboardWidget,
    },

    mixins: [DashboardFetch],

    data() {
      return {
        widgetTitle: widgetTitle,
        source: source,
        legendData: getDashboardData(this.caseStatusPath),
      }
    },

    computed: {
      // We can't go directly to /everify/cases because of our persisted filter routes that are saved via localStorage,
      // so we go directly to the filtered cases we care about instead
      allCasesPath() {
        return this.caseStatusPath(EVERIFY_STATUSES.ALL)
      },
      ...mapGetters({
        currentAccount: 'account/current',
      }),
      everifyStagingEnabled () {
        return this.currentAccount.everifyStagingEnabled
      },
    },

    methods: {
      // Returns a path to the everify cases page with a case_status
      caseStatusPath(status) {
        return UrlUtil.urlWithParams(this.$routes.everify_cases_path, {
          case_status: status,
          page: 1,
        })
      },
    },
  }
</script>
