import SignaturePad from 'views/common/forms/signature_pad_view'

export default Marionette.LayoutView.extend({
  template: false,
  ui: {
    form: 'form.w9-form',
    taxClassSelect: 'select#w9_submission_tax_class',
    llcTaxClassFormGroup: '.form-group.w9_submission_llc_tax_class',
    llcTaxClassSelect: 'select#w9_submission_llc_tax_class',
    llc3bFormGroup: '.form-group.w9_submission_llc_3b',
    otherTaxClassFormGroup: '.form-group.w9_submission_tax_other_details',
    ssnInputField: 'input#w9_submission_ssn',
    einInputField: 'input#w9_submission_ein',
    foreignPartnersCheckbox: 'input#w9_submission_llc_3b',
  },

  triggers: {
    'change @ui.taxClassSelect': 'taxClassSelectChanged',
    'change @ui.llcTaxClassSelect': 'llcTaxClassSelectChanged',
    'keydown @ui.ssnInputField': {
      event: 'moderateSSNInput',
      stopPropagation: false,
      preventDefault: false,
    },
    'keydown @ui.einInputField': { 
      event: 'moderateEINInput',
      stopPropagation: false,
      preventDefault: false,
    },
  },
  
  initialize: function(options) {
  },

  onRender: function() {
    this.ui.taxClassSelect.change()

    this.signaturePadView = new SignaturePad({ el: $('.integrated-signature-pad')[0] })
    this.signaturePadView.render()
  },

  onTaxClassSelectChanged: function() {
    // Show the 'LLC Type' field if the tax classification is LLC; or 'Other Details' if Other is selected
    this.ui.llcTaxClassFormGroup.toggle(this.ui.taxClassSelect.val() === 'llc')
    this.ui.otherTaxClassFormGroup.toggle(this.ui.taxClassSelect.val() === 'other')
    this.updateForeignPartnersCheckbox()    
  },

  onLlcTaxClassSelectChanged: function() {
    this.updateForeignPartnersCheckbox()
  },

  // These two functions prevent the user from being able to enter values into both EIN and SSN
  onModerateEINInput: function() {
    if (this.ui.einInputField.val() !== '') {
      this.ui.ssnInputField.val('')
    }
  },
  onModerateSSNInput: function() {
    if (this.ui.ssnInputField.val() !== '') {
      this.ui.einInputField.val('')
    }
  },
  updateForeignPartnersCheckbox: function(){
    const allowForeignPartners = this.shouldShowForeignPartnersCheckbox()
    if (!allowForeignPartners) {
      this.ui.foreignPartnersCheckbox?.prop("checked", false)
    }
    this.ui.llc3bFormGroup.toggle(allowForeignPartners)
  },
  shouldShowForeignPartnersCheckbox: function() {
    const taxClass = this.ui.taxClassSelect.val()
    if (taxClass === 'partnership' || taxClass === 'trust_estate') {
      return true
    } else {
      return taxClass === 'llc' && this.ui.llcTaxClassSelect.val() === 'P'
    }
  },
})
