<template>
  <div>
    <h2>New Account</h2>

    <form class="form-horizontal fv-form fv-form-bootstrap" :errors="formErrors">
      <FormFieldSelect
        orientation="horizontal"
        :required="true"
        label="Account Type"
        :errors="formErrors"
        name="account-type"
        :options="accountTypes"
        placeholder="Active"
        v-model="formData.accountType"
      />

      <FormFieldInput
        orientation="horizontal"
        :required="true"
        label="Company Name"
        :errors="formErrors"
        name="name"
        type="text"
        v-model="formData.companyName"
      />

      <FormFieldInput
        orientation="horizontal"
        :required="true"
        label="Subdomain"
        :errors="formErrors"
        name="subdomain"
        type="text"
        v-model="formData.subdomain"
        :addon="emptyHostname"
      />

      <FormFieldCheckbox
        label="User Permissions"
        name="account[permissions_feature]"
        v-model="formData.permissionsFeature"
        value="false"
      />

      <FormFieldCheckbox
        label="Upsells Enabled"
        name="account[upsells_enabled]"
        v-model="formData.upsellsEnabled"
        value="true"
      />

      <FormFieldCheckbox
        label="Embedded Mode"
        name="account[embedded_feature]"
        v-model="formData.embeddedFeature"
        value="false"
      />

      <FormFieldCheckbox
        label="E-Verify"
        name="account[everify_feature]"
        value="false"
        v-model="formData.everifyFeature"
      />

      <FormFieldCheckbox
        label="Remote I-9 Verification Enabled"
        name="account[remote_countersign_enabled]"
        v-model="formData.remoteCountersignEnabled"
        value="false"
      />

      <FormFieldCheckbox
        label="MFA Required for Admins"
        name="account[mfa_required_for_admins]"
        v-model="formData.mfaRequiredForAdmins"
        value="false"
      />

      <FormFieldCheckbox
        label="MFA Required for Staff"
        name="account[mfa_required_for_staff]"
        v-model="formData.mfaRequiredForStaff"
        value="false"
      />

      <FormFieldCheckbox
        label="Single Sign-On (SSO)"
        name="account[sso_enabled]"
        v-model="formData.ssoEnabled"
        value="false"
      />

      <FormFieldCheckbox
        label="ADP® Workforce Now"
        name="account[payroll_enabled]"
        v-model="formData.payrollEnabled"
        value="false"
      />

      <FormFieldCheckbox
        label="Remote I-9 Text Feature"
        name="account[ri9_sms_enabled]"
        v-model="formData.ri9SmsEnabled"
        value="false"
      />

      <FormFieldCheckbox
        label="Onboarding Text Feature"
        name="account[onboarding_sms_enabled]"
        v-model="formData.onboardingSmsEnabled"
        value="false"
      />

      <FormFieldCheckbox
        label="Main Inbox Pagination"
        name="account[inbox_pagination_enabled]"
        v-model="formData.inboxPaginationEnabled"
        value="false"
      />

      <FormFieldCheckbox
        label="I-9 Inbox Pagination"
        name="account[i9_inbox_pagination_enabled]"
        v-model="formData.i9InboxPaginationEnabled"
        value="false"
      />

      <FormFieldInput
        orientation="horizontal"
        :required="true"
        label="Administrator Email"
        :errors="formErrors"
        name="settings_administrator_email"
        type="text"
        v-model="formData.administratorEmail"
      />

      <FormFieldCheckbox
        label="No Administrator Email"
        name="account[no_administrator_email]"
        v-model="formData.noAdministratorEmail"
        value="false"
      />

      <hr/>

      <FormFieldInput
        orientation="horizontal"
        :required="true"
        label="Salesforce ID"
        :errors="formErrors"
        name="settings_salesforce_id"
        type="text"
        v-model="formData.salesforceId"
      />

      <FormFieldCheckbox
        label="No Salesforce Account"
        name="account[no_salesforce]"
        v-model="formData.noSalesforceAccount"
        value="false"
      />

      <hr/>

      <FormFieldSelect
        orientation="horizontal"
        :required="false"
        label="Clone from existing"
        :errors="formErrors"
        name="clone-account"
        :options="accounts"
        v-model="formData.cloneAccount"
        ref="cloneInput"
        hint="All documents will be cloned from this existing account"
      />

      <div class="form-group">
        <div class="col-sm-8 col-md-9 col-sm-offset-4 col-md-offset-3">
          <LaddaButton class="submit" ref="submitButton" @click="handleSubmit">Submit</LaddaButton>
          <a class="btn btn-default btn-form-cancel" :href="$routes.accounts_path">Cancel</a>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
  import FormFieldCheckbox from 'components/common/FormFieldCheckbox'
  import FormFieldInput from 'components/common/FormFieldInput'
  import FormFieldSelect from 'components/common/FormFieldSelect'
  import LaddaButton from 'components/common/LaddaButton'

  import { mapState } from 'vuex'
  import Routes from 'lib/routes'
  import PubSubUtil from 'lib/pub_sub_wrapped_action'
  import Util from 'lib/util'
  import Locale from 'lib/locale'

  export default {
    name: 'the-new-account-root',

    components: {
      FormFieldCheckbox,
      FormFieldInput,
      FormFieldSelect,
      LaddaButton,
    },

    data() {
      return {
        formData: {
          accountType: 'active',
          cloneAccount: null,
          companyName: '',
          permissionsFeature: false,
          upsellsEnabled: true,
          everifyFeature: false,
          embeddedFeature: false,
          remoteCountersignEnabled: false,
          mfaRequiredForAdmins: false,
          mfaRequiredForStaff: false,
          ssoEnabled: false,
          ri9SmsEnabled: false,
          onboardingSmsEnabled: false,
          inboxPaginationEnabled: false,
          i9InboxPaginationEnabled: false,
          subdomain: '',
          administratorEmail: '',
          salesforceId: '',
          noSalesforceAccount: false,
          noAdministratorEmail: false,
        },
        formErrors: {},
      }
    },

    computed: {
      accountTypes() {
        return this.pageContext.accountTypes.map((at) => { return { value: at.value, label: at.name } })
      },

      accounts() {
        return this.pageContext.accounts.map((a) => { return { value: a.id, label: a.name } })
      },

      successStr() {
        return `${this.formData.companyName} has been created`
      },

      emptyHostname() {
        return new URL(this.$routes.root_url({ subdomain: '' })).hostname
      },

      ...mapState([
        'pageContext',
      ]),
    },

    methods: {
      handleSubmit() {
        this.success = false
        this.formErrors = {}

        this.newAccount()
      },

      createRequest(channelName) {
        const accountData = {
          account: {
            account_type: this.formData.accountType,
            name: this.formData.companyName,
            subdomain: this.formData.subdomain,
            permissions_feature: this.formData.permissionsFeature,
            upsells_enabled: this.formData.upsellsEnabled,
            everify_feature: this.formData.everifyFeature,
            embedded_feature: this.formData.embeddedFeature,
            remote_countersign_enabled: this.formData.remoteCountersignEnabled,
            mfa_required_for_admins: this.formData.mfaRequiredForAdmins,
            mfa_required_for_staff: this.formData.mfaRequiredForStaff,
            payroll_enabled: this.formData.payrollEnabled,
            sso_enabled: this.formData.ssoEnabled,
            ri9_sms_enabled: this.formData.ri9SmsEnabled,
            onboarding_sms_enabled: this.formData.onboardingSmsEnabled,
            inbox_pagination_enabled: this.formData.inboxPaginationEnabled,
            i9_inbox_pagination_enabled: this.formData.i9InboxPaginationEnabled,
            settings_salesforce_id: this.formData.salesforceId,
            no_salesforce_id: this.formData.noSalesforceAccount,
            settings_administrator_email: this.formData.administratorEmail,
            no_administrator_email: this.formData.noAdministratorEmail,
          },
        }
        if (this.formData.cloneAccount) {
          // Select2 is returning a string, despite us providing integer IDs
          // We should get Select2 to return back the type we originally provided, but
          // until then we'll convert it back to an integer
          accountData.account.clone_account_id = parseInt(this.formData.cloneAccount)
        }
        if (channelName) {
          accountData.pubsub_channel = channelName
        }

        return $.ajax({
          method: 'POST',
          url: Routes.accounts_path,
          data: accountData,
          wbGenericFailureMsg: Locale.t("accounts.create.fail"),
          progressBar: false,
        })
      },

      newAccount() {
        return PubSubUtil.pubSubWrappedAction(
          'create_account',
          (channelName, reject) => {
            this.createRequest(channelName).fail((xhr) => {
              return reject(xhr)
            })
          },
          (_response, resolve, _reject) => {
            resolve()
          },
        ).then((_result) => {
          Util.navigateAndShowFlashNotice(Routes.accounts_path, this.successStr)
        }).catch((xhr) => {
          this.$refs.submitButton.stop()

          if (xhr && xhr.responseJSON) {
            this.formErrors = xhr.responseJSON.errors
          }
        })
      },

      handleCancel() {
        this.$emit('cancelled')
      },
    },
  }
</script>
