<template>
  <WbModal
    :title="modalTitle"
    :largeModal="true"
    @modalShown="onOpen"
    @modalHidden="onClose"
    ref="rejectModal"
    :watchPageContextVariable="modalKey"
  >
    <slot>
      <AlertMessage
        v-if="showErrorAlert"
        ref="noReasonProvidedAlert"
        title="No Reason Provided"
        :message="noReasonProvidedText"
        variant="danger"
      />
      <div class="form-horizontal" v-if="hasReasonCheckboxes">
        <FormFieldCheckbox
          name="documents_do_not_match"
          ref="documentsDoNotMatch"
          label="The document(s) do not match the information provided."
          v-model="documentsNotMatch"
          gridColumns="col-xs-12"
        />
        <FormFieldCheckbox
          name="documents_not_genuine"
          ref="documentsNotGenuine"
          label="The document(s) do not appear genuine."
          v-model="documentsNotGenuine"
          gridColumns="col-xs-12"
        />
        <FormFieldCheckbox
          name="documents_not_relating"
          ref="documentsNotRelating"
          :label="`The document(s) do not appear to be relating to ${employeeName}.`"
          v-model="documentsNotRelating"
          gridColumns="col-xs-12"
        />
        <FormFieldCheckbox
          name="information_provided_not_correct"
          ref="informationProvidedNotCorrect"
          :label="`${employeeName} has indicated the information provided in Section 1 is incorrect.`"
          v-model="informationNotCorrect"
          gridColumns="col-xs-12"
        />
      </div>
      <FormFieldTextArea
        class="mb-3"
        ref="additionalDetailsTextArea"
        orientation="vertical"
        :label="additionalDetailsLabel"
        type="text"
        v-model="additionalDetails"
        placeholder="Notes for future reference"
        v-if="showAdditionalDetailsField"
      />
    </slot>
    <template #footer>
      <button
        class="btn btn-default"
        @click="hideModal"
        :disabled="false"
      >
        Close
      </button>
      <WbButton
        :isLoading="isLoading"
        @click="submit"
        label="Submit"
      />
    </template>
  </WbModal>
</template>

<script>
  import AlertMessage from 'components/common/AlertMessage'
  import FormFieldCheckbox from 'components/common/FormFieldCheckbox'
  import FormFieldTextArea from 'components/common/FormFieldTextArea'
  import WbModal from 'components/common/WbModal'
  import WbButton from 'components/common/WbButton'
  import { mapMutations, mapActions } from 'vuex'
  import { DELETE_PAGE_CONTEXT_KEY, SET_PAGE_CONTEXT_KEYS } from 'vuex/mutation_types'
  import Util from 'lib/util'

  export default {
    name: 'reject-reason-modal',
    components: {
      AlertMessage,
      FormFieldTextArea,
      FormFieldCheckbox,
      WbModal,
      WbButton,
    },

    props: {
      employeeName: {
        required: true,
      },
      submissionId: {
        required: true,
      },
      documentId: {
        required: true,
      },
      adminRejectionReasonsEnabled: {
        type: Boolean,
        default: false,
      },
      modalKey: {
        type: String,
      },
      onSubmit: {
        type: Function,
        default: () => {},
      },
      i9RejectionHideAdditionalDetailsFieldEnabled: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        isLoading: false,
        showErrorAlert: false,
        additionalDetails: '',
        documentsNotMatch: false,
        documentsNotGenuine: false,
        documentsNotRelating: false,
        informationNotCorrect: false,
      }
    },

    computed: {
      reasonMissing() {
        if (this.hasReasonCheckboxes) {
          return !this.selectedReasons.length
        } else {
          return this.additionalDetails.trim() === ''
        }
      },
      additionalDetailsLabel() {
        return this.hasReasonCheckboxes ? 'Additional details:' : 'Please enter a reason for the rejection:'
      },
      modalTitle() {
        return this.hasReasonCheckboxes ? 'To reject this submission, select the applicable reason(s):' : 'Reject Submission'
      },
      noReasonProvidedText() {
        return this.hasReasonCheckboxes ? 'Please select at least one reason to reject this submission.' : 'A reason for the rejection is required.'
      },
      hasReasonCheckboxes() {
        return this.documentId === 'i9' && this.adminRejectionReasonsEnabled
      },
      selectedReasons() {
        return [
          this.documentsNotMatch ? 'form_not_information_match' : null,
          this.documentsNotGenuine ? 'form_not_genuine_information' : null,
          this.documentsNotRelating ? 'form_not_relating_to_employee' : null,
          this.informationNotCorrect ? 'form_not_information_correct' : null,
        ].filter((v) => v)
      },
      showAdditionalDetailsField() {
        return !(this.i9RejectionHideAdditionalDetailsFieldEnabled && this.hasReasonCheckboxes)
      },
    },

    beforeDestroy () {
      // Dirty solution but we need to make sure backdrop is hidden when this component is destroyed
      // when it is a nested modal
      document.querySelector('.modal-backdrop')?.remove()
    },

    methods: {
      hideModal () {
        this.deletePageContextKey(this.modalKey)
      },
      ...mapMutations({
        deletePageContextKey: DELETE_PAGE_CONTEXT_KEY,
        setPageContextKeys: SET_PAGE_CONTEXT_KEYS,
      }),
      ...mapActions({
        rejectSubmission: 'onboarding_submissions/rejectSubmission',
      }),
      onClose() {
        this.handleJQueryNestedModalClose()
        this.$emit('closed')
      },
      onOpen() {
        this.$emit('opened')
      },
      submit() {
        this.isLoading = true
        this.showErrorAlert = this.reasonMissing

        if (!this.showErrorAlert) {
          this.rejectSubmission({
            submissionId: this.submissionId,
            additional_details: this.additionalDetails,
            reasons: this.selectedReasons,
          }).then(() => {
            Util.showFlashNotice('Submission rejected successfully')

            this.hideModal()

            this.onSubmit()
          }).catch((xhr) => {
            Util.genericAjaxError('An error occurred rejecting this submission.', xhr)
          }).finally(() => {
            this.isLoading = false
          })
        } else {
          this.isLoading = false
        }
      },

      // Since this modal is not closed via `.modal('hide')`, no `hidden.bs.modal` event
      // is triggered; thus, we need to update the `open-modal-count` counter.
      // more info: app/assets/javascripts/vendor/vendor_extensions/bootstrap.upgrades.js:70
      handleJQueryNestedModalClose() {
        const $body = $('body')
        const openModalCount = $body.data('open-modal-count')

        // Since this is a nested modal we need to subtract 2.
        $body.data('open-modal-count', openModalCount - 2)
        $body.addClass('modal-open')
      },
    },
  }
</script>
