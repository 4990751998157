// Group Permissions by their Target
class TargetGroupedPermissions {
  // Constructor
  //
  // permissionSet: UserPermissionSet
  // query: Object with query parameters for permissionSet (target_id, target_type...)
  //
  constructor(permissionSet, query) {
    const permissions = query ? permissionSet.findWhere(query) : permissionSet.permissions

    // Build an object with 'target_id' as the key
    // { target_id: [Permission, Permission], ... }
    const groupedPermissionsObj = {}
    permissions.forEach((permission) => {
      groupedPermissionsObj[permission.target_id] = groupedPermissionsObj[permission.target_id] || []
      groupedPermissionsObj[permission.target_id].push(permission)
    })

    // Reformat the Object as an Array
    // [ { key: 'target_id', permissions: [Permission, Permission] } ]
    const groupedArr = []
    Object.keys(groupedPermissionsObj).forEach((key) => {
      groupedArr.push({ key: key == 'null' ? null : key, permissions: groupedPermissionsObj[key]})
    })

    // Sort by "key" property, with null last
    this.groupedPermissions = groupedArr.sort((a, b) => {
      if (a.key == null) { return 1 }
      if (b.key == null) { return -1 }
      if (a.key < b.key) {
        return -1
      }
      return 1
    })
  }
}

export default TargetGroupedPermissions
