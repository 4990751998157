import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import Vue from 'vue'
import BrowserSupport from 'lib/browser_support'

// Bugsnag static initialization
const BUGSNAG_NOTIFY_RELEASE_STAGES = ["staging", "production"]

$(document).ready(() => {
  Bugsnag.start({
    apiKey: gon.global.JS_ENV.BUGSNAG_JS_API_KEY,
    enabledReleaseStages: BUGSNAG_NOTIFY_RELEASE_STAGES,
    onError: (event) => {
      // Don't send errors for browsers we don't support. Helps cut down on noise.
      const browserSupport = new BrowserSupport()
      if (!browserSupport.isSupported) {
        return false
      }

      const ignorePatterns = /gCrWeb/i
      const match = (event.message?.length) ? event.message.match(ignorePatterns) : null
      return !match?.[0].length
    },
    plugins: [new BugsnagPluginVue(Vue)],
    releaseStage: gon.global.rails_env,
  })

  if (gon.current_user?.id) {
    const { email, id, name, role } = gon.current_user
    Bugsnag.setUser(id, email, name)
    Bugsnag.addMetadata('user', 'role', role)
  }

  if (gon.current_account?.subdomain) {
    const { name, subdomain } = gon.current_account
    Bugsnag.addMetadata('account', { name, subdomain })
  }
})
