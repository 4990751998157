// APPENDER
// TODO:
//  * callbacks
//  * reorderability

// the semi-colon before the function invocation is a safety 
// net against concatenated scripts and/or other plugins 
// that are not closed properly.
;(function ($, window, document, undefined) {
  "use strict" 

  const defaults = {
    hideFirstRowDelete: false,
    firstRowDeleteClears: true,
    inputSelector: 'input',
    createDefaultRowsIfEmpty: true,
    numDefaultRowsToCreate: 1,
    autoFocusNewRows: true,

    template: null,               // (required) consumers must provide HTML
    addButton: null,              // (optional) consumers must provide $ wrapped element or make their own calls to addRow
    initialCollection: null,      // (optional) an initial collection of vals to pass to addRow
  }

  $.extend(Appender.prototype, {
    initialize: function() {
      if (this.options.initialCollection)
      {this._processCollection(this.options.initialCollection)}

      if (this.rowCount() == 0 && this.options.createDefaultRowsIfEmpty) {
        let i
        for (i = 0; i < this.options.numDefaultRowsToCreate; i++)
        {this.addRow()}
      }

      // handlers
      $(this.$element).on('click', '.appender-remove-action', $.proxy(this._onRemoveClick, this))
      if (this.options.addButton) 
      {this.options.addButton.on('click', $.proxy(this._onAddRowClick, this))}
    },

    addRow: function(value) {
      const $newRow = $(this.options.template).appendTo(this.$element)

      if (value !== undefined && value !== null) {
        const $inputEl = $newRow.find(this.options.inputSelector)
        if ($inputEl) 
        {$inputEl.val(value)}
      }

      if (this.options.autoFocusNewRows) {
        $newRow.find(this.options.inputSelector).focus()
      }

      this._refreshRowControls()
    },

    deleteRow: function($row) {
      if (!$row) {return}
      $row.fadeOut(150, function(){ $(this).remove() })
      this._refreshRowControls()
    },

    deleteAllRows: function() {
      this.deleteRow(this.$element.find('li.appender-row'))
    },

    rowCount: function() {
      return this.$element.find('li.appender-row').length
    },

    values: function() {
      return this.$element.find(this.options.inputSelector).map(function(i,v) { 
        if ($(this).val().length > 0) 
        {return $(this).val()} 
      }).toArray()
    },

    reset: function() {
      this.resetTo(this.options.initialCollection || null)
    },

    resetTo: function(collection) {
      this.deleteAllRows()
      this.initialCollection = collection
      this._processCollection(collection)
    },
  })

  function Appender(element, options) {
    this.element = element
    this.$element = $(element)

    this.options = $.extend({}, defaults, options) 
    this._defaults = defaults
    
    /* PRIVATE */ {
      this._refreshRowControls = function() {
        if (this.options.hideFirstRowDelete)
        {this.$element.find('li.appender:first-child .appender-remove-action').toggle(this.rowCount > 1)}
      }

      this._onRemoveClick = function(e) {
        e.preventDefault()
        e.stopPropagation()

        const $targetRow = $(e.target).closest('li.appender-row')
        if (this.options.firstRowDeleteClears && $targetRow.is(':first-child')) {
          $targetRow.find(this.options.inputSelector).val('')
        } else {
          this.deleteRow($targetRow)
        }
      }

      this._onAddRowClick = function(e) {
        e.preventDefault()
        e.stopPropagation()
        this.addRow()
      }
 
      this._processCollection = function(collection) {
        if (collection == null || collection.length == 0) {return}
        const self = this
        $.each(collection, function(i, v) {
          self.addRow(v)
        })
      }
    }

    this.initialize()
  }

  // A really lightweight plugin wrapper around the constructor, 
  // preventing against multiple instantiations
  $.fn.appender = function(options) {
    return this.each(function () {
      if ($.data(this, 'appender') === undefined) {
        $.data(this, 'appender', new Appender(this, options))
      }
    })
  }
  
})(jQuery, window, document)
