/* global moment */

import I9DocumentationSet from 'models/document_submissions/i9_documentation_set'

export default class I9Submission extends Backbone.Model.extend({
  defaults: {
    signature_data_uri: null,
  },
}) {
  // Initializer
  initialize() {
    this.on('change:citizenship_designation', this.onChangeCitizenshipDesignation)
    this._initAlienOptionExclusivity()
    this._initAlienNumberTypeSync()

    // The `add` event is triggered when a document choice is selected/changed
    this.documentationSet.on('add', (attachmentModel) => {
      // Prefill employee's SSN if we have it
      if ((attachmentModel.get('documentation_key') == 'ssn_card') && this.get('ssn')) {
        attachmentModel.set({ document_number: this.get('ssn') })
      }
    })
    this.setOriginalEmployeeStartDate()
  }

  // Called by Backbone when initializing model with JSON data
  parse(data) {
    data.employee_start_date = moment(data.employee_start_date || '')
    data.employer_certify_date = moment() // set certify-date to today whenever submission is countersigned

    data.documentation_set = new I9DocumentationSet(data.documentation_set)

    return data
  }

  // Returns - [I9DocumentationSet]
  get documentationSet() {
    return this.get('documentation_set')
  }

  toJSON() {
    const attrs = _.clone(this.attributes)

    if (moment.isMoment(attrs.employee_start_date)) {
      attrs.employee_start_date = attrs.employee_start_date.toISODateString()
    }

    if (moment.isMoment(attrs.employer_certify_date)) {
      attrs.employer_certify_date = attrs.employer_certify_date.toISODateString()
    }

    if (!_.isEmpty(attrs.documentation_set)) {
      attrs.documentation_set = attrs.documentation_set.map(function(attachment) {
        return attachment.toJSON()
      })
    }
    return attrs
  }

  // Only one of the 3 options for Alien "document number" is allowed at any given time. This clears out the
  // other two when any one field changes.
  _initAlienOptionExclusivity() {
    this.on('change:alien_reg_number', (_model, value) => {
      if (!value || value.length == 0) {
        return
      }

      this.set('i94_admission_number', null)
      this.set('foreign_passport_number', null)
      this.set('country_of_issuance', null)
    })

    this.on('change:i94_admission_number', (_model, value) => {
      if (!value || !value.match(/^[A-Za-z0-9]{11}/)) {
        return
      }

      this.set('alien_reg_number', null)
      this.set('foreign_passport_number', null)
      this.set('country_of_issuance', null)
    })

    const passportChangeFn = (_model, value) => {
      if (!value || !value.match(/^[A-Za-z0-9]{6,12}/)) {
        return
      }

      this.set('alien_reg_number', null)
      this.set('i94_admission_number', null)
    }
    this.on('change:foreign_passport_number', passportChangeFn)
    this.on('change:country_of_issuance', passportChangeFn)
  }

  // Keep the alien_reg_number and arn_type fields in sync. `arn_type` is a special property which is only used by
  // the UI (not processed by the backend).
  _initAlienNumberTypeSync() {
    // When the ARN number changes, read the value and switch 'Alien Number' / 'USCIS Number' appropriately
    this.on('change:alien_reg_number', (_model, value) => {
      if (typeof value != "string") {
        return
      }

      // presence of leading A indicates what type of number it is
      const isAlienArnType = (value.charAt(0) === 'A')
      this.set('arn_type', isAlienArnType ? 'alien' : 'uscis')
    })

    // When the Typechanges, add/remove the 'A' prefix
    this.on('change:arn_type', (_model, value) => {
      const arnValue = this.get('alien_reg_number')
      switch (value) {
      case 'alien':
        if (typeof arnValue == 'string' && arnValue.charAt(0) !== 'A') {
          this.set('alien_reg_number', `A${arnValue}`)
        }
        break
      case 'uscis':
        if (typeof arnValue == 'string' && arnValue.charAt(0) == 'A') {
          this.set('alien_reg_number', arnValue.substring(1, arnValue.length))
        }
        break
      }
    })
  }

  // When the Citizenship Designation changes, clear out values for fields which do not apply to the newly-selected
  // citizenship type. e.g. if Citizen, clear out all Permanent Resident and Alien field values.
  onChangeCitizenshipDesignation(_model, value) {
    const isPermanentResident = (value == 'permanent_resident')
    const isAlien = (value == 'alien')

    if (!isAlien) {
      this.set('alien_exp_date', null)
      this.set('i94_admission_number', null)
      this.set('foreign_passport_number', null)
      this.set('country_of_issuance', null)
    }

    if (!isPermanentResident && !isAlien) {
      this.set('alien_reg_number', null)
    }
  }

  setOriginalEmployeeStartDate() {
    const current = this.get('employee_start_date')
    if (!this._originalEmployeeStartDate) {
      this._originalEmployeeStartDate = current
    }
  }
  hasOriginalEmployeeStartDate() {
    const isSame = (
      this.get('employee_start_date').isSame(this._originalEmployeeStartDate) ||
      !this.get('employee_start_date')._isValid && !this._originalEmployeeStartDate._isValid
    )
    return isSame
  }
}
