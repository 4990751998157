import RepresentableField from 'models/representable_field'
import Filter from 'models/filter'

class Scope {
  constructor(options = {}) {
    this.field_key = options.field_key
    this.value = options.value
    this.op = options.op
  }

  toJSON() {
    return {
      field_key: this.field_key,
      op: this.op,
      value: this.value,
    }
  }
}

export default Scope
