import Constants from 'lib/constants'

export default class PermanentResidentCitizenshipFieldsView extends Backbone.Marionette.ItemView.extend({
  template: '#i9-form-citizenship-permanent-resident-tmpl',

  ui: {
    arnInput: 'input[name="i9_submission[alien_reg_number]"]',
  },

  bindings: {
    'select.arn-type': 'arn_type',
    'input[name="i9_submission[alien_reg_number]"]': 'alien_reg_number',
  },
}) {
  initialize() {
    this.fvPlugin = this.getOption('rfv').fvPlugin
  }

  onRender() {
    this.stickit()
    $.runInitializers(this.$el)

    this.ui.arnInput.inputmask('Regex', { regex: Constants.I9_ARN_INPUTMASK })
  }

  onShow() {
    this.fvPlugin.addField('i9_submission[alien_reg_number]', {
      validators: { wbRemote: {} },
      err: '.i9_submission_alien_reg_number .input-wrapper',
    })
  }

  onBeforeDestroy() {
    this.fvPlugin.removeField('i9_submission[alien_reg_number]')
  }
}
