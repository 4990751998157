import { getRoute } from 'lib/routes'
import { ajax } from 'jquery'

const state = () => ({})

const actions = {
  create: ({}, { data }) => {
    return new Promise((resolve, reject) => {
      ajax({
        method: 'POST',
        url: getRoute('webhooks_path'),
        data: data,
      })
        .then(xhr => resolve(xhr))
        .fail(xhr => reject(xhr))
    })
  },
  update({}, { id, data }) {
    return new Promise((resolve, reject) => {
      ajax({
        method: 'PATCH',
        url: getRoute('webhook_path', {id}),
        data: data,
      })
        .then(xhr => resolve(xhr))
        .fail(xhr => reject(xhr))
    })
  },
}

export default {
  namespaced: true,
  state,
  actions,
}
