<template>
  <WbModal
    title="Remove Admin Access"
    :watchPageContextVariable="modalKey"
    ref="modal"
  >
    <slot>
      <p>
        This will remove admin access from this person. However, because they also have an employee record within WorkBright, they will still be able to login to submit or update their own forms. They will not have access to view any other employees or manage anything else in the system.
      </p>
    </slot>
    <template #footer>
      <WbButton
        context="default"
        @click="hideModal"
        :disabled="false"
      >
        Cancel
      </WbButton>
      <WbButton
        context="danger"
        @click="handleRevoke"
        label="Remove Admin Access"
        :isLoading="isLoading"
      />
    </template>
  </WbModal>
</template>
<script>
  import Util from 'lib/util'
  import WbButton from 'components/common/WbButton'
  import WbModal from 'components/common/WbModal'
  import { mapActions } from 'vuex'

  export default {
    components: {
      WbButton,
      WbModal,
    },
    props: {
      user: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        isLoading: false,
      }
    },
    computed: {
      modalKey () {
        return `revokeAdminModal-${this.user.id}`
      },
    },
    methods: {
      ...mapActions({
        fetchRevokeAdmin: 'admin_users/revoke',
      }),
      async handleRevoke () {
        this.isLoading = true

        try {
          await this.fetchRevokeAdmin({ id: this.user.id })
        } catch {
          Util.showFlashNotice('Sorry, we were not able to remove admin access.')
        } finally {
          this.isLoading = false
          this.hideModal()
          this.$emit('adminUpdate')
        }
      },
      hideModal () {
        this.$refs.modal.hide()
      },
    },
  }
</script>
