<template>
  <WbSearch
    width="100%"
    :route="route"
    v-model="localValue"
    :closeOnSelect="false"
    :multiple="true"
    :responseProcessor="processResponse"
    placeholder="Search Accounts"
    @input="handleInput"
  />
</template>
<script>
  import WbSearch from 'components/common/WbSearch'
  import { getRoute } from 'lib/routes'

  export default {
    components: {
      WbSearch,
    },
    props: {
      value: {
        type: Array,
      },
    },
    data () {
      return {
        localValue: this.value || "+ALL_ACCOUNTS",
      }
    },
    computed: {
      route () {
        return getRoute('account_search_path')
      },
    },
    methods: {
      handleInput (value) {
        this.$emit('input', value)
      },
      processResponse (response) {
        const accounts = response.accounts || []

        return accounts.map(account => {
          return {
            label: account.name,
            value: account.subdomain,
          }
        })
      },
    },
  }
</script>
