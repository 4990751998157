<template>
  <div>
    <FormFieldSsn
      ref="ssnField"
      :errors="formErrors"
      label="SSN"
      :name="inputName"
      orientation="horizontal"
      :required="ssnFieldRequired"
      :value="ssnFieldValue"
      :disabled="ssnFieldDisabled"
      @input="handleSsnFieldInput"
    />
    <div v-if="required">
      <input value="false" type="hidden" :name="appliedForSsnInputName"/>
      <FormFieldCheckbox
        ref="appliedForSsnField"
        :label="$locale.t('staff.profile.applied_for_ssn.checkbox.label')"
        :helpPopoverText="$locale.t('staff.profile.applied_for_ssn.checkbox.help_text')"
        v-model="localAppliedForSsn"
        :name="appliedForSsnInputName"
        @input  ="handleCheckboxInput"
        :hint="$locale.t('staff.profile.applied_for_ssn.help_block')"
      />
    </div>
  </div>
</template>
<script>
  import FormFieldSsn from 'components/common/FormFieldSsn'
  import FormFieldCheckbox from 'components/common/FormFieldCheckbox'

  export default {
    name: 'the-employee-profile-ssn-root',
    components: {
      FormFieldSsn,
      FormFieldCheckbox,
    },

    props: {
      errors: {
        type: Object,
      },
      formName: {
        type: String,
        default: 'employee_profile',
      },
      required: {
        type: Boolean,
      },
      value: {
        type: [String, Number],
      },
      appliedForSsn: {
        type: Boolean,
        default: false,
      },
    },

    data: function() {
      return {
        ssnFieldValue: this.value,
        localSsnFieldValue: this.value,
        localAppliedForSsn: this.appliedForSsn,
      }
    },

    computed: {
      formErrors () {
        const errors = this.errors || {}
        errors[this.inputName] = errors[this.inputName] || errors['ssn']
        return errors
      },
      inputName () {
        return `${this.formName}[ssn]`
      },
      appliedForSsnInputName () {
        return `${this.formName}[applied_for_ssn]`
      },
      ssnFieldRequired () {
        return this.required ? !this.localAppliedForSsn : this.required
      },
      ssnFieldDisabled () {
        return this.required ? this.localAppliedForSsn : false
      },
    },

    methods: {
      handleCheckboxInput: function (){
        if (this.localAppliedForSsn === true){
          this.$refs.ssnField.localValue = null
        } else {
          this.$refs.ssnField.localValue = this.localSsnFieldValue
        }
      },
      handleSsnFieldInput: function(value) {
        if (this.localAppliedForSsn === false){
          this.localSsnFieldValue = value
        }
        this.ssnFieldValue = value
      },
    },
  }
</script>
