import { ajax } from 'jquery'
import { getRoute } from 'lib/routes'

export function resolveDocumentVerification(submissionId, isApproved) {
  return new Promise((resolve, reject) => {
    const action = isApproved ? 'approve' : 'decline'
    const params = {
      id: submissionId,
      resolve_action: action,
    }

    ajax({
      method: 'PATCH',
      type: 'JSON',
      url: getRoute('resolve_document_verifications_i9_submission_path', params),
    }).success(({ submission, verified_attachments }) => {
      resolve({ submission, verified_attachments })
    }).fail(xhr => {
      reject(xhr)
    })
  })
}
