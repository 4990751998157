import ReportListTableCollectionView from 'views/reports/reports_list_table_collection_view'
import ReportsCollection from 'collections/reports_collection'

export default Marionette.LayoutView.extend({
  template: false,
  ui: {
  },

  triggers: {

  },

  initialize: function() {
    this.$tableEl = $("#reports-table")
  },

  onBeforeRender: function() {
    if (this.$tableEl) {
      this.attachToListTable()

      this.addRegion("toolbarRegion", ".dt-toolbar")
      // TODO: toolbar tmpl marionette cache
      const toolbarView = new Marionette.ItemView({ template: "#reports-toolbar-tmpl" })
      this.toolbarRegion.show(toolbarView)
      toolbarView.prerendered = true // after showing, turn on prerendered so it doesn't get removed from the DOM on nav
    }
  },

  attachToListTable: function() {
    this.$tableEl.DataTable({
      retrieve: true,
      destroy: true,
      sorting: [3],
      columns: [
        /* 0 Name */ null,
        /* 1 Decription */ null,
        /* 2 Created on */ { type: "date" },
        /* 3 Last Updated */ { type: "date" },
        /* 4 Created by */ null,
        /* 5 Actions */ null,
      ],
      language: {
        emptyTable: `<div class="text-center"><h4>No reports have been created.</h4>` +
                    `<p><a href="${App.Util.getPageRootPath()}/new">Click here</a> to create your first report.</p></div>`,
      },
    })

    App.Reports.listTableCollectionView = new ReportListTableCollectionView()
    App.Reports.listTableCollectionView.attachToTable(this.$tableEl, ReportsCollection, function() {
      return {
        id: this.data("id"),
      }
    })
  },

  onBeforeDestroy: function() {
    if (App.Reports.listTableCollectionView) {
      App.Reports.listTableCollectionView.destroy()
      delete App.Reports.listTableCollectionView
    }
  },
})
