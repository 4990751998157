// This code is heavily based on:
// views/document_submissions/i9/step_documentation_view.js
//
// I-9 Supplement B Form Documentation Step
//
// Multipart Step:
// Step 0    - Select Documentation              DocumentSelectView
// Step 1..n - Upload/Edit Specific Attachment   DocumentAttachmentView
//
import DocumentSelectView from 'views/document_submissions/i9/supplement_b/document_select_view'
import EditView from 'views/document_submissions/i9/supplement_b/edit_view'
import RemoteFormValidation from 'lib/remote_form_validation'
import Routes from 'lib/routes'
import Constants from 'lib/constants'


export default Marionette.LayoutView.extend({
  template: "#i9-supplement-b-documentation-tmpl",
  className: 'i9-documentation',
  ui: {
    error: '.error',
    form: 'form',
  },
  regions: {
    docsPane: '.documentation-container',
  },

  initialize: function() {
    this.context = this.getOption('context')
  },

  onShow: function() {
    this.showSubStep()
    setTimeout(() => {
      new RemoteFormValidation({
        partialValidation: false,
        path: Routes.staff_i9_submission_path,
        resourceName: 'i9_submission',
        model: this.model,
        formEl: this.ui.form,
        successCallback: (xhr) => {
          this.validationCompleteCallback(true, xhr)
        },
        failedCallback: () => {
          this.validationCompleteCallback(false)
        },
        errorCallback: (xhr) => this.onRemoteValidateError(xhr),
      })
    }, Constants.CSS_FADE_DURATION)
  },

  showSubStep: function() {
    const action = this.context.get('action')
    let subView = null
    if (!action || action.display == 'select_documents'){
      subView = new DocumentSelectView({
        model: this.model.documentationSet,
        documentationChoices: this.context.get('documentationChoices'),
        context: this.context,
      })
    } else if (action.display == 'upload_document') {
      const doc = this.model.documentationSet.at(action.index)
      subView = new EditView({model: doc, context: this.context})
    }

    this.docsPane.show(subView)
  },

  // Pass validation to substeps, if present
  validate: function(validationCompleteCallback){
    if (this.docsPane.currentView.validate){
      this.docsPane.currentView.validate(validationCompleteCallback)
    } else {
      validationCompleteCallback(true)
    }

    this.ui.form.submit()
  },
})
