import DateTime from 'lib/util/datetime'
import Locale from 'lib/locale'

const STATUSES_TO_SHOW_DEADLINE = ['pending_referral', 'pending_referral_bad_data', 'referred', 'final_nonconfirmation', 'submission_error']

export default {
  computed: {
    caseDeadline () {
      return DateTime.daysFromNow(this.everifyCase.deadline_on)
    },

    shouldShowDeadline () {
      return STATUSES_TO_SHOW_DEADLINE.includes(this.everifyCase.case_status) && this.everifyCase.deadline_on
    },
  },

  data () {
    return {
      deadlineLabel: Locale.t('everify.deadline'),
    }
  },
}
