export const VERIFIED_STATUSES = ['approved', 'declined']

export const CONFIG_BY_STATUS = {
  completed: {
    classNames: ['label-verified'],
    label: 'Verified',
    icon: 'check-circle',
  },
  issue: {
    classNames: ['label-default'],
    label: 'Not Verified',
    icon: 'exclamation-circle',
  },
  failed: {
    classNames: ['label-warning'],
    label: 'Unable to Verify',
    icon: 'times-circle',
  },
  approved: {
    classNames: ['label-success'],
    label: 'Approved by Admin',
    icon: 'check-circle',
  },
  declined: {
    classNames: ['label-danger'],
    label: 'Declined by Admin',
    icon: 'times-circle',
  },
}

export function getDocumentVerificationClassNames(status) {
  return CONFIG_BY_STATUS[status]?.classNames || []
}

export function getDocumentVerificationHasValidStatus (status) {
  return !!CONFIG_BY_STATUS[status]
}

export function getDocumentVerificationIcon (status) {
  return CONFIG_BY_STATUS[status]?.icon
}

export function getDocumentVerificationLabel (status) {
  return CONFIG_BY_STATUS[status]?.label || 'N/A'
}
