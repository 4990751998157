/* PRERENDERED SUPPORT
 * Marionette Extension
 *
 */

// When Turbolinks moves to a new page, the App.currentSector is stopped, causing all of the views to teardown and remove themselves
// from the DOM. This behavior is a bit startling to the user: all of a sudden the UI elements are all removed from the page. 
// In order to prevent this from happening, we override `View._removeElement` to check if `pageIsChanging`. If it is, we don't
// need to bother removing elements from the DOM. 
// 
// Marionette.pageIsChanging is set and unset via a couple of listeners in all4staff.js.
export default function() {
  // LEGACY: The 'getOption' portion can safely be removed whenever our usage of `prerendered` is absolved
  if (Marionette.pageIsChanging || this.getOption('prerendered') === true) {
    return
  }

  this.$el.remove()
}
