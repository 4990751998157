<template>
  <FormField
    :label="label"
    :name="name"
    :helpPopoverText="helpPopoverText"
    :orientation="orientation"
    :errors="errors"
    :required="required"
    :hint="hint"
  >
    <div :class="{ 'input-group': addon }">
      <input class="form-control"
        :autocomplete="autocomplete"
        :type="type"
        :name="name"
        :id="name"
        :placeholder="placeholderValue"
        :value="value"
        :disabled="disabled"
        ref="inputField"
        v-mask="mask"
        @input="$emit('input', $event.target.value)"
      />
      <span class="input-group-addon" v-if="addon">
        {{ addon }}
      </span>
    </div>
  </FormField>
</template>

<script>
  import FormField from 'components/common/FormField'
  import FormFieldConcern from 'mixins/FormFieldConcern'

  export default {
    name: 'form-field-input',
    components: {
      FormField,
    },
    mixins: [FormFieldConcern],

    props: {
      // Input Group Addon Text
      addon: {
        type: String,
        required: false,
      },

      type: {
        required: false,
        default: 'text',
      },

      value: {
        required: false,
      },

      autocomplete: {
        required: false,
        default: 'on',
      },

      disabled: {
        required: false,
        default: false,
      },

      mask: {
        type: String,
        required: false,
      },
    },

    methods: {
      focusInput() {
        this.$refs.inputField.focus()
      },
    },
  }
</script>
