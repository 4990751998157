// Adds behavior which shows the modal on render() and self-destroys the view when the modal is hidden.
// This allows users of the modal to instantiate a new View (binding it to the modal el in the DOM) and call
// `render()` on it each time the modal is intended to be open.
export default class PrerenderedModalView extends Marionette.Behavior.extend({
}) {
  onRender() {
    // Show the model immediately on render
    this.$el.modal({ show: true })

    this.$el.one("hidden.bs.modal", this.bsModalHidden.bind(this.view))
  }

  bsModalHidden() {
    // When the modal is hidden, destroy ourselves
    this.destroy()
  }
}
