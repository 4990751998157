// Select2 Control with Editable Value
// View takes an text input field as an element. The text view is hidden and replaced with a Select2.
// Selecting an option allows the user to edit it.
//
// Options:
// el - Text input dom element
// choices       - Array of choices: [{label: 'Red', value: 0}, {label: 'Blue', value: 1}, ...]
//               - optional 'checked' boolean to display a checkmark next to the option name
//                 [{label: 'Check Please!', value: 'check-me', checked: true}]
// textInputHint - Hint text shown with text field
// textInputLink - Hint text to toggle Select2 on
// selectHint    - Hint text shown with Select2
// selectLink    - Hint text to toggle Text Edit on
// checkedHint   - Hint text on the meaning of the checked icon
//
export default Marionette.ItemView.extend({
  template: false,

  // Show the text input
  // Hide Select2, show appropriate hint text
  showTextInput: function() {
    this.$el.removeClass('select-mode')
    this.$el.find('input').focus()

    const selectListLink = $('<a>').attr('href', 'javascript:void(0)').html(this.getOption('textInputLink'))
    const helpBlock = this.$el.find('.help-block')
    helpBlock.html(this.getOption('textInputHint'))
    helpBlock.append(selectListLink)

    selectListLink.click(function(){
      this.clearValues()
      this.showSelectList()
    }.bind(this))
  },

  // Show Select2
  // Hide text input, show appropriate hint text
  showSelectList: function() {
    this.$el.addClass('select-mode')

    const manuallyEditLink = $('<a>').attr('href', 'javascript:void(0)').html(this.getOption('selectLink'))
    const helpBlock = this.$el.find('.help-block')
    helpBlock.html(this.getOption('selectHint'))
    helpBlock.append(manuallyEditLink)

    const anyChecked = _(this.getOption('choices')).filter((c) => { return c.checked }).length > 0
    if (anyChecked) {
      helpBlock.append(`<span class='d-block mt-1'><i class='fa fa-check text-success' /> ${this.getOption('checkedHint')}</span>`)
    }


    manuallyEditLink.click(function(){
      this.showTextInput()
    }.bind(this))
  },

  // Clear text input and Select2 values
  clearValues: function() {
    const inputEl = this.$el.find('input')
    inputEl.val(null)
    inputEl.trigger('change')
    this.setSelectValue(null)
  },

  // Set the Select2 value
  //
  // value - string or null
  setSelectValue: function(value) {
    this.$el.find('select').val(value)
    this.$el.find('select').trigger('change.select2')
  },

  onRender: function(){
    this.$el.addClass('select2-text-input select-mode')

    // Find input elements
    const inputEl = this.$el.find('input')
    const inputName = inputEl.attr('name')
    const inputValue = inputEl.val()
    const inputWrapper = inputEl.parent()

    // Add an icon to indicate text edit mode
    // Identify text edit elements with a class
    inputEl.addClass('text-input')
    const editIcon = $('<i>').addClass('fa fa-pencil form-control-feedback text-input')
    inputWrapper.append(editIcon)

    // Create a select
    const selectName = `${inputName.replace(/[\[\]]/g, '_')}select2` // Replace '[' and ']' with '_'
    const selectEl = $('<select>').attr('name', selectName).attr('id', selectName).addClass('select2 select form-control').html('')
    _.each(this.getOption('choices'), function(choice){
      const option = new Option(choice.label, choice.value)
      $(option).data('checked', choice.checked) // Checked option set?
      selectEl.append(option)
    })

    selectEl.data('template-result', function(state) {
      const element = $('<span>')
      element.text(state.text)

      if ($(state.element).data('checked')) {
        element.addClass('checked')
      }

      return element
    })


    inputWrapper.append(selectEl)

    // Is the input blank or does it match a select choice? - Show Select2
    // Does this input *not* match a select choice? - Show Text Input (modified field)
    if (inputValue.length == 0 || _.select(this.getOption('choices'), function(c){ return c.value == inputValue}).length){
      this.setSelectValue(inputValue)
      this.showSelectList()
    } else {
      setTimeout(function(){this.showTextInput()}.bind(this))
    }

    // Switch to Text Input if Select2 is changed
    selectEl.on('change', function(event){
      // Get the value from Select2
      const value = event.target.value
      if (!value || value.length == 0) {return}

      inputEl.val(value)
      inputEl.trigger('change')
    }.bind(this))
  },
})
