import UdfFieldsListTableCollectionView from 'src/views/user_defineable_fields/udf_fields_list_table_collection_view'
import UserDefineableFieldsCollection from 'src/collections/user_defineable_fields_collection'

export default Marionette.LayoutView.extend({
  template: false,
  initialize: function() {
    this.$tableEl = this.$el.find('table#document-fields')
  },

  onBeforeRender: function() {
    if (this.$tableEl) {
      this.attachToListTable()
      this.makeTableReorderable()

      this.addRegion("toolbarRegion", ".dt-toolbar")
      const toolbarView = new Marionette.ItemView({ template: "#document-fields-toolbar-tmpl" })
      this.toolbarRegion.show(toolbarView)
      toolbarView.prerendered = true // after showing, turn on prerendered so it doesn't get removed from the DOM on nav
    }
  },

  makeTableReorderable: function () {
    const self = this
    // Get tbody instead of table element for Sortable.js
    const el = document.getElementById('document-fields').tBodies[0]
    Sortable.create(el, {
      handle: '.choice-handle',
      ghosting: true,

      // Changed sorting within list
      onUpdate: function (evt) {
        self.updateRowPosition(evt)
      },
    })
  },

  reorderPreview: function() {
    // Place newly reordered IDs and HTML into 2D array
    const reorderedTableRows = []
    $('#document-fields tr').each(function() {
      const fieldId = this.getAttribute("data-id")
      if (fieldId) {
        reorderedTableRows.push( [fieldId, $(`._udf_${fieldId}`).html()] )
      }
    })
    // Get Preview form fields and replace their inner HTML and class
    $('.form-group').each(function (index) {
      const $this = $(this)
      const oldClass = this.getAttribute('class').split(" ").pop()
      const newClass = `_udf_${reorderedTableRows[index][0]}`
      // Replace _udf_ class in Preview
      $this.removeClass(oldClass).addClass(newClass)
      $this.html(reorderedTableRows[index][1])
    })
  },

  updateRowPosition: function(event) {
    const fieldId = event.item.getAttribute("data-id")
    const documentId = $('body').data("root-path").split("/")[2]
    $.post( `/forms/${documentId}/fields/${fieldId}/update_position`, {
      elementId: fieldId,
      fromPos: event.oldIndex,
      toPos: event.newIndex,
    })
    this.reorderPreview()
  },

  attachToListTable: function() {
    this.$tableEl.DataTable({
      retrieve: true,
      destroy: true,
      sorting: [],  // No initial sort
      columns: this.getColumns(),
      columnDefs: [],
      language: {
        emptyTable: `<div class="text-center"><h4>No fields have been set up yet.</h4>` +
                    `<p><a href="${App.Util.getPageRootPath()}/new">Click here</a> to add a field.</p></div>`,
      },
    })

    this.listTableCollectionView = new UdfFieldsListTableCollectionView()
    this.listTableCollectionView.attachToTable(this.$tableEl, UserDefineableFieldsCollection, function() {
      return {
        id: this.data("id"),
      }
    })
  },

  getColumns: function() {
    const columns = [
      /* 0 Drag Handle */ null,
      /* 1 Label */ null,
      /* 2 Type */ null,
      /* 3 Required? */ null,
      /* 4 Internal? */ null,
      /* 5 Actions */ null,
    ]

    return columns
  },

  onBeforeDestroy: function() {
    // Clear the template cache because this toolbar template will change depending on the page
    // TODO: genericize this because it'll probably come up more often
    Marionette.TemplateCache.clear("#document-fields-toolbar-tmpl")

    if (this.listTableCollectionView) {
      this.listTableCollectionView.destroy()
    }
  },
})
