<template>
  <div>
    <div class="alert alert-danger" v-if="errorsAlert">
      {{ $locale.t('errors.form.generic') }}
    </div>
    <form class="form-horizontal" id="supplement-a">
      <PageHeader
        title="Preparer & Translator Certification"
        :lead="$locale.t('staff.i9.preparerTranslator.subTitle')"
      />
      <FormFieldRadio
        :options="preparerOptions"
        :value="include_supplement_a"
        label="Enter preparer/translator info"
        orientation="horizontal"
        name="showPreparerForm"
        ref="showPreparerForm"
        @input="handleIncludeUpdate"
      />
      <div v-show="showForm">
        <I9PreparerFormFields
          v-for="(preparer, index) in preparers"
          :key="index"
          :preparer="preparer"
          :usStates="usStates"
          :index="index"
          :showErrors="showErrors"
          :shouldShowIndex="supportsMultipleTranslators"
          :shouldShowRemoveLink="hasMoreThanOnePreparer"
          ref="preparerSection"
          @update-preparer="preparer => { handleFormUpdate(index, preparer) }"
          @remove-preparer="handleRemovePreparer"
        />
        <div class="text-center" v-if="supportsMultipleTranslators">
          <div v-if="canAddPreparer">
            <button
              class="btn btn-lg btn-link"
              @click.stop.prevent="addPreparerSection"
              ref="addPreparer"
            >
              + Add another Preparer/Translator
            </button>
          </div>
          <div v-else class="alert alert-info visible-inline-block">
            You have reached the maximum of 4 Preparers/Translators
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
  import FormFieldRadio from 'components/common/FormFieldRadio'
  import I9PreparerFormFields from 'components/i9/I9PreparerFormFields'
  import PageHeader from 'components/common/PageHeader'

  export default {
    name: 'i9-preparer-step-container',
    components: {
      FormFieldRadio,
      I9PreparerFormFields,
      PageHeader,
    },

    props: {
      supportsMultipleTranslators: {
        type: Boolean,
        default: false,
      },
      supplementA: {
        type: Object,
        required: false,
      },
      usStates: {
        type: Array,
        required: true,
      },
      updateBackboneModel: {
        type: Function,
        required: true,
      },
      nextButtonStatus: {
        type: Function,
        required: false,
      },
    },

    data() {
      return {
        preparerOptions: [
          { label: 'Yes', value: true },
          { label: 'No', value: false },
        ],
        include_supplement_a: this.supplementA?.include,
        preparers: [],
        preparerFields: {
          submitted_at: '',
          last_name: '',
          first_name: '',
          middle_initial: '',
          address: '',
          city: '',
          state: '',
          zip: '',
          signature: {
            signature: '',
            signature_name: '',
          },
          signature_name_field: {
            name: 'supplement_a_signature_name',
            autofocus: false,
          },
          errors: {},
        },
        showErrors: false,
      }
    },

    computed: {
      showForm() {
        return this.include_supplement_a
      },

      isPreparersEmpty() {
        return this.preparers.length === 0
      },

      hasMoreThanOnePreparer() {
        return this.preparers.length > 1
      },

      // Do any of the preparer sections contain fields with entered data? If not, then don't show the form.
      hasEnteredData() {
        return this.preparers.some(preparer => {
          const filteredFields = this.filteredFields(preparer)
          return Object.values(filteredFields).some(x => !!x)
        })
      },

      canAddPreparer() {
        return this.preparers.length < 4
      },

      errors() {
        return this.preparers.filter(preparer => {
          return Object.keys(preparer.errors || {}).length
        }).flatMap(preparer => {
          return preparer.errors || []
        })
      },

      hasErrors() {
        return this.errors.some(error => error)
      },

      errorsAlert() {
        return this.hasErrors && this.showErrors
      },
    },

    watch: {
      include_supplement_a () {
        this.toggleForm()
      },
    },

    created() {
      // Add 'errors' property to each section/preparer on an existing Supplement A. This is needed when a user clicks
      // the Next/Previous button and then returns to the Supplement A page because 'errors' has been removed.
      const sectionsWithErrors = Object.entries(this.supplementA.sections).map(([_index, section]) => {
        return ({ ...section, errors: {} })
      })
      this.preparers = Object.values(sectionsWithErrors)

      this.setNextButtonStatus()
    },

    mounted() {
      // Add 'errors' property to each section/preparer on an existing Supplement A. This is needed when a user clicks
      // the Next/Previous button and then returns to the Supplement A page because 'errors' has been removed.
      const sectionsWithErrors = Object.entries(this.supplementA.sections).map(([_index, section]) => {
        return ({ ...section, errors: {} })
      })
      this.preparers = Object.values(sectionsWithErrors)

      this.setNextButtonStatus()
    },

    methods: {
      toggleForm() {
        if (this.showForm) {
          if (this.isPreparersEmpty) {
            this.addPreparerSection()
          }
        } else {
          this.removePreparerSections()
          this.setNextButtonStatus()
        }
      },

      handleIncludeUpdate (shouldInclude) {
        this.include_supplement_a = !!shouldInclude
      },

      // When a user enters input data, send data to Backbone/Rails and update status of 'Next' button.
      handleFormUpdate(preparerIndex, preparer) {
        this.preparers[preparerIndex] = preparer
        this.preparers = [...this.preparers] // Trigger reactivity
        
        // Remove errors property from this.preparers before sending to backend.
        const sections = this.preparers.map((preparer) => {
          return { ...(({ errors, ...clone }) => clone) (preparer) }
        })

        const supplementA = {
          supplement_a: {
            include: this.hasEnteredData,
            sections: [ ...sections ],
          },
        }

        this.setNextButtonStatus()
        this.updateBackboneModel(supplementA)
      },

      // When a user removes a section, send updated data to Backbone/Rails.
      handleRemovePreparer(index) {
        // Filter out the preparer by the index
        this.preparers = this.preparers.filter((_preparer, ix) => {
          return index !== ix
        }).map((preparer) => {
          return { ...(({ errors, ...clone }) => clone)(preparer) }
        })

        const sections = this.preparers

        const supplementA = {
          supplement_a: {
            include: this.hasEnteredData,
            sections: [...sections],
          },
        }

        this.setNextButtonStatus()
        this.updateBackboneModel(supplementA) 
      },

      // We want to show the validation errors when the "Next" button is clicked (to mimic the behavior on other steps
      // in the I-9 wizard). This method is meant to be called from the Backbone wizard.
      handleErrors() {
        this.showValidationErrors()
        if (this.hasErrors) {
          this.scrollToTop()
        }
      },

      addPreparerSection() {
        this.hideValidationErrors()
        this.preparers.push({ ...this.preparerFields })
      },

      // Enable (true) or disable (false) 'Next' button.
      setNextButtonStatus() {
        if (this.nextButtonStatus) {
          this.nextButtonStatus(!this.hasErrors)
        }
      },

      removePreparerSections() {
        this.preparers = []
      },

      // Return a subset of the preparer fields. This returns a cloned preparer object with only the listed fields,
      // using some destructuring magic: https://stackoverflow.com/a/39333479.
      filteredFields(preparer) {
        return (({ first_name, last_name, address, city, state, zip }) => ({
          first_name, last_name, address, city, state, zip,
        }))(preparer)
      },

      showValidationErrors() {
        this.showErrors = true
      },

      hideValidationErrors() {
        this.showErrors = false
      },

      scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      },
    },
  }
</script>
