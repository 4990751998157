import UserPermissionSet from './user_permission_set'
import Scope from 'models/policy_generator/scope'

// A User's Permission Policy, for use with the Policy Generator
//
// NOTE: At the time of writing, a user will have two policy sets: group, and baseline
// However, the Policy Generator UI is complicated. It's difficult to sort out the baseline set among the different UIs to
// preserve the desired appearance. Instead, we're splitting Baseline into two permission sets: Baseline and Other. This makes
// the UI a little easier to implement. They are recombined in toJSON() when sent to the API.
class Policy {
  constructor() {
    this.scopedPermissionSet = new UserPermissionSet('group', new Scope({ field_key: 'a4s_employee_groups', op: 'eq_any', value: [] }))
    this.unscopedPermissionSet = new UserPermissionSet('baseline')
    this.otherUnscopedPermissionSet = new UserPermissionSet('other')

    this.permissionSets = [
      this.scopedPermissionSet,
      this.unscopedPermissionSet,
      this.otherUnscopedPermissionSet,
    ]
  }

  groupPermissionSet() {
    return this.scopedPermissionSet
  }

  baselinePermissionSet() {
    return this.unscopedPermissionSet
  }

  otherPermissionSet() {
    return this.otherUnscopedPermissionSet
  }

  setScope(scope) {
    this.scopedPermissionSet.scope.value = scope
  }

  valid() {
    return (
      this.scopedPermissionSet.scope.value.length > 0
      && this.scopedPermissionSet.permissions.length > 1
    )
  }

  toJSON() {
    // Recombine Baseline and Other PermissionSets
    const baselinePermissionSet = new UserPermissionSet(null, null, {
      permissions: this.baselinePermissionSet().permissions.concat(this.otherPermissionSet().permissions),
    })

    return [this.groupPermissionSet(), baselinePermissionSet].map((permissionSet) => permissionSet.toJSON())
  }
}

export default Policy
