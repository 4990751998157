// Filterable list of all E-Verify cases

<template>
  <div>
    <EVerifyStagingAlertMessage
      v-if="everifyStagingEnabled"
      ref="stagingEverifyInboxAlertMessage"
    />
    <EverifyCaseListHeader />
    <div class="everify-cases-root">
      <EverifyCaseListContainer />
      <EverifyCaseSlideout
        :everifyCase="selectedCase"
        :allowDownload="allowDownload"
      />
    </div>
  </div>
</template>

<script>
  import EverifyCaseListHeader from 'components/everify/cases/EverifyCaseListHeader'
  import EverifyCaseListContainer from 'components/everify/cases/EverifyCaseListContainer'
  import EverifyCaseSlideout from 'components/everify/cases/EverifyCaseSlideout'
  import { mapGetters } from 'vuex'
  import EVerifyStagingAlertMessage from 'components/everify/EverifyStagingAlertMessage'

  export default {
    name: 'the-everify-cases-index-root',
    components: {
      EVerifyStagingAlertMessage,
      EverifyCaseListHeader,
      EverifyCaseListContainer,
      EverifyCaseSlideout,
    },
    computed: {
      ...mapGetters({
        pageContext: 'pageContext',
        getCase: 'everify_cases/get',
        currentAccount: 'account/current',
      }),
      selectedCase () {
        return this.getCase(this.pageContext.everify_case_id)
      },
      everifyStagingEnabled () {
        return this.currentAccount.everifyStagingEnabled
      },
      allowDownload () {
        return !this.currentAccount.disableDownloadAllFormsEnabled
      },
    },
  }
</script>
