<template>
  <div>
    <p>
      You are signed in as
      <strong>
        {{ this.pageContext.currentUserEmail }}
      </strong>
      <span class="label label-success">
        {{ currentUserRole }}
      </span>
    </p>
    <hr />
    <MfaVerificationContainer
      :mfaData="this.pageContext.mfaData"
    />
  </div>
</template>

<script>
  import MfaVerificationContainer from 'components/mfa/MfaVerificationContainer'
  import {mapState} from 'vuex'

  export default {
    name: 'the-mfa-root',

    components: {
      MfaVerificationContainer,
    },

    computed: {
      ...mapState([
        'pageContext',
      ]),

      currentUserRole() {
        let roleLabel = ""
        if (this.pageContext.currentUserRole)
        {roleLabel = this.pageContext.currentUserRole.toLowerCase().replace("employee", "").replace("restricted_", "")}
        return roleLabel
      },
    },
  }
</script>
