export default {
  namespaced: true,
  state: () => {
    return {
      current: null,
    }
  },
  getters: {
    current: state => state.current,
  },
  mutations: {
    setCurrent: (state, account) => {
      state.current = account
    },
  },
  actions: {
    init ({ commit }) {
      commit('setCurrent', window.gon.current_account)
    },
  },
}
