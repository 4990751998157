import RepresentableFieldSelectView from 'views/common/representable_field_select_view'
import FilterOpView from 'views/reports/filter_op_view'
import FilterValueView from 'views/reports/filter_value_view'

// Represents a single row in a collection of filters. This View directly controls the Field dropdown, but the Operator and
// Value components are regions controlled by other views (FilterOpView and FilterValueView, respectively).
export default class FilterRowView extends Backbone.Marionette.LayoutView.extend({
  template: '#filter-row-item-template',
  className: 'filter-row',

  regions: {
    opRegion: '.filter-op-region',
    valueRegion: '.filter-value-region',
  },

  ui: {
    fieldSelectContainer: '.filter-field-view',
    removeFilterAction: '.js-remove-filter',
    errorMessageLabel: '.help-block',
  },

  events: {
    'click @ui.removeFilterAction': 'removeFilter',
  },

  modelEvents: {
    invalid: 'validationFailed',
  },
}) {

  initialize() {
    this.fieldsTree = this.getOption('fieldsTree')
  }

  onRender() {
    // Initialize a RepresentableFieldSelectView that we will manage ourselves. Don't render it now.
    this.fieldSelectView = new RepresentableFieldSelectView({
      fieldsTree: this.fieldsTree,
      el: this.ui.fieldSelectContainer,
      selectedField: this.model.get('field'),
    })

    this.fieldSelectView.on('field:changed', this.onFieldChanged, this)

    this.opRegion.show(new FilterOpView({ model: this.model }))
    this.valueRegion.show(new FilterValueView({ model: this.model }))
  }

  onShow() {
    // We MUST render fieldSelectView only after this FilterRowView is shown in its region
    this.fieldSelectView.render()
  }

  removeFilter() {
    this.model.destroy()
  }

  onBeforeValidation() {
    this.$el.removeClass('has-error')
    this.ui.errorMessageLabel.text('')
  }

  validationFailed() {
    this.$el.addClass('has-error')
    this.ui.errorMessageLabel.text(this.model.validationError)
  }

  onBeforeDestroy() {
    // Since we are managing fieldSelectView ourselves, destroy it manually before we go away.
    this.fieldSelectView.off()
    this.fieldSelectView.destroy()
  }

  // Callback fired from RepresentableFieldSelectView when a field has been selected.
  onFieldChanged(field) {
    // Reset the model with the new field, set the op to the first one available, and blank out the value
    this.model.set({
      field: field,
      op: field.get('available_filter_ops')[0][1],
      value: null,
      fieldError: null,
    })
  }
}
