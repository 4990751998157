// Shows the Account Access Expiration Timer
export default function($scope) {
  const expirationBanner = $scope.find('#access-expiration-banner')

  if (expirationBanner.length == 0 ||
     !gon.current_account_access_activity ||
     !gon.current_account_access_activity.expires_at) {
    return
  }

  const countDownDate = new Date(gon.current_account_access_activity.expires_at).getTime()

  var interval = setInterval(function() {
    // Get todays date and time
    const now = new Date().getTime()

    // Find the time remaining between now and the count down date
    const timeRemaining = countDownDate - now

    // Time calculations for days, hours, minutes and seconds
    const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24))
    const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000)

    // Only show with 30 min left
    if (days < 1 && hours < 1 && minutes < 30) {
      expirationBanner.removeClass('hidden')

      // Display the result in the element
      const expirationMsg = `Access Expires in ${minutes}m ${seconds}s`

      expirationBanner.text(expirationMsg)

      if (minutes < 2) {
        expirationBanner.addClass('warning')
      }

      if (minutes < 1) {
        expirationBanner.removeClass('warning')
        expirationBanner.addClass('expired')
      }
    }

    // If the count down is finished, clear interval
    if (timeRemaining < 0) {
      expirationBanner.text('ACCESS EXPIRED')
      clearInterval(interval)

      // Kick the superadmin back to tenant select
      window.location = ErbRoutes.root_url({ subdomain: 'app'})
    }
  }, 1000)
}
