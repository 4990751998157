<template>
  <Panel variant="snapshot">
    <template v-slot:heading>
      <div class="panel-title">
        <h3>Action Required</h3>
        <h4>{{ $locale.t('everify.status.referred.title') }}</h4>
      </div>
    </template>
    <template v-slot:body>
      <p>
        {{ $locale.t('everify.status.referred.helpText') }}
      </p>
      <template v-if="shouldRenderLinks">
        <div class="mb-2">
          <a
            v-if="fanPath"
            class="btn btn-default"
            :href="fanPath"
            ref="fanLink"
            download
            target="_blank"
          >
            <WbIcon type="download" />
            Download Further Action Notice - English
          </a>
        </div>
        <div class="mb-2">
          <a
            v-if="fanPathSpanish"
            class="btn btn-default"
            :href="fanPathSpanish"
            ref="fanLinkSpanish"
            download
            target="_blank"
          >
            <WbIcon type="download" />
            Download Further Action Notice - Spanish
          </a>
        </div>
        <div class="mb-2">
          <a
            v-if="referralPath"
            download
            class="btn btn-default"
            :href="referralPath"
            ref="referralLink"
            target="_blank"
          >
            <WbIcon type="download" />
            Download Referral Date Confirmation Letter - English
          </a>
        </div>
        <div>
          <a
            v-if="referralPathSpanish"
            download
            class="btn btn-default"
            :href="referralPathSpanish"
            ref="referralLinkSpanish"
            target="_blank"
          >
            <WbIcon type="download" />
            Download Referral Date Confirmation Letter - Spanish
          </a>
        </div>
      </template>
      <div
        v-else-if="!hasSufficientData"
        class="alert alert-warning"
        ref="noLinksAlert"
      >
        {{  $locale.t('everify.status.referred.noData') }}
      </div>
    </template>
  </Panel>
</template>
<script>
  import WbIcon from 'components/common/WbIcon'
  import Panel from 'components/common/Panel'

  export default {
    components: {
      Panel,
      WbIcon,
    },
    props: {
      everifyCase: {
        type: Object,
        required: true,
      },
      allowDownload: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      fanPath () {
        return this.everifyCase.fan_url
      },
      fanPathSpanish () {
        return this.everifyCase.fan_url_spanish
      },
      hasSufficientData () {
        return this.fanPath || this.referralPath
      },
      shouldRenderLinks () {
        return this.allowDownload && this.hasSufficientData
      },
      referralPath () {
        return this.everifyCase.rdc_url
      },
      referralPathSpanish () {
        return this.everifyCase.rdc_url_spanish
      },
    },
  }
</script>
