import UDFValuesStoreConcern from 'models/concerns/udf_values_store_concern'
import HALAPIConcern from 'models/concerns/hal_api_concern'
import EmployeeGroupMembership from 'models/employee_group_membership'
import EmployeeGroupMembershipCollection from 'collections/employee_group_membership_collection'

export default class Employee extends Backbone.Model.extend({
  urlRoot: "/staff",
  ...HALAPIConcern,
  ...UDFValuesStoreConcern,
}) {

  parse(data, options) {
    this.parseHAL(data)

    // Deep initialize UDF fields
    this.parseUDFValuesStore(data, 'custom_fields')

    // Deep initialize group memberships
    const groups = $.map(data['groups'] || [], function(group, index) {
      return new EmployeeGroupMembership(group, { parse: true })
    })
    const GroupsCollection = EmployeeGroupMembershipCollection.extend({ url: `${this.url()}/groups` })
    data['groups'] = new GroupsCollection(groups)

    return data
  }

  updateUntrackedPriorSeasons(value) {
    this.save({
      untracked_prior_tenure: value,
    }, {
      patch: true,
      wait: true,
      wbGenericFailureMsg: "Sorry, we couldn't update this employee.",
    }).done((data, status, xhr) => {
      this.set(data)
    })
  }
}
