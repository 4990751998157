// Mixin which prevents memory leaks across Turbolinks page changes by tearing down appropriately.
//
// This is ONLY necessary on root-level components (which includes The-XYZ-Root components and orphaned components).
// Since child components will automatically get torn down with their parents, it is only necessary to include this
// mixin on root-level components.
//
// You automatically get this functionality with:
//
//   * The-XYZ-Root components (as part of `startRootVue`)
//   * Pure, orphaned components (as part of Vue.pureOrphanVue).
//
// If, however, you are instantiating your own Vue components (say, within a larger Backbone/Marionette context), you
// should include this mixin, or provide your own teardown functionality to prevent memory leaks.
export default {
  beforeCreate() {
    $(document).on('page:fetch', () => {

      // We've started fetching the next page. Unfortunately, Vue leaves event bindings in place during this process, meaning
      // that a user can still interact with the UI and invoke stuff that's been destroyed.
      // https://github.com/vuejs/vue/issues/7086 (closed, by design)
      //
      // To prevent this, we'll just override any clicks on Vue DOM element and stop them from reaching Vue during the transition.
      $(this.$el).on('click', (e) => {
        e.preventDefault()
        e.stopImmediatePropagation()
      })

      // For components that we want to persist across Turbolinks page changes
      if (!this.dontUnmountOnPageFetch) {
        this.$.appContext.app.unmount()
      }
    })
  },
}
