<template>
  <div class="wb-list__row">
    <div class="wb-list__container">
      <WbSimpleDateTime
        ref="date"
        :value="date"
        :format="dateFormat"
      />
      <div ref="title" class="text-strong mt-3">
        {{ title }}
      </div>
      <span ref="actorName" v-if="actorName">
        by {{ actorName }}
      </span>
    </div>
  </div>
</template>
<script>
  import Constants from 'lib/constants'
  import StringUtil from 'lib/util/string'

  import WbSimpleDateTime from 'components/common/WbSimpleDateTime'
  export default {
    components: {
      WbSimpleDateTime,
    },
    props: {
      event: {
        type: Object,
        required: true,
      },
    },
    computed: {
      date () {
        return this.event.server_timestamp
      },
      dateFormat () {
        return Constants.DATE_EXT_NO_WEEKDAY_FORMAT
      },
      title () {
        if (this.event.title) {
          return this.event.title
        } else if (this.event.details?.status) {
          return StringUtil.humanize(this.event.details?.status)
        } else {
          return StringUtil.humanize(this.event.name)
        }
      },
      actorName () {
        return this.event.details?.actor_name
      },
    },
  }
</script>
