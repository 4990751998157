/*
*
* Radio button field that will display Other option field if exists
*
*/
<template>
  <FormField
    :label="label"
    :name="name"
    :orientation="orientation"
    :required="required"
    :errors="errors"
    :hint="hint"
  >
    <select
      class="form-control multiselect"
      ref="select"
      :value="value"
      multiple="multiple"
    >
      <option
        v-for="(option, index) in options"
        v-bind:value="option.value"
        v-bind:key="index"
      >
        {{ option.label }}
      </option>
    </select>
  </FormField>
</template>

<script>
  import FormField from 'components/common/FormField'
  import FormFieldConcern from 'mixins/FormFieldConcern'

  export default {
    name: 'form-field-multi-checkbox',
    components: {
      FormField,
    },
    mixins: [FormFieldConcern],

    props: {
      value: {
        type: Array,
        required: false,
      },

      options: {
        type: Array,
        required: true,
      },
    },

    mounted() {
      $(this.$refs.select).multiselect({
        onChange: (option, checked) => {
          const optionValue = option.context.value // Option selected
          const value = this.value ? this.value.slice(0) : [] // Clone array, or create if DNE

          if (checked) {
            // Add choice to list
            value.push(optionValue)
          } else {
            // Remove choice from list
            value.splice(value.indexOf(optionValue), 1)
          }

          this.$emit('input', value)
        },
      })
    },
  }
</script>
