<template>
  <WbModal
    id="bulk-reset-assignments-modal"
    ref="modal"
    title="Request New Submissions"
    watchPageContextVariable="bulkResetAssignments"
    @modalHidden="$emit('hidden')"
    :locked="loading"
  >
    <v-wait :for="loadingName">
      <template #waiting>
        <Spinner :showLongWaitMessage="true" />
      </template>
      <component
        :is="currentStepComponentName"
        :nav-direction="navDirection"
        @skip="skipStep"
        ref="currentStep"
        :employeeIds="employeeIds"
        :collectionViewName="collectionViewName"
        @updateData="handleStepUpdateData"
      />
    </v-wait>
    <template #footer>
      <WbButton
        label="Cancel"
        context="default"
        :disabled="loading"
        @click="hide"
      />
      <WbButton
        :label="nextLabel"
        context="primary"
        :disabled="loading"
        @click="nextStep"
      />
    </template>
  </WbModal>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  import WbModal from 'components/common/WbModal'
  import BaseWizard from 'components/common/BaseWizard'
  import Spinner from 'components/common/Spinner'
  import StepSelectDocument from 'components/employees/bulk_reset_assignments/StepSelectDocument'
  import StepValidate from 'components/employees/bulk_reset_assignments/StepValidate'
  import StepConfirm from 'components/employees/bulk_reset_assignments/StepConfirm'
  import Locale from 'lib/locale'
  import Util from 'lib/util'
  import WbButton from 'components/common/WbButton'

  export default {

    name: 'bulk-reset-assignments',

    components: {
      WbModal,
      StepSelectDocument,
      StepValidate,
      StepConfirm,
      Spinner,
      WbButton,
    },
    mixins: [BaseWizard],

    props: {
      employeeIds: {
        type: Array,
        required: true,
      },

      collectionViewName: {
        type: String,
        required: true,
      },
    },

    data() {
      return {
        steps: [
          { component: 'step-select-document', label: 'Select Document' },
          { component: 'step-validate', label: 'Validation' },
          { component: 'step-confirm', label: 'Confirm' },
        ],
        document: null,
        emailMessage: null,
        loadingName: 'bulk_reset_loading',
      }
    },

    computed: {

      loading() {
        return this.$wait.is(this.loadingName)
      },

      ...mapGetters({
        assignmentsGetBatch: 'document_assignments/getBatch',
      }),

      ...mapState({
        assignments(state) {
          return this.assignmentsGetBatch(state[this.collectionViewName].selected)
        },
      }),
    },

    methods: {
      hide() {
        this.$refs.modal.hide()
      },

      done() {
        this.resetSubmissions()
      },

      stepChanged() {
        switch (this.currentStepComponentName) {
          case 'step-select-document':
            this.fetchEmployees()
            break

          case 'step-validate':
            this.fetchAssignmentsAndAuthorize()
            break

          case 'step-confirm':
            if (this.assignments.length == 0) {
              this.hide()
            }
            break

          default:
            break
        };
      },

      handleStepUpdateData(data) {
        Object.keys(data).forEach((key) => {
          this[key] = data[key]
        })
      },

      handleEmailMessageChange(emailMessage) {
        this.emailMessage = emailMessage
      },

      // Fetch Employees for EmployeeIds
      fetchEmployees() {
        this.startLoading()

        Promise.all([
          this.employeesBulkShow({ employeeIds: this.employeeIds }),
          this.employmentsBulkFind({ employeeIds: this.employeeIds }),
        ]).finally(this.endLoading)
          .catch(this.fetchFail)
      },

      // Fetch DocumentAssignments and Permissions
      fetchAssignmentsAndAuthorize() {
        this.startLoading()

        // Fetch Permissions on these Assignments
        const authorize = () => {
          this.permissionsBulkAuthorize({
            resourceType: 'document_assignments',
            resourceIds: this.assignments.map(a => a.id),
            actions: ['unlock_submission'],
          })
            .catch(this.fetchFail)
            .finally(() => {
              this.endLoading()
            })
        }

        if (this.assignments.length) { // Assignments already selected? No need to fetch em. Just authorize
          authorize()
        } else { // No assignments selected? Fetch em, then authorize
          // Fetch the DocumentAssignments we'll be resetting
          this.documentAssignmentsBulkFind({
            documentIds: [this.document.id],
            employeeIds: this.employeeIds,
          }).then((assignments) => {
            this.$store.dispatch(`${this.collectionViewName}/selectAll`, assignments.map((e) => e.id))
            authorize()
          }).catch(this.fetchFail)
        }
      },

      // Reset the Submissions
      resetSubmissions() {
        this.startLoading()

        this.assignmentsReset({
          assignments: this.assignments,
          emailMessage: this.emailMessage,
        }).then(() => {
          Util.showFlashNotice(Locale.t('assignments.reset.success', this.assignments.length))
          this.endLoading() // Called to unlock the modal, allow to hide
          setTimeout(() => { this.hide() }) // Delay until after end load has occurred and modal is unlocked
          this.$emit('success')
        }).catch(() => {
          this.endLoading()
        })
      },

      fetchFail(xhr) {
        this.hide()
        Util.genericAjaxError(Locale.t('errors.ajax.generic', 'reset status'), xhr)
      },

      startLoading() {
        this.$wait.start(this.loadingName)
      },

      endLoading() {
        this.$wait.end(this.loadingName)
      },

      ...mapActions({
        assignmentsReset: 'document_assignments/reset',
        documentAssignmentsBulkFind: 'document_assignments/bulkFind',
        employeesBulkShow: 'employees/bulkShow',
        employmentsBulkFind: 'employments/bulkFind',
        permissionsBulkAuthorize: 'permissions/bulkAuthorize',
      }),
    },
  }
</script>
