<template>
  <div>
    <!-- / If we used a `form-group` here we could remove this `div`, but since we do not use a true `input` here, label needs this div -->
    <div v-if="!!title">
      <label class="small">
        {{ title }}
      </label>
    </div>
    <div class="btn-group radio-toggle">
      <button
        v-for="(option, index) in options"
        :class="['btn btn-default', buttonSize, {active: (value == option.value)}]"
        :id="option.value"
        :disabled="disabled"
        :aria-label="option.ariaLabel"
        type="button"
        @click="changeSelection(option.value)"
        :key="index"
      >
        <i class="fa" :class="option.icon" v-if="!!option.icon" />
        {{ option.label }}
      </button>
    </div>
    <span v-if="helpText">
      <a class="help-popover ml-1" v-tooltip.html="helpText" href="javascript:void(0)">
        <i class="fa fa-info-circle" />
      </a>
    </span>
  </div>
</template>

<script>
  export default {
    name: 'radio-toggle',
    props: {
      title: {
        type: String,
      },
      options: {
        type: Array,
        required: true,
      },
      value: {
        type: String,
        required: true,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      helpText: {
        type: String,
        required: false,
      },
      buttonSize: {
        type: String,
        required: false,
        default: '',
        validator: value => {
          return [
            'btn-xs',
            'btn-sm',
            '', // default/medium width
            'btn-lg',
          ].includes(value)
        },
      },
    },
    data() {
      return {
        changeSelectionEventName: 'selected',
        selected: this.value,
      }
    },
    methods: {
      changeSelection(selection) {
        this.selected = selection

        // Tell the parent we changed
        this.$emit(this.changeSelectionEventName, this.selected)
      },
    },
  }
</script>
