export default Backbone.Model.extend({

  // Is the Workbright field currently matched to a spreadsheet column
  //
  // Returns true if matched
  isMatched: function(){
    return !!this.get('matched')
  },

  // Is the Workbright field required (i.e. must be present for an Employee to exist)
  isRequired: function(){
    return !!this.get('required_for_employee_create')
  },
})
