import RemoteFormValidation from 'lib/remote_form_validation'
import Routes from 'lib/routes'
import FormsUtil from 'lib/util/forms'
import PermanentResidentCitizenshipFieldsView from 'views/document_submissions/i9/permanent_resident_citizenship_fields_view'
import AlienCitizenshipFieldsView from 'views/document_submissions/i9/alien_citizenship_fields_view'

// I-9 Form Citizenship Step
export default Backbone.Marionette.LayoutView.extend({
  template: '#i9-form-step-citizenship-tmpl',

  regions: {
    citizenshipSpecificRegion: '.region-citizenship-specific-fields',
  },

  ui: {
    form: 'form',
  },

  bindings: {
    'input[name="i9_submission[citizenship_designation]"]': 'citizenship_designation',
  },

  modelEvents: {
    'change:citizenship_designation': 'toggleCitizenshipSpecificView',
  },

  onRender: function() {
    this.stickit()
    $.runInitializers(this.$el)

    this.rfv = new RemoteFormValidation({
      resourceName: 'i9_submission',
      model: this.model,
      formEl: this.ui.form,
      validations: {
        'i9_submission[citizenship_designation]': { validators: { wbRemote: {} } },
        // NOTE: All other validations come from citizenship-specific views.
      },
      successCallback: this.onRemoteValidateSuccess.bind(this),
      failedCallback: this.onRemoteValidateFailed.bind(this),
      errorCallback: this.onRemoteValidateError.bind(this),
      path: Routes.staff_i9_submission_path,
    })

    this.toggleCitizenshipSpecificView()
  },

  toggleCitizenshipSpecificView: function() {
    const citizenship = this.model.get('citizenship_designation')
    const region = this.getRegion('citizenshipSpecificRegion')

    const viewOptions = {
      model: this.model,
      rfv: this.rfv,      // Pass along RFV instance so that views can dynamically add their own fields for validation
    }

    // If they change the citizenship, clear any existing errors
    this.rfv.fvPlugin.resetForm()

    switch (citizenship) {
    case 'permanent_resident':
      region.show(new PermanentResidentCitizenshipFieldsView(viewOptions))
      break

    case 'alien':
      region.show(new AlienCitizenshipFieldsView(viewOptions))
      break

    default:
      region.empty()
    }
  },

  validate: function(validationCompleteCallback){
    this.validationCompleteCallback = validationCompleteCallback
    this.ui.form.submit()
  },

  onRemoteValidateSuccess: function() {
    FormsUtil.clearFormInvalidNotification(this.ui.form)
    this.validationCompleteCallback(true)
  },

  onRemoteValidateFailed: function(_response) {
    this.validationCompleteCallback(false)
    FormsUtil.showFormInvalidNotification(this.ui.form)
  },

  onRemoteValidateError: function(_xhr) {
    this.validationCompleteCallback(false)
  },
})
