import Constants from 'lib/constants'


export default Backbone.Model.extend({
  defaults: {
    url: '',            // String
    content_type: '',   // String
    filename: '',       // String
    direct_url: '',     // [Extras] String
  },

  parse: function(data) {
    if (data.direct_url) {
      data.direct_url = encodeURIComponent(data.direct_url)
    }
    
    return data
  },

  // Is the file an image based on its MIME type?
  // 
  // Returns - boolean 
  isImage: function() {
    return this.get('content_type').match(Constants.MIME_REGEX_IMAGE)
  },

  // Is the file a PDF based on its MIME type?
  // 
  // Returns - boolean
  isPdf: function() {
    return this.get('content_type').match(Constants.MIME_REGEX_PDF)
  },
})
