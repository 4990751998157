import SlideoutPanel from 'views/common/slideout_panel_view'
import BulkEmployeeChangeStatusOrphanContainer from 'components/employees/bulk_change_status/BulkEmployeeChangeStatusOrphanContainer'
import BulkUpdateEmployeeGroupsOrphanContainer from 'components/employees/bulk_update_employee_groups/BulkUpdateEmployeeGroupsOrphanContainer'
import BulkResetAssignmentsOrphanContainer from 'components/employees/bulk_reset_assignments/BulkResetAssignmentsOrphanContainer'
import BulkUpdateEmploymentsOrphanContainer from 'components/employments/BulkUpdateEmploymentsOrphanContainer'
import BulkUpdateCustomFieldOrphanContainer from 'components/employees/bulk_update_custom_fields/BulkUpdateCustomFieldsOrphanContainer'
import BulkDownloadsOrphanContainer from 'components/employees/bulk_downloads/BulkDownloadsOrphanContainer'
import { pureOrphanVue } from 'lib/vue/pureOrphanVue'

export default Marionette.Behavior.extend({
  defaults: {
  },

  ui: {
    dropdown: '.dropdown-bulk-actions',
    dropdownToggle: '.dropdown-bulk-actions .dropdown-toggle',
    customFieldBulkUpdateBtn: '.js-bulk-update-custom-fields',
    addEmployeesToGroupBtn: '.js-bulk-add-employees-to-group',
    removeEmployeesFromGroupBtn: '.js-bulk-remove-employees-from-group',
    resetAssignmentsBtn: '.js-bulk-reset-assignments',
    activateEmployeesBtn: '.js-bulk-activate-employees',
    deactivateEmployeesBtn: '.js-bulk-deactivate-employees',
    employmentStatusHeader: '.js-bulk-employment-status-header',
    changeStatusDivider: '.js-bulk-change-status-divider',
    updateEmploymentDatesBtn: '.js-bulk-update-employment-dates',
    downloadSubmissions: '.js-bulk-download-submissions',
  },

  triggers: {
    'click @ui.customFieldBulkUpdateBtn': 'customField:action',
    'click @ui.addEmployeesToGroupBtn': 'addEmployeesToGroup:action',
    'click @ui.removeEmployeesFromGroupBtn': 'removeEmployeesFromGroup:action',
    'click @ui.activateEmployeesBtn': 'activateEmployees:action',
    'click @ui.deactivateEmployeesBtn': 'deactivateEmployees:action',
    'click @ui.resetAssignmentsBtn': 'resetAssignments:action',
    'click @ui.updateEmploymentDatesBtn': 'updateEmployments:action',
    'click @ui.downloadSubmissions': 'downloadSubmissions:action',
  },

  initialize: function () {
    this.collectionView = this.view.getOption('collectionView')
    this.listenTo(this.collectionView, 'rows:selection:changed', this.updateDrowdownBtnState)
  },

  onRender: function () {
    $.runInitializers(this.$el)

    if (gon.isActiveSeason === false) {
      this.ui.employmentStatusHeader.hide()
      this.ui.activateEmployeesBtn.hide()
      this.ui.deactivateEmployeesBtn.hide()
      this.ui.updateEmploymentDatesBtn.hide()
      this.ui.changeStatusDivider.hide()
    }
  },

  updateDrowdownBtnState: function () {
    this.ui.dropdownToggle.toggleClass('disabled', this._noneSelected())
  },

  onCustomFieldAction: function () {
    this.ui.dropdown.removeClass('open')
    this.startVueBulkAction(BulkUpdateCustomFieldOrphanContainer)
  },

  onAddEmployeesToGroupAction: function() {
    this.ui.dropdown.removeClass('open')
    this.bulkUpdateEmployeeGroups('add')
  },

  onRemoveEmployeesFromGroupAction: function() {
    this.ui.dropdown.removeClass('open')
    this.bulkUpdateEmployeeGroups('remove')
  },

  onActivateEmployeesAction: function() {
    this.ui.dropdown.removeClass('open')
    this.bulkChangeEmployeeStatus('activate')
  },

  onDeactivateEmployeesAction: function() {
    this.ui.dropdown.removeClass('open')
    this.bulkChangeEmployeeStatus('deactivate')
  },

  onResetAssignmentsAction: function() {
    this.ui.dropdown.removeClass('open')
    this.startVueBulkAction(BulkResetAssignmentsOrphanContainer)
  },

  // Start workflow to bulk update employment dates
  onUpdateEmploymentsAction: function() {
    this.ui.dropdown.removeClass('open')
    this.startVueBulkAction(BulkUpdateEmploymentsOrphanContainer)
  },

  // Start workflow to bulk download form submissions
  onDownloadSubmissionsAction: function() {
    this.ui.dropdown.removeClass('open')
    this.startVueBulkAction(BulkDownloadsOrphanContainer)
  },

  // Start workflow to update employee status, bulk activate or deactivate
  //
  // action: String, 'activate' or 'deactivate'
  bulkChangeEmployeeStatus(action) {
    this.ui.dropdown.removeClass('open')
    this.startVueBulkAction(BulkEmployeeChangeStatusOrphanContainer, { action: action })
  },

  // Start workflow to update employee groups, bulk add/remove
  //
  // action: String, 'add' or 'remove'
  bulkUpdateEmployeeGroups(action) {
    this.startVueBulkAction(BulkUpdateEmployeeGroupsOrphanContainer, { action: action })
  },

  startVueBulkAction(component, props = {}) {
    // The element gets consumed by pureOrphanVue, so just set it up here. Otherwise, it won't be there when you try to
    // reopen the modal.
    const elementClass = 'vue-bulk-action'
    // Need to append to body, otherwise the modal can have a weird appearance due to placement quirks, like the backdrop in
    // front of the modal dialog.
    $('body').append($('<component/>').addClass(elementClass))

    pureOrphanVue(component, `component.${elementClass}`, {
      props: _.extend(props, {
        employeeIds: this.collectionView.getSelections().map((e) => e.get('id')),
      }),
    }, {
      removeElementOnDestroy: true,
    })
  },

  startSlideout: function(viewType) {
    this.ui.dropdown.removeClass('open')
    this.slideoutPanel = new SlideoutPanel({
      employees: this.collectionView.getSelections(),
      contentView: viewType,
      panelTitle: 'Bulk Action',
    })
    this.slideoutPanel.render()
  },

  _noneSelected: function () {
    return this.collectionView.collection.length == 0 || this.collectionView.getSelectionCount() == 0
  },
})
