import Bugsnag from '@bugsnag/js'

// Proxy class for reporting JavaScript exceptions and error messages to bug tracking service.
// 
// Examples
//    
//    /* Send up an exception w/ extra data */
//    try { 
//      ...
//    } catch (e) {
//      new ErrorService(e).report({ extraDataOne: "x", ... })
//      // Optionally rename the error:
//      // new ErrorService(e, "BetterErrorName").report({ extraDataOne: "x", ... })
//    }
//    
//    /* Send a custom error */
//    new ErrorService("Failed to upload file to DZ bucket", "AWSUploadError").report({ status: xhr.status })

export default class ErrorService {
  // exceptionOrMessage - (Object) The Error object or message String
  // errorName - (String) Optionally override the name of the error (many JS errors are just 'Error')
  constructor(exceptionOrMessage, errorName) {
    this._errorName = errorName
    this._exceptionOrMessage = exceptionOrMessage
  }

  // Sends up the exception along with any optional data. 
  // 
  // NOTE: Bugsnag automatically sends user and account metadata, if applicable, along with breadcrumbs.
  // 
  // data - (Object) Hash of additional data
  report(data) {
    this._reportBugsnag(data)
  }

  /* PRIVATE */

  _reportBugsnag(data) {
    // Add optional name for more information than may be specified by the error message itself
    data.errorName = this._errorName
    let bsError = this._errorName
    let severity = "warning"
    if (this._exceptionOrMessage instanceof Error) {
      bsError = this._exceptionOrMessage
      severity = "error"
    }

    const onError = event => {
      event.addMetadata('custom', data)
      event.severity = severity
    }
    // Surface manual calls to `notify` so they don't get swallowed by BugSnag
    const postReportCallback = (...args) => {
      if (gon.global.rails_env === "development") {
        console.log("BUGSNAG NOTIFY:", args)
      }
    }
    Bugsnag.notify(bsError, onError, postReportCallback)
  }
}
