<template>
  <div>
    <template v-if="hasAssignments">
      <table
        class="table table-striped"
        ref="assignments"
      >
        <thead>
          <tr>
            <th>
              <div
                v-if="hasSelectableAssignments"
                ref="assignmentMultiSelect"
                class="row-selectors btn-group"
              >
                <div class="btn btn-default btn-xs dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                  <WbIcon
                    type="chevron-down"
                    class="right"
                  />
                </div>
                <ul class="dropdown-menu" role="menu">
                  <li class="dropdown-header">Select:</li>
                  <li>
                    <a href="javascript:void(0)" @click="handleSelection" data-value="all">All</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" @click="handleSelection" data-value="none">None</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" @click="handleSelection" data-value="ready-for-section-2" >Ready for Section 2</a>
                  </li>
                </ul>
              </div>
            </th>
            <th
              class="sortable"
              :class="getSortClassName('name')"
              role="button"
              @click="handleSortAction('name')"
            >
              Name
            </th>
            <th
              class="sortable"
              :class="getSortClassName('received')"
              role="button"
              @click="handleSortAction('received')"
            >
              Received
            </th>
            <th>Link</th>
            <th>
              Status
            </th>
            <th>Expiration Date</th>
            <th
              v-if="shouldShowVerificationStatus"
              ref="verificationStatus"
            >
              Verification Status
            </th>
            <th>
              <span class="sr-only">Audit Log</span>
            </th>
            <th
              v-if="hasRestartableAssignments"
              ref="additionalActions"
            >
              <span class="sr-only">Additional Actions</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <I9InboxAssignmentItem
            v-for="assignment in assignments"
            :key="assignment.id"
            :assignment="assignment"
            :shouldShowAdditionalActions="hasRestartableAssignments"
            :shouldShowVerificationStatus="shouldShowVerificationStatus"
          />
        </tbody>
      </table>
      <SubmissionAuditLogContainer />
    </template>
    <div
      v-else
      ref="noAssignments"
      class="alert alert-info"
    >
      No I-9 Forms
    </div>
  </div>
</template>
<script>
  import I9InboxAssignmentItem from 'components/i9/employer_review/I9InboxAssignmentItem'
  import SubmissionAuditLogContainer from 'components/document_submissions/SubmissionAuditLogContainer'
  import SelectableI9Assignment from 'components/mixins/i9/SelectableI9Assignment'
  import WbIcon from 'components/common/WbIcon'
  import { mapActions } from 'vuex'

  export default {
    components: {
      I9InboxAssignmentItem,
      SubmissionAuditLogContainer,
      WbIcon,
    },

    mixins: [
      SelectableI9Assignment,
    ],

    props: {
      assignments: {
        type: Array,
        required: true,
      },
      shouldShowVerificationStatus: {
        type: Boolean,
        default: false,
      },
      sort: {
        type: Object,
        default: () => ({}),
      },
    },

    emits: [
      'sort',
    ],

    computed: {
      hasAssignments () {
        return this.assignments && this.assignments.length > 0
      },

      hasRestartableAssignments () {
        return this.assignments.some(assignment => assignment.can_restart_remote_i9)
      },

      hasSelectableAssignments () {
        return this.assignments.some(assignment => this.checkAssignmentSelectable(assignment))
      },
    },

    methods: {
      ...mapActions({
        unselectAllAssignments: 'i9_inbox_assignments/unselectAllAssignments',
        selectAllAssignmentsReady: 'i9_inbox_assignments/selectAllAssignmentsReady',
      }),
      getSortClassName (field) {
        return this.sort.field === field ? [`sort-${this.sort.direction}`] : []
      },
      handleSortAction (field) {
        this.$emit('sort', field)
      },
      handleSelection (event) {
        if (event.target.dataset.value == 'none') {
          this.unselectAllAssignments()
        } else {
          this.selectAllAssignmentsReady()
        }
      },
    },
  }
</script>
