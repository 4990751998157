// This module includes Rails Routes pre-processed via ERB
import _mock_routes  from 'lib/_mock_routes'
import { get } from 'underscore'

const routes = window.ErbRoutes || _mock_routes

// Rather than referencing Routes directly, use this method so that we can mock it in our tests and get away from having to mock
// all of our routes via _mock_routes
// Usage: Routes.Api.submission_path becomes getRoute('Api.submission_path')
// If you are expecting a method: getRoute('Api.submission_path', params)
export const getRoute = (path, params) => {
  const routeObject = window.ErbRoutes || {}
  let route = get(routeObject, path.split('.'))

  if (typeof route === 'function') {
    route = route(params)
  }

  return route
}

export default routes
