import BaseDocumentSubmission from 'views/document_submissions/base_document_submission_view'
import AsyncItemView from 'views/lib/async_itemview'

export default class AsyncDocumentSubmissionView extends AsyncItemView.extend(BaseDocumentSubmission) {
  onShow() {
    this.renderUI()
    this.renderSubmissionDetails()
  }

  renderSubmissionDetails() {
    if (this.$el.find('#the-submission-audit-log-root')) {
      window.startRootVue('the-submission-audit-log-root', {
        pageContext: {},
        initialData: {},
        propsData: {
          submission: gon.document_submission,
        },
      })
    }
  }

  // REVIEW: The only override here is the secondaryActions attribute which enables the
  // secondary actions of the Historical Submission list items. This is disabled currently because permissions
  onOpenHistoricalSubmissions() {
    this.openHistoricalSubmissionsSlideout({
      show_historical_submissions_assignment_id: this.options.assignment.id,
      secondaryActionsEnabled: false,
    })
  }
}
