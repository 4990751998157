import PrerenderedTableRowItemView from 'src/views/common/prerendered_table_row_item_view'

export default PrerenderedTableRowItemView.extend({
  ui: {
    deleteAction: '.js-delete',
    downloadAction: '.js-download',
    viewAction: '.js-view',
    editAction: '.js-edit',
  },
  triggers: {
    'click @ui.deleteAction': 'supplementalFile:delete',
    'click @ui.downloadAction': 'supplementalFile:download',
    'click @ui.viewAction': 'supplementalFile:view',
    'click @ui.editAction': 'supplementalFile:edit',
  },
})
