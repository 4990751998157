<template>
  <div class="pb-4">
    <h3>Referred</h3>
    <p>
      Your status with E-Verify is now <em>Referred</em>, meaning you have chosen to work with DHS or SSA to resolve any mismatches between the data on your Form I-9 and the data fields found at E-Verify. 
    </p>
    <p>
      You must initiate contact with DHS or SSA by the date indicated in your <a :href="url" target="_self" download>Referral Date Confirmation (RDC) Notice</a>.
    </p>
    <p>
      Below you will find the Further Action Notice (FAN) that outlines the data discrepancy.
    </p>
  </div>
</template>

<script>

  export default {
    props: {
      url: {
        type: String,
        required: true,
      },
    },
  }
</script>
