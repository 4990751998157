/* global gon */
import PubSub from 'lib/pub_sub'

// PubSub Initializer
// Sets configuration based on window.gon values
//
$(document).on('ready', () => {
  const use_fake_pusher = ['development', 'test'].includes(gon.global.rails_env)

  PubSub.init({
    useLocalPusherFake: use_fake_pusher,
    pusherKey: gon.global.JS_ENV.PUSHER_KEY,
    pusherCluster: gon.global.JS_ENV.PUSHER_CLUSTER,
    accountSubdomain: gon.current_account.subdomain,
    userToken: gon.current_user.pubsub_token,
  })
})
