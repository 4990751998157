<template>
  <div>
    <h2>Edit Company Profile</h2>

    <div class="form-horizontal panel profile">
      <FormFieldInput
        ref="companyName"
        orientation="horizontal"
        label="Company Name"
        :errors="formErrors"
        name="name"
        type="text"
        v-model="formData.name"
        :required="true"
      />

      <FormFieldInput
        ref="street"
        orientation="horizontal"
        label="Address"
        :errors="formErrors"
        name="street"
        type="text"
        v-model="formData.address.street"
      />

      <FormFieldInput
        ref="city"
        orientation="horizontal"
        label="City"
        :errors="formErrors"
        name="city"
        type="text"
        v-model="formData.address.city"
      />

      <FormFieldSelect
        ref="state"
        orientation="horizontal"
        label="State"
        :errors="formErrors"
        name="state"
        :options="states"
        :value="formData.address.state"
        v-model="formData.address.state"
      />

      <FormFieldInput
        ref="zip"
        orientation="horizontal"
        label="Zip Code"
        :errors="formErrors"
        name="zip"
        type="text"
        v-model="formData.address.zip"
      />

      <FormFieldInput
        v-if="einFieldVisible"
        ref="ein"
        orientation="horizontal"
        label="EIN"
        :errors="formErrors"
        name="settings_ein"
        type="text"
        v-model="formData.settings_ein"
      />

      <FormFieldSelect
        ref="timezone"
        orientation="horizontal"
        label="Time Zone"
        :errors="formErrors"
        name="settings_time_zone"
        :options="timezones"
        v-model="formData.settings_time_zone"
        :required="true"
      />

      <div class="row">
        <div class="col-sm-offset-4 col-md-offset-3 col-sm-7">
          <LaddaButton
            ref="submit"
            class="form-field"
            @click="saveButtonClicked"
          >
            Save
          </LaddaButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import FormFieldInput from 'components/common/FormFieldInput'
  import FormFieldSelect from 'components/common/FormFieldSelect'
  import LaddaButton from 'components/common/LaddaButton'

  export default {
    name: 'edit-company-profile-container',

    components: {
      FormFieldInput,
      FormFieldSelect,
      LaddaButton,
    },

    props: {
      einFieldVisible: {
        type: Boolean,
        default: false,
      },

      companyProfileData: {
        type: Object,
        default: () => {},
      },

      states: {
        type: Array,
        default: () => [],
      },

      timezones: {
        type: Array,
        default: () => [],
      },

      formErrors: {
        type: Object,
        default: () => {},
      },
    },

    data () {
      return {
        formData: {},
      }
    },

    created() {
      this.formData = {...this.companyProfileData}
      if (!this.formData.address) {
        this.formData.address = {}
      }
    },

    methods: {
      saveButtonClicked(){
        this.$emit('wb-save-company-profile', {...this.formData})
      },
    },
  }
</script>
