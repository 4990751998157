import UserImportPageHeader from 'components/user_imports/UserImportPageHeader'
import { pureOrphanVue } from 'lib/vue/pureOrphanVue'

export default Marionette.LayoutView.extend({
  template: '#layout-employee-validation',
  
  regions: {
    validationMessageRegion: '.validation-message',
    employeeTableRegion: '.employee-table',
    uploaderRegion: '.uploader',
    importConfirmRegion: '.import-confirm',
    reuploadStartRegion: '.reupload-start',
  },

  onRender: function() {
    pureOrphanVue(UserImportPageHeader, "component.vue-user-import-header", {
      props: {
        wizardStep: 'validate',
      },
    })
  },
})
