import BaseSystemEvent from 'models/system_events/base_system_event'

export default class EmployeeI9SubmittedEvent extends BaseSystemEvent{
  constructor(documentVerificationResult) {
    super()

    this.documentVerificationResult = documentVerificationResult
  }

  get key() {
    return 'employee.i9_submission.submitted'
  }

  payload() {
    return {
      document_verification_result: this.documentVerificationResult,
      event_key: this.key,
    }
  }
}
