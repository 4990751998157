<template>
  <ListItem
    :item="event.id"
    :secondaryActions="false"
  >
    <div class="wb-list__content">
      <div class="wb-list__title-slideout">
        <div class="mb-3">
          <WbSimpleDateTime :value="event.server_timestamp" :format="dateFormat" />
        </div>
        <span class="d-block">
          <strong>
            {{ title }}
          </strong>
        </span>
        <span class="d-block" ref="adminName" v-if="adminName">
          by {{ adminName }}
        </span>
        <SubmissionAuditLogItemAuthRepSection :event="event" />
      </div>
    </div>
  </ListItem>
</template>
<script>
  import Constants from 'lib/constants'
  import ListItem from 'components/common/ListItem'
  import WbSimpleDateTime from 'components/common/WbSimpleDateTime'
  import StringUtil from 'lib/util/string'
  import SubmissionAuditLogItemAuthRepSection from 'components/document_submissions/SubmissionAuditLogItemAuthRepSection'

  export default {
    components: {
      SubmissionAuditLogItemAuthRepSection,
      ListItem,
      WbSimpleDateTime,
    },

    props: {
      event: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        dateFormat: Constants.DATE_EXT_NO_WEEKDAY_FORMAT,
      }
    },

    computed: {
      adminName () {
        return this.event.details?.employer_or_authrep_name || this.event.details?.actor_name
      },

      // Use the audit log's title, if available. If not, convert the audit log's name into human readable title.
      title(){
        if (this.event.title) {
          return this.event.title
        } else if (this.event.name) {
          return StringUtil.humanize(this.event.name)
        } else {
          return ''
        }
      },
    },
  }
</script>
