function getCurrentValuesFromSelectOptions(selectEl) {
  const ids = []
  const currentOptions = selectEl.options
  for (let i = 0; i < currentOptions.length; i++) {
    ids.push(currentOptions[i].value)
  }
  return ids
}

async function createDeviceOptions(uiElementsCollection) {
  startLoading(uiElementsCollection)
  hideDZButtons(uiElementsCollection)

  await navigator.mediaDevices.getUserMedia({audio: false, video: true})
  const devicesInfo = await navigator.mediaDevices.enumerateDevices()
  const { videoSelect } = uiElementsCollection
  const currentIDs = getCurrentValuesFromSelectOptions(videoSelect)

  devicesInfo.forEach(device => {
    if (currentIDs.includes(device.deviceId)) { return }

    const option = document.createElement('option')
    option.value = device.deviceId
    if (device.kind === 'videoinput') {
      option.text = device.label || `Camera ${videoSelect.length}`
      videoSelect.appendChild(option)
    }
  })

  stopLoading(uiElementsCollection)
  showDZButtons(uiElementsCollection)
}

async function getStream(event, dropzone, uiElementsCollection) {
  const options = { audio: false, video: false }
  const { videoFrame } = uiElementsCollection
  const videoSource = event.target.value

  closeMediaStreams(dropzone.$mediaStream)

  if (videoSource) {
    options["video"] = { deviceId: { exact: videoSource } }
  }
  try {
    const stream = await navigator.mediaDevices.getUserMedia(options)
    dropzone.$mediaStream = videoFrame.srcObject = stream
    dropzone.$imageCapture = new ImageCapture(stream.getVideoTracks()[0])
    openTakePhotoFrame(uiElementsCollection)
  } catch {
    closeTakePhotoFrame(uiElementsCollection)
  }
}

async function createCanvasFromImageCapture(imageCapture) {
  const imageBitMap = await imageCapture.grabFrame()
  const canvas = document.createElement('canvas')
  canvas.width = imageBitMap.width
  canvas.height = imageBitMap.height
  const ctx = canvas.getContext('bitmaprenderer')
  ctx.transferFromImageBitmap(imageBitMap)
  return canvas
}

async function takePhoto(event, dropzone, uiElementsCollection) {
  event.preventDefault()
  if (!dropzone.$imageCapture) { return }

  const canvas = await createCanvasFromImageCapture(dropzone.$imageCapture)
  const blob = await new Promise(res => canvas.toBlob(res))
  const file = new File([blob], 'photo-taken.jpeg', { type: blob.type })

  dropzone.addFile(file)
  closeTakePhotoFrame(uiElementsCollection)
}

function openTakePhotoFrame(uiElementsCollection) {
  const { videoFrame, takePhotoButton } = uiElementsCollection
  videoFrame.removeAttribute('hidden')
  takePhotoButton.removeAttribute('hidden')
}

function closeTakePhotoFrame(uiElementsCollection) {
  const { videoFrame, takePhotoButton } = uiElementsCollection
  videoFrame.setAttribute('hidden', 'true')
  takePhotoButton.setAttribute('hidden', 'true')
}

function closeDZButtons(uiElementsCollection) {
  const { dzButton1, dzButton2 } = uiElementsCollection
  dzButton1.removeAttribute('open')
  dzButton2.removeAttribute('open')
}

export function showDZButtons(uiElementsCollection) {
  const { dzButton1, dzButton2 } = uiElementsCollection
  dzButton1.removeAttribute('hidden')
  dzButton2.removeAttribute('hidden')
}

export function hideDZButtons(uiElementsCollection) {
  const { dzButton1, dzButton2 } = uiElementsCollection
  dzButton1.setAttribute('hidden', 'true')
  dzButton2.setAttribute('hidden', 'true')
  closeDZButtons(uiElementsCollection)
}

export function resetSelectOptions(uiElementsCollection) {
  const { videoSelect } = uiElementsCollection
  videoSelect.getElementsByTagName('option')[0].selected = 'selected'
}

export function startLoading(uiElementsCollection) {
  const { loadingEl } = uiElementsCollection
  loadingEl.removeAttribute('hidden')
}

export function stopLoading(uiElementsCollection) {
  const { loadingEl } = uiElementsCollection
  loadingEl.setAttribute('hidden', 'true')
}

export function closeMediaStreams(streams) {
  if (!streams) { return }

  streams.getTracks().forEach(track => track.stop())
}

async function toggleOptions(e, dropzone, uiElementsCollection) {
  e.preventDefault()

  const { takePhotoElement } = uiElementsCollection
  if (takePhotoElement.open) {
    resetSelectOptions(uiElementsCollection)
    takePhotoElement.removeAttribute('open')
    closeTakePhotoFrame(uiElementsCollection)
    closeMediaStreams(dropzone.$mediaStream)
    stopLoading(uiElementsCollection)
  } else {
    await createDeviceOptions(uiElementsCollection)
    takePhotoElement.setAttribute('open', 'true')
  }
}

export function initTakePhoto(el, dropzone) {
  const takePhotoElement = el.querySelector("details[data-dz-section='takePhoto']")
  const takePhotoOption = takePhotoElement?.querySelector('summary')
  const takePhotoButton = takePhotoElement?.querySelector('.takePhoto')
  const videoFrame = takePhotoElement?.querySelector('.photoPreview')
  const videoSelect = takePhotoElement?.querySelector('.videoSource')
  const loadingEl = $(el).find('.dropzoneLoading')[0]
  const optionsUI = {}
  const dzButtons = $(el).find('details')
  for (let i = 0; i < dzButtons.length; i++) {
    optionsUI[`dzButton${i + 1}`] = dzButtons[i]
  }

  const uiElementsCollection = {takePhotoButton, videoFrame, videoSelect, takePhotoElement, loadingEl, ...optionsUI}

  takePhotoOption?.addEventListener('click',  (e) => toggleOptions(e, dropzone, uiElementsCollection))
  videoSelect?.addEventListener('change', (e) => getStream(e, dropzone, uiElementsCollection))
  takePhotoButton?.addEventListener('click', (e) => takePhoto(e, dropzone, uiElementsCollection))

  return uiElementsCollection
}
