$(document).on('page:change.global', function() {
  // data spy affix only works on window load in BS 3.0
  $('[data-spy="affix"]').each(function () {
    var $spy = $(this)
    var data = $spy.data()

    data.offset = data.offset || {}

    if (data.offsetBottom) data.offset.bottom = data.offsetBottom
    if (data.offsetTop)    data.offset.top    = data.offsetTop

    $spy.affix(data)
  })

  // Hide popovers 
  // Side effect: The popover-link each iterator below will get called as many times as the event bubbles up. Future
  // improvement would be to prevent the 'each' function below from running again once the popover has aleady been dismissed. 
  $(':not(#anything)').on('click', function (e) {
    $('.popover-link').each(function () {
      if (!$(this).data("bs.popover"))
        return

      //the 'is' for buttons that trigger popups
      //the 'has' for icons and other elements within a button that triggers a popup
      if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
        $(this).data("bs.popover").hide()
      }
    })
  })
  $(document).on('hidden.bs.popover', function(e) {
    var $popover = $(e.target).data('bs.popover')
    // HACK: not sure why, but popovers which are closed manually (or via the Hide Popovers effect above)
    // are not detached from the DOM... sometimes.
    if ($popover && $popover.tip())
      $popover.tip().detach()
  })
})

// HACKFIX for stacked modals; this is cobbled together from:
// • http://stackoverflow.com/questions/19305821/bootstrap-3-0-multiple-modals-overlay?answertab=active#tab-top
// • http://gurde.com/stacked-bootstrap-modals/
$(document).on('show.bs.modal', '.modal', function(event) {
  if (typeof($('body').data('open-modal-count')) == 'undefined') {
    $('body').data('open-modal-count', 0)
  }

  var $this = $(this)

  // HACKFIX: for some reason this function fires when a modal closes for every modal on the page at that time. No idea
  // why that happens. This check circumvents unwanted behavior.
  if ($this.hasClass('.modal-stack-managed'))
    return

  var zIndex = 1050 + ($('body').data('open-modal-count') + 2)
  $this.css('zIndex', zIndex).addClass('.modal-stack-managed')
  setTimeout(function() {
    $this.next('.modal-backdrop').css('zIndex', zIndex - 1)
  }, 0)
})
$(document).on('hide.bs.modal', '.modal', function (event) {
    var paddingRight = 0
    $('.modal.in').each(function() {
      if($(this).modal().data('bs.modal').scrollbarWidth > paddingRight) {
        paddingRight = $(this).modal().data('bs.modal').scrollbarWidth
      }
    })
    $('body').data('paddingRight', paddingRight + 'px')
})
$(document).on('hidden.bs.modal', '.modal', function (event) {
  $('body').data('open-modal-count', $('body').data('open-modal-count') - 1)
  if($('body').data('open-modal-count') > 0) {
    $('body').addClass('modal-open')
    $('body').css('padding-right', $('body').data('paddingRight'))
  }
})
$(document).on('shown.bs.modal', '.modal', function (event) {
  $('body').data('open-modal-count', $('body').data('open-modal-count') + 1)
  $('body').css('padding-right', (parseInt($('body').css('padding-right')) / $('body').data('open-modal-count') + 'px'))
})

